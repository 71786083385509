import invoiceTypes from "./invoice.types";

const INITIAL_STATE = {
    invoices: [],
    customerInvoices: [],
    isLoading: false
};

const invoiceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case invoiceTypes.FETCH_INVOICES_START:
            return {
                ...state,
                isLoading: true
            }
        case invoiceTypes.FETCH_INVOICES:
            return {
                ...state,
                isLoading: false,
                invoices: action.payload
            };
        case invoiceTypes.FETCH_CUSTOMER_INVOICES:
            return {
                ...state,
                isLoading: false,
                customerInvoices: action.payload
            };
        default:
            return state
    }
};

export default invoiceReducer;
