import paymentsTypes from "./payments.types";

const INITIAL_STATE = {
    payments: [],
    customerPayments: [],
    isLoading: false
};

const paymentsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case paymentsTypes.FETCH_PAYMENTS_START:
            return {
                ...state,
                isLoading: true
            }
        case paymentsTypes.FETCH_PAYMENTS:
            return {
                ...state,
                isLoading: false,
                payments: action.payload
            }
        case paymentsTypes.FETCH_PAYMENTS_BY_CUSTOMER:
            return {
                ...state,
                isLoading: false,
                customerPayments: action.payload
            }
        default:
            return state
    }
};

export default paymentsReducer;
