import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';
import {parse} from 'fast-time-input';
import ReactModal from 'react-modal';

const DateTimePicker = ({title = '', modal = true, date, onCancelClicked, onDateSubmitted, hideTime = false}: {hideTime?: boolean, title?: string, modal?: boolean, date: Date | Date[], onCancelClicked: (evt: any) => void, onDateSubmitted: (date: any) => void}) => {


    const inputTime = useRef<HTMLInputElement>();
    const inputTime1 = useRef<HTMLInputElement>();
    const [tempTime, setTempTime] = useState('');
    const [tempTime1, setTempTime1] = useState('');
    const [isRange, setIsRage] = useState(false);

    const [myDate, setMyDate] = useState(
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        });


        function getDateFormatted(_date: Date, _time: string)
        {
            let time = '';
            let temp = _time.split(':');
            let temp2 = temp[1].split(" ");
            temp[1] = temp2[0];
            temp[2] = temp2[1];

            if (temp[2] != 'AM' && temp[0] != '12')
                temp[0] = (Number(temp[0]) + 12).toString();
            else if (temp[2] == 'AM' && temp[0] == '12')
                temp[0] = '00';


            time = `${temp[0]}:${temp[1]}:00`;

            return new Date(moment(_date).format('YYYY/MM/DD ') + time);
        }
    useEffect(() => {
        let isrange = Array.isArray(date);
        console.log('HERE');
        setMyDate({...myDate, startDate: Array.isArray(date) ?  new Date(date[0]) : new Date(date), endDate:  Array.isArray(date) ?  new Date(date[1]) : new Date(date)});
        setTempTime(parse(moment(Array.isArray(date) ? date[0] : date).format('hh:mm A')));
        if (Array.isArray(date))
            setTempTime1(parse(moment(date[1]).format('hh:mm A')));
        setIsRage(isrange);
    }, [date]);

    function handleSelect(ranges: any){

        if (!isRange)
        {
            let value;
            if (moment(ranges.selection.endDate).format('YYYY/MM/DD') != moment(myDate.endDate).format('YYYY/MM/DD') )
            {
                value = ranges.selection.endDate;
            }
            else
            {
                value = ranges.selection.startDate;
            }


            setMyDate({
                key: 'selection',
                startDate: value,
                endDate: value
            });
        }
        else
        {
            console.log('here');
            setMyDate({...myDate, ...{
                startDate: ranges.selection.startDate,
                endDate: ranges.selection.endDate
            }})
        }
      }

    const getContent = () => {
        return <div onClick={(evt) => evt.stopPropagation()}>

            {title && <h4 className='title-text mb-3' style={{fontSize: '1.2rem'}}>{title}</h4>}
         <div className={modal ? `modal-content p-3` : ''} style={{width: !modal ? '100%' : 'fit-content', height: 'auto'}}>
            <div>
                <h4 className='title-text' style={{fontSize: '1rem'}}>Date</h4>
                <div className='mx-auto' style={{width: 'fit-content'}}>
                    <DateRange
                            showDateDisplay={false}
                            showPreview={false}
                            ranges={[myDate]}
                            onChange={handleSelect}

                        />
                </div>
            </div>
            {!hideTime && <div className="row">
            <div className={'form-group col-' + (isRange ? '6' : '12')}>
                <h4 className='title-text mb-2' style={{fontSize: '1rem'}}>Time{isRange ? ' Start' : ''}</h4>
                <input className='form-field'
                type='text'
                ref={inputTime}
                onSelect={(evt) => evt.preventDefault()}
                onFocus={(evt) => {
                    evt.target.select()
                }}
                value={tempTime}
                onBlur={(evt) => setTempTime(parse(evt.target.value.trim() ? evt.target.value : '12:00am'))}
                onKeyPress={(evt) => {
                    if (evt.which == 13)
                    {

                        setTempTime(parse(tempTime));
                        setTimeout(() => {

                            inputTime.current?.select();
                        }, 100);
                    }
                }}
                onChange={(evt) => setTempTime(evt.target.value)}
                />
            </div>
            {
                isRange &&
                <div className='form-group col-6'>
                <h4 className='title-text mb-2' style={{fontSize: '1rem'}}>Time end</h4>
                <input className='form-field'
                type='text'
                ref={inputTime1}
                onSelect={(evt) => evt.preventDefault()}
                onFocus={(evt) => {
                    evt.target.select()
                }}
                value={tempTime1}
                onBlur={(evt) => setTempTime1(parse(evt.target.value.trim() ? evt.target.value : '12:00am'))}
                onKeyPress={(evt) => {
                    if (evt.which == 13)
                    {

                        setTempTime1(parse(tempTime1));
                        setTimeout(() => {

                            inputTime1.current?.select();
                        }, 100);
                    }
                }}
                onChange={(evt) => setTempTime1(evt.target.value)}
                />
            </div>
            }
            </div>
            }
            <div className='d-flex justify-content-between mt-3'>
                <button className="btn btn-clear text-blue-light col-6 py-2" onClick={onCancelClicked}>CANCEL</button>
                <button className="btn btn-orange col-6 py-2" onClick={(evt) => {

                    if (isRange){
                        let one = getDateFormatted(myDate.startDate, tempTime);
                        let two = getDateFormatted(myDate.endDate, tempTime1);
                        let start = one.getTime() < two.getTime() ? one : two;
                        let end = one.getTime() > two.getTime() ? one : two;
                        onDateSubmitted([start, end]);
                    }
                    else
                        onDateSubmitted(getDateFormatted(myDate.startDate, tempTime));
                }}>ACCEPT</button>
            </div>
         </div>
        </div>
    }

    return !modal ? getContent() : <ReactModal isOpen={true} className={'modal-fit'}>
        {getContent()}
    </ReactModal>;

}

export default React.memo(DateTimePicker);
