import taxType from "./tax.type";

export const setTaxes: any = taxes => ({
    type: taxType.SET_TAXES,
    payload: taxes
});

export const fetchTaxesStart: any = () => ({
    type: taxType.FETCH_TAXES_START
});

export const addTaxStart = taxData => ({
    type: taxType.ADD_TAX_START,
    payload: taxData
});

export const updateTaxStart = taxData => ({
    type: taxType.UPDATE_TAX_START,
    payload: taxData
});

export const stopTaxes: any = () => ({
    
})
