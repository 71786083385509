import { Customer } from '../../classes/classes';
import IReducerAction from '../models/IReducerAction';
import
{
    GET_CUSTOMERS_STARTED,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS_ERROR
} from '../types/customerSelection.type';


interface IState {
    customers: Customer[],
    error: string,
    loading: boolean,
    customerEdit?: Customer
}

const initalState: IState = {
    customers: [],
    error: null,
    loading: false,
    customerEdit: null
}



export default (state: IState = initalState, action: IReducerAction): IState =>
{   
    switch (action.type)
    {
        case GET_CUSTOMERS_STARTED:
            return {
                ...state,
                loading: true
            }
        case GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                customers: action.payload
            }
        case GET_CUSTOMERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};