import IReducerAction from "../models/IReducerAction";
import { GET_DAILY_REPORT_ERROR, GET_DAILY_REPORT_STARTED, GET_DAILY_REPORT_SUCCESS, GET_PICKUPS_REPORT_ERROR, GET_PICKUPS_REPORT_STARTED, GET_PICKUPS_REPORT_SUCCESS, GET_TAKEINS_REPORT_ERROR, GET_TAKEINS_REPORT_STARTED, GET_TAKEINS_REPORT_SUCCESS } from "../types/dailyReport.type";

export {};

interface IState {
    loading: boolean,
    error: string,
    reports: any[],
    takeIns: any[],
    pickUps: any[],
    beginingOfDay: string
}

const initialState: IState = {
    loading: false,
    error: null,
    reports: [],
    takeIns: [],
    pickUps: [],
    beginingOfDay: null
};

export default function dailyReport(state: IState = initialState, action: IReducerAction): IState
{
    switch (action.type)
    {
        case GET_DAILY_REPORT_STARTED:
            return {
                ...state,
                loading: true,
                beginingOfDay: action.payload
            }
        case GET_DAILY_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                reports: action.payload
            }
        case GET_DAILY_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case GET_TAKEINS_REPORT_STARTED:
            return {
                ...state,
                loading: true
            }
        case GET_TAKEINS_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                takeIns: action.payload
            }
        case GET_TAKEINS_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case GET_PICKUPS_REPORT_STARTED:
            return {
                ...state,
                loading: true
            }
        case GET_PICKUPS_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                pickUps: action.payload
            }
        case GET_PICKUPS_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}