import ordersTypes from './orders.types';

const INITIAL_STATE = {
    customerOrders: [],
    isLoading: false
};

const orderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ordersTypes.SET_ORDERS_BY_CUSTOMER:
            return {
                ...state,
                isLoading: false,
                customerOrders: action.payload,
            };
        default:
            return state;
    }
};

export default orderReducer;
