import { gql } from "apollo-boost";

export const open_orders = gql`query {open_orders{
    charge
    balance
    tickets
    order_id
    payments
    ordered_on
    pickup_date
    order_number
    customer_id
    customer_name
    customer_email
    customer_phone
    completed
}}`;

export const orders_by_dates = gql`query($start:String, $end:String){
    orders_by_dates(start: $start, end: $end) {
    charge
    balance
    tickets
    order_id
    payments
    ordered_on
    pickup_date
    order_number
    customer_id
    customer_name
    customer_email
    customer_phone
    completed
}
}`;

export const get_terminals = gql`query {get_terminals{
    id
    name
    hsn
}}`;

export const get_service_selection = gql`query ($servicegroupid: Int, $selection: [chargesSP]){
get_service_selection (servicegroupid: $servicegroupid, selection: $selection){
   id
   cost
   icon
   count
   name
   tax_id 
   }
}`;

export const create_order = gql`mutation ($order:addOrder){
    create_order(input: $order){
        id
    }
}`;

export const process_charge = gql`mutation ($order_id: Int! $hsn:String!){
process_charge(order_id: $order_id, hsn:$hsn){
  transaction_id
}
}`;

export const merge_orders = gql`mutation ($order_ids: [Int]){
    merge_orders(order_ids:$order_ids){
        id
        success
        message
    }
}`;

export const manual_input = gql`mutation ($input: String){
  manual_input(input:$input){
    id
    transaction_id
    type
    amount
  }
}`;

export const payment_request = gql`mutation ($order_id: Int, $email: String, $phone: String){
    payment_request(order_id: $order_id, email: $email, phone: $phone){
        id
        order_reference
        phone
        email
        name
        token
        note
        amount
        time
        amount_remaining
    }}`;