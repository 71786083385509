import Helper from "../../../../Helper/Helper";
import {useState} from "react";
import {Tax} from "../../../../classes/classes";
import {useDispatch} from "react-redux";
import {addTaxStart, updateTaxStart} from "../../../../saga/Tax/tax.actions";
import ICONS from "../../../../../assets/svg";

export const TaxModal = ({title = "", currentTax = undefined, onCloseModal}) => {

    const dispatch = useDispatch();
    const [myTax, setMyTax] = useState(currentTax ? currentTax : new Tax());
    const [showError, setShowError] = useState('');

    const closeModal = (event) => {
        event.stopPropagation();
        onCloseModal();
    };

    const saveTax = (event) => {
        event.stopPropagation();

        if (myTax.name == '') {
            setShowError('Tax name can not be empty');
            return;
        }
        if (myTax.amount < 0) {
            setShowError('Amount can not be less than zero');
            return;
        }

        setShowError('');

        if (currentTax) {
            myTax.id = currentTax.id;
            dispatch(updateTaxStart(myTax));
        } else {
            dispatch(addTaxStart(myTax));
        }
        onCloseModal();
    };

    return (
        <>
            <div className='modal-container'>
                <div className='modal-component'>
                    <div className="modal-body">

                        <div className="modal-title">
                            <div className={'header'}>
                                <h6 className="title-text">{ title }</h6>
                                <img src={ ICONS.IconCloseBig } onClick={closeModal} alt=""/>
                            </div>
                        </div>

                        <div className="body">
                            <form>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label className="m-0">Tax Name*</label>
                                            <input type="text"
                                                   value={ myTax.name }
                                                   className="form-field"
                                                   placeholder="Tax Name"
                                                   onChange={(event) => setMyTax({...myTax, name: event.target.value})}/>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="form-group mb-3">
                                            <label>Amount*</label>
                                            <input type="number"
                                                   value={ myTax.amount }
                                                   className="form-field"
                                                   placeholder="$ 00.00"
                                                   onChange={(event) => setMyTax({...myTax, amount: +event.target.value})}/>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <p className="text-red">{showError}</p>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="modal-footer">
                            <div className="row">
                                <div className="col-6">
                                    <button className={'btn btn-clear btn-block rounded-pill'} onClick={closeModal}>Cancel</button>
                                </div>

                                <div className="col-6">
                                    <button className={'btn btn-orange btn-block rounded-pill'}
                                            disabled={myTax.name == '' || myTax.amount < 0}
                                            onClick={saveTax}>Save Tax</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
