import gql from "graphql-tag";

export const GET_DAILY_REPORT = gql`
    query ($beginingOfDay:String){
  daily_report(beginingOfDay:$beginingOfDay){
    time
    amount
    status
    payment_id
    order_number
    transaction_number
    type
    payment_number
    order_id
    customer_name
  }
}
`