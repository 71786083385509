import Table from "../../../components/Table/Table";
import IColumn from "../../../components/Table/models/IColumn";
import {useDispatch, useSelector} from "react-redux";
import {fetchInvoicesStart} from "../../../saga/Invoice/invoice.actions";
import {useEffect, useRef, useState} from "react";
import React from "react";
import Helper from "../../../Helper/Helper";
import ICONS from "../../../../assets/svg";

const mapState = ({invoicesData}) => ({
    invoices: invoicesData.invoices
});

const InvoicesTable = ({invoicesData, dates}: {invoicesData?, dates?: Date[]}) => {
    const dispatch = useDispatch();

    const wasLoaded = useRef(false);

    

    const  {invoices, isLoading}=  useSelector<any>(x => x.invoicesData) as any;
  
    async function getInvoices() {
        // return dispatch(fetchInvoicesStart());
    }

    const [rows, setRows] = useState([]);


    useEffect(() => {
        getInvoices();
    }, [dates]);

    useEffect(() =>  {
        
        setRows(!wasLoaded.current ? [] : invoices.map(invoice => {
            console.log(invoice.items)
            return {
                ...invoice,
                id: invoice.id,
                due: '-',
                reference: invoice.order_reference,
                customer: invoice.name,
                status: invoice.items.every(x => x.payment_id) || invoice.amount == 0 ? 'PAID' : 'NOT PAID',
                payment: [!invoice.transaction_id ? '-' : `P-${invoice.transaction_id}`, Helper.FORMAT.USDate(invoice.time, 'do')],
                time: [Helper.FORMAT.USDate(invoice.time, 'do'), Helper.FORMAT.USDate(invoice.time, 'to')],
                amount: Helper.FORMAT.USCurrency(invoice.amount),
                link: invoice.link,
                profusepay_link: invoice.profusepay_link
            };
         }))
    }, [invoices])
    

    useEffect(() => {
        if (isLoading)
            wasLoaded.current = true;
    }, [isLoading]);

    useEffect(() => {
        getInvoices().then();
    }, []);

    const accountColumns: IColumn[] = [
        { orderBy: 'ASC', active: false, label: 'DUE',              name: 'due' },
        { orderBy: 'ASC', active: false, label: 'INVOICE',          name: 'id' },
        { orderBy: 'ASC', active: false, label: 'REFERENCE',        name: 'reference' },
        { orderBy: 'ASC', active: false, label: 'STATUS',           name: 'status' },
        { orderBy: 'ASC', active: false, label: 'CUSTOMER',         name: 'customer' },
        { orderBy: 'ASC', active: false, label: 'PAYMENT #',        name: 'payment' },
        { orderBy: 'ASC', active: false, label: 'PAID ON',          name: 'time' },
        { orderBy: 'ASC', active: false, label: 'AMOUNT',           name: 'amount' },
    ];

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <p className="font-14 fw-600">{ invoicesData?.length || invoices?.length } Invoices</p>
            </div>
            <Table 
            menuOptions={[
                {
                    label: 'Open',
                    icon: ICONS.IconDetails,
                    action: row => {
                        Helper.Navigation.NewTab(row.link);
                    }
            
                },
                {
                    label: 'Send Link',
                    icon: ICONS.IconDetails,
                    action: row => {
                        // Helper.Navigation.NewTab(row.link);
                    }
            
                },
                {
                    label: 'See on Profuse Pay',
                    icon: ICONS.IconDetails,
                    action: row => {
                        Helper.Navigation.NewTab(row.profusepay_link);
                    }
            
                }
        ]}
            loading={isLoading && !invoicesData}
                   columns={accountColumns}
                   rows={invoicesData || rows}
                   tableHeight="65vh"
                   onRowClicked={(row) => {
                    //    console.log(row.link)
                       Helper.Navigation.NewTab(row.link);
                   }}/>
        </>
    );
}

export default InvoicesTable;
