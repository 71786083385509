import {takeLatest, call, all, put} from 'redux-saga/effects';
import customerTypes from './customer.types';
import {
    fetchCustomersStart,
    setCustomer,
    setCustomers,
    setCustomerTable,
    customerAddedSuccess,
    setCustomerTableById,
    updateCustomerSuccess
} from "./customer.actions";

import {
    handleAddCustomer,
    handleFetchCustomers,
    handleFetchCustomerTable, handleFetchCustomerTableByID,
    handleGetCustomerById,
    handleUpdateCustomer
} from "./customer.helper";
// Fetch customers

export function* fetchCustomers() {
    try {
        const customers = yield handleFetchCustomers();
        yield put(
            setCustomers(customers)
        );
    } catch (err) {
        console.log(err);
    }
}

export function* onFetchCustomersStart() {
    yield takeLatest(customerTypes.FETCH_CUSTOMERS_START, fetchCustomers);
}

// Fetch customer table

export function* fetchCustomerTable() {
    try {
        const customers = yield handleFetchCustomerTable();
        yield put(
            setCustomerTable(customers)
        );
    } catch (err) {
        console.log(err);
    }
}

export function* onFetchCustomerTableStart() {
    yield takeLatest(customerTypes.FETCH_CUSTOMER_TABLE_START, fetchCustomerTable);
}

// Customer table by ID

export function* fetchCustomerTableById({customerId}) {
    try {
        const customers = yield handleFetchCustomerTableByID(customerId);
        yield put(
            setCustomerTableById(customers)
        );
    } catch (err) {
        console.log(err);
    }
}

export function* onFetchCustomerTableStartById() {
    // @ts-ignore
    yield takeLatest(customerTypes.FETCH_CUSTOMER_TABLE_START_BY_ID, fetchCustomerTableById);
}


// Customer by ID

export function* getCustomerById({id}) {
    try {
        const customer = yield handleGetCustomerById(+id);
        yield put(setCustomer(customer));
    } catch (err) {
        console.log(err);
    }
}

export function* onGetCustomerByIdStart() {
    // @ts-ignore
    yield takeLatest(customerTypes.GET_CUSTOMER_BY_ID_START, getCustomerById);
}


// Add Customer

export function* addCustomer({payload}) {

    try {
        let data = yield handleAddCustomer({
            ...payload,
        });

        yield put(
            fetchCustomersStart()
        );

        yield put(
            customerAddedSuccess(data.id)
        );
    } catch (err) {
        
    }

}

export function* onAddCustomerStart() {
    // @ts-ignore
    yield takeLatest(customerTypes.ADD_CUSTOMER_START, addCustomer);
}


// Update Customer

export function* updateCustomer({payload}) {
    console.log(payload)
    try {
        yield handleUpdateCustomer({
            ...payload,
        });
        yield put(
            fetchCustomersStart()
        );

        yield put(
            updateCustomerSuccess(payload.id)
        )
    } catch (err) {
        
    }

}

export function* onUpdateCustomerStart() {
    // @ts-ignore
    yield takeLatest(customerTypes.UPDATE_CUSTOMER_START, updateCustomer);
}

export default function* customerSagas() {
    yield all([
        call(onFetchCustomersStart),
        call(onFetchCustomerTableStart),
        call(onFetchCustomerTableStartById),
        call(onGetCustomerByIdStart),
        call(onAddCustomerStart),
        call(onUpdateCustomerStart),
    ])
}
