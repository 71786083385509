import {useEffect, useRef, useState} from "react";
import Searchbar from "../../../components/Searchbar/Searchbar";
import Helper from "../../../Helper/Helper";
import CustomerDetailOrders from "../CustomerDetailOrders/CustomerDetailOrders";
import CustomerDetailInvoices from "../CustomerDetailInvoices/CustomerDetailInvoices";
import CustomerDetailPayments from "../CustomerDetailPayments/CustomerDetailPayments";
import ICONS from "../../../../assets/svg";

const CustomerDetailTable = (props) => {
    enum Screens {
        Orders,
        Invoices,
        Payments
    }

    const customerId = props.customerId;
    const ref = useRef();
    const [currentScreen, setCurrentScreen] = useState(Screens.Orders);


    const getTitle = () => {
        switch (currentScreen) {
            case Screens.Orders: return 'Orders';
            case Screens.Invoices: return 'Invoices';
            case Screens.Payments: return 'Payments';
        }
    };

    const customerNavigation = () => {
        return (
            <nav className="App-header pb-0" style={{width: 'auto'}}>
                <ul className='pt-0'>
                    <li>
                        <a onClick={(evt) => setCurrentScreen(Screens.Orders)}
                           className={currentScreen == Screens.Orders ? 'active' : ''}>Orders</a>
                    </li>
                    <li>
                        <a onClick={(evt) => setCurrentScreen(Screens.Invoices)}
                           className={currentScreen == Screens.Invoices ? 'active' : ''}>Invoices</a>
                    </li>
                    <li>
                        <a onClick={(evt) => setCurrentScreen(Screens.Payments)}
                           className={currentScreen == Screens.Payments ? 'active' : ''}>Payments</a>
                    </li>
                </ul>
            </nav>
        );
    }

    const customerSearchbar = () => {
        return (
            <div className="col-6 d-flex justify-content-end">
                <div className='col-8'>
                    <Searchbar background='#F4F4F4' reference={ref} />
                </div>

                <button className="btn ml-3"
                        style={{background: '#F4F4F4', width: '2rem', height: '2rem', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img src={ICONS.IconRefresh} alt=""/>
                </button>
            </div>
        );
    }

    const customerTables = () => {
        switch (currentScreen) {
            case Screens.Orders:
                return <CustomerDetailOrders customerId={customerId} />;
            case Screens.Invoices:
                return <CustomerDetailInvoices customerId={customerId} />;
            case Screens.Payments:
                return <CustomerDetailPayments customerId={customerId} />;
            default: return 'List is empty';
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                { customerNavigation() }
                                { customerSearchbar() }
                            </div>

                            <div className="mt-3">
                                <p className="fw-600 font-16">{ getTitle() }</p>
                            </div>

                            <div className="mt-3">
                                { customerTables() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomerDetailTable;
