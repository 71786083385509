import moment from 'moment';
import React, { FC, useEffect, useRef, useState } from 'react';
import { start } from 'repl';
import ICONS from '../../../assets/svg';
import Chart from '../../components/Chart/Chart';
import Helper from '../../Helper/Helper';
import { GenericItem, printGenericReport, printReport } from '../../services/printerDrawer';
import { IPaymentsReportGrouped } from './ReportsPage';

interface IProp {
    start: Date,
    end: Date,
    dataSet: IPaymentsReportGrouped[]
}

const CARD_COLOR = '#17A8EB';
const CASH_COLOR = '#76D6FF';
const OTHER_COLOR = '#FF8ABF';
const TOTAL_COLOR = '#D92434'

const PaymentsComponent: FC<IProp> = ({start, end, dataSet}) => {
    const ref = useRef();

    const grapichParent = useRef<HTMLDivElement>();
    const [widthDounts, setWidthDounts] = useState(0);
    const [lineGraphFilter, setLineGraphFilter] = useState<string>('day');
    const [filteredDataSet, setFilteredDataSet] = useState<IPaymentsReportGrouped[]>([]);

    //filterDataSetForLineGraph(JSON.parse(JSON.stringify(dataSet)));

    const getByDay = (day = 0) => {
        return dataSet.filter(x => new Date(x.day).getDay() == day).sumBy(x => x.cash.amount + x.credit_card.amount + x.other.amount);
    }

    function handleResize(evt) {
        // console.log(grapichParent.current.scrollHeight);
        setWidthDounts(grapichParent.current?.scrollHeight || 0)
    }
    

    useEffect(() => {
        filterDataSetForLineGraph(JSON.parse(JSON.stringify(dataSet)));
    }, [lineGraphFilter, dataSet]);


    function getWeek(day: Date){
        return `${moment(day).week()}-${new Date(moment(day).year())}`;
    }
    function getMonth(day: Date){
        return moment(day).format('MM-YYYY');
    }

    async function printAllReport(){
        if(filteredDataSet.length < 1) return;

        let header = {
            name: 'REPORT',
            street: 'ALL PAYMENTS',
            city_state: `BY ${lineGraphFilter}`,
            contact: `${moment(dataSet[0].day).format('M/D/YYYY')} to ${moment(dataSet[dataSet.length - 1].day).format('M/D/YYYY')}`
        };

        let data: GenericItem[] = [];
        let count = dataSet.length;
        let filter = count < 14 ? 'day' : lineGraphFilter == 'month' && count < 60 ? 'week' : lineGraphFilter;
        switch(filter){
            case 'week':
                data = filteredDataSet.map(x => ({
                    firstLine: 'Week of',
                    secondLine: moment(x.day).format('MM/DD/YYYY'),
                    rightLine: Helper.FORMAT.USCurrency(x.cash.amount + x.credit_card.amount + x.other.amount)
                }));
                break;
            case 'month':
                data = filteredDataSet.map(x => ({
                    firstLine: moment(x.day).format('MMMM'),
                    secondLine: moment(x.day).format('YYYY'),
                    rightLine: Helper.FORMAT.USCurrency(x.cash.amount + x.credit_card.amount + x.other.amount)
                }));
                break;
            default:
                data = filteredDataSet.map(x => ({
                    firstLine: moment(x.day).format('MM/DD/YYYY'),
                    secondLine: moment(x.day).format('dddd'),
                    rightLine: Helper.FORMAT.USCurrency(x.cash.amount + x.credit_card.amount + x.other.amount)
                }));
                break;
        }
        let title: GenericItem = {
            firstLine: 'PAYMENTS COLLECTED PER ' + filter.toUpperCase(),
            secondLine: `${moment(dataSet[0].day).format('M/D/YYYY')} to ${moment(dataSet[dataSet.length - 1].day).format('M/D/YYYY')}`,
            rightLine: `${filteredDataSet.length} ${filter.toUpperCase()}${filteredDataSet.length == 1 ? '':'S'}`
        }
        let totalLine: GenericItem = {
            firstLine: 'TOTAL',
            secondLine: '',
            rightLine: Helper.FORMAT.USCurrency(filteredDataSet.sumBy(x => x.cash.amount + x.credit_card.amount + x.other.amount))
        }
        await printGenericReport(data, title, totalLine, header);
    }

    async function printOtherReport(){
        if(filteredDataSet.length < 1) return;

        let header = {
            name: 'REPORT',
            street: 'OTHER PAYMENTS',
            city_state: `BY ${lineGraphFilter}`,
            contact: `${moment(dataSet[0].day).format('M/D/YYYY')} to ${moment(dataSet[dataSet.length - 1].day).format('M/D/YYYY')}`
        };

        let data: GenericItem[] = [];
        let count = dataSet.length;
        let filter = count < 14 ? 'day' : lineGraphFilter == 'month' && count < 60 ? 'week' : lineGraphFilter;
        switch(filter){
            case 'week':
                data = filteredDataSet.map(x => ({
                    firstLine: 'Week of',
                    secondLine: moment(x.day).format('MM/DD/YYYY'),
                    rightLine: Helper.FORMAT.USCurrency(x.other.amount)
                }));
                break;
            case 'month':
                data = filteredDataSet.map(x => ({
                    firstLine: moment(x.day).format('MMMM'),
                    secondLine: moment(x.day).format('YYYY'),
                    rightLine: Helper.FORMAT.USCurrency(x.other.amount)
                }));
                break;
            default:
                data = filteredDataSet.map(x => ({
                    firstLine: moment(x.day).format('MM/DD/YYYY'),
                    secondLine: moment(x.day).format('dddd'),
                    rightLine: Helper.FORMAT.USCurrency(x.other.amount)
                }));
                break;
        }
        let title: GenericItem = {
            firstLine: 'OTHER PAYMENTS COLLECTED PER ' + filter.toUpperCase(),
            secondLine: `${moment(dataSet[0].day).format('M/D/YYYY')} to ${moment(dataSet[dataSet.length - 1].day).format('M/D/YYYY')}`,
            rightLine: `${filteredDataSet.length} ${filter.toUpperCase()}${filteredDataSet.length == 1 ? '':'S'}`
        }
        let totalLine: GenericItem = {
            firstLine: 'TOTAL OTHER',
            secondLine: '',
            rightLine: Helper.FORMAT.USCurrency(filteredDataSet.sumBy(x => x.other.amount))
        }
        await printGenericReport(data, title, totalLine, header);
    }

    async function printCashReport(){
        if(filteredDataSet.length < 1) return;

        let header = {
            name: 'REPORT',
            street: 'CASH PAYMENTS',
            city_state: `BY ${lineGraphFilter}`,
            contact: `${moment(dataSet[0].day).format('M/D/YYYY')} to ${moment(dataSet[dataSet.length - 1].day).format('M/D/YYYY')}`
        };

        let data: GenericItem[] = [];
        let count = dataSet.length;
        let filter = count < 14 ? 'day' : lineGraphFilter == 'month' && count < 60 ? 'week' : lineGraphFilter;
        switch(filter){
            case 'week':
                data = filteredDataSet.map(x => ({
                    firstLine: 'Week of',
                    secondLine: moment(x.day).format('MM/DD/YYYY'),
                    rightLine: Helper.FORMAT.USCurrency(x.cash.amount)
                }));
                break;
            case 'month':
                data = filteredDataSet.map(x => ({
                    firstLine: moment(x.day).format('MMMM'),
                    secondLine: moment(x.day).format('YYYY'),
                    rightLine: Helper.FORMAT.USCurrency(x.cash.amount)
                }));
                break;
            default:
                data = filteredDataSet.map(x => ({
                    firstLine: moment(x.day).format('MM/DD/YYYY'),
                    secondLine: moment(x.day).format('dddd'),
                    rightLine: Helper.FORMAT.USCurrency(x.cash.amount)
                }));
                break;
        }
        let title: GenericItem = {
            firstLine: 'CASH PAYMENTS COLLECTED PER ' + filter.toUpperCase(),
            secondLine: `${moment(dataSet[0].day).format('M/D/YYYY')} to ${moment(dataSet[dataSet.length - 1].day).format('M/D/YYYY')}`,
            rightLine: `${filteredDataSet.length} ${filter.toUpperCase()}${filteredDataSet.length == 1 ? '':'S'}`
        }
        let totalLine: GenericItem = {
            firstLine: 'TOTAL CASH',
            secondLine: '',
            rightLine: Helper.FORMAT.USCurrency(filteredDataSet.sumBy(x => x.cash.amount))
        }
        await printGenericReport(data, title, totalLine, header);
    }

    async function printCreditCardsReport(){
        if(filteredDataSet.length < 1) return;

        let header = {
            name: 'REPORT',
            street: 'CREDIT CARD PAYMENTS',
            city_state: `BY ${lineGraphFilter}`,
            contact: `${moment(dataSet[0].day).format('M/D/YYYY')} to ${moment(dataSet[dataSet.length - 1].day).format('M/D/YYYY')}`
        };

        let data: GenericItem[] = [];
        let count = dataSet.length;
        let filter = count < 14 ? 'day' : lineGraphFilter == 'month' && count < 60 ? 'week' : lineGraphFilter;
        switch(filter){
            case 'week':
                data = filteredDataSet.map(x => ({
                    firstLine: 'Week of',
                    secondLine: moment(x.day).format('MM/DD/YYYY'),
                    rightLine: Helper.FORMAT.USCurrency(x.credit_card.amount)
                }));
                break;
            case 'month':
                data = filteredDataSet.map(x => ({
                    firstLine: moment(x.day).format('MMMM'),
                    secondLine: moment(x.day).format('YYYY'),
                    rightLine: Helper.FORMAT.USCurrency(x.credit_card.amount)
                }));
                break;
            default:
                data = filteredDataSet.map(x => ({
                    firstLine: moment(x.day).format('MM/DD/YYYY'),
                    secondLine: moment(x.day).format('dddd'),
                    rightLine: Helper.FORMAT.USCurrency(x.credit_card.amount)
                }));
                break;
        }
        let title: GenericItem = {
            firstLine: 'CREDIT CARD PAYMENTS COLLECTED PER ' + filter.toUpperCase(),
            secondLine: `${moment(dataSet[0].day).format('M/D/YYYY')} to ${moment(dataSet[dataSet.length - 1].day).format('M/D/YYYY')}`,
            rightLine: `${filteredDataSet.length} ${filter.toUpperCase()}${filteredDataSet.length == 1 ? '':'S'}`
        }
        let totalLine: GenericItem = {
            firstLine: 'TOTAL CREDIT CARD',
            secondLine: '',
            rightLine: Helper.FORMAT.USCurrency(filteredDataSet.sumBy(x => x.credit_card.amount))
        }
        await printGenericReport(data, title, totalLine, header);
    }

    function filterDataSetForLineGraph(currentDataSet: IPaymentsReportGrouped[]){
        if(currentDataSet.length < 1) return;
        let temp: IPaymentsReportGrouped[] = [];
        let count = currentDataSet.length;
        let filter = count < 14 ? 'day' : lineGraphFilter == 'month' && count < 60 ? 'week' : lineGraphFilter;
        switch(filter){
            case 'week':
                let weekData = JSON.parse(JSON.stringify(currentDataSet));
                for(let item of weekData){
                    if(temp.length == 0 || getWeek(item.day) != getWeek(temp[temp.length - 1].day)){
                        temp.push(item);
                    }
                    else{
                        let i = temp.length - 1;
                        temp[i].cash.amount = temp[i].cash.amount + item.cash.amount;
                        temp[i].cash.count = temp[i].cash.count + item.cash.count;
                        temp[i].credit_card.amount = temp[i].credit_card.amount + item.credit_card.amount;
                        temp[i].credit_card.count = temp[i].credit_card.count + item.credit_card.count;
                        temp[i].other.amount = temp[i].other.amount + item.other.amount;
                        temp[i].other.count = temp[i].other.count + item.other.count;
                    }
                }
                break;
            case 'month':
                let monthData = JSON.parse(JSON.stringify(currentDataSet));
                for(let item of monthData){
                    if(temp.length == 0 || getMonth(item.day) != getMonth(temp[temp.length - 1].day)){
                        temp.push(item);
                    }
                    else{
                        let i = temp.length - 1;
                        temp[i].cash.amount = temp[i].cash.amount + item.cash.amount;
                        temp[i].cash.count = temp[i].cash.count + item.cash.count;
                        temp[i].credit_card.amount = temp[i].credit_card.amount + item.credit_card.amount;
                        temp[i].credit_card.count = temp[i].credit_card.count + item.credit_card.count;
                        temp[i].other.amount = temp[i].other.amount + item.other.amount;
                        temp[i].other.count = temp[i].other.count + item.other.count;
                    }
                }
                break;
            default:
                temp = JSON.parse(JSON.stringify(currentDataSet));
                break;
        }
        setFilteredDataSet(temp);
        return temp;
    }
    
    return   <div className="">
    <div className='row col-12'>

        <div ref={grapichParent} className="col-7">
            <div className='col-12 mb-3 row'>
                <h4 style={{fontSize: '16px'}} className="mb-1 fw-400">From: {Helper.FORMAT.USDate(start, 'do')}</h4>
                <h4 style={{fontSize: '16px'}} className="mb-2 fw-400">To: {Helper.FORMAT.USDate(end, 'do')}</h4>
                <h5 className='pl-4' style={{fontSize: '40px'}}><sup style={{fontSize: '16px', fontWeight: 300}}>$</sup> <span className='fw-600'>{(dataSet.sumBy(x => x.cash.amount + x.credit_card.amount + x.other.amount))?.toCurrency('')}</span></h5>
                    
                <div className="row col-12 d-flex justify-content-between">
                    <div className="col-4">
                        <div className="d-flex">
                            <h4 className="mb-1 justify-content-between mt-2 fw-400" style={{fontSize: '16px'}} >Total credit cards</h4>
                            {<button className="btn ml-3" style={{border: '1px solid #F28705', background: '#FFF', width: '32px', height: '32px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <img  onClick={printCreditCardsReport} src={ICONS.IconPrintYellow} alt=""/>
                            </button>}
                        </div>
                        <h5 className='pl-4' style={{fontSize: '32px'}}><sup style={{fontSize: '16px', fontWeight: 300}}>$</sup> <span className='fw-600'>{(dataSet.sumBy(x => x.credit_card.amount))?.toCurrency('')}</span></h5>
                    </div>
                    <div className="col-4">
                        <div className="d-flex">
                        <h4 style={{fontSize: '16px'}} className="mb-1 mt-2 fw-400">Total cash</h4>{<button className=" col-2 btn ml-3" style={{border: '1px solid #F28705', background: '#FFF', width: '32px', height: '32px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <img  onClick={printCashReport} src={ICONS.IconPrintYellow} alt=""/>
                            </button>}
                        </div>
                        <h5 className='pl-4' style={{fontSize: '32px'}}><sup style={{fontSize: '16px', fontWeight: 300}}>$</sup> <span className='fw-600'>{(dataSet.sumBy(x => x.cash.amount))?.toCurrency('')}</span></h5>    
                    </div>
                    <div className="col-4">
                        <div className="d-flex">
                        <h4 style={{fontSize: '16px'}} className="mb-1 mt-2 fw-400">Total other</h4>{<button className=" col-2 btn ml-3" style={{border: '1px solid #F28705', background: '#FFF', width: '32px', height: '32px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <img  onClick={printOtherReport} src={ICONS.IconPrintYellow} alt=""/>
                            </button>}
                        </div>
                        <h5 className='pl-4' style={{fontSize: '32px'}}><sup style={{fontSize: '16px', fontWeight: 300}}>$</sup> <span className='fw-600'>{(dataSet.sumBy(x => x.other.amount))?.toCurrency('')}</span></h5>    
                    </div>
                </div>
            </div>
            
            <div className='col-12'>
                <div className='row col-12 mt-4' style={{alignItems: 'center', justifyContent: 'space-between'}}>
                    <div className='col-8' style={{alignItems: 'center', display: 'flex'}}>
                        <h4 style={{fontSize: '32px'}} className="mb-1 mt-2 fw-400 mr-2">Payments for each </h4>
                        <button className={'btn mx-2 btn-orange' + (lineGraphFilter == 'day' ? '' : '-outline')} onClick={()=>setLineGraphFilter('day')}>Day</button>
                        {dataSet.length >= 14 && <button className={'btn mx-2 btn-orange' + (lineGraphFilter == 'week' ? '' : '-outline')} onClick={()=>setLineGraphFilter('week')}>Week</button>}
                        {dataSet.length >= 60 && <button className={'btn mx-2 btn-orange' + (lineGraphFilter == 'month' ? '' : '-outline')} onClick={()=>setLineGraphFilter('month')}>Month</button>}
                    </div>
                    {<button className="btn ml-3" style={{border: '1px solid #F28705', background: '#FFF', width: '2rem', height: '2rem', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <img  onClick={printAllReport} src={ICONS.IconPrintYellow} alt=""/>
                    </button>}
                </div>
                <Chart
                labels={filteredDataSet.map(x => moment(x.day).format('M/D/YY'))}
                datasets={[
                    {
                        label: 'Total',
                        borderColor: TOTAL_COLOR,
                        data: filteredDataSet.map(x => x.cash.amount + x.other.amount + x.credit_card.amount)
                    },
                    {
                        label: 'Cash',
                        borderColor: CASH_COLOR,
                        data: filteredDataSet.map(x => x.cash.amount)
                    },
                    {
                        label: 'Other',
                        borderColor: OTHER_COLOR,
                        data: filteredDataSet.map(x => x.other.amount)
                    },
                    {
                        label: 'Credit Card',
                        borderColor: CARD_COLOR,
                        data: filteredDataSet.map(x => x.credit_card.amount)
                    },
                ]}
                type={'line'} /> 
            </div>
            <div className='col-12 mt-4'>
                <h4 style={{fontSize: '32px'}} className="mb-1 mt-2 fw-400 mr-2">Total per day of the week</h4>
                <Chart 
                labels={[
                    'SUN',
                    'MON',
                    'TUE',
                    'WED',
                    'THU',
                    'FRI',
                    'SAT'
                ]}
                datasets={[
                    {
                        label: 'Weekly',
                        data: [
                            getByDay(0),
                            getByDay(1),
                            getByDay(2),
                            getByDay(3),
                            getByDay(4),
                            getByDay(5),
                            getByDay(6)
                        ],
                        backgroundColor: TOTAL_COLOR
                    }
                ]}
                type={'bar'} />
            </div>
        </div>
        <div className='col-5'>
            <h4 style={{fontSize: '32px'}} className="mb-1 fw-400 mr-2">Total per type of payment</h4>
            <div className='p-5'>
            <Chart 
                labels={['Credit Card', 'Cash', 'Other']}
                datasets={
                    [
                        {
                            label: 'Credit Card',
                            backgroundColor:[ CARD_COLOR, CASH_COLOR, OTHER_COLOR],
                            data: [dataSet.sumBy(x => x.credit_card.amount), dataSet.sumBy(x => x.cash.amount), dataSet.sumBy(x => x.other.amount)]
                        }
                    ]
                }
                type={'doughnut'} />   
            </div>
        </div>

    </div>
 </div>
}

export default PaymentsComponent;