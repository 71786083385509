import gql from "graphql-tag";

export const GET_SERVICE_CHARGES_BY_TICKET_NAME = gql`
    query ($ticket_name: String!, $selectedServices: [add_service_charge])
    {
      get_service_charges_by_ticket_name(ticket_name: $ticket_name, selectedServices: $selectedServices) {
            id
            name
            count
            amount
            completed
            refund_id
            ticket_id
            payment_id
            service_id
            tax_amount
            base_amount
        }   
    }
`