import taxType from "./tax.type";

const INITIAL_STATE = {
    taxes: [],
    isLoading: false
};

const taxReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case taxType.SET_TAXES:
            return {
                ...state, isLoading: false, taxes: action.payload
            };
        case taxType.ADD_TAX:
            return {
                ...state, isLoading: true
            };
        case taxType.UPDATE_TAX:
            return {
                ...state, isLoading: true
            };
        default:
            return state
    }
};

export default taxReducer;
