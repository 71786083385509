import serviceType from "./service.type";

export const setServices: any = services => ({
    type: serviceType.SET_SERVICES,
    payload: services
});

export const fetchServicesStart: any = () => ({
    type: serviceType.FETCH_SERVICES_START
});

export const addServiceStart = serviceData => ({
    type: serviceType.ADD_SERVICE_START,
    payload: serviceData
});
