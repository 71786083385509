import {handleFetchPayments, handleFetchPaymentsByCustomer} from "./payments.helper";
import {all, call, put, takeLatest} from "redux-saga/effects";
import {setPayments, setPaymentsByCustomer} from "./payments.actions";
import paymentsTypes from "./payments.types";

export function* fetchPayments() {
    try {
        const payments = yield handleFetchPayments();
        yield put(setPayments(payments));
    } catch (err) {
        console.log(err);
    }
}

export function* onFetchpaymentsStart() {
    yield takeLatest(paymentsTypes.FETCH_PAYMENTS_START, fetchPayments);
}

// FETCH PAYMENTS BY CUSTOMER

export function* fetchPaymentsByCustomer({customerId}) {
    try {
        const payments = yield handleFetchPaymentsByCustomer(+customerId);
        yield put(setPaymentsByCustomer(payments));
    } catch (err) {
        console.log(err);
    }
}

export function* onFetchPaymentsByCustomerStart() {
    // @ts-ignore
    yield takeLatest(paymentsTypes.FETCH_PAYMENTS_BY_CUSTOMER_START, fetchPaymentsByCustomer);
}

export default function* paymentsSagas() {
    yield all([
        call(onFetchpaymentsStart),
        call(onFetchPaymentsByCustomerStart)
    ]);
}
