import React, {useEffect, useRef, useState} from 'react';

import {Color, Customer, Order, Service_charge, Ticket} from '../../classes/classes';
import Searchbar from '../../components/Searchbar/Searchbar';
import {TypePicker} from '../../components/TypePicker/TypePicker';
import ServiceChargeEnpoint from '../../endpoints/ServiceCharge.endpoint';
import CustomerInfo from './CustomerInfo/CustomerInfo';
import CustomerSelection from './CustomerSelection/CustomerSelection';
import ServicesSelection from './ServicesSelection/ServicesSelection';
import TicketsDetails from './TicketsDetails/TicketsDetails';
import './OverviewPage.scss';
import ColorPicker from "../../components/TypePicker/components/ColorPicker/ColorPicker";
import DatePicker from '../../components/DatePicker/DatePicker';
import MaterialPicker from "../../components/TypePicker/components/MaterialPicker/MaterialPicker";
import moment from 'moment';
import Helper from '../../Helper/Helper';
import TicketInformation from '../../components/TicketInformation/TicketInformation';
import {useDispatch, useSelector} from "react-redux";
import {ReducerType} from "../../store";
import { createOrderAction, getInvoicesOrderAction, getPaymentsOrderAction, resetNewOrderAction, setOrderEdittingAction } from '../../redux/actions/order.action';
import { ICreateOrder, IServiceCharge } from '../../models/interfaces';
import LoadSpinner from '../../components/LoadSpinner/LoadSpinner';
import { RESET_TYPE } from '../../components/TypePicker/state/actionTypes';
import { resetTypePickerItem } from '../../components/TypePicker/state/actions';
import { Redirect, useParams } from 'react-router';
import OrderBreakdown from '../../components/OrderBreakdown/OrderBreakdown';
import { GraphqlService, ObservableQueryT } from '../../services/graphql.service';
import { ObservableQuery } from 'apollo-client';
import { GET_ORDER_BY_ID, ORDER_RECEIPT_DATA } from '../../graphql/query/Order';
import gql from 'graphql-tag';
import Swal from 'sweetalert2';
import Table from '../../components/Table/Table';
import IColumn from '../../components/Table/models/IColumn';
import { GET_TRANSACTION_STATUS } from '../../graphql/query/Transaction';
import { VOID_TRANSACTION } from '../../graphql/mutation/Transaction';
import AuthService from '../../services/auth.service';
import InvoicesTable from '../AccountingPage/InvoicesTable/InvoicesTable';
import Loading from '../../components/Loading/Loading';
import TicketPickerModal from './TicketPickerModal/TicketPickerModal';
import { checkPrinter, openDrawer, printReceipt } from '../../services/printerDrawer';
import ICONS from '../../../assets/svg';
import { Link } from 'react-router-dom';
import Status from '../../components/Status/Status';
import { TicketService } from '../../services/ticket.service';

const OverviewPage = () => {


    let lastTicket = useRef<any>(-1);
    const dispatch = useDispatch();
    const {order_id} = useParams<any>();

    const [loading1, setLoading1] = useState(false);
    
    const loading = useSelector<any>(state => state.orders.loading);
    const norder = useSelector<any>(state => state.orders.newOrder) as any;
    const payments = useSelector<any>(state => state.orders.payments) as any[];
    const invoices = useSelector<any>(state => state.orders.invoices) as any[];
    const ticketsCount = useRef<number>(0);

    
    const [order, setOrder] = useState<Order>(new Order());
    const [currentTicket, setCurrentTicket] = useState(-1);
    const [calendarOpen, setCalendarOpen] = useState(false);

    const paymentColumns: IColumn[] = [
        {
            label: 'TIME', name: 'time', active: false, orderBy: 'ASC'
        },
        {
            label: 'ORDER #', name: 'order_number', active: false, orderBy: 'ASC'
        },
        {
            label: 'STATUS', name: 'status', active: false, orderBy: 'ASC'
        },
        {
            label: 'AMOUNT', name: 'amount', active: false, orderBy: 'ASC'
        },
        {
            label: 'TYPE', name: 'type', active: false, orderBy: 'ASC'
        },
        {
            label: 'PAYMENT #', name: 'payment_number', active: false, orderBy: 'ASC'
        },
        {
            label: 'TRANSACTION #', name: 'transaction_number', active: false, orderBy: 'ASC'
        },
        {
            label: 'CHARGES', name: 'charges', active: false, orderBy: 'ASC'
        }
    ];

    const [orderQuery, setOrderQuery] = useState<ObservableQueryT>();

    function loadOrder()
    {
        try {
            let z = GraphqlService.SendQueryObservable(GET_ORDER_BY_ID, {orderId: +order_id});
            setOrderQuery(z);
            z.onResults.subscribe(result => {
                
                if (result.data)
                {
                  


                    let oldTickets = ticketsCount.current;
                    
                    let o = new Order(JSON.parse(JSON.stringify(result.data)));
                    
                    ticketsCount.current = o.tickets.length;

                    if (o.tickets.length > 0 && !o.cancelled)
                    {
                        if (lastTicket.current == -1 || oldTickets != o.tickets.length)
                        {
                            let index = o.tickets.findIndex(x => x.id == TicketService.TicketSelected);
                            if (index > -1)
                            {
                                lastTicket.current = index;
                                setCurrentTicket(index);
                            }
                            else
                            {
                                lastTicket.current = o.tickets.length - 1;
                                setCurrentTicket(o.tickets.length - 1);
                            }
                        }
                        setOrder({...order, amount_remaining: o.amount_remaining, service_charges: o.service_charges, scheduled: o.scheduled, tickets: o.tickets.sort((x, y) => x.id > y.id ? 1 : -1), customer: o.customer, completed: o.completed, id: o.id, log: o.log, note: o.note, time: o.time, user: o.user});

                    }
                    else
                    {
                        Swal.fire({
                            title: 'Warning',
                            text: `This order is cancelled.`,
                            icon: 'warning'
                        }).then(result => {
                            Helper.Navigation.NavigateTo(TicketService.LastPage || '/');
                        })
                    }
                }
                
                if (result.error)
                {
                    Swal.fire({
                        title: 'Error',
                        text: result.error.message?.replace('GraphQL error: ', ''),
                        icon: 'error'
                    }).then(d => {
                        Helper.Navigation.NavigateTo('/orders')
                    })
                }
                
            })
        } catch (ex) {
            
        }
    }
    
    

    enum ActionScreens {
        Ticket,
        Invoices,
        Payments
    }

    const [currentActionS, setCurrentActionS] = useState(ActionScreens.Ticket);

    enum Screens {
        Customer,
        SelectCustomer,
        Ticket,
        SelectTicket
    }



    const handleCreateOrder = evt => {

        let temp: ICreateOrder = {
            customer_id: order.customer.id,
            scheduled: order.scheduled,
            service_charges: order.service_charges.map(sc => {
                let s: IServiceCharge = {
                    amount: sc.amount,
                    base_amount: sc.base_amount,
                    count: sc.count,
                    name: sc.name,
                    tax_amount: sc.tax_amount
                }
                return s;
            }),
            tickets: order.tickets.map(ticket => ({ label: ticket.label, name: ticket.name, status: ticket.status, notes: ticket.notes, service_group_id: ticket.service_group_id,
                    description: {
                        materials: ticket.description.materials,
                        colors: ticket.description.colors
                    },
                    service_charges: ticket.service_charges.map(sc => {
                        let s: IServiceCharge = {
                            amount: sc.amount,
                            base_amount: sc.base_amount,
                            count: sc.count,
                            name: sc.name,
                            tax_amount: sc.tax_amount
                        }
                        return s;
                    })
                
                })),
            user_id: AuthService.USER.id
        }

        dispatch(createOrderAction(temp));
    }

    const resetTypePicker = () => {
        dispatch(resetTypePickerItem());
    };

    const alertUser = e => {
        if (order.customer.id != -1 || order.tickets.length > 0)
        {
            e.preventDefault()
            e.returnValue = '';
        }
    }

    useEffect(() => {

        
        if (!order_id) {
            setCurrentScreen(Screens.Customer)
            window.addEventListener('beforeunload', alertUser)
            return () => {
            window.removeEventListener('beforeunload', alertUser)
            }}
        else
        {
            dispatch(setOrderEdittingAction(order_id))
            dispatch(getPaymentsOrderAction(order_id));
            dispatch(getInvoicesOrderAction(order_id));
            loadOrder();
            setCurrentScreen(Screens.SelectTicket)
            return () =>{ dispatch(setOrderEdittingAction(null))
                orderQuery?.stopPolling();
                setOrderQuery(null);
            };
        }

    }, [])



    const [currentScreen, setCurrentScreen] = useState(Screens.SelectCustomer);



    const renderSwitch = () => {
        switch (currentScreen) {
            case Screens.Customer:
                return <>
                    <div className="sticky-header pb-3">
                        <div className='sticky-header pb-3'><h6 className='title-text'>Customer</h6></div>
                    </div>
                    <CustomerSelection customer_id={order.customer?.id} onCustomerSelected={async (customer) => {
                        
                        if (order.id == -1)
                            setOrder({...order, customer});

                        if (customer.id != -1)
                            setCurrentScreen(Screens.SelectTicket)

                        

                        if (order.id != -1)
                        {
                            setLoading1(true);
                            try {
                                await GraphqlService.SendMutation(gql`mutation($id:Int! $customer_id: Int $scheduled: String){
                                    update_order(id:$id order: {
                                    customer_id: $customer_id
                                    scheduled: $scheduled
                                    }){
                                    id
                                    }
                                }`, {id: order.id, customer_id: customer.id, scheduled: order.scheduled})

                                setLoading1(false);
                            } catch (ex) {
                                
                                setLoading1(false);
                            }
                        }

                        if (order.tickets.length > 0 && customer.id != -1)
                        {
                            setCurrentTicket(0);
                            lastTicket.current = 0;
                        }
                    }}/> </>
            case Screens.SelectCustomer:
                return <>
                <div >
                    {/* <div className='mb-5 pb-5'>
                            <img style={{display: 'block', marginTop: '25%', height: '80px', transform: 'rotate(35deg) translate(-50px, -50px)'}} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVqLE_UCFfwsqWeDTx-5Lz-gUmQ6zZlwdqQxRFwiEFuptw4Egy8Dt1RfstoM3CpWsUWYQ&usqp=CAU" alt=""/>
                        </div> */}
                    <div style={{marginTop: '35%'}} className="sticky-header mb-3 text-center">
                            <div className='sticky-header'><h6 style={{fontSize: '2rem'}}>Add/Select Customer</h6></div>
                        </div>
                       
                </div>
                </> ;
            case Screens.Ticket:
                return <>
                    {/* <div className='sticky-header pb-3'> */}
                    {/* <h6 className='title-text'>{order.tickets[currentTicket].name} ({order.tickets[currentTicket].count})</h6> */}

                    {/* <ServicesSelection serviceCharges={serviceCharges} onToggleServices={(services) => {

                            let temp = [...order.tickets];
                            temp[currentTicket].service_charges = services;
                            setOrder({...order, tickets: temp});
                        }} ticket={order.tickets[currentTicket]}/> */}
                        {/* </div> */}
                        <TicketInformation onRemoveNotesClicked={(index) => {
                            
                            const temp = JSON.parse(JSON.stringify(order.tickets)) as Ticket[];
                            let notes = [...temp[currentTicket].notes];
                            notes.splice(index, 1);
                            let ticket_id = temp[currentTicket].id;
                            if (ticket_id == -1)
                            {
                                temp[currentTicket].notes.splice(index, 1);
                                setOrder({...order, tickets: temp});
                            }
                            else
                            {
                                Swal.fire({
                                    title: 'Are you sure?',
                                    text: 'Do you want to delete this note?',
                                    icon: 'question',
                                    showDenyButton: true,
                                    confirmButtonText: 'Yes'
                                })
                                .then(async (r) => {
                                    if (r.isConfirmed)
                                    {
                                        try {
                                            setLoading1(true);
                                            let z = await GraphqlService.SendMutation(gql`
                                                mutation ($ticketId: Int $notes: [noteInput]){
                                                    delete_notes(ticketId: $ticketId notes: $notes)
                                                }
                                            `, {ticketId: ticket_id, notes: notes.map(x => ({message: x.message, username: x.username, user_id: x.user_id, time: x.time}))});

                                            setLoading1(false);
                                            console.log(z);
                                        } catch (ex) {
                                            setLoading1(false);
                                            console.log('ex', ex.message);
                                        }
                                    }
                                })
                            }
                        }} scheduled={order.scheduled} ticket={{...order.tickets[currentTicket]}} onTicketChanged={async (ticket, data) => {

                            const temp = JSON.parse(JSON.stringify(order.tickets));
                            temp[currentTicket] = JSON.parse(JSON.stringify(ticket));
                            
                            if (data.note)
                            {
                                temp[currentTicket].notes.push(data.note);
                                if (ticket.id != -1)
                                {
                                    try {
                                        setLoading1(true);
                                        await GraphqlService.SendMutation(gql`mutation ($ticketId: Int, $note:noteInput){
                                            add_notes_toTicket(ticketId: $ticketId note: $note){
                                              id
                                            }
                                          }`, {ticketId: ticket.id, note: data.note});
                                          setLoading1(false)
                                    } catch (ex) {
                                        setLoading1(false)
                                    }
                                }
                            }
                            if (ticket.id != -1)
                            {
                                if (data.services)
                                {
                                    setLoading1(true);
                                    try {
                                        let charges = ticket.service_charges.map(x => ({"id": x.id == -1 ? null : x.id, "name": x.name, "count": x.count, "amount": x.amount, "completed": false, "refund_id": null, "ticket_id": ticket.id, "payment_id": x.payment_id, "service_id": null, "tax_amount": x.tax_amount, "base_amount": x.base_amount}));

                                        await GraphqlService.SendMutation(gql`mutation ($input:update_charges){
                                            update_service_charges(input: $input){
                                              id
                                            }
                                          }`, {input: {ticketID: ticket.id, charges: charges} });
                                          
                                        setLoading1(false);
                                    } catch (ex) {
                                        setLoading1(false);
                                        console.log('ex', ex.message);
                                    }
                                }
                                if (data.status)
                                {
                                    try {
                                        setLoading1(true)
                                        await GraphqlService.SendMutation(gql`mutation($id: Int! $status:String){
                                            update_status(id:$id  status:$status){
                                              id
                                            }
                                          }`, {id: ticket.id, status: ticket.status})
                                        setLoading1(false);
                                    } catch (ex) {
                                        setLoading1(false);
                                    }
                                }
                                else
                                {
                                    try {
                                        setLoading1(true);
                                        await GraphqlService.SendMutation(gql`
                                       
                                       mutation( $id:Int $label:String $name:String $colors: [colorInput] $materials: [materialInput]){
                                        update_ticket(id:$id ticket: {
                                            label: $label
                                            name: $name
                                        }){
                                            id
                                        }
                                        update_ticket_description(ticketid: $id colors: $colors materials:$materials){
                                            success
                                            message
                                        }
                                        }`, {id: ticket.id, label: ticket.label, name: ticket.name, colors: ticket.description.colors.map(x => ({color: x.color, count: x.count})), materials: ticket.description.materials.map(x => ({material: x.material, count: x.count}))})
                                        setLoading1(false);
                                    } catch (ex) {
                                        console.log('ex', ex.message);
                                        setLoading1(false);
                                    }
                                }
                            }
                            else
                            {

                                let o = JSON.parse(JSON.stringify(order));


                                setOrder({...o, tickets: temp});
                            }
                        }} />

                </>

            case Screens.SelectTicket:
                return <>
                <div >
                    {/* <div className='mb-5 pb-5'>
                            <img style={{display: 'block', marginTop: '25%', height: '80px', transform: 'rotate(0)'}} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVqLE_UCFfwsqWeDTx-5Lz-gUmQ6zZlwdqQxRFwiEFuptw4Egy8Dt1RfstoM3CpWsUWYQ&usqp=CAU" alt=""/>
                        </div> */}
                    <div style={{marginTop: 'calc(35%)'}} className="sticky-header mb-3 text-center">
                            <div className='sticky-header'><h6 style={{fontSize: '2rem'}}>Add/Select Ticket</h6></div>
                        </div>
                       
                </div>
                </>
        }
    }

    const options = [
        {
            label: 'Receipt',
            icon: ICONS.IconReceipt,
            action: async (data) => {
                let payment_id = +Helper.Masks.IntNumbers(data.payment_number);
                Helper.Receipt.ShowConfirmation(payment_id, 1);
            }
        },
        {
            label: 'Void Payment',
            icon: ICONS.IconDelete,
            action: async (data) => {
                let payment_id = +Helper.Masks.IntNumbers(data.payment_number);
               
                Swal.fire({
                    title: 'Are you sure?',
                    text:  `Void Payment #` + payment_id + `, this action can't be undo!`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, void it!'
                  }).then(async result => {
                      if (result.isConfirmed)
                      {
                           

                        try {
                            let data = await GraphqlService.SendMutation(VOID_TRANSACTION, {paymentId: payment_id})
                        } catch (ex) {
                            console.log('ex', ex.message);
                            Swal.fire({
                                titleText: 'Failed',
                                text: ex.message,
                                icon: 'error'
                            });
                        }
                      }
                  })
            }
        },
        {
            label: 'Check Status',
            icon: ICONS.IconCheck,
            action: async (data) => {
                
                let payment_id = +Helper.Masks.IntNumbers(data.payment_number);
                try {
                    let result = await GraphqlService.SendQuery(GET_TRANSACTION_STATUS, {paymentId: payment_id});
                    Swal.fire({
                        title: 'Transaction ' + data.transaction_number,
                        text: JSON.stringify(result),
                        icon: 'info'
                    })
                } catch (ex) {
                    
                    Swal.fire({
                        text: ex.message.toLowerCase().includes('not found') ? 'Error: Transaction Not Found' : ex.message,
                        title: 'Error',
                        icon: 'error'
                    })
                }
            }
        }
    ]
    // STATES FOR MODALS
    const [tPShown, setTPShown] = useState(false);

    const [tempTickets, setTempTickets] = useState<Ticket[]>([]);

    const [tempTicket, setTempTicket] = useState(new Ticket());

    async function getOrderData()
    {
        try {
            Helper.Receipt.PrintOrderReceipt(+norder?.id, 2);
        } catch (ex) {
            console.log('here', ex.message);
        }
    }

    if (norder?.id != null)
        {
            // getOrderData();
            return <Redirect to={'/process-payment/' + norder.id} />
        }
    return (
        <>

{/* MODALS */}

        {(loading || loading1) && <LoadSpinner />}
        {calendarOpen && <DatePicker showTime title="Pickup date" date={order.scheduled} onCancelClicked={(evt) => setCalendarOpen(false)} onDateSelected={async (date) => {
                setCalendarOpen(false);
            if (order.id == -1)
            {
                setOrder({...order, scheduled: date});
            }
            else
            {
                setLoading1(true);
                try {
                    await GraphqlService.SendMutation(gql`mutation($id:Int! $customer_id: Int $scheduled: String){
                        update_order(id:$id order: {
                          customer_id: $customer_id
                          scheduled: $scheduled
                        }){
                          id
                        }
                      }`, {id: order.id, customer_id: order.customer.id, scheduled: date})

                setLoading1(false);
                } catch (ex) {
                    console.log('ex', ex.message);
                    
                    setLoading1(false);
                }
            }
        }} />}
        {tPShown &&  <TicketPickerModal onTickedAdded={async (evt) => {

            let total = evt.service_charges.sumBy(x => x.amount);
            if (total < 0)
            {
                return Swal.fire({
                    title: `Error`,
                    text: `You could not add these services, the ticket can't be negative, Current Total: ` + Helper.Masks.ValidUSMoney(total),
                    icon: 'error'
                });
            }
            if (order.id == -1)
            {
                let temp = [...order.tickets];
                temp.push(evt);
                setOrder({...order, tickets: temp});
                setTPShown(false);
                setCurrentTicket(temp.length - 1);
                lastTicket.current = temp.length - 1;
            }
            else
            {
                if (order.id != -1)
                {
                    setLoading1(true);
                    try {
                        let obj ={name: evt.name, order_id: order.id, label: '', service_group_id: evt.service_group_id, service_charges: evt.service_charges.map((sc) => {
                            delete sc.__typename;
                            delete sc.id;
                            return sc;
                        })};

                        
                       let data = await GraphqlService.SendMutation(gql`
                            mutation($input:add_ticket){
                                create_ticket(input:$input){
                                    id
                                }
                            }
                        `, {input: obj});


                        setTPShown(false);
                    
                    setLoading1(false);
                    } catch (ex) {
                        console.log('ex', ex.message);
                        setLoading1(false);
                    }
                }
            }
        }} onCloseClicked={(evt) => setTPShown(false)} />}
{/* END MODALS */}

            <div className='flex-1-container'>
                <div className="d-flex justify-content-between px-0 mb-3">
                    <h4 className='header-title px-0'>{order_id != null ? 'Details for order #' + order_id : 'Create new order'}</h4>
                    <div className='d-flex align-items-center'> 
                    {order_id != null && <img onClick={async (evt) => {
                            let order_id = order.id;

                            let payments = order.tickets.flatMap(x => x.service_charges).filter(x => x.payment_id != null).map(y => y.payment_id);
            
                            if (payments.length > 0)
                            {
                                for (let p of payments)
                                {
                                    
                                    await Helper.Receipt.ShowConfirmation(p, 1);
                                }
                            }
                            else
                            {
                                await Helper.Receipt.PrintOrderReceipt(+order_id, 1);
                            }
                        }} className='cursor-pointer' src={ICONS['IconPrintYellow']} />}
                    {order_id != null && <button className="btn btn-clear text-red ml-1" onClick={(evt) => {
                        if (order.tickets.flatMap(x => x.service_charges).some(x => x.payment_id != null))
                        {
                            return Swal.fire({
                                title: 'Error',
                                text: `You can't delete this order because one or more services are already paid.`,
                                icon: 'error'
                            })
                        }

                        

                        Swal.fire({
                            title: 'Are you sure?',
                            text:  `Delete Order O-${order.id}, You won't be able to revert this!`,
                            icon: 'question',
                            showDenyButton: true,
                            confirmButtonText: 'Yes, delete it!',
                            denyButtonText: 'Cancel'
                        }).then(async r => {
                            if (r.isConfirmed)
                            {
                                
                                try {
                                    
                                    await GraphqlService.SendMutation(gql`mutation ($orderId: Int){
                                        delete_order(orderId: $orderId)
                                    }`, {orderId: order.id});
                                } catch (ex) {
                                    Swal.fire({
                                        title: 'Error',
                                        text: ex.message,
                                        icon:'error'
                                    })
                                }
                            }
                        })
                    }}>DELETE ORDER <i className="fas fa-times ml-2" style={{transform: 'scale(1.5)'}}></i></button>}
                    
                    </div>
            </div>
                

                <div className='flex-1-container row-container'>
                {/* LEFT SIDE */}
                <div className="col-5 pl-0 pt-0 pb-0 pr-3 d-flex">
                    <div className="flex-1-container">
                        <div>
                        <div className={"card p-4 mb-3 " + ((order.customer.id == -1 ) ? 'red-border' : 'transparent-border')}>
                        <CustomerInfo onCustomerUpdated={(evt) => {
                            setOrder({...order, customer: {...evt}});
                        }} onCustomerEditClicked={(customer) => {
                            setCurrentScreen(Screens.Customer)
                            setCurrentActionS(ActionScreens.Ticket);
                            setCurrentTicket(-1)
                            lastTicket.current = -1;
                            
                        }} customer={order.customer}/>
                    </div>

                    <div  className={"card p-4 mb-3 " + (((Screens.Ticket == currentScreen || Screens.SelectTicket == currentScreen) && order.tickets.length == 0) ? 'red-border' : 'transparent-border')}> 
                        <TicketsDetails onAddMoreItemsClicked={(evt) => {
                            resetTypePicker();
                            setTempTickets([]); setTPShown(true)}}
                                        currentTicket={currentTicket} tickets={order.tickets}
                                        
                                        onDeleteClicked={(index) => {
                                            if (order.tickets[index]?.id == -1)
                                            {
                                                setCurrentScreen(Screens.SelectTicket);
                                                setCurrentTicket(-1);
                                                lastTicket.current = -1;
                                                const temp = order.tickets.removeAt(index);
                                                setOrder({...order, tickets: temp});
                                            }
                                            else
                                            {

                                                if (order.tickets[currentTicket].service_charges.some(x => x.payment_id != null))
                                                {
                                                    return Swal.fire({
                                                        title: 'Error',
                                                        text: `You can't delete this ticket because one or more services are already paid.`,
                                                        icon: 'error'
                                                    })
                                                }
                                                let last = order.tickets.length == 1;
                                                Swal.fire({
                                                    title: 'Are you sure?',
                                                    text:  `Delete Ticket T-${order.tickets[index].id}${last ? ' (this action also will delete the order)' : ''}, You won't be able to revert this!`,
                                                    icon: 'warning',
                                                    showCancelButton: true,
                                                    confirmButtonColor: '#3085d6',
                                                    cancelButtonColor: '#d33',
                                                    confirmButtonText: 'Yes, delete it!'
                                                  }).then(async (result) => {
                                                    if (result.isConfirmed) {
                                                        try {

                                                            setLoading1(true);
                                                            await GraphqlService.SendMutation(gql`mutation($ticketId:Int){
                                                                delete_ticket_by_id(ticketId:$ticketId)
                                                              }`, {ticketId: order.tickets[index].id})
                                                            if (last)
                                                                setCurrentScreen(Screens.SelectTicket)
                                                            setLoading1(false);
                                                            if (!last)
                                                                Swal.fire(
                                                                    'Deleted!',
                                                                    'Your ticket has been deleted.',
                                                                    'success'
                                                                )
                                                        } catch (error) {
                                                            setLoading1(false);
                                                            console.log('error', error.message);
                                                        }
                                                    }
                                                  })
                                            }
                                        }}
                                        onTicketSelected={(index) => {
                                            let current = index;

                                            setCurrentTicket(current);
                                            lastTicket.current = current;
                                            setTempTicket(order.tickets[current]);
                                            setCurrentScreen(Screens.Ticket);

                                        }}/>
                    </div>

                    <div className="card p-4 mb-3">
                        <div className='d-flex justify-content-between align-items-center'>

                            <h6 className="title-text fw-400">Pickup date</h6>
                            <button className='btn btn-orange-light' onClick={(evt) => setCalendarOpen(true)}>EDIT</button>
                        </div>
                        <h4 className='title-text'>{Helper.FORMAT.USDate(order.scheduled, 'n')}</h4>
                    </div>

                    <div className="card mb-3">
                        <OrderBreakdown 
                        onChargesUpdated={(order) => {
                            if (order.id == -1 || !order.id)
                                setOrder(order);
                                
                        }} order={order} />
                    </div>

                    <div className="card p-4">
                        
                        <h6 className="title-text fw-400">Order Logs</h6>

                        <div className="pl-3">
                        {order.log.map((note, i) => <div key={'n-' + (i + 1)} className='mt-3'>
                            <h6 className="regular-text fw-400">{note.message}</h6>
                            <h6 className="regular-text fw-600 mt-2">Made by <span
                                className=' text-orange'>{note.username}</span> at {Helper.FORMAT.USDate(note.time, 'n')}
                            </h6>
                        </div>)}
                    </div>
                    </div>

                    
                        </div>

                        <div className='pt-3' style={{position: 'sticky', bottom: '0', background: '#F5F8FA'}}>
                        {order_id == null && <button disabled={order.customer.id == -1 || order.tickets.length == 0} className="btn btn-orange btn-block" onClick={handleCreateOrder}>CREATE ORDER</button>}
                        {order_id != null && order.amount_remaining > 0 && <Link className="d-block text-center btn btn-orange btn-block" to={'/process-payment/' + order.id}>PROCESS PAYMENT</Link>}
                        {order_id != null && order.id != -1 && order.amount_remaining <= 0 && <Status className='btn btn-block br-0 text-center fw-bold' statusName='PAID' />}
                    </div>
                    </div>
                    
                </div>

                {/* RIGHT SIDE */}

                <div className="col-7 action-side d-flex" >
                    <div className="flex-1-container p-3 bg-white rounded-card col-12">
                    {order_id != null && <div className='d-flex mb-3'>
                            <h5 onClick={(evt) => setCurrentActionS(ActionScreens.Ticket)} className={"regular-text cursor-pointer " + (currentActionS != ActionScreens.Ticket ? 'text-gray' : '')}>Order</h5>
                            <h5 onClick={(evt) => setCurrentActionS(ActionScreens.Invoices)} className={"regular-text cursor-pointer mx-3 " + (currentActionS != ActionScreens.Invoices ? 'text-gray' : '') }>Invoices</h5>
                            <h5 onClick={(evt) => setCurrentActionS(ActionScreens.Payments)} className={"regular-text cursor-pointer " + (currentActionS != ActionScreens.Payments ? 'text-gray' : '')}>Payments</h5>
                        </div>}
                        {currentActionS == ActionScreens.Ticket && <div className='flex-1-container'>{renderSwitch()}</div>}
                        {currentActionS == ActionScreens.Invoices && <div className='flex-1-container'><InvoicesTable invoicesData={invoices.map(invoice => ({
                            id: invoice.id,
                            due: '-',
                            link: invoice.link,
                            profusepay_link: invoice.profusepay_link,
                            reference: invoice.order_reference,
                            customer: invoice.name,
                            status: invoice.items.every(x => x.payment_id) || invoice.amount == 0 ? 'PAID' : 'NOT PAID',
                            payment: [!invoice.transaction_id ? '-' :  `P-${invoice.transaction_id}`, Helper.FORMAT.USDate(invoice.time, 'do')],
                            time: [Helper.FORMAT.USDate(invoice.time, 'do'), Helper.FORMAT.USDate(invoice.time, 'to')],
                            amount: Helper.FORMAT.USCurrency(invoice.amount)
                        }))} /></div>}
                        {currentActionS == ActionScreens.Payments &&
                            <div className='flex-1-container'>
                                <Table 

                                    menuOptions={options}
                                    rows={payments.map(x => ({
                                        time: [Helper.FORMAT.USDate(x.time, 'do'), Helper.FORMAT.USDate(x.time, 'to')],
                                        order_number: x.order_number,
                                        status: x.status,
                                        amount: x.amount,
                                        type: x.type,
                                        payment_number: x.payment_number,
                                        transaction_number: x.transaction_number,
                                        charges: [x.charges.length > 0 ? x.charges[0]?.name?.substring(0, 10) + '...' : x.charges[0]?.name, x.charges.length > 1 ? '+' + (x.charges.length - 1) : '']

                                    }))}
                                    onRowClicked={(evt) => evt}
                                    
                                    tableHeight={'72.5vh'}
                                columns={paymentColumns} />
                            
                            </div>}
                    
                       
                    </div>
                </div>
            </div>
            </div>
            {
            
            
            }
        </>
    )
}

export default OverviewPage;
