import { ObservableQuery } from 'apollo-client';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Order } from '../../classes/classes';

import LoadSpinner from '../../components/LoadSpinner/LoadSpinner';
import MessageBox from '../../components/MessageBox/MessageBox';
import { GET_ORDER_BY_ID } from '../../graphql/query/Order';

import Helper from '../../Helper/Helper';
import { resetNewOrderAction } from '../../redux/actions/order.action';
import AuthService from '../../services/auth.service';
import { Mutation } from '../../services/Endpoints/enpoints';
import { GraphqlService, ObservableQueryT } from '../../services/graphql.service';
import CardForm from './CardForm/CardForm';
import CashForm from './CashForm/CashForm';

const ProcessPayment = ({onToggleSideMenu}: {onToggleSideMenu: (evt: any) => void}) => {

    const dispatch = useDispatch();
    const { master_id } = useParams<{master_id: string}>();
    const [master, setMaster] = useState<any>({});
    const [contact, setContact] = useState<{email: string, phone: string}>({email: '', phone: ''});
    const [paymentType, setPaymentType] = useState<'' | 'card' | 'cash' | 'later' | 'check'>('')
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [reference, setReference] = useState('');
    const [orderQuery, setOrderQuery] = useState<ObservableQueryT>();
    const [order, setOrder] = useState(new Order());

    const getUnPaidCharges = (o = order) => {
        return [...o.tickets.flatMap(x => x.service_charges), ...o.service_charges].filter(x => x.payment_id == null)
    }

    function loadOrder()
    {
        
        try {
            let z = GraphqlService.SendQueryObservable(GET_ORDER_BY_ID, {orderId: +master_id});
            setOrderQuery(z);
            z.onResults.subscribe(result => {
                
                if (result.data)
                {
                    let o = new Order(JSON.parse(JSON.stringify(result.data)));
                    let tot =   o.amount_remaining;
                    setTotal(tot)
                    if (tot == 0)
                        showMessage('Error', 'This order is already paid: O-' + master_id, 'redirect');
                    setOrder({...o, amount_remaining: o.amount_remaining, scheduled: o.scheduled, tickets: o.tickets.sort((x, y) => x.id > y.id ? 1 : -1), customer: o.customer, completed: o.completed, id: o.id, log: o.log, note: o.note, time: o.time, user: o.user});
                }
                else if (!result.error)
                {
                        showMessage('Error', 'This order does not exist: O-' + master_id, 'redirect');
                }
                
                
            })
        } catch (ex) {
            
        }
    }

    const [paymentMethods, setPaymentMethods] = useState([
        {
            title: 'Debit/Credit Card',
            subtitle: '',
            value: 'card'
        },
        {
            title: 'Cash',
            subtitle: '',
            value: 'cash'
        },
        {
            title: 'Check',
            subtitle: '',
            value: 'check'
        },
        {
            title: 'Send Invoice',
            subtitle: '',
            value: 'later'
        }]);

    async function sendInvoice() {
        try {
            setLoading(true);
            await Mutation.payment_request({order_id: order.id, phone: contact.phone, email: contact.email});
              setLoading(false);
            // let id = await PaymentsEndpoint.transactionInvoice(data.id, `INV-${data.id} for M-${master.id}`, master.address.customerInfo.name, Helper.Util.ServicesChargeToItems(master.orders[0].service_charges), contact.phone, contact.email, contact.note);
            showMessage('Message', 'Invoice was sent successfully', 'redirect');
            
        } catch (ex) {
            setLoading(false);
            showMessage('Error', ex.message, '');
        }
    }
    async function loadMaster() {
        try {

            setLoading(true);
            let data = {}; // await MasterOrderEndpoint.getMasterByOrderId(+master_id);
            
            setMaster(data);
            // let total = data.orders.sumBy(x => x.service_charges.filter(y => (y.payment_id || -1) == -1).sumBy(y => y.charge))
            // if (total == 0)
            //     return showMessage('Warning', 'This master is already paid', 'redirect');
            // setTotal(total)
            setContact({phone: order.customer.phone || '', email: order.customer.email || ''});
            setLoading(false);
        } catch (ex) {
            setLoading(false);
            showMessage('Error', ex.message, 'redirect');
        }
    }

    function showMessage(title: string, message: string, reference: string){
        setTitle(title);
        setMessage(message);
        setReference(reference);
        setOpen(true);
    }

    useEffect(() => {

        dispatch(resetNewOrderAction());
        if (isNaN(Number(master_id)))
            return showMessage('Error', 'Invalid Master Order Number: ' + master_id, 'redirect');

            loadOrder();


        loadMaster();
    }, []);

    async function handleJustPrint(evt) {
        try {
            Helper.Navigation.NavigateTo('/');
            await Helper.Receipt.PrintOrderReceipt(+order.id, 2);
        } catch (ex) {
            console.log('here', ex.message);
        }
    }

    
    return( <div className='main-container flex-1-container' >
        {loading && <LoadSpinner />}
        <MessageBox onConfirmClicked={(ref) => {
            if (ref == 'redirect')
                Helper.Navigation.NavigateTo('/');
        }} isOpen={open} setIsOpen={setOpen} message={message} title={title} reference={reference} />
    <div className='d-flex main-header align-items-center justify-content-between'>

        <div className="d-flex align-items-center mb-3">
            <h4 className='header-title'>Process Payment for O-{master_id}</h4>
        </div>
        <h4 className="header-title">{Helper.Masks.ValidUSMoney(order.amount_remaining)}</h4>
    </div>
        <div className='main-content flex-1-container'>
            <div className='inner-content flex-1-container'>
                <div className='d-flex justify-content-between flex-1-container'>
                    <div className='small-form mx-auto flex-1-container bg-white rounded-card'>
                        <div className="flex-1-container p-3">
                            <h4 className="title-text mb-3">Payment method</h4>
                            <div className="flex-1-container">
                            {paymentMethods.map(x => (paymentType == x.value || paymentType == '') && <div onClick={(evt) => setPaymentType(x.value as any)} className={'row-outlined p-3 mb-3' + (paymentType == x.value ? ' selected' : '')}>
                                <h6 className="regular-text py-1">{x.title}</h6>
                                <h6 className="regular-text fw-light py-1">{x.subtitle}</h6>
                            </div>)}

                            

                            {paymentType == 'cash' && <CashForm order={order} onCancelClicked={(evt) => setPaymentType('')} />}
                            {paymentType == 'check' && <CashForm check order={order} onCancelClicked={(evt) => setPaymentType('')} />}
                            {paymentType == 'card' && <CardForm master={order} onCancelClicked={(evt) => setPaymentType('')} />}
                            {paymentType == 'later' &&
                                <div className='flex-1-container'>
                                    <div className="flex-1-container">
                                        <div className="form-group mb-3">
                                            <label htmlFor="email">EMAIL*</label>
                                            <input id='email' name='email' value={contact.email} onChange={(evt) => setContact({...contact, email: evt.target.value})} placeholder='abc@xyz.com' type="text" className="form-field"/>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="phone">PHONE*</label>
                                            <input id='phone' name='phone' value={contact.phone} onChange={(evt) => setContact({...contact, phone: Helper.Masks.USPhone(evt.target.value)})} placeholder='000-000-0000' type="text" className="form-field"/>
                                        </div>
                                    </div>
                                    <button className="btn btn-orange btn-block mt-4" disabled={!(!contact.email ? contact.phone.length == 12 : (!contact.phone ? Helper.Validators.IsValidEmail(contact.email) : Helper.Validators.IsValidEmail(contact.email) && contact.phone.length == 12)) } onClick={(evt) => sendInvoice()}>SEND INVOICE</button>
                                    <button className="btn btn-orange-outline btn-block mt-2" onClick={(evt) => {
                                        // setContact({phone: master.contacts[0]?.json_contacts.phones[0] || '', email: master.contacts[0]?.json_contacts.emails[0], note: ''});
                                        setPaymentType('');
                                    }}>CANCEL</button>
                                </div>}
                            </div>
                            {paymentType == '' && <button className='btn btn-orange-outline btn-block mt-3' onClick={(evt) => handleJustPrint(evt)}>PRINT RECEIPT & PAY LATER</button>}
                            {paymentType == '' && <button className='btn btn-orange-outline btn-block mt-3' onClick={(evt) => Helper.Navigation.NavigateTo('/')}>NO RECEIPT</button>}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>)
}

export default ProcessPayment;