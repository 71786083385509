import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {setCustomerStart} from "../../saga/Customer/customer.actions";
import CustomerDetailTable from "./CustomerDetailTable/CustomerDetailTable";
import CustomerForm from '../../components/Forms/CustomerForm/CustomerForm';

const mapState = ({customersData}) => ({
    customer: customersData.customer
});

export const CustomerDetail = () => {
    let { customer_id } = useParams<any>();

    const dispatch = useDispatch();
    const { customer } = useSelector(mapState);

    useEffect(() => {
        getCustomer().then();
    }, [customer_id]);

    async function getCustomer() {
        
        return dispatch(setCustomerStart(customer_id));
    }

    const [editMode, setEditMode] = useState(false);

    return (
        <>

        {editMode && <div className="modal">
            <div className="modal-content col-6">
                <CustomerForm customer={customer} onCancelClicked={(evt) => setEditMode(false)} onCustomerSubmitted={(evt) => {
                    setEditMode(false);
                    getCustomer().then();
                }} />
            </div>            
        </div>}
            <div className="row">
                <div className="col-12">
                    <p className="font-20 fw-600">{ customer?.name }</p>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="font-14">Contact</p>
                                <button disabled={(customer?.id || -1) == -1} className="btn btn-orange-light px-3 rounded-pill" onClick={(evt) => {
                                    setEditMode(true);
                                }
                                    
                                    }>Edit</button>
                            </div>

                            <p className="font-16 fw-600 mt-3">{ customer?.name }</p>
                            <p className="font-16 fw-500">{ customer?.phone }</p>
                            <p className="font-16 fw-500">{ customer?.email }</p>
                            <p className="font-16 fw-500">{ customer?.note }</p>
                        </div>
                    </div>

                    {/* <div className="card mb-3">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="font-14">Customer Balance</p>
                                <p className="font-14 fw-600 text-orange">NET30</p>
                            </div>


                            <div className="d-flex justify-content-between align-items-center border-bottom py-3">
                                <p className="font-16 fw-500">Total due</p>
                                <p className="font-16 fw-600">$ 1,000.00</p>
                            </div>

                            <div className="d-flex justify-content-between align-items-center border-bottom py-3">
                                <p className="font-16 fw-500">Current</p>
                                <p className="font-16 fw-600">$ 100.00</p>
                            </div>

                            <div className="d-flex justify-content-between align-items-center border-bottom py-3">
                                <p className="font-16 fw-500">31 - 60 days</p>
                                <p className="font-16 fw-600">-</p>
                            </div>

                            <div className="d-flex justify-content-between align-items-center border-bottom py-3">
                                <p className="font-16 fw-500">61 - 90 days</p>
                                <p className="font-16 fw-600">$ 100.00</p>
                            </div>

                            <div className="d-flex justify-content-between align-items-center border-bottom py-3">
                                <p className="font-16 fw-500">91+ days</p>
                                <p className="font-16 fw-600">-</p>
                            </div>
                        </div>
                    </div>
                         */}
              
                </div>

                <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                    <CustomerDetailTable customerId={customer_id}/>
                </div>
            </div>
        </>
    )
}

export default CustomerDetail;
