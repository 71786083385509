import Table from "../../components/Table/Table";
import IColumn from "../../components/Table/models/IColumn";
import Searchbar from "../../components/Searchbar/Searchbar";
import { useEffect, useRef, useState } from "react";
import Helper from "../../Helper/Helper";
import { useDispatch, useSelector } from "react-redux";
import { getHistoryOrdersAction, getOpenOrdersAction } from "../../redux/actions/order.action";
import { Link } from "react-router-dom";
import ICONS from "../../../assets/svg";
import Swal from "sweetalert2";
import { GraphqlService } from "../../services/graphql.service";
import gql from "graphql-tag";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import moment from "moment";
import { getAllOrdersSP } from "../../services/Endpoints/classes";
import IRow from '../../components/Table/models/IRow'
import MessageBox from "../../components/MessageBox/MessageBox";
import { Mutation } from "../../services/Endpoints/enpoints";

enum Screens {
    Open,
    History
}


const OrdersPage = () => {

    const dispatch = useDispatch();

    const orders = useSelector<any>(state => state.orders.orders) as any[]; 
    const loading = useSelector<any>(state => state.orders.loading) as boolean;
    const [searchTerm, setSearchTerm] = useState('');
    const [gotoOrder, setGotoOrder] = useState('');
    const [start, setStart] = useState(new Date((moment().add(-1, 'month')).format('YYYY/MM/DD 00:00:00')));
    const [end, setEnd] = useState(new Date(moment().format('YYYY/MM/DD 23:59:59')))
    const [dateOpen, setDateOpen] = useState(false);
    const [currentScreen, setCurrentScreen] = useState(Screens.Open);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [reference, setReference] = useState<any>();
    const [type, setType] = useState<'alert' | 'confirmation'>('alert');

    const accountColumns: IColumn[] = [
        { orderBy: 'ASC', active: false, label: 'PICKUP DATE', name: 'pickup_date' },
        { orderBy: 'ASC', active: false, label: 'TICKETS', name: 'tickets' },
        { orderBy: 'ASC', active: false, label: 'ORDER #', name: 'order_number', sort_by: 'order_id' },
        { orderBy: 'ASC', active: false, label: 'CUSTOMER', name: 'customer' },
        { orderBy: 'ASC', active: false, label: 'CHARGE', name: 'charge' },
        { orderBy: 'ASC', active: false, label: 'BALANCE', name: 'balance' },
        { orderBy: 'ASC', active: false, label: 'ORDERED ON', name: 'ordered_on' },
        { orderBy: 'ASC', active: false, label: 'PAYMENT #', name: 'payments' }
    ];

    const [includeAll, setIncludeAll] = useState(false);

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        let order_id = Helper.Masks.IntNumbers(gotoOrder);
        Helper.Navigation.NavigateTo('/order/' + order_id)
    }


  function showMessage(title: string, message: string, reference: string = '', type: 'alert' | 'confirmation' = 'alert'){
    setTitle(title);
    setMessage(message);
    setReference(reference);
    setOpen(true);
    setType(type);
}

    const options = [
        {
            label: 'Merge Orders',
            icon: ICONS.IconProcessPayment,
            condition: (x: IRow | IRow[]) => {
                return Array.isArray(x);
            },
            multipleRows: true,
            action: async (data: {row:getAllOrdersSP}[]) => {
                console.log('data', data);
                if(!data || data.length == 0) return;


                if(data.filter(x => x.row.customer_id != data[0].row.customer_id).length > 0){
                    showMessage('Cannot do that', 'All orders must belong to the same customer');
                }
                else{
                    try {
                        let res = await Mutation.merge_orders({order_ids: data.map(x=>x.row.order_id)});
                        if(!res.success){
                            throw new Error(res ? res.message : 'Could not communicate with the server');
                        }
                        showMessage('Orders merged', res.message, '', 'confirmation');
                    } catch (ex) {
                        console.log('Error in Merge Orders Menu: ', ex.message);
                        showMessage('Failed to Merge', ex.message);
                    }
                }
            }
        },
        {
            label: 'Merge Orders and Process Payment',
            icon: ICONS.IconProcessPayment,
            condition: (x: IRow | IRow[]) => {
                return Array.isArray(x);
            },
            multipleRows: true,
            action: async (data: {row:getAllOrdersSP}[]) => {
                console.log('data', data);
                if(!data || data.length == 0) return;

                if(data.filter(x => x.row.customer_id != data[0].row.customer_id).length > 0){
                    showMessage('Cannot do that', 'All orders must belong to the same customer');
                }
                else{
                    try {
                        let res = await Mutation.merge_orders({order_ids: data.map(x=>x.row.order_id)});
                        if(!res.success || !res.id){
                            throw new Error(res ? res.message : 'Could not communicate with the server');
                        }
                        Helper.Navigation.NavigateTo('/process-payment/' + res.id);
                    } catch (ex) {
                        console.log('Error in Merge Orders Menu: ', ex.message);
                        showMessage('Failed to Merge', ex.message);
                    }
                }
            }
        },
        {
            label: 'Process Payment',
            icon: ICONS.IconProcessPayment,
            action: (data) => {
                let id = data.order_id;

                Helper.Navigation.NavigateTo('/process-payment/' + id);
            }
        },
        {
            label: 'See order details',
            icon: ICONS.IconDetails,
            action: (evt) => {
                let order_id = Helper.Masks.IntNumbers(evt.order_number);
                Helper.Navigation.NavigateTo('/order/' + order_id)
            }
        },
        {
            label: 'Print receipt',
            icon: ICONS.IconReceipt,
            action:async (evt) => {
                let order_id = Helper.Masks.IntNumbers(evt.order_number);

                let payments = evt.payments.split(',').filter(x => x != '').map(x => +Helper.Masks.IntNumbers(x));

                if (payments.length > 0)
                {
                    for (let p of payments)
                    {
                        await Helper.Receipt.ShowConfirmation(p, 1);
                    }
                }
                else
                {
                    await Helper.Receipt.PrintOrderReceipt(+order_id, 1);
                }
            }
        },
        {
            label: 'Cancel Order',
            icon: ICONS.IconDelete,
            action: (evt) => {
                if (evt.payments)
                {
                    return Swal.fire({
                        title: 'Error',
                        text: `This order can't be deleted because has some services already paid.`,
                        icon: 'error'
                    });
                }
                else{

                    let order_id = +Helper.Masks.IntNumbers(evt.order_number);
                    Swal.fire({
                        title: 'Are you sure?',
                        text:  `Delete Order O-${order_id}, You won't be able to revert this!`,
                        icon: 'question',
                        showDenyButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        denyButtonText: 'Cancel'
                    }).then(async r => {
                        if (r.isConfirmed)
                        {
                            try { 
                                await GraphqlService.SendMutation(gql`mutation ($orderId: Int){
                                    delete_order(orderId: $orderId)
                                }`, {orderId: order_id});
                                Swal.fire({
                                    title: 'Success',
                                    text: 'Order has been deleted',
                                    icon: 'success'
                                })
                            } catch (ex) {
                                Swal.fire({
                                    title: 'Error',
                                    text: ex.message,
                                    icon:'error'
                                })
                            }
                        }
                    })
                }
            }
        },
    ];

    const handleDblClick = (evt) => {
        
        let order_id = Helper.Masks.IntNumbers(evt.order_number || evt.order_id);
        

        let link = `/order/` + order_id;
        
        Helper.Navigation.NavigateTo(link)
    }

   const accounts = [];

    async function loadData(event) {
        event.complete();
    }

    const searchRef = useRef();
    const gotoOrderRef = useRef();


    useEffect(() => {
        if(currentScreen == Screens.Open){
            dispatch(getOpenOrdersAction());
        }
        else if (currentScreen == Screens.History){
            dispatch(getHistoryOrdersAction(start, end));
        }
    }, [])


    useEffect(() => {
        if(currentScreen == Screens.Open){
            dispatch(getOpenOrdersAction());
        }
        else if (currentScreen == Screens.History){
            dispatch(getHistoryOrdersAction(start, end));
        }
    }, [currentScreen])


    const ordersFiltered= () => 
    {
        // let array = [];

        // if (includeAll)
        //     array = [...orders];
        // else
        //     array = orders.filter(x => !x.completed);


        return orders.filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim()));
    }

    return (
        <>
        <MessageBox isOpen={open} setIsOpen={setOpen} title={title} message={message} type={type}
        onConfirmClicked={(ref) => {setOpen(false)}}/>
            <div className="px-0 flex-1-container">
                <div className="d-flex align-items-center justify-content-between col-12 mb-3">
                    
                    <h4 className='header-title'>Orders</h4>
                    <div className="col-6 d-flex">
                        <div className="mr-3">
                            {currentScreen == Screens.History && <button style={{minWidth: 'max-content'}} className="btn btn-orange-outline d-flex" onClick={(evt) => setDateOpen(true)}>
                                <i className="fa fa-calendar mr-2"></i> 
                                {moment(start).format('YYYY/MM/DD') == moment(end).format('YYYY/MM/DD') ? 
                                moment(start).format('MM/DD/YYYY')    
                                :
                                moment(start).format('MM/DD/YYYY')    + ' - ' + moment(end).format('MM/DD/YYYY')    
                            }
                            </button>}
                        </div>
                        <div className=' mr-3' style={{flex: 1}}>
                            <Searchbar value={searchTerm} onChange={(evt) => setSearchTerm(evt.target.value)} background='#FFF' reference={searchRef} />
                        </div>

                        <button className="btn" style={{background: '#FFF', width: '2rem', height: '2rem', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img onClick={(evt) => dispatch(getHistoryOrdersAction(start, end))} className={loading && 'spin'} src={ICONS.IconRefresh} alt=""/>
                        </button>
                    </div>
                    <Link className="btn btn-orange px-5" to='/new-order'>Create new order</Link>
                </div>

                <div className="flex-1-container">
                    <div className="row">
                        <div className="col-9">
                            <nav className="App-header pb-0" style={{width: 'auto'}}>
                                <ul className='pt-0'>
                                    <li>
                                        <a onClick={(evt) => setCurrentScreen(Screens.Open)} className={currentScreen == Screens.Open ? 'active' : ''}>OPEN ORDERS</a>
                                    </li>
                                    <li>
                                        <a onClick={(evt) => setCurrentScreen(Screens.History)} className={currentScreen == Screens.History ? 'active' : ''}>PAST ORDERS</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="d-flex align-items-center justify-content-end col-3" >
                            <p className="mr-3">GO TO:</p>
                            <Searchbar onSubmit={handleSubmit.bind(this)} placeholder="Order #" value={gotoOrder} onChange={(evt) => setGotoOrder(Helper.Masks.IntNumbers(evt.target.value))} background='#FFF' reference={gotoOrderRef}/>
                        </div>
                    </div>
                    <div className="flex-1-container mt-3 bg-white rounded-card p-3">
                        <div className="col-12 col-sm-12 col-12 flex-1-container">
                            <div className="flex-1-container">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                        <p className="font-14 fw-600">{ordersFiltered().length} / {orders.length} Orders</p>
                                    </div>
                                    <Table 
                                        showCheckbox = {true}
                                        menuOptions={options}
                                        onDoubleClick={handleDblClick}
                                        columns={accountColumns}
                                        loading={loading}
                                        rows={ordersFiltered()}
                                        tableHeight="71vh"
                                        onRowClicked={() => {}}/>
                            </div>
                        </div>
                    </div>
                </div>
        </div>


        {dateOpen && <DateTimePicker
        hideTime
        onCancelClicked={(evt) => {
            setDateOpen(false)
        }}
        date={[start, end]} onDateSubmitted={evt => {
            evt[0] = new Date(moment(evt[0]).format('YYYY/MM/DD') + ' 00:00:00');
            evt[1] = new Date(moment(evt[1]).format('YYYY/MM/DD') + ' 23:59:59');
            
            setStart(evt[0]);
            setEnd(evt[1]);

            setDateOpen(false)
            dispatch(getHistoryOrdersAction(evt[0], evt[1]));
        }} />}
           
        </>
    );
}

export default OrdersPage;
