import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "apollo-boost";
import environments from "../../environment/environment";
import cryptoJS from 'crypto-js';
import {randomRegularString} from '../services/encrypt'


const getPlatform = (token: string) => {
    let secret = token.substring(token.length - 8);
    let time = (new Date()).getTime().toString();
    return cryptoJS.AES.encrypt(time, secret).toString();

}

function getToken(){
    let token = localStorage.getItem("token") || randomRegularString(8, ' ');
    return token;
}

export const createClient = () => {
    let token = getToken();
    let client = new ApolloClient({
        link: new ApolloLink((operation, forward) => {
            operation.setContext({
                headers: {
                    "Authentication": token,
                    "Platform": getPlatform(token)
                }
            });
            return forward(operation);
        }).concat(
            new HttpLink({
                uri: environments.SERVER_LINK + '/graphql', // Server URL
            })
        ),
        cache: new InMemoryCache({
            addTypename: false
        })

    })
    return client;
}