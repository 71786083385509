import { ObservableQuery } from "apollo-client";
import { Agenda } from "../../classes/classes";
import { GET_AGENDAS, GET_ORDER_AGENDAS } from "../../graphql/query/Agenda";
import { GET_CUSTOMERS } from "../../graphql/query/Customer";
import { GraphqlService, ObservableQueryT } from "../../services/graphql.service";
import { GET_AGENDAS_ERROR, GET_AGENDAS_STARTED, GET_AGENDAS_SUCCESS, GET_AORDERS_ERROR, GET_AORDERS_STARTED, GET_AORDERS_SUCCESS } from "../types/agenda.type"

let agendasQuery: ObservableQueryT;
let aOrdersQuery: ObservableQueryT;



export function getAgendasAction(startMonth: any)
{
    startMonth = new Date(startMonth);

    
    return async dispatch => {
        dispatch(getAgendasStarted(startMonth));

        try {
            // dispatch(clearAgendaOrders());
            let data = await GraphqlService.SendQuery(GET_AGENDAS, {startofmonth: startMonth});
            dispatch(getAgendasSuccess(data));
        } catch (ex) {
            dispatch(getAgendasError(ex.message));
        }
    }    
}
const getAgendasStarted = startMonth => ({type: GET_AGENDAS_STARTED, payload: startMonth});
const getAgendasSuccess = agendas => ({type: GET_AGENDAS_SUCCESS, payload:agendas});
const getAgendasError = error => ({type: GET_AGENDAS_ERROR, payload: error});


export function getAgendasOrderAction(agenda: Agenda[])
{
    return async dispatch => {
        dispatch(getAgendasOrderStarted(agenda))
        let ids = agenda.flatMap(x => x.tickets?.map(y => y.id) || []) || [];
        const variables = {ids: ids.length == 0? [-1] : ids};
        try {
            
            let data = await GraphqlService.SendQuery(GET_ORDER_AGENDAS, variables);
            
            dispatch(getAgendasOrderSuccess(data));
        } catch (ex) {
            dispatch(getAgendasOrderError(ex.message));
        }
    }    
}

function clearAgendaOrders()
{
    return async dispatch => {
        
        dispatch(getAgendasOrderSuccess([]));
    }    
}

const getAgendasOrderStarted = tickets_id => ({type: GET_AORDERS_STARTED, payload: tickets_id});
const getAgendasOrderSuccess = orders => ({type: GET_AORDERS_SUCCESS, payload: orders});
const getAgendasOrderError = error => ({type: GET_AORDERS_ERROR, payload: error});