import customerTypes from './customer.types';

export const setCustomers: any = customers => ({
    type: customerTypes.SET_CUSTOMERS,
    payload: customers
});

export const setCustomerTable: any = customers => ({
    type: customerTypes.SET_CUSTOMERS_TABLE,
    payload: customers
});

export const setCustomerTableById: any = customers => ({
    type: customerTypes.SET_CUSTOMERS_TABLE_BY_ID,
    payload: customers
});

export const setCustomer: any = customer => ({
    type: customerTypes.GET_CUSTOMER_BY_ID,
    customer: customer
});

export const setCustomerStart: any = id => ({
    type: customerTypes.GET_CUSTOMER_BY_ID_START,
    id: id
});

export const fetchCustomersStart: any = () => ({
    type: customerTypes.FETCH_CUSTOMERS_START
});

export const fetchCustomerTableStart: any = () => ({
    type: customerTypes.FETCH_CUSTOMER_TABLE_START
});

export const addCustomerStart = customerData => ({
    type: customerTypes.ADD_CUSTOMER_START,
    payload: customerData
});

export const updateCustomerStart = customerData => ({
    type: customerTypes.UPDATE_CUSTOMER_START,
    payload: customerData
});

export const updateCustomerSuccess = id => ({
    type: customerTypes.UPDATE_CUSTOMER_SUCCESS,
    payload: id
});


export const customerAddedSuccess = customerData => ({
    type: customerTypes.ADD_CUSTOMER_SUCCESS,
    payload: customerData
})

export const resetNewCustomer = () => ({
    type: customerTypes.RESET_NEW_CUSTOMER
})