import Table from "../../components/Table/Table";
import IColumn from "../../components/Table/models/IColumn";
import { Link } from "react-router-dom";
import React, { FC, useEffect, useRef, useState } from "react";
import Searchbar from "../../components/Searchbar/Searchbar";
import Helper from "../../Helper/Helper";
import OrderAging from "../OverviewPage/OrderAging/OrderAging";
import TicketAging from "../OverviewPage/TicketAging/TicketAging";
import AgendaTable from "../../components/AgendaTable/AgendaTable";
import { useDispatch, useSelector } from "react-redux";
import { getAgendasAction, getAgendasOrderAction } from '../../redux/actions/agenda.action';
import moment from "moment";
import { Agenda } from "../../classes/classes";
import InvoiceCard from "../../components/InvoiceCard/InvoiceCard";
import OrderCard from "../../components/OrderCard/OrderCard";
import PaymentCard from "../../components/PaymentCard/PaymentCard";
import OrderBreakdown from "../../components/OrderBreakdown/OrderBreakdown";
import { getOAgingDataAction } from "../../redux/actions/orderAging.action";
import { getTAgingDataAction } from "../../redux/actions/ticketAging.action";
import { GraphqlService } from "../../services/graphql.service";
import gql from "graphql-tag";
import Loading from "../../components/Loading/Loading";
import ICONS from "../../../assets/svg";
import { TicketService } from "../../services/ticket.service";


const DashboardPage = () => {

    const dispatch = useDispatch();

    const [aSearchTerm, setASearchTerm] = useState('');
    const loadingAgenda = useSelector<any>(state => state.agendas.loading) as boolean;
    const agendas = useSelector<any>(state => state.agendas.agendas) as Agenda[];
    const startOfMonth = useSelector<any>(state => state.agendas.startOfMonth) as string;
    const agendaOrders = useSelector<any>(state => state.agendas.ordersAgenda) as any[];
    const loadingAgendaTable = useSelector<any>(state => state.agendas.loadingOAgenda) as boolean;
    const selectedDays = useSelector<any>(state => state.agendas.selectedDays) as boolean;
    const [statusLoading, setStatusLoading] = useState(false);


    // ORDER AGING
    
    const [oSearchTerm, setOSearchTerm] = useState('');
    const ordersAgingData = useSelector<any>(state => state.orderAging.agignData);
    const ordersAging = useSelector<any>(state => state.orderAging.ordersAging) as any[];
    const loadingOAging = useSelector<any>(state => state.orderAging.loading) as boolean;
    const loadingAOrder = useSelector<any>(state => state.orderAging.loadingOAging) as boolean;


    // TASKS AGING

    const [tSearchTerm, setTSearchTerm] = useState('');
    const ticketsAgingData = useSelector<any>(state => state.ticketAging.agignData);
    const ticketsAging = useSelector<any>(state => state.ticketAging.ticketsAging) as any[];
    const loadingAtickets = useSelector<any>(state => state.ticketAging.loadingTAging) as boolean;
    const loadingTAging = useSelector<any>(state => state.ticketAging.loading) as boolean;


    const orderAgingColumns: IColumn[] = [
        {
            active: false, label: 'PICKUP DATE', name: 'pickup_date', orderBy: 'ASC'
        },
        {
            active: false, label: 'ORDER #', name: 'order_number', orderBy: 'ASC', sort_by: 'order_id'
        },
        {
            active: false, label: 'TICKETS', name: 'tickets', orderBy: 'ASC'
        },
        {
            active: false, label: 'CUSTOMER', name: 'customer', orderBy: 'ASC'
        },
        {
            active: false, label: 'CHARGE', name: 'charge', orderBy: 'ASC'
        }
    ]
    
    const agendaCalendar: IColumn[] = [
        {
            active: true, label: 'TASKS #', name: 'ticket_column', orderBy: 'ASC', sort_by: 'ticket_id',
        },
        {
            active: false, label: 'TASKS NAME', name: 'ticket_name', orderBy: 'ASC',
        },
        {
            active: false, label: 'STATUS', name: 'status', orderBy: 'ASC',
        },
        {
            active: false, label: 'CHARGES', name: 'charges', orderBy: 'ASC', sort_by: 'charge_name'
        },
        {
            active: false, label: 'PICKUP DATE', name: 'pickup_date', orderBy: 'ASC', sort_by: 'date',
        },
        {
            active: false, label: 'CUSTOMER', name: 'customer', orderBy: 'ASC', sort_by: 'customer_name',
        },
        {
            active: false, label: 'TAG', name: 'label', orderBy: 'ASC',
        }
    ];

    async function changeStatus(ticket_id, status)
    {
        try {
            setStatusLoading(true);
            await GraphqlService.SendMutation(gql`mutation($id: Int! $status:String){
                update_status(id:$id  status:$status){
                id
                }
            }`, {id: +ticket_id, status});

            setStatusLoading(false);
            handleReload();
        } catch (ex) {
            setStatusLoading(false);
        }
    }

    const options = [

        {
            label: 'Change Status',
            icon: ICONS.IconRefresh,
            condition: (x) => x?.status,
            submenu: [
                {
                    label: 'RECEIVED',
                    condition: (x) => x.status != 'RECEIVED',
                    action: (x) => {
                        changeStatus(x.ticket_id, "RECEIVED")
                    }
                },
                {
                    label: 'IN PROGRESS',
                    condition: (x) => x.status != 'IN PROGRESS',
                    action: (x) => {
                        changeStatus(x.ticket_id, "IN PROGRESS")
                    }
                },
                {
                    label: 'FINISHED',
                    condition: (x) => x.status != 'FINISHED',
                    action: (x) => {
                        changeStatus(x.ticket_id, "FINISHED")
                    }
                },
                {
                    label: 'PICKED UP',
                    condition: (x) => x.status != 'PICKED UP',
                    action: (x) => {
                        changeStatus(x.ticket_id, "PICKED UP")
                    }
                }
            ]
        },
        {
            label: 'Process Payment',
            icon: ICONS.IconProcessPayment,
            action: (data) => {
                let id = data.order_id;

                Helper.Navigation.NavigateTo('/process-payment/' + id);
            }
        },
        {
            label: 'See order details',
            icon: ICONS.IconDetails,
            action: (data) => {

                let ticket_id = data.ticket_id;
                TicketService.TicketSelected = ticket_id;
                let id = data.order_id;
                
                Helper.Navigation.NavigateTo('/order/' + id);
            }
        },
        {
            label: 'Print receipt',
            icon: ICONS.IconReceipt,
            action:async (evt) => {

                let order_id = evt.order_id || -1;

                

                await Helper.Receipt.PrintOrderReceipt(order_id, 1);
            }
        }
    ];


    const handleDblClick = data => {

        
                let ticket_id = data.ticket_id;
                TicketService.TicketSelected = ticket_id;
        
        
                
                Helper.Navigation.NavigateTo('/order/' + data.order_id);
    }

    enum Screens {
        Agenda,
        OrderAging,
        TicketAging,
        Components
    }

    const [currentScreen, setCurrentScreen] = useState(Screens.Agenda);

    // const [columns, setColumns] = useState<any[]>([]);

    async function loadData(event) {
        event.complete();
    }

    const handleOnSearchChanged = evt => {
        switch (currentScreen)
        {
            case Screens.Agenda:
                return setASearchTerm(evt.target.value)
            case Screens.OrderAging:
                return setOSearchTerm(evt.target.value)
            case Screens.TicketAging:
                return setTSearchTerm(evt.target.value)
        }
    }

    const getCurrentSearchValue = () => 
    {
        switch (currentScreen)
        {
            case Screens.Agenda:
                return aSearchTerm;
            case Screens.OrderAging:
                return oSearchTerm;
            case Screens.TicketAging:
                return tSearchTerm;
        }
    }

    const handleReload = () => {
        switch (currentScreen)
        {
            case Screens.Agenda:
                 return dispatch(getAgendasAction(currentAgendaDate.format('YYYY/MM/01'))) 
                 
            case Screens.OrderAging:
                return dispatch(getOAgingDataAction());
            case Screens.TicketAging:
                return dispatch(getTAgingDataAction());
        }
    }

    const isLoading = () => {
        switch (currentScreen)
        {
            case Screens.Agenda:
                return loadingAgenda || loadingAgendaTable;
            case Screens.OrderAging:
                return loadingAOrder || loadingOAging;
            case Screens.TicketAging:
                return loadingAtickets || loadingTAging;
        }
    }

    const Agenda = () => {
        return <>
        {/* <div className="flex-1-container">

        </div> */}

    <div className="w-100 flex-1-container row-container">
    <div className='d-flex' style={{maxWidth: '708px', width: '100%', paddingRight: '1rem'}}>
        <div className="p-3 bg-white rounded-card d-flex" >
            <div className="d-flex flex-1-container">
                <AgendaTable onAgendaSelected={selected => dispatch(getAgendasOrderAction(selected))} loading={loadingAgenda} onMonthChange={(date) => {
                    dispatch(getAgendasAction(date.format('YYYY/MM/01')))
                    setCurrentAgendaDate(date);

                }} currentDate={currentAgendaDate} agendas={agendas} />
            </div>
        </div>
    </div>
    <div className='d-flex' style={{width: '100%'}}>
        <div className="flex-1-container p-3 bg-white rounded-card">
            <div className="flex-1-container">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <p className="font-14 fw-600">{agendaOrders.length} Tasks</p>
                </div>
                <Table
                        menuOptions={options as any}
                        onDoubleClick={handleDblClick}
                        columns={agendaCalendar}
                        loading={loadingAgendaTable}
                        rows={agendaOrders.map(x => (
                            {
                                ticket_id: x.ticket_id,
                                order_id: x.order_id,
                                ticket_column: [x.ticket_number, x.order_number],
                                ticket_name: x.ticket_name,
                                status: x.status.text,
                                charges: ['', ...(x.charges || ['-'])],
                                charge_name: (x.charges?.[0] || '-').trim(),
                                pickup_date: [Helper.FORMAT.USDate(x.pickup_date, 'do'), Helper.FORMAT.USDate(x.pickup_date, 'dd')],
                                date: new Date(x.pickup_date),
                                customer: [x.customer_name, x.customer_phone],
                                customer_name: x.customer_name,
                                label: x.label,
                                order_number: x.order_number
                            })).filter(x => JSON.stringify(x).toLowerCase().includes(aSearchTerm.toLowerCase().trim()))}
                        tableHeight="71vh"
                        onRowClicked={() => {}}/>
            </div>
        </div>
    </div>
    </div>
    
    </>
    }


    const OrderAgingComponent = () => {
        return <>
        <div className="flex-1-container row-container">
        <div className="col-lg-6 col-sm-12 col-12 d-flex pr-3">
             <div className="flex-1-container bg-white p-3 rounded-card" >
                 
                 {loadingOAging && <Loading />}
                     <OrderAging data={ordersAgingData as any} />
             </div>
         </div>
         <div className="col-lg-6 col-sm-12 col-12 d-flex p-0">
             <div className="rounded-card bg-white p-3 flex-1-container" style={{maxHeight: ''}}>
                 <div className="flex-1-container">
                     <div className="d-flex justify-content-between align-items-center mb-3">
                         <p className="font-14 fw-600">{ordersAging.length} Orders Not Paid</p>
                     </div>
                     <Table
                             menuOptions={options as any}
                             onDoubleClick={handleDblClick}
                             columns={orderAgingColumns}
                             loading={loadingAOrder}
                             rows={ordersAging.map(x => ({
                                 pickup_date: [Helper.FORMAT.USDate(x.pickup_date, 'do'), Helper.FORMAT.USDate(x.pickup_date, 'dd')],
                                 order_id: x.order_id,
                                 order_number: x.order_number,
                                 tickets: ['', x.tickets],
                                 customer: [x.customer_name, x.customer_phone],
                                 charge: Helper.FORMAT.USCurrency(x.charge)
                             })).filter(x => JSON.stringify(x).toLowerCase().includes(oSearchTerm.toLowerCase().trim()))}
                             tableHeight="65vh"
                             onRowClicked={() => {}}/>
                 </div>
             </div>
         </div>
        </div>
     </>
    }



    const TicketAgign = () => {
        return <>
        <div className="flex-1-container row-container">
        <div className="col-lg-4 col-sm-12 col-12 pr-3 d-flex ">
            <div className="flex-1-container p-3 bg-white rounded-card" style={{maxHeight: ''}}>
                <div className="flex-1-container">
                {/* {renderView()} */}
                {loadingTAging && <Loading />}
                <TicketAging data={ticketsAgingData} />
                </div>
            </div>
        </div>
        <div className="col-lg-8 col-sm-12 col-12 d-flex">
            <div className="flex-1-container p-3 bg-white rounded-card" style={{maxHeight: ''}}>
                <div className="flex-1-container">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <p className="font-14 fw-600">{ticketsAging.length} Tasks Not Picked Up</p>
                    </div>
                    <Table
                    
                            menuOptions={options as any}
                            onDoubleClick={handleDblClick}
                            columns={agendaCalendar}
                            loading={loadingAtickets}
                            rows={ticketsAging.map(x => (
                                {
                                    ticket_id: x.ticket_id,
                                    order_id: x.order_id,
                                    ticket_column: [x.ticket_number, x.order_number],
                                    ticket_name: x.ticket_name,
                                    status: x.status.text,
                                    charges: ['', ...(x.charges || ['-'])],
                                    charge_name: (x.charges?.[0] || '').trim(),
                                    pickup_date: [Helper.FORMAT.USDate(x.pickup_date, 'do'), Helper.FORMAT.USDate(x.pickup_date, 'dd')],
                                    date: new Date(x.pickup_date),
                                    customer: [x.customer_name, x.customer_phone],
                                    customer_name: x.customer_name,
                                    label: x.label,
                                    order_number: x.order_number
                                })).filter(x => JSON.stringify(x).toLowerCase().includes(tSearchTerm.toLowerCase().trim()))}
                            tableHeight="65vh"
                            onRowClicked={() => {}}/>
                </div>
            </div>
        </div>
        </div>
    </>
    }
    
    
    const ref = useRef();

    const [currentAgendaDate, setCurrentAgendaDate] = useState(moment(startOfMonth || new Date()));

    useEffect(() => {
        dispatch(getAgendasAction(currentAgendaDate.format('YYYY/MM/01')));
        dispatch(getOAgingDataAction());
        dispatch(getTAgingDataAction());
    }, [])


    return (
        <>


            <div className="px-0 flex-1-container">
                <div className="d-flex justify-content-between col-12">
                    
                    <h4 className='header-title'>Dashboard</h4>
                    <Link className="btn btn-orange px-5" to='/new-order'>Create new order</Link>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <nav className="App-header pb-0" style={{width: 'auto'}}>
                        <ul className='pt-0'>
                            <li>
                                <a onClick={(evt) => setCurrentScreen(Screens.Agenda)} className={currentScreen == Screens.Agenda ? 'active' : ''}>AGENDA</a>
                            </li>
                            <li>
                                <a onClick={(evt) => setCurrentScreen(Screens.OrderAging)} className={currentScreen == Screens.OrderAging ? 'active' : ''}>ORDER AGING</a>
                            </li>
                            <li>
                                <a onClick={(evt) => setCurrentScreen(Screens.TicketAging)} className={currentScreen == Screens.TicketAging ? 'active' : ''}>TASKS AGING</a>
                            </li>
                            
                        </ul>
                    </nav>

                    <div className="col-6 d-flex justify-content-end">
                            <div className='col-8'>
                                <Searchbar onChange={handleOnSearchChanged} value={getCurrentSearchValue()} background='#FFF' reference={ref} />
                            </div>
                            
                            <button onClick={(evt) => handleReload()} className="btn ml-3" style={{background: '#FFF', width: '2rem', height: '2rem', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <img className={isLoading() ? 'spin' : ''} src={ICONS.IconRefresh} alt=""/>
                            </button>
                            
                        </div>
                </div>

                <div className="flex-1-container mt-3">
                    {/* {renderView()} */}

                    {currentScreen == Screens.Agenda && Agenda()}
                    {currentScreen == Screens.OrderAging && OrderAgingComponent()}
                    {currentScreen == Screens.TicketAging && TicketAgign()}
                        
                </div>
            </div>



            {statusLoading && <div className='modal'>
                    <div className="modal-content">
                        <h4 className="title-text">Changing Status...</h4>
                        <Loading />
                    </div>
                </div>}
        </>
    );
}

export default DashboardPage;
