import {ITypePickerItem} from "../../../models";
import {Color} from "../../../../../classes/classes";

// PROPS
export type ITypeColorPickerProps = {
    selectedColors: ITypePickerColorItem[] | Color[];
    onColorSelection: any;
}

export type ITypePickerColorItem = {
    id: number,
    color: string,
    value: string,
    count: number,
    selected: boolean
}

// STATE
export type ITypePickerColorState = {
    type: string;
    colorList: ITypePickerColorItem[] | Color[];
}

// ACTION
export type TypePickerAction = {
    type: string
    item: ITypePickerColorItem
}

export type DispatchType = (args: TypePickerAction) => TypePickerAction

// CONST
export const COLOR_LIST =
    [
        {
            id: 1,
            color: 'Black',
            value: '#000000',
            count: 0,
            selected: false
        },
        {
            id: 2,
            color: 'Gray',
            value: '#808080',
            count: 0,
            selected: false
        },
        {
            id: 3,
            color: 'White',
            value: '#FFFFFF',
            count: 0,
            selected: false
        },
        {
            id: 4,
            color: 'Beige',
            value: '#DEB887',
            count: 0,
            selected: false
        },
        {
            id: 5,
            color: 'Red',
            value: '#FF0000',
            count: 0,
            selected: false
        },
        {
            id: 6,
            color: 'Orange',
            value: '#FFA500',
            count: 0,
            selected: false
        },
        {
            id: 7,
            color: 'Yellow',
            value: '#FFFF00',
            count: 0,
            selected: false
        },
        {
            id: 8,
            color: 'Pink',
            value: '#FFC0CB',
            count: 0,
            selected: false
        },
        {
            id: 9,
            color: 'Green',
            value: '#008000',
            count: 0,
            selected: false
        },
        {
            id: 10,
            color: 'Blue',
            value: '#0000FF',
            count: 0,
            selected: false
        },
        {
            id: 11,
            color: 'Purple',
            value: '#800080',
            count: 0,
            selected: false
        },
        {
            id: 12,
            color: 'Brown',
            value: '#A52A2A',
            count: 0,
            selected: false
        },
        {
            id: 13,
            color: 'Mixed',
            value: '#778899',
            count: 0,
            selected: false
        },


    ];
