import { Agenda, Customer, Order } from '../../classes/classes';
import { ITicket } from '../../models/interfaces';
import IReducerAction from '../models/IReducerAction';
import
{
    GET_AGENDAS_STARTED,
    GET_AGENDAS_SUCCESS,
    GET_AGENDAS_ERROR,

    GET_AORDERS_STARTED,
    GET_AORDERS_SUCCESS,
    GET_AORDERS_ERROR
} from '../types/agenda.type';


interface IState {
    agendas: Agenda[],
    ordersAgenda: any[],
    error: string,
    errorOAgenda: string,
    loading: boolean,
    loadingOAgenda: boolean,
    selectedAgendas: Agenda[],
    startOfMonth: string,
    agendaSelected: Agenda[],
    tickets_id: number[]
}

const initalState: IState = {
    agendas: [],
    ordersAgenda: [],
    selectedAgendas: [],
    error: null,
    errorOAgenda: null,
    loading: false,
    loadingOAgenda: false,
    startOfMonth: null,
    agendaSelected: [],
    tickets_id: []
}



export default (state: IState = initalState, action: IReducerAction): IState =>
{   
    switch (action.type)
    {
        case GET_AGENDAS_STARTED:
            return {
                ...state,
                loading: true,
                startOfMonth: action.payload
            }
        case GET_AGENDAS_SUCCESS:
            return {
                ...state,
                loading: false,
                agendas: action.payload
            }
        case GET_AGENDAS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_AORDERS_STARTED:
            return {
                ...state,
                loadingOAgenda: true,
                agendaSelected: action.payload
            }
        case GET_AORDERS_SUCCESS:
            return {
                ...state,
                ordersAgenda: action.payload,
                loadingOAgenda: false
            }
        case GET_AORDERS_ERROR:
            return {
                ...state,
                loadingOAgenda: false,
                errorOAgenda: action.payload
            }
        default:
            return state;
    }
};