const userType = {
    FETCH_USERS_START: 'FETCH_USERS_START',
    SET_USERS: 'SET_USERS',
    ADD_USER: 'ADD_USER',
    ADD_USER_START: 'ADD_USER_START',
    DELETE_USER: 'DELETE_USER',
    CHANGE_PASSWORD_USER: 'CHANGE_PASSWORD_USER',
    DELETE_USER_START: 'DELETE_USER_START',
    CHANGE_PASSWORD_USER_START: 'CHANGE_PASSWORD_USER_START'
}

export default userType;
