import IReducerAction from "../models/IReducerAction"

import
{
    GET_TADATA_STARTED,
    GET_TADATA_SUCCESS,
    GET_TADATA_ERROR,
    GET_TICKETS_AGING_STARTED,
    GET_TICKETS_AGING_SUCCESS,
    GET_TICKETS_AGING_ERROR
} from '../types/ticketAging.type';

interface IState {
    agignData: any,
    loading: boolean,
    error: string,
    ticketsAging: any[],
    loadingTAging: boolean,
    errorTAging: string,
    aging: null
}

const initialState: IState = {
    error: null,
    loading: false,
    ticketsAging: [],
    loadingTAging: false,
    errorTAging: null,
    aging: null,
    agignData: {
        "paid": [{"old": [], "last16": [], "last_2": [], "last_4": [], "last_8": [], "current": [], "last_32": []}],
        "not_paid": [{"old": [], "last16": [], "last_2": [], "last_4": [], "last_8": [], "current": [], "last_32": []}]
    }
}


export default (state: IState = initialState, action: IReducerAction): IState => {
    switch (action.type)
    {
        case GET_TADATA_STARTED:
            return {
                ...state,
                loading: true
            }
        case GET_TADATA_SUCCESS:
            return {
                ...state,
                loading: false,
                agignData: action.payload
            }
        case GET_TADATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_TICKETS_AGING_STARTED:
            return {
                ...state,
                loadingTAging: true,
                aging: action.payload
            }

        case GET_TICKETS_AGING_SUCCESS:
            return {
                ...state,
                loadingTAging: false,
                ticketsAging: action.payload
            }
        case GET_TICKETS_AGING_ERROR:
            return {
                ...state,
                loadingTAging: false,
                errorTAging: action.payload
            }
        
        default:
            return state;
    }
}