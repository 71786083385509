
import { ApolloClient, ApolloCurrentResult, gql, NormalizedCache, NormalizedCacheObject, Observable, ObservableQuery } from "apollo-boost";
import { useApolloClient } from "react-apollo";
import Helper from "../Helper/Helper";
import AuthService from "./auth.service";
import { Subject, Subscription } from 'rxjs';
import Swal from "sweetalert2";
import { createClient } from "../config/graphql.client";


export class ObservableQueryT extends ObservableQuery {

    onResults: Subject<{error: Error, data: any}> = new Subject();
    subscription: Subscription;
    constructor(x)
    {
        super(x);

        this.subscription = x.subscribe(data => this.handleResponse(data),
        err => this.handleError(err));
    }


    handleResponse = (result) =>
    {
        if (result.errors)
            this.onResults.next({error: GraphqlService.ErrorFormated(new Error(result.errors.map((x, i) => `${i + 1}.) ${x.message}`).join('\n'))), data: null});
        else
        {
            const firstKey = Object.keys(result.data)[0];
            this.onResults.next({error: null, data: result.data[firstKey]});
        }
    }

    handleError = (error) => {
        this.onResults.next({error: GraphqlService.ErrorFormated(error), data: null});
    }

    async refetch(variables?): Promise<any> {
        try {
            let data = await super.refetch(variables);
            
            this.handleResponse(data);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async stopPolling() {
        try {
            super.stopPolling();
            this.subscription.unsubscribe();
        } catch (ex) {
            
        }
    }

}

export class GraphqlService
{


    private static client: ApolloClient<any>;

    private static Client(){
        if(!this.client){
            this.SetClient();
        }
        return this.client;
    }

    static SetClient()
    {
        this.client = createClient();
    }
    private static fromObservableQuery<T = any>(observableQuery: ObservableQuery<T>): Observable<ApolloCurrentResult<T>> {
        return new Observable((subscriber) => {
            const subscription: ZenObservable.Subscription = observableQuery.subscribe(
                (value) => subscriber.next(value),
                (error) => subscriber.error(error),
                () => subscriber.complete());
            return () => {
                subscription.unsubscribe();
            };
        });
    }
    static SendQueryObservable(query: import('graphql').DocumentNode, variables?: any, preventRedirect = false): ObservableQueryT
    {
        return new ObservableQueryT(this.Client().watchQuery({query, variables, pollInterval: 500, fetchPolicy: 'no-cache'}));
        
        // this.fromObservableQuery(result).pipe
        
        // result.map(d => d);
        // result.map((r) => {
            
        //     let errors = r.errors ? null : new Error(r.errors.map((x, i) => `${i + 1}.) ${x.message}`).join('\n')) as any;
            
        //     let firstKey = Object.keys(r.data)[0];
        //     const data = r.data[firstKey];
        //     return {data, errors: errors, loading: r.loading, networkStatus: r.networkStatus, stale: r.stale};
        // })
        
        
    }

    static async SendQuery(query: import('graphql').DocumentNode, variables?: any, preventRedirect = false)
    {
        try {
            const { data, errors  } = await this.Client().query({query, variables, fetchPolicy: 'no-cache',fetchResults: false});

            if (errors) throw new Error(errors.map((x, i) => `${i + 1}.) ${x.message}`).join('\n'));

            let firstKey = Object.keys(data)[0];
            


            return data[firstKey];
        } catch (ex) {
            throw this.ErrorFormated(ex);
        }

        
    }

    static async SendMultipleQuery(query: import('graphql').DocumentNode, variables?: any)
    {


        try {
            const { data, errors } = await this.Client().query({query, variables, fetchPolicy: 'no-cache'});


            if (errors) throw new Error(errors.map((x, i) => `${i + 1}.) ${x.message}`).join('\n'));

            let keys  = Object.keys(data);


            return keys.map(key => data[key]);
        } catch (ex) {
            throw this.ErrorFormated(ex);
        }
        
    }

    static async SendMutation(mutation: import('graphql').DocumentNode, variables?: any)
    {
        try {
            const { data, errors } = await this.Client().mutate({mutation, variables, fetchPolicy: 'no-cache'});

            if (errors) throw new Error(errors.map((x, i) => `${i + 1}.) ${x.message}`).join('\n'));
                
            let firstKey = Object.keys(data)[0];
            
            return data[firstKey];
        } catch (ex) {
            throw this.ErrorFormated(ex);
        }


    }

    static ErrorFormated(ex){
        let msg: string = (ex.networkError ? (ex.networkError?.result?.errors ? ex.networkError.result.errors.map((x: any, i: number) => `${i + 1}.) ${x.message}`).join('\n') : ex.networkError?.message) : ex.message || ex) || '';

        if (msg.toLowerCase().includes('token expired') || msg.toLowerCase().includes('session expired'))
        {
            setTimeout(() => {
                Swal.fire({
                    text: `Session Expired`,
                    titleText: msg,
                    icon: 'question',
                    confirmButtonText: 'Re-log',
                    cancelButtonText: 'No',
                    showCancelButton: true
                })
                .then((d) => {
                    if (d.isConfirmed)
                        Helper.Session.DoLogout();
                })
            }, 10);
        }
        
        return new Error(msg);
    }
}