import { encrypt } from '../encrypt';
import {GraphqlService, ObservableQueryT} from '../graphql.service';
import { addOrder, add_service_charge, chargesSP, getAllOrdersSP, Order, payInput, Payment, service_selectionSPRes, StandardResult, terminalSP, Transaction_invoice } from './classes';
import { create_order, get_service_selection, get_terminals, manual_input, merge_orders, open_orders, orders_by_dates, payment_request, process_charge } from './Nodes';

export class ObservableQuery{
    observable: ObservableQueryT;

    refetch(data: any = null){
        if(this.observable){
            this.observable.refetch(data);
        }
    }

    orders_by_dates(input:{start:string, end:string},callback:(error: Error, data: getAllOrdersSP[]) => void){
        if(this.observable){
            this.observable.subscription.unsubscribe();
        }
        this.observable = GraphqlService.SendQueryObservable(orders_by_dates, input);
        this.observable.onResults.subscribe(result => callback(result.error, result.data));
    }

    open_orders(callback:(error: Error, data: getAllOrdersSP[]) => void){
        if(this.observable){
            this.observable.subscription.unsubscribe();
        }
        this.observable = GraphqlService.SendQueryObservable(open_orders);
        this.observable.onResults.subscribe(result => callback(result.error, result.data));
    }
}

export class Query{
    static get_terminals(){
        return GraphqlService.SendQuery(get_terminals) as Promise<terminalSP[]>;
    }

    static get_service_selection(input:{servicegroupid: number, selection: chargesSP[]}){
        return GraphqlService.SendQuery(get_service_selection, input) as Promise<service_selectionSPRes[]>;
    }

    static open_orders(){
        return GraphqlService.SendQuery(open_orders) as Promise<getAllOrdersSP[]>;
    }
}

export class Mutation{

    static process_charge(input:{order_id: number, hsn: string}) {
        return GraphqlService.SendMutation(process_charge,input) as Promise<{transaction_id: number}>;
    }

    static create_order(input: {order: addOrder}){
        return GraphqlService.SendMutation(create_order, input) as Promise<Order>
    }

    static merge_orders(input: {order_ids: number[]}){
        return GraphqlService.SendMutation(merge_orders, input) as Promise<StandardResult>
    }

    static manual_input(input: {customer_id: number, order_id: number, order_reference:string, charges: add_service_charge[], payinfo:payInput}){
        return GraphqlService.SendMutation(manual_input, {input: encrypt(input)}) as Promise<Payment>;
    }

    static payment_request(input: {order_id: number, email: string, phone: string}){
        return GraphqlService.SendMutation(payment_request, input) as Promise<Transaction_invoice>;
    }
}