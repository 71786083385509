import React from 'react';

import './LoadSpinner.scss';

const LoadSpinner = () => {
    return (<div className='load-spinner-component'>
        <div className='loading-spinner-container'>

            <div className="lds-ripple"><div></div><div></div></div>
            {/* <img src="https://i.pinimg.com/originals/5f/e0/e5/5fe0e55f8e19bc4cc3201876dce7b224.gif" alt=""/>
            <div className='d-flex justify-content-center aling-items-center'>
                <h6>Loading</h6><span>...<div /></span>
            </div> */}
        </div>
    </div>)
}

export default LoadSpinner;