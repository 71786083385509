import gql from "graphql-tag";

export const GET_AGENDAS = gql`
    query ($startofmonth: String!){
        get_calendar_agenda(startofmonth:$startofmonth){
            tickets{
            id
            scheduled
            status
            }
            start_of_day
        }
    }
`

export const GET_ORDER_AGENDAS = gql`
    query($ids: [Int]!){
        table_ticketsByIds(ids: $ids){
            order_id
            label
            status{
                text
                fill_color
                text_color
            }
            charges
            ticket_id
            pickup_date
            customer_name
            customer_email
            customer_phone
            order_number
            ticket_name
            ticket_number
        }
    }
`