import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Service_charge, TicketSelection } from '../../../classes/classes';
import { ADD_SERVICE, UPDATE_SERVICE } from '../../../graphql/mutation/services';
import Helper from '../../../Helper/Helper';
import { fetchTaxesStart } from '../../../saga/Tax/tax.actions';
import { GraphqlService } from '../../../services/graphql.service';

const mapState = ({taxesData}) => ({
    taxes: taxesData.taxes.filter(e => e.active),
});
const ServiceForm = ({service, onNewServiceSubmitted, order_id = null, onCancelClicked, selection = [], service_group_id = null, editing = false, services = []}) => {

    const dispatch = useDispatch();
    const {taxes = []} = useSelector(mapState);
    const [tax, setTax] = useState({id: -1, name: '-- Not selected --', amount: 0})
    const [save, setSave] = useState(false);
    const [isNew, setIsNew] = useState(true);
    const [error, setError] = useState('');
    // console.log(services);

    
    
    useEffect(() => {
        setError('');
        if (taxes.length > 0 && tax.id == -1)
        {
            let t = taxes.find(x => x.id == service.tax_id) || {id: -1, name: '-- Not selected --', amount: 0};
            if (t.id == -1)
                t = taxes.find(x => x.amount == 0) || {id: -1, name: '-- Not selected --', amount: 0};
            setTax(t);
        }
        setNService({...nService, name: service.name, cost: service.cost, count: service.count, id: service.id});
        getTaxes();
    }, [service])

    function addCount(count ) {
        let nc = nService.count + count;

        if (nc <= 0)
            nc = 1;

        setNService({...nService, count: nc});
    }

    async function getTaxes() {
        return dispatch(fetchTaxesStart());
    }

    const isValid = () => nService.name.trim() && (!editing? nService.count > 0 : true) && !isNaN(nService.cost) && tax.id != -1;

    useEffect(() => {
        if (taxes.length > 0 && tax.id == -1)
        {
            let t = taxes.find(x => x.id == service.tax_id) || {id: -1, name: '-- Not selected --', amount: 0};
            if (t.id == -1)
                t = taxes.find(x => x.amount == 0) || {id: -1, name: '-- Not selected --', amount: 0};
            setTax(t);
        }
    }, [taxes])


    async function addCharge()
    {
        // let _tax = +Number(+nService.base_amount * (+tax.amount / 100)).toFixed(2);

            
        // let s = {name: nService.name, base_amount: +nService.base_amount,  tax_amount: _tax, amount: +Number(+nService.base_amount + _tax).toFixed(2)};
        try {

            
            // if (services.includes(nService?.name?.toLowerCase()))
            // {
            //     return Swal.fire({
            //         icon: 'warning',
            //         text: 'There is another service type with that name. Please create a new name for this service.',
            //         title: 'Warning'
            //     });
            // }

            let t = new TicketSelection();
            t.cost = +nService.cost;
            t.count = editing ? 0 : nService.count;
            t.tax_id = tax.id;
            t.name = nService.name;
            t.id = nService.id;
            if (save || (nService.id == null && editing))
                {
                    let id = (await GraphqlService.SendMutation(ADD_SERVICE, {name: t.name, cost: t.cost, tax_id: tax.id, service_group_id})).id;
                    t.id = id;
                }
                if (nService.id != null)
                {
                    (await GraphqlService.SendMutation(UPDATE_SERVICE, {id: t.id, name: t.name, cost: t.cost, tax_id: t.tax_id, service_group_id}));
                    let temp = [...selection];
                    const index = temp.findIndex(x => x.id == service.id);
                    if (index > -1)
                    {
                        temp[index] = {...t};
                        onNewServiceSubmitted(temp);
                    }
                }
            else
                onNewServiceSubmitted([...selection, {...t, service_group_id}]);
            

        } catch (ex) {
            setError(ex.message);
            console.log('ex', ex.message);
        }
    }

    const [nService, setNService] = useState(new TicketSelection());
    return (<form onSubmit={(evt) => {evt.preventDefault();
        evt.preventDefault();
        addCharge();
    }} className="d-flex flex-wrap mt-3">
    <div className="form-group col-6 pr-2 mb-3">
        <label htmlFor="cname">Charge name*</label>
        <input id='cname' name='cname' value={nService.name} onChange={(evt) => setNService({...nService, name: evt.target.value})} placeholder='Charge name' type="text" className="form-field"/>
    </div>
    <div className="form-group col-6 pl-2 mb-3">
        {!editing && <><label htmlFor="cname">Count*</label>
        <h4 className="title-text d-flex align-items-center"><button type='button' onClick={(evt) => addCount(-1)} className='btn btn-orange-light'><i className="fas fa-minus"></i></button> <span className='d-inline-block mx-3'>{nService.count}</span> <button type='button' onClick={(evt) => addCount(1)} className='btn btn-orange-light mr-2'><i className="fas fa-plus"></i></button></h4></>}
    </div>
    <div className="form-group col-6 px-0 mb-3">
        <label htmlFor="amount">Cost*</label>
        <input id='amount' name='amount' value={nService.cost} onChange={(evt) => setNService({...nService, cost: Helper.Masks.DecimalNumbers(evt.target.value, true) as any})} placeholder='00.00' type="text" className="form-field dollar-sign"/>
    </div>
    <div className="col-6"></div>
    <div className="form-group col-6 pr-2 mb-3">
        <label htmlFor="tax_amount">Tax*</label>
        {/* <input id='amount' name='amount' value={nService.amount} onChange={(evt) => setNService({...nService, amount: Helper.Masks.DecimalNumbers(evt.target.value) as any})} placeholder='00.00' type="text" className="form-field dollar-sign"/> */}
        <select name="tax_amount" id="tax_amount" className="form-field" value={tax.id} onChange={(evt) => setTax(taxes.find(x => x.id == evt.target.value as any) || {id: -1, name: '-- Not selected --', amount: 0})}>
            <option value={-1}>-- Not selected --</option>
            {taxes.map(t => <option value={t.id}>{t.name} {t.amount}%</option>)}
        </select>
    </div>

    <div className="form-group col-6 pl-2 mb-3">
        <label htmlFor="tax">Tax amount*</label>
        <input id='tax' name='tax' readOnly value={Helper.FORMAT.USCurrency(((nService.cost * tax.amount)) / 100)}  style={{background: '#FFF'}} placeholder='00.00' type="text" className="form-field dollar-sign"/>
    </div>

    {(!editing && nService.id == null) && <div className="d-flex justify-content-end my-3">
        <div className="form-group d-flex align-items-center pl-2">
            <input checked={save} onChange={(evt) => setSave(evt.target.checked)} style={{transform: 'scale(1.8)'}} className='m-0' type="checkbox" name="save" id="save"/>
            <label className='mb-0 ml-3 medium-text' htmlFor="save">Save for future use</label>
        </div>
    </div>}

    {error && <p className='font-12 text-red'>{error}</p>}

    <div className="d-flex col-12 px-0 justify-content-between">
        
    <button type='button' className="btn btn-danger px-3 btn-block mt-3 mr-2" onClick={(evt) => onCancelClicked(selection)}>Cancel</button>
    <button className="btn btn-orange px-3 btn-block mt-3 ml-2" disabled={!isValid()}>{service?.id == null ? 'Add' : 'Update'} charge</button>
    </div>

</form>)
}

export default ServiceForm;