import gql from "graphql-tag";

export const GET_CUSTOMERS = gql`
    query {
        customers {
            id,
            name,
            phone,
            email,
            note
        }
    }
`;

export const GET_CUSTOMERS_TABLE = gql`
    query ($customerId: Int) {
        accounting_page_customer(customerId:$customerId){
            id
            name
            email
            phone
            orders_id
            terms
            total_due
            current
            _31_60
            _61_90
            _91
        }
    }
`;

export const GET_CUSTOMER_BY_ID = gql(`query ($customerId: Int!) {
  customer(customerId: $customerId) {
    id
    name
    phone
    note
    email
  }
}`);
