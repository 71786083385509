import * as actionTypes from "./actionTypes"
import {DispatchType, ITypePickerColorItem, TypePickerAction} from "../models";

export const addColor = (color: ITypePickerColorItem) => ({
    type: actionTypes.ADD_COLOR, item: color
});

export const resetColor = () => ({
    type: actionTypes.RESET_COLOR
});

export const mergeColors = (items: ITypePickerColorItem[]) => ({
    type: actionTypes.MERGE_COLORS,
    item: items
});

export const removeColor = (color: ITypePickerColorItem) => ({
    type: actionTypes.REMOVE_COLOR, item: color
});

export const selectColor = (color: ITypePickerColorItem) => ({
    type: actionTypes.SELECT_COLOR, item: color
});

export function simulateHttpRequest(action: TypePickerAction) {
    return (dispatch: DispatchType) => {
        setTimeout(() => {
            dispatch(action)
        }, 500)
    }
}
