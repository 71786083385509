import Table from "../../../components/Table/Table";
import IColumn from "../../../components/Table/models/IColumn";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {fetchPaymentsStart} from "../../../saga/Payments/payments.actions";
import Helper from "../../../Helper/Helper";
import moment from 'moment';
import Swal from "sweetalert2";
import {GraphqlService} from "../../../services/graphql.service";
import {VOID_TRANSACTION} from "../../../graphql/mutation/Transaction";
import {GET_TRANSACTION_STATUS} from "../../../graphql/query/Transaction";
import React from "react";
import ICONS from "../../../../assets/svg";



const PaymentsTable = (props) => {
    const dispatch = useDispatch();
    const {payments, isLoading} = useSelector<any>(x => x.paymentsData) as any;

    const wasLoaded = useRef(false);
    const [rows, setRows] = useState([]);

    async function getPayments() {
        return dispatch(fetchPaymentsStart());
    }

    useEffect(() => {
        // getPayments().then();
    }, [props.dates]);


    useEffect(() => {
        let x = !wasLoaded.current ? [] : payments.map(payment => {
            
            return {
                ...payment,
                time: [Helper.FORMAT.USDate(payment.time, 'do'), Helper.FORMAT.USDate(payment.time, 'to')],
                order_number: payment.order_number,
                status: payment.status,
                amount: payment.amount,
                type: payment.type,
                payment_number: payment.payment_number,
                transaction_number: payment.transaction_number,
                charges: [payment.charges.length > 0 ? payment.charges[0]?.name?.substring(0, 10) + '...' : payment.charges[0]?.name, payment.charges.length > 1 ? '+' + (payment.charges.length - 1) : '']
            };
        });
        
        setRows(x)
    }, [payments]);

    useEffect(() => {
        console.log(isLoading)
        if (isLoading)
            wasLoaded.current = true;
    }, [isLoading]);

    const options = [
        {
            label: 'See order details',
            icon: ICONS.IconDetails,
            action: (evt) => {
                let order_id = Helper.Masks.IntNumbers(evt.order_number);
                Helper.Navigation.NavigateTo('/order/' + order_id)
            }
        },
        {
            label: 'Receipt',
            icon: ICONS.IconReceipt,
            action: async (data) => {
                let payment_id = +Helper.Masks.IntNumbers(data.payment_number);
                Helper.Receipt.ShowConfirmation(payment_id, 1);
            }
        },
        {
            label: 'Void Payment',
            icon: ICONS.IconDelete,
            action: async (data) => {
                let payment_id = +Helper.Masks.IntNumbers(data.payment_number);

                Swal.fire({
                    title: 'Are you sure?',
                    text:  `Void Payment #` + payment_id + `, this action can't be undo!`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, void it!'
                }).then(async result => {
                    if (result.isConfirmed)
                    {


                        try {
                            let data = await GraphqlService.SendMutation(VOID_TRANSACTION, {paymentId: payment_id})
                        } catch (ex) {
                            console.log('ex', ex.message);
                        }
                    }
                })
            }
        },
        {
            label: 'Check Status',
            icon: ICONS.IconCheck,
            action: async (data) => {

                let payment_id = +Helper.Masks.IntNumbers(data.payment_number);
                try {
                    let result = await GraphqlService.SendQuery(GET_TRANSACTION_STATUS, {paymentId: payment_id});
                    Swal.fire({
                        title: 'Transaction ' + data.transaction_number,
                        text: JSON.stringify(result),
                        icon: 'info'
                    })
                } catch (ex) {
                    Swal.fire({
                        text: ex.message.toLowerCase().includes('not found') ? 'Error: Transaction Not Found' : ex.message,
                        title: 'Error',
                        icon: 'error'
                    })
                }
            }
        }
    ]

    const columns: IColumn[] = [
        {orderBy: 'ASC', active: false, label: 'TIME', name: 'time'},
        {orderBy: 'ASC', active: false, label: 'ORDER #', name: 'order_number'},
        {orderBy: 'ASC', active: false, label: 'STATUS', name: 'status'},
        {orderBy: 'ASC', active: false, label: 'AMOUNT', name: 'amount'},
        {orderBy: 'ASC', active: false, label: 'TYPE', name: 'type'},
        {orderBy: 'ASC', active: false, label: 'PAYMENT #', name: 'payment_number'},
        {orderBy: 'ASC', active: false, label: 'TRANSACTION #', name: 'transaction_number'},
        {orderBy: 'ASC', active: false, label: 'CHARGES', name: 'charges'},
    ];

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <p className="font-14 fw-600">{payments?.length} Payments</p>
            </div>
            <Table 
                   menuOptions={options}
                    loading={isLoading}
                   columns={columns}
                   rows={rows}
                   tableHeight="65vh"
                   onRowClicked={() => {
                   }}/>
        </>
    );
}

export default PaymentsTable;
