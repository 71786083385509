import React, {useEffect, useRef, useState} from 'react';
import {ITypePickerItem, ITypePickerProps} from "./models";
import './TypePicker.scss';
import ICONS from '../../../assets/svg';
import {useDispatch, useSelector} from "react-redux";
import {addTypePickerItem, openCustomItemForm, removeTypePickerItem} from "./state/actions";
import {baseItems} from "./state/helper";
import CustomTicketModalForm from "../Modals/CustomTicketModalForm/CustomTicketModalForm";
import Helper from '../../Helper/Helper';
import {ControlledMenu, MenuItem} from "@szhsin/react-menu";
import Swal from "sweetalert2";
import {GraphqlService} from "../../services/graphql.service";
import {TicketSelection} from "../../classes/classes";
import {TicketCell} from "../../pages/OverviewPage/TicketPickerModal/TicketPickerModal.style";

const mapState = ({typePickerReducer}) => ({
    items: typePickerReducer.items,
    isLoading: typePickerReducer.isLoading,
    isOpenCustomForm: typePickerReducer.isOpenCustomForm
});

export const TypePicker: React.FC<ITypePickerProps> = (props) => {

    const dispatch = useDispatch();
    const modalRef = useRef();

    // let { items, isLoading, isOpenCustomForm } = useSelector(mapState);

    const [items, setItems] = useState([]);
    const [isOpenCustomForm, setIsOpenCustomForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const [anchorPoint, setAnchorPoint] = useState({x: 0, y: 0});

    const interval = useRef<any>(null);


    const [service, setService] = useState(new TicketSelection());
    const [sItem, setSItem] = useState({id: -1, name: ''});
    const [sgMenu, setSGMenu] = useState({index: -1});


    const onTouchStart = (evt: React.TouchEvent<any>, idx: number) => {

        interval.current = setInterval(() => {

            setAnchorPoint({x: evt.touches[0].clientX, y: evt.touches[0].clientY});
            setSGMenu({index: idx});
            clearInterval(interval.current);
        }, 500)
    };


    const onTouchEnd = (evt: any) => {
        clearInterval(interval.current);
    };

    const onContextMenuHandled = (event, index) => {
        event.preventDefault();
        setAnchorPoint({ x: event.clientX, y: event.clientY });
        setSGMenu({index});
    };

    const selectItem = async (item: ITypePickerItem) => {

        if(props.editing){
            return props.onEditItem(item);
        }

        if (item.count == 0) {
            addItem(item);
        }
    };

    useEffect(() => {
        setItems(props.selectedItems.map((x) => {
            return {
                ...x
            }
        }));
    }, [props.selectedItems]);

    const addItem = async (item: ITypePickerItem) => {
        // dispatch(addTypePickerItem(item));

        setItems([...items].map((itemx) => {
            if (itemx.name === item.name) {
                itemx.count! += 1;
            }
            return itemx;
        }));

        // TODO: Remove this way and use redux
        const selectedItems = await Promise.all(items.map((sitem: any) => {
            if (sitem.name === item.name) {
                sitem = item
            }
            return sitem
        }));
        props.onItemSelection(selectedItems)
    };

    const removeItem = async (event: any, item: ITypePickerItem) => {
        event.stopPropagation();
        event.preventDefault();
        // dispatch(removeTypePickerItem(item));

        setItems([...items].map((itemx) => {
            if (itemx.name === item.name) {
                itemx.count! -= 1;
            }
            return itemx;
        }));

        // TODO: Remove this way and use redux
        const selectedItems = await Promise.all(items.map((sitem: any) => {
            if (sitem.name === item.name) {
                sitem = item
            }
            return sitem
        }));
        props.onItemSelection(selectedItems);
    };

    const saveItem = (item) =>{
        items.push(item);
        setItems(items);
        props.onSaveItem(items);
        setIsOpenCustomForm(false);
    };

    return (
        <>
            <div className="row item-picker-cnt g-0" ref={modalRef}>
                <div className="col-12">
                    <div className="row g-0">
                        {
                            !isLoading && items.map((item: any, index: number) => {
                                return (
                                    <>
                                        <div key={index}
                                             onTouchStart={(evt) => onTouchStart(evt, index)}
                                             onTouchEnd={onTouchEnd}
                                             onContextMenu={(e) => onContextMenuHandled(e, index) }
                                             className="g-25 col-6 col-sm-4 col-md-3 col-lg-3 text-center mb-3">
                                            <div className={`item-type ${item.count > 0 ? "active" : ""}`}
                                                 onClick={() => selectItem(item)}>
                                                <div className="pb-3">
                                                    <img className="item-img" src={ICONS[item.icon] || ICONS['CUSTOM']}/>
                                                </div>
                                                <p className="item-name text-red">
                                                    <b>{Helper.FORMAT.USCurrency(item.count && (item.count * item.cost) || item.cost)}</b>
                                                </p>
                                                <p className="item-name">
                                                    {item.name}
                                                </p>
                                                <div className="selected-layer">
                                                    <div className="row g-0 w-100">
                                                        <div className="g-1 col-4 text-center">
                                                            <div className="item-btn item-remove"
                                                                 onClick={(e) => removeItem(e, item)}>
                                                                <img src={ICONS.REMOVE} alt="blouse"/>
                                                            </div>
                                                        </div>
                                                        <div className="g-1 col-4 text-center align-self-center">
                                                                    <span className="item-qty">
                                                                        {item.count}
                                                                    </span>
                                                        </div>
                                                        <div className="g-1 col-4 text-center">
                                                            <div className="item-btn item-add"
                                                                 onClick={() => addItem(item)}>
                                                                <img src={ICONS.ADD} alt="blouse"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>


                    {/*Manage Right click options*/}
                    <ControlledMenu className='submenu' anchorPoint={anchorPoint} boundingBoxRef={modalRef} onClose={(evt) => setSGMenu({index: -1})} isOpen={sgMenu.index != -1}>
                        <MenuItem onClick={(evt) => {
                            
                            setSItem({id: items[sgMenu.index].id, name: items[sgMenu.index].name});
                            props.onEditItem(items[sgMenu.index]);
                        }}><img className='mr-3' style={{width: '1rem'}} src={ICONS.IconPencilEdit} alt=""/> Edit Item "{items[sgMenu.index]?.name}"</MenuItem>
                        <MenuItem onClick={(evt) => {
                            props.onDeleteItem({id: items[sgMenu.index].id, name: items[sgMenu.index].name});
                        }}><img className='mr-3' style={{width: '1rem'}} src={ICONS.IconDelete} alt=""/> Delete Item "{items[sgMenu.index]?.name}"</MenuItem>
                    </ControlledMenu>
                </div>
            </div>
            <div className="row">
                {
                    isOpenCustomForm &&
                    <CustomTicketModalForm title={'New Ticket'} onSaveItem={saveItem} currentTicket={null} onCloseModal={() => {
                        setIsOpenCustomForm(false)
                    }}/>
                }
            </div>
        </>
    )
};
