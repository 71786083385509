import Table from "../../../components/Table/Table";
import IColumn from "../../../components/Table/models/IColumn";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchTaxesStart, updateTaxStart} from "../../../saga/Tax/tax.actions";
import {TaxModal} from "./TaxModal/TaxModal";
import IMenuOption from "../../../components/Table/models/IMenuOption";
import Helper from "../../../Helper/Helper";
import {ConfirmMessageModal} from "../../../components/ConfirmMessageModal/ConfirmMessageModal";
import {MessageModal} from "../../../components/MessageModal/MessageModal";
import ICONS from "../../../../assets/svg";

const mapState = ({taxesData}) => ({
    taxes: taxesData && taxesData.taxes && taxesData.taxes.filter(e => e.active) || [],
});

const Taxes = () => {
    const dispatch = useDispatch();
    const {taxes = []} = useSelector(mapState);
    const [currentTax, setCurrentTax] = useState(undefined);
    const [showTaxModal, setShowTaxModal] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);

    async function getTaxes() {
        return dispatch(fetchTaxesStart());
    }

    useEffect(() => {
        getTaxes().then();
        return () => {
            
        }
    }, []);

    const columns: IColumn[] = [
        { orderBy: 'ASC', active: false, label: 'NAME',     name: 'name' },
        { orderBy: 'ASC', active: false, label: 'AMOUNT',   name: 'amount' }
    ];

    const menuOptions: IMenuOption[] = [
        {
            icon: ICONS.IconPencilEdit,
            label: 'Edit Tax',
            action: (row) => {
                setCurrentTax(row);
                setShowTaxModal(true);
            }
        },
        {
            icon: ICONS.IconDelete,
            label: 'Delete Tax',
            action: (row) => {
                setCurrentTax(row);
                setShowConfirmation(true);
            }
        },
    ];

    const onDeleteTax = (wasConfirmed) => {
        if (wasConfirmed) {
            let tax = { ...currentTax, active: false };
            dispatch(updateTaxStart(tax));

            setShowMessageModal('TAX WAS DELETED');
        }

        setCurrentTax(undefined);
        setShowConfirmation(false);
    };

    async function loadData(event) {
        event.complete();
    }

    return (
        <>
            {
                (showTaxModal && currentTax == undefined) ? <TaxModal title={'Add New Tax'}
                                         onCloseModal={() => {
                                             setShowTaxModal(false);
                                             setShowMessageModal('NEW TAX ADDED');
                                         }}/> : null
            }
            {
                (showTaxModal && currentTax != undefined) ? <TaxModal title={'Modify Tax'}
                                                                      currentTax={currentTax}
                                                                      onCloseModal={() => {
                                                                          setShowTaxModal(false);
                                                                          setCurrentTax(undefined);
                                                                          setShowMessageModal('TAX WAS MODIFIED');
                                                                      }}/> : null
            }
            {
                (showConfirmation && currentTax != undefined) ? <ConfirmMessageModal title={'Delete Tax'}
                                                                                     message={'Are you sure you want to delete this tax?'}
                                                                                     onCloseModal={onDeleteTax}/> : null
            }
            {
                showMessageModal != '' ? <MessageModal title={'Delete Tax'}
                                                 message={ showMessageModal }
                                                 onCloseModal={() => setShowMessageModal('')} /> : null
            }
            <div className="col-12 flex-1-container">
                <div className="card flex-1-container">
                    <div className="card-body flex-1-container">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <p className="font-14">Taxes</p>
                            <button className="btn btn-orange-light rounded-pill font-10"
                                    onClick={(event) => setShowTaxModal(true)}>
                                <i className="ti-plus"> </i> Add tax
                            </button>
                        </div>
                        <Table
                               columns={columns}
                               tableHeight="25vh"
                               menuOptions={menuOptions}
                               rows={taxes}
                               onRowClicked={() => {}}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Taxes;
