import {GraphqlService, ObservableQueryT} from "../../services/graphql.service";
import {ALL_INVOICES, CUSTOMER_INVOICES} from "../../graphql/query/Invoice";

let invoicesQuery: ObservableQueryT;
let invoicesCustomerQuery: ObservableQueryT;

export const handleFetchInvoices = () => {
    return new Promise((resolve, reject) => {
        if (!invoicesQuery) {
            invoicesQuery = GraphqlService.SendQueryObservable(ALL_INVOICES);
            invoicesQuery.onResults.subscribe(result => {
                

                if (result.error)
                    reject(result.error.message);
                else
                    resolve(result.data);
            });
        } else {
            invoicesQuery.refetch();
            invoicesQuery.onResults.subscribe(result => {
                if (result.error)
                    reject(result.error.message);
                else
                    resolve(result.data);
            });
        }
    });
};


export const handleFetchCustomerInvoices = (customerId) => {
    return new Promise((resolve, reject) => {
        invoicesCustomerQuery = GraphqlService.SendQueryObservable(CUSTOMER_INVOICES, {id: customerId});
        invoicesCustomerQuery.onResults.subscribe(result => {
            

            if (result.error)
                reject(result.error.message);
            else
                resolve(result.data);
        });

    });
};
