import React, {useEffect, useState} from 'react';
import {Customer} from '../../../classes/classes';
import Helper from '../../../Helper/Helper';
import {addCustomerStart, resetNewCustomer, updateCustomerStart} from "../../../saga/Customer/customer.actions";
import {useDispatch, useSelector} from "react-redux";
import { updateCustomer } from '../../../saga/Customer/customer.saga';

interface ICustomerForm {
    onCancelClicked: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
    onCustomerSubmitted: (customer: Customer) => any;
    customer: Customer
}

const CustomerForm = ({onCancelClicked, onCustomerSubmitted, customer}: ICustomerForm) => {

    const [myCustomer, setMyCustomer] = useState<Customer>(new Customer());
    const dispatch = useDispatch();

    const customerAddedID = useSelector<any>(state => state.customersData.customerAdded) as number;

    

    const saveCustomer = (myCustomer) => {
        if (myCustomer.id == -1)
            dispatch(
                addCustomerStart(myCustomer)
            );
        else
            dispatch(
                updateCustomerStart(myCustomer)
            )
    };


    if (customerAddedID != null)
        (() =>{
            let customer = {...myCustomer};
            customer.id = customerAddedID;
            dispatch(resetNewCustomer());
            onCustomerSubmitted(customer)
        })();


    const isValid = () => {
        return myCustomer.name.trim() && myCustomer.phone.trim().length == 12 && (myCustomer.email.trim() ? Helper.Validators.IsValidEmail(myCustomer.email) : true);
    }

    useEffect(() => {
        setMyCustomer(JSON.parse(JSON.stringify(customer || new Customer())));
    }, [customer])

    return (<form className='row'>
        <div className="form-group col-6 mb-3">
            <label htmlFor="cname">Customer name*</label>
            <input id='cname' name='cname' value={myCustomer.name} onChange={(evt) => setMyCustomer({...myCustomer, name: evt.target.value})}
                   placeholder='Customer name' type="text" className="form-field"/>
        </div>

        <div className="col-6"></div>

        <div className="form-group col-6 mb-3">
            <label htmlFor="phone">Phone #*</label>
            <input id='phone' name='phone' value={myCustomer.phone}
                   onChange={(evt) => setMyCustomer({...myCustomer, phone: Helper.Masks.USPhone(evt.target.value)})}
                   placeholder='000-000-0000' type="text" className="form-field"/>
        </div>

        <div className="form-group col-6 mb-3">
            <label htmlFor="email">Email (optional)</label>
            <input id='email' name='email' value={myCustomer.email} onChange={(evt) => setMyCustomer({...myCustomer, email: evt.target.value})}
                   placeholder='abc@xyz.com' type="text" className="form-field"/>
        </div>

        <div className="form-group col-12 mb-3">
            <label htmlFor="notes">Notes (optional)</label>
            <textarea name="notes" id="notes" placeholder='Customer notes...' className="form-field" value={myCustomer.note}
                      onChange={(evt) => setMyCustomer({...myCustomer, note: evt.target.value})}></textarea>
        </div>

        <div className="d-flex justify-content-end mb-5">
            <button className="btn btn-clear mr-2" type='button' onClick={onCancelClicked}>Cancel</button>
            <button className="btn btn-orange" type='button' onClick={(evt) => saveCustomer(myCustomer)} disabled={!isValid()}>Save customer
            </button>
        </div>

    </form>)
}

export default CustomerForm;
