import IColumn from "../../../components/Table/models/IColumn";
import Table from "../../../components/Table/Table";
import {useEffect} from "react";
import {fetchCustomerInvoicesStart} from "../../../saga/Invoice/invoice.actions";
import {useDispatch, useSelector} from "react-redux";
import Helper from "../../../Helper/Helper";

const mapState = ({invoicesData}) => ({
    invoices: invoicesData.customerInvoices.map(invoice => {
        return {
            ...invoice,
            id: invoice.id,
            due: '-',
            reference: invoice.order_reference,
            customer: invoice.name,
            status: invoice.transaction_id != null || invoice.amount == 0 ? 'PAID' : 'NOT PAID',
            payment: [`P-${invoice.transaction_id}`, Helper.FORMAT.USDate(invoice.time, 'do')],
            time: [Helper.FORMAT.USDate(invoice.time, 'do'), Helper.FORMAT.USDate(invoice.time, 'to')],
            amount: Helper.FORMAT.USCurrency(invoice.amount)
        };
    })
});


const CustomerDetailInvoices = (props) => {

    const columns: IColumn[] = [
        { orderBy: 'ASC', active: false, label: 'DUE',              name: 'due' },
        { orderBy: 'ASC', active: false, label: 'INVOICE',          name: 'id' },
        { orderBy: 'ASC', active: false, label: 'REFERENCE',        name: 'reference' },
        { orderBy: 'ASC', active: false, label: 'STATUS',           name: 'status' },
        { orderBy: 'ASC', active: false, label: 'PAYMENT #',        name: 'payment' },
        { orderBy: 'ASC', active: false, label: 'PAID ON',          name: 'time' },
        { orderBy: 'ASC', active: false, label: 'AMOUNT',           name: 'amount' },
    ];

    const dispatch = useDispatch();
    const customerId = props.customerId;

    const { invoices } = useSelector(mapState);

    useEffect(() => {
        getCustomerInvoices().then();
    }, [props.customerId]);


    async function getCustomerInvoices() {
        return dispatch(fetchCustomerInvoicesStart(customerId));
    }


    return (
        <>
            <div className="row">
                <div className="col-12">
                    <Table columns={columns}
                           rows={invoices}
                           onRowClicked={() => null}
                           
                           tableHeight={'59vh'}/>
                </div>
            </div>
        </>
    );
}

export default CustomerDetailInvoices;
