import gql from "graphql-tag";
import { Order } from "../../classes/classes";
import { GET_ALL_ORDERS } from "../../graphql/query/Order";
import Helper from "../../Helper/Helper";
import { ICreateOrder } from "../../models/interfaces";
import { Mutation, ObservableQuery, Query } from "../../services/Endpoints/enpoints";
import { GraphqlService, ObservableQueryT } from "../../services/graphql.service";
import { GET_AGENDAS_ERROR } from "../types/agenda.type";
import { CREATE_ORDER_ERROR, CREATE_ORDER_STARTED, CREATE_ORDER_SUCCESS, GET_HISTORY_ORDERS_ERROR, GET_HISTORY_ORDERS_STARTED, GET_HISTORY_ORDERS_SUCCESS, GET_INVOICES_ORDER_ERROR, GET_INVOICES_ORDER_STARTED, GET_INVOICES_ORDER_SUCCESS, GET_OPEN_ORDERS_ERROR, GET_OPEN_ORDERS_STARTED, GET_OPEN_ORDERS_SUCCESS, GET_PAYMENTS_ORDER_ERROR, GET_PAYMENTS_ORDER_STARTED, GET_PAYMENTS_ORDER_SUCCESS, RESET_NEW_ORDER, SET_ORDER_EDITTING } from "../types/orders.type";

export function createOrderAction(order: ICreateOrder)
{
    let res =  async exec => {
        exec(createOrder(order))

        try {
            const result = await Mutation.create_order({order});
            // order.id = result.id;
            exec(createOrderSuccess(result))
        } catch (ex) {
            console.log('ex', ex.message);
            exec(createOrderError(ex.message));
        }
    }
    return res;
}

const createOrder = (order: ICreateOrder) => ({type: CREATE_ORDER_STARTED, payload: order});
const createOrderSuccess = (order: Order) => ({type: CREATE_ORDER_SUCCESS, payload: order});
const createOrderError = (error: string) => ({type: CREATE_ORDER_ERROR, payload: error});

// ========================================================================================================= //

let ordersQuery: ObservableQuery;
let openOrdersObservable : ObservableQuery

export function getHistoryOrdersAction(start: Date, end: Date)
{   
    return dispatch => {
        dispatch(getHistoryOrdersStarted());

        if (!ordersQuery)
        {
            ordersQuery = new ObservableQuery();// GraphqlService.SendQueryObservable(GET_ALL_ORDERS, {start, end});
            ordersQuery.orders_by_dates({start: start.toISOString(), end: end.toISOString()}, (error, data) => {
                if (error)
                    return dispatch(getHistoryOrdersError(error.message));
                dispatch(getHistoryOrdersSuccess(data.map(x => (
                    {
                        ...x,
                        pickup_date: [Helper.FORMAT.USDate(new Date(x.pickup_date), 'do'), Helper.FORMAT.USDate(new Date(x.pickup_date), 'dd')],
                        tickets: ['', ...x.tickets],
                        order_number: x.order_number,
                        customer: [x.customer_name, x.customer_phone],
                        charge: Helper.FORMAT.USCurrency(x.charge),
                        balance: Helper.FORMAT.USCurrency(x.balance),
                        ordered_on: Helper.FORMAT.USDate(new Date(x.ordered_on), 'do'),
                        payments: x.payments,
                        completed: x.completed,
                        phone_number: [x.customer_phone.split('-').join(''), x.customer_phone.split('-').join(' ')]
                    }
                    ))));
            })
        }
        else
        {
            ordersQuery.refetch({start, end});
        }
    }
}
const getHistoryOrdersStarted = () => ({type: GET_HISTORY_ORDERS_STARTED});
const getHistoryOrdersSuccess = (orders: any[]) => ({type: GET_HISTORY_ORDERS_SUCCESS, payload: orders});
const getHistoryOrdersError = (error: string) => ({type: GET_HISTORY_ORDERS_ERROR, payload: error});



export function getOpenOrdersAction()
{   
    return dispatch => {
        dispatch(getOpenOrdersStarted());

        if (!openOrdersObservable)
        {
            openOrdersObservable = new ObservableQuery();
            openOrdersObservable.open_orders((error, data) => {
                if (error)
                    return dispatch(getOpenOrdersError(error.message));
                dispatch(getOpenOrdersSuccess(data.map(x => (
                    {
                        ...x,
                        pickup_date: [Helper.FORMAT.USDate(new Date(x.pickup_date), 'do'), Helper.FORMAT.USDate(new Date(x.pickup_date), 'dd')],
                        tickets: ['', ...x.tickets],
                        order_number: x.order_number,
                        customer: [x.customer_name, x.customer_phone],
                        charge: Helper.FORMAT.USCurrency(x.charge),
                        balance: Helper.FORMAT.USCurrency(x.balance),
                        ordered_on: Helper.FORMAT.USDate(new Date(x.ordered_on), 'do'),
                        payments: x.payments,
                        completed: x.completed,
                        phone_number: [x.customer_phone.split('-').join(''), x.customer_phone.split('-').join(' ')]
                    }
                    ))));
            })
        }
        else
        {
            openOrdersObservable.refetch();
        }
    }
}
const getOpenOrdersStarted = () => ({type: GET_OPEN_ORDERS_STARTED});
const getOpenOrdersSuccess = (orders: any[]) => ({type: GET_OPEN_ORDERS_SUCCESS, payload: orders});
const getOpenOrdersError = (error: string) => ({type: GET_OPEN_ORDERS_ERROR, payload: error});


export function setOrderEdittingAction(order_id)
{
    return dispatch => dispatch(setOrderEditting(order_id))
}

const setOrderEditting = (order_id) => ({type: SET_ORDER_EDITTING, payload: order_id})
 


// 


let paymentsQuery: ObservableQueryT;

export function getPaymentsOrderAction(order_id)
{
    return dispatch =>{
        dispatch(getPaymentsOrderStarted(order_id));

        if (!paymentsQuery)
        {
            paymentsQuery = GraphqlService.SendQueryObservable(gql`query ($orderId: Int){
                get_payments_forOrder(orderid:$orderId){
                  time
                  amount
                  status
                  payment_id
                  order_number
                  payment_number
                  transaction_number
                  type
                  charges{
                    id
                    ticket_id
                    name
                    count
                    base_amount
                    tax_amount
                    amount
                  }
                }
              }`, {orderId: +order_id});

            paymentsQuery.onResults.subscribe(result => {
                if (result.error)
                    return dispatch(getPaymentsOrderError(result.error.message));
                dispatch(getPaymentsOrderSuccess(result.data));
                
            })
        }
        else
            paymentsQuery.refetch({orderId: +order_id})
    }
}

export const getPaymentsOrderStarted = order_id => ({type: GET_PAYMENTS_ORDER_STARTED, payload: order_id});
export const getPaymentsOrderSuccess = payments => ({type: GET_PAYMENTS_ORDER_SUCCESS, payload: payments});
export const getPaymentsOrderError = error => ({type: GET_PAYMENTS_ORDER_ERROR, payload: error});

// ===============================================


let invoicesQuery: ObservableQueryT;

export function getInvoicesOrderAction(order_id)
{
    return dispatch =>{
        dispatch(getInvoicesOrderStarted(order_id));

        if (!invoicesQuery)
        {
            invoicesQuery = GraphqlService.SendQueryObservable(gql`query ($orderId:Int!){
                gett_allinvoices_byOrder(orderID: $orderId) {
                  id
                  name
                  note
                  time
                  email    
                  phone
                  link
                  profusepay_link
                  items {
                    name
                    count
                    amount
                    tax_amount
                    base_amount
                    payment_id
                  }
                  token
                  business_id
                  transaction_id
                  order_reference
                  amount
                }
              }`, {orderId: +order_id});

            invoicesQuery.onResults.subscribe(result => {
                if (result.error)
                    return dispatch(getInvoicesOrderError(result.error.message));
                dispatch(getInvoicesOrderSuccess(result.data));
                
            })
        }
        else
            invoicesQuery.refetch({orderId: +order_id})
    }
}

export const getInvoicesOrderStarted = order_id => ({type: GET_INVOICES_ORDER_STARTED, payload: order_id});
export const getInvoicesOrderSuccess = invoices => ({type: GET_INVOICES_ORDER_SUCCESS, payload: invoices});
export const getInvoicesOrderError = error => ({type: GET_INVOICES_ORDER_ERROR, payload: error});

// ===============================================

export function resetNewOrderAction()
{
    return dispatch => dispatch(resetNewOrder())
}

const resetNewOrder = () => ({type: RESET_NEW_ORDER});