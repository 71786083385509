import React, { useEffect, useState } from 'react';

import './MessageBox.scss';
interface IMessageBoxProps {
    type?: 'alert' | 'confirmation',
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    title: string,
    message: string,
    reference?: any,
    onConfirmClicked?: (reference: any) => void,
    onCancelClicked?: (evt: any) => void
}

const MessageBox = ({isOpen, setIsOpen, title, message, type, reference, onConfirmClicked, onCancelClicked}: IMessageBoxProps) => {

    if (!type)
        type = 'alert';

    const [open, setOpen] = useState(true);




    return (
    <div className={'message-box-container' + (isOpen ? ' shown' : '')}>
        <div className={"box"  + (isOpen ? ' shown' : '')}>
            <h4>{title}</h4>
            <div style={{maxHeight: '80vh', overflow: 'auto',}}>
                <p>{message}</p>
                {/* {message.includes(`{`) && <div style={{maxWidth: '100%', overflow: 'auto',}}><pre>{message}</pre></div>} */}
            </div>

            {type == 'alert' && <div className='d-flex justify-content-center mt-2'>
                <button className='btn px-4 py-2' onClick={(evt) => {setIsOpen(false); if (onConfirmClicked) onConfirmClicked(reference)}}>OK</button>
            </div>}

            {type == 'confirmation' && <div className='d-flex justify-content-between'>
                <button className='btn mr-5 px-4 py-2' onClick={(evt) => {setIsOpen(false); if (onCancelClicked) onCancelClicked(evt)}}>CANCEL</button>
                <button className='btn btn-blue px-4 py-2' onClick={(evt) => {setIsOpen(false); if (onConfirmClicked) onConfirmClicked(reference)}}>CONFIRM</button>
            </div>}
        </div>
    </div>)
}

export default MessageBox;