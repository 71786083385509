import React from 'react';
import './MaterialPicker.scss';
// import ICONS from "../../assets/icons";
import {ITypeMaterialPickerProps, ITypePickerMaterialItem} from "./models";
import {useDispatch, useSelector} from "react-redux";
import {addMaterial, removeMaterial, selectMaterial} from "./state/actions";
import {ReducerType} from "../../../../store";
import {ITypePickerColorItem} from "../ColorPicker/models";
import ICONS from '../../../../../assets/svg';

export const MaterialPicker: React.FC<ITypeMaterialPickerProps> = (props) => {
    const dispatch = useDispatch();

    // GET MATERIALS FROM REDUCER USING REDUX (I THINK)
    const materialList =
        useSelector<ReducerType, ReducerType['materialPickerReducer']['materialList']>(
            (state) => state.materialPickerReducer.materialList
        );

    const selectItem = async (material: ITypePickerMaterialItem) => {
        dispatch(selectMaterial(material));
        const selectedItems = await Promise.all(materialList.map((imaterial: ITypePickerMaterialItem) => {
            if (imaterial.material === material.material) {
                imaterial = material
            }
            return imaterial
        }));
        props.onColorSelection(selectedItems.filter((item: ITypePickerMaterialItem) => item.count! > 0))
    }

    const addItem = async (material: ITypePickerMaterialItem) => {
        dispatch(addMaterial(material));
        const selectedItems = await Promise.all(materialList.map((imaterial: ITypePickerMaterialItem) => {
            if (imaterial.material === material.material) {
                imaterial = material
            }
            return imaterial
        }));
        props.onColorSelection(selectedItems.filter((item: ITypePickerMaterialItem) => item.count! > 0))
    }

    const removeItem = async (event: any, material: any) => {
        event.stopPropagation();
        dispatch(removeMaterial(material));
        const selectedItems = await Promise.all(materialList.map((imaterial: ITypePickerMaterialItem) => {
            if (imaterial.material === material.material) {
                imaterial = material
            }
            return imaterial
        }));
        props.onColorSelection(selectedItems.filter((item: ITypePickerMaterialItem) => item.count! > 0))
    }

    return (
        <>
            <div className="row color-picker-cnt g-0">
                <div className="col-12">
                    <div className="row g-25">
                        {
                            materialList.map((material: any) => (
                                <div
                                    className={`col-6 col-sm-4 col-md-4 col-lg-4 text-center mb-3 g-25`}>
                                    <div id={material.id}
                                         className={`item-type ${(material.selected && material.count > 0) ? 'active' : ''} ${!material.selected && material.count > 0 ? 'color-active' : ''}`}
                                         onClick={() => selectItem(material)}>
                                        <div className={'d-flex align-items-center justify-content-start ml-4'}>
                                            <p className="ml-2 mr-2">{material.material}</p>
                                            {!material.selected && material.count > 0 ?
                                                <p className='ml-auto mr-4'>{material.count}</p> : null}
                                        </div>
                                        <div className="selected-layer  ">
                                            <div className="row g-0 w-100 mt-3 align-items-center">
                                                <div className="g-25 col-4 text-center ">
                                                    <div className={`item-btn item-remove ${ material.count > 0 ? '' : 'disabled' }`}
                                                         onClick={(e) => removeItem(e, material)}>
                                                        <img src={ICONS.REMOVE} alt="remove"/>
                                                    </div>
                                                </div>
                                                <div className="g-25 col-4 text-center">
                                                                    <span className="item-qty">
                                                                        {material.count}
                                                                    </span>
                                                </div>
                                                <div className="g-25 col-4 text-center">
                                                    <div className="item-btn item-add"
                                                         onClick={() => addItem(material)}>
                                                        <img src={ICONS.ADD} alt="blouse"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
}
//
// class MaterialPicker extends Component<ITypeMaterialPickerProps, ITypePickerMaterialState> {
//     constructor(props: ITypeMaterialPickerProps) {
//         super(props);
//         this.state = {
//             materialList: MATERIAL_LIST.map((item: ITypePickerMaterialItem | Material) => {
//                 item.count = this.props.selectedMaterials.find((x: ITypePickerMaterialItem | Material) => {
//                     return x.material == item.material
//                 })?.count || 0;
//                 return item;
//             })
//         };
//     }
//
//     selectItem(material: any) {
//         this.closeItem(material)
//         material.active! = !material.active;
//     }
//
//     async addItem(material: any) {
//         material.count! += 1;
//
//         const selectedMaterials = await Promise.all(this.state.materialList.map((sitem: ITypePickerMaterialItem | Material) => {
//             if (sitem.material === material.material) {
//                 sitem = material
//             }
//             return sitem
//         }));
//
//         this.setState({
//             ...this.state,
//             materialList: selectedMaterials
//         });
//
//         this.props.onColorSelection(selectedMaterials.filter((item: ITypePickerMaterialItem) => item.count! > 0))
//     }
//
//     async removeItem(event: any, material: any) {
//         event.stopPropagation();
//
//         if (material.count < 1) {
//             return;
//         }
//
//         material.count -= 1;
//
//         const selectedMaterials = await Promise.all(this.state.materialList.map((sitem: ITypePickerMaterialItem | Material) => {
//             if (sitem.material === material.material) {
//                 sitem = material
//             }
//             return sitem
//         }));
//
//         this.setState({
//             ...this.state,
//             materialList: selectedMaterials
//         });
//
//         this.props.onColorSelection(selectedMaterials.filter((item: ITypePickerMaterialItem) => item.count! > 0))
//     }
//
//     closeItem(material: any) {
//         return this.state.materialList.map((smaterial: any) => {
//             if (smaterial.active == true) {
    
//                 smaterial.active! = !smaterial.active;

//
//                 this.setState({
//                     ...this.state
//                 });
//                 return smaterial
//             }
//         })
//     }
//
//     render() {
//         return (
//             <>
//                 <div className="row color-picker-cnt g-0">
//                     <div className="col-12">
//                         <div className="row g-25">
//                             {
//                                 this.state.materialList.map((material: any) => (
//                                     <div
//                                         className="col-6 col-sm-4 col-md-4 col-lg-4 text-center mb-3 g-25 `${color.active == true ? 'color-active' : ''}`">
//                                         <div id={material.id}
//                                              className={`item-type ${material.active ? "active" : ""} ${!material.active && material.count > 0 ? 'color-active' : ''}`}
//                                              onClick={() => this.selectItem(material)}>
//                                             <div className={'d-flex align-items-center justify-content-start ml-4'}>
//                                                 <p className="ml-2 mr-2">{material.material}</p>
//                                                 {!material.active && material.count > 0 ?
//                                                     <p className='ml-auto mr-4'>{material.count}</p> : null}
//                                             </div>
//                                             <div className="selected-layer  ">
//                                                 <div className="row g-0 w-100 mt-3 align-items-center">
//                                                     <div className="g-25 col-4 text-center ">
//                                                         <div className="item-btn item-remove"
//                                                              onClick={(e) => this.removeItem(e, material)}>
//                                                             <img src={ICONS.REMOVE} alt="remove"/>
//                                                         </div>
//                                                     </div>
//                                                     <div className="g-25 col-4 text-center">
//                                                                     <span className="item-qty">
//                                                                         {material.count}
//                                                                     </span>
//                                                     </div>
//                                                     <div className="g-25 col-4 text-center">
//                                                         <div className="item-btn item-add"
//                                                              onClick={() => this.addItem(material)}>
//                                                             <img src={ICONS.ADD} alt="blouse"/>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//
//                                         </div>
//                                     </div>
//                                 ))}
//                         </div>
//                     </div>
//                 </div>
//             </>)
//     }
// }

export default MaterialPicker;
