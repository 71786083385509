import gql from "graphql-tag";

export const GET_ALL_ORDERS = gql`
   query($start:String, $end:String){
  orders_by_dates(start: $start, end: $end) {
    charge
    balance
    tickets
    order_id
    payments
    ordered_on
    pickup_date
    order_number
    customer_name
    customer_email
    customer_phone
    completed
  }
}
`

export const GET_ORDERS_BY_CUSTOMER_ID = gql`
    query($customerId: Int!){
        get_customer_orders(customerId:$customerId){
            charge
            balance
            tickets
            order_id
            payments
            ordered_on
            pickup_date
            order_number
            customer_name
            customer_email
            customer_phone
        }
    }
`

export const GET_ORDER_BY_ID = gql`
    query ($orderId: Int){
  order_by_id(orderId: $orderId) {
    id
    time
    note
    amount_remaining
    service_charges {
        id
        amount
        completed
        name
        base_amount
        tax_amount
        count
        payment_id
      }
    customer {
        id
        name
        email
        phone
    }
    log {
      time
      message
      user_id
      username
    }
    completed
    scheduled
    tickets {
      id
      label
      service_group_id
      log {
        time
        message
        user_id
        username
      }
      notes {
        time
        message
        user_id
        username
      }
      name
      service_charges {
        id
        amount
        completed
        name
        base_amount
        tax_amount
        count
        payment_id
      }
      description {
        colors {
          color
          count
        }
        materials {
          material
          count
        }
      }
      status
    }
  }
}
`


export const ORDER_RECEIPT_DATA = gql`
  query($orderid: Int) {
  order_receipt_data(orderid: $orderid) {
    items {
      name
      base_amount
      tax_amount
      count
      amount
      ticket_id
    }
    header {
      name
      street
      city
      city_state
      contact
    }
    received_by
    time
    order_received
    order_reference
    link
    note
    footer {
      name
      payment_id
      transaction_id
      lastfour
      type
      phone
    }
  }
}

`