import {GraphqlService, ObservableQueryT} from "../../services/graphql.service";
import {GET_SERVICES} from "../../graphql/query/Services";
import {Service} from "../../classes/classes";
import {ADD_SERVICE, UPDATE_SERVICE} from "../../graphql/mutation/services";
import gql from "graphql-tag";

let servicesQuery: ObservableQueryT;

export const handleFetchServices = () => {
    return new Promise(async (resolve, reject) => {
        if (!servicesQuery) {
            servicesQuery = GraphqlService.SendQueryObservable(GET_SERVICES);
            servicesQuery.onResults.subscribe(result => {
                if (result.error)
                    reject(result.error.message);
                else
                    resolve(result.data);
            });
        } else {
            servicesQuery.refetch();
            servicesQuery.onResults.subscribe(result => {
                if (result.error)
                    reject(result.error.message);
                else
                    resolve(result.data);
            });
        }
    });
};

export const handleAddService = (serviceData: Service) => {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(serviceData);
            const {name, type, cost, tax_id, id} = serviceData;
            console.log('data', serviceData);
            let mutation = id == -1 ? ADD_SERVICE : UPDATE_SERVICE;
            let variables = {name, type, cost, tax_id, id};
            if (id == -1)
                delete variables.id;
            const result = await GraphqlService.SendMutation(mutation, variables);

            resolve(result);
        } catch (err) {
            console.log(err);
            reject(err);
        }
    });
};
