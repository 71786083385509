import * as actionTypes from "./actionTypes"
import {TypePickerAction, ITypePickerMaterialState, MATERIAL_LIST, ITypePickerMaterialItem} from "../models";

const initialState: ITypePickerMaterialState = {
    type: '',
    materialList: MATERIAL_LIST
};

const materialPickerReducer = (state: ITypePickerMaterialState = initialState, action: TypePickerAction): ITypePickerMaterialState => {
    switch (action.type) {
        case actionTypes.ADD_MATERIAL:
            action.item.count! += 1;

            let addMaterials = [...state.materialList];
            let addIndex = state.materialList.findIndex(e => e.material == action.item.material);

            addMaterials[addIndex] = action.item;
            return {...state, materialList: [...addMaterials]};

        case actionTypes.MERGE_MATERIALS:
            const materials: any = action.item;
            const selectedMaterials: any = state.materialList.map((material: ITypePickerMaterialItem) => {
                material.selected = false;
                material.count = materials.find(y => y.material == material.material)?.count || 0;
                return material;
            });
            return {...state, materialList: [...selectedMaterials]};

        case actionTypes.REMOVE_MATERIAL:
            if (action.item.count > 0)
                action.item.count! -= 1;

            let removeMaterials = [...state.materialList];
            let removeIndex = state.materialList.findIndex(e => e.material == action.item.material);

            removeMaterials[removeIndex] = action.item;
            return {...state, materialList: [...removeMaterials]};

        case actionTypes.SELECT_MATERIAL:
            let selectMaterials = [...state.materialList];
            selectMaterials = selectMaterials.map(e => {
                e['selected'] = e['id'] == action.item.id;
                e['count'] = (e['id'] == action.item.id ? (e['count'] == 0 ? 1 : e['count']) : e['count']);
                return e;
            });

            return {...state, materialList: [...selectMaterials]};
        case actionTypes.RESET_MATERIAL:
            return {
                type: state.type,
                materialList: [...state.materialList].map((item: ITypePickerMaterialItem) => {
                    item.selected = false;
                    item.count = 0;
                    return item;
                }),
            };
        default:
            return state;
    }
}

export default materialPickerReducer;
