import {GraphqlService, ObservableQueryT} from "../../services/graphql.service";
import {GET_TAXES} from "../../graphql/query/Tax";
import {Tax} from "../../classes/classes";
import {ADD_TAX, UPDATE_TAX} from "../../graphql/mutation/Tax";

let taxesQuery: ObservableQueryT;

export const handleFetchTaxes = () => {
    return GraphqlService.SendQuery(GET_TAXES);
}

export const handleAddTax = (taxData: Tax) => {
    return new Promise(async (resolve, reject) => {
        try {
            const {name, amount} = taxData;
            const result = await GraphqlService.SendMutation(ADD_TAX, {tax: {name, amount}})

            resolve(result);
        } catch (err) {
            console.log(err);
            reject(err);
        }
    })
}

export const handleUpdateTax = (taxData: Tax) => {
    return new Promise(async (resolve, reject) => {
        try {
            const {id, name, amount, active} = taxData;
            const result = await GraphqlService.SendMutation(UPDATE_TAX, {tax: {name, amount, active}, id})

            resolve(result);
        } catch (err) {
            console.log(err);
            reject(err);
        }
    })
}
