import IReducerAction from "../models/IReducerAction"

import
{
    GET_OADATA_STARTED,
    GET_OADATA_SUCCESS,
    GET_OADATA_ERROR,
    GET_ORDERS_AGING_STARTED,
    GET_ORDERS_AGING_SUCCESS,
    GET_ORDERS_AGING_ERROR
} from '../types/orderAging.type';

interface IState {
    agignData: any,
    loading: boolean,
    error: string,
    ordersAging: any[],
    loadingOAging: boolean,
    errorOAging: string,
    aging: null
}

const initialState: IState = {
    error: null,
    loading: false,
    ordersAging: [],
    loadingOAging: false,
    errorOAging: null,
    aging: null,
    agignData: {
        "pickedup": [{"old": [], "last_2": [], "last_4": [], "last_8": [], "current": [], "last_16": [], "last_32": []}],
        "in_progress": [{"old": [], "last_2": [], "last_4": [], "last_8": [], "current": [], "last_16": [], "last_32": []}],
        "not_pickedup": [{"old": [], "last_2": [], "last_4": [], "last_8": [], "current": [], "last_16": [], "last_32": []}]
    }
}


export default (state: IState = initialState, action: IReducerAction): IState => {
    switch (action.type)
    {
        case GET_OADATA_STARTED:
            return {
                ...state,
                loading: true
            }
        case GET_OADATA_SUCCESS:
            return {
                ...state,
                loading: false,
                agignData: action.payload
            }
        case GET_OADATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_ORDERS_AGING_STARTED:
            return {
                ...state,
                loadingOAging: true,
                aging: action.payload
            }

        case GET_ORDERS_AGING_SUCCESS:
            return {
                ...state,
                loadingOAging: false,
                ordersAging: action.payload
            }
        case GET_ORDERS_AGING_ERROR:
            return {
                ...state,
                loadingOAging: false,
                errorOAging: action.payload
            }
        
        default:
            return state;
    }
}