import {all, call, put, takeLatest} from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import {handleAddItem, handleFetchItems} from "./helper";
import {fetchItemsStart, setItems} from "./actions";

export function* fetchItems() {
    try {
        const items = yield handleFetchItems();
        yield put(setItems(items));
    } catch (err) {
        console.log(err);
    }
}

export function* onFetchItemsStart() {
    yield takeLatest(actionTypes.FETCH_ITEMS_TYPE_START, fetchItems)
}

export function* addItem(item) {
    try {
        yield handleAddItem(item);
        yield put(fetchItemsStart());
    } catch (err) {
        console.log(err);
    }
}

export function* onAddCustomItemStart() {
    yield takeLatest(actionTypes.ADD_CUSTOM_ITEM_START, addItem)
}

export default function* typePickerSagas() {
    yield all([
        call(onFetchItemsStart),
        call(onAddCustomItemStart),
    ])
}
