import {useDispatch} from "react-redux";
import {useState} from "react";
import {changePasswordUserStart} from "../../../../saga/User/user.actions";
import Helper from "../../../../Helper/Helper";
import {UserChangePassword} from "../../../../saga/User/user.helper";
import ICONS from "../../../../../assets/svg";

const PasswordAccountModal = ({user, onCloseModal}) => {
    const dispatch = useDispatch();
    const [myUser, setMyUser] = useState(user);
    const [myUserPassword, setMyUserPassword] = useState(new UserChangePassword());
    const [showError, setShowError] = useState('');

    const closeModal = (event) => {
        event.stopPropagation();
        onCloseModal();
    };

    const saveUser = (event) => {
        event.stopPropagation();

        if (myUser.name == '') {
            setShowError('Username can not be empty');
            return;
        }
        if (myUserPassword.oldPassword == '') {
            setShowError('Old Password can not be empty');
            return;
        }
        if (myUserPassword.newPassword == '') {
            setShowError('New Password can not be empty');
            return;
        }

        setShowError('');
        dispatch(changePasswordUserStart(myUser, myUserPassword.newPassword));
        onCloseModal();
    }

    return(
        <div className='modal-container'>
            <div className='modal-component'>
                <div className="modal-body">

                    <div className="modal-title">
                        <div className={'header'}>
                            <h6 className="title-text">Create User</h6>
                            <img src={ ICONS.IconCloseBig } onClick={closeModal} alt=""/>
                        </div>
                    </div>

                    <div className="body">
                        <form>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group mb-3">
                                        <label className="m-0">Username*</label>
                                        <input type="text"
                                               readOnly={true}
                                               value={ myUser.name }
                                               className="form-field"
                                               placeholder="username"
                                               onChange={(event) => setMyUser({...myUser, name: event.target.value})}/>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-group mb-3">
                                        <label>Old Password*</label>
                                        <input type="password"
                                               value={ myUserPassword.oldPassword }
                                               className="form-field"
                                               placeholder="XXXXXXXX"
                                               onChange={(event) => setMyUserPassword({...myUser, oldPassword: event.target.value})}/>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-group mb-3">
                                        <label>New Password*</label>
                                        <input type="password"
                                               value={ myUserPassword.newPassword }
                                               className="form-field"
                                               placeholder="XXXXXXXX"
                                               onChange={(event) => setMyUserPassword({...myUser, newPassword: event.target.value})}/>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <p className="text-red">{showError}</p>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <div className="row">
                            <div className="col-6">
                                <button className={'btn btn-clear btn-block rounded-pill'} onClick={closeModal}>Cancel</button>
                            </div>

                            <div className="col-6">
                                <button className={'btn btn-orange btn-block rounded-pill'}
                                        disabled={myUser.name == '' || myUserPassword.oldPassword == '' || myUserPassword.newPassword == ''}
                                        onClick={saveUser}>Change Password</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordAccountModal;
