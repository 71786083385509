import {combineReducers, createStore, compose, applyMiddleware} from "redux";
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import typePickerReducer from "../components/TypePicker/state/reducer";
import colorPickerReducer from "../components/TypePicker/components/ColorPicker/state/reducer";
import materialPickerReducer from "../components/TypePicker/components/MaterialPicker/state/reducer";
import {ITypePickerState} from "../components/TypePicker/models";
import {ITypePickerMaterialState} from "../components/TypePicker/components/MaterialPicker/models";
import customerSelection from '../redux/reducers/customerSelection.reducer';
import orders from '../redux/reducers/order.reducer';
import agendas from '../redux/reducers/agenda.reducer';
import dailyReport from '../redux/reducers/dailyReport.reducer';
import orderAging from "../redux/reducers/orderAging.reducer";
import ticketAging from '../redux/reducers/ticketAging.reducer';
import createSagaMiddleware from 'redux-saga'
import rootSaga from "../saga";
import customerReducer from "../saga/Customer/customer.reducer";
import {ITypePickerColorState} from "../components/TypePicker/components/ColorPicker/models";
import serviceReducer from "../saga/Service/service.reducer";
import userReducer from "../saga/User/user.reducer";
import invoiceReducer from "../saga/Invoice/invoice.reducer";
import paymentsReducer from "../saga/Payments/payments.reducer";
import orderReducer from "../saga/Orders/orders.reducer";
import taxReducer from "../saga/Tax/tax.reducer";
import settings from "../redux/reducers/settings.reducer";
export interface ReducerType {
    typePickerReducer: ITypePickerState,
    colorPickerReducer: ITypePickerColorState,
    materialPickerReducer: ITypePickerMaterialState
}

const rootReducer = combineReducers({
    typePickerReducer,
    colorPickerReducer,
    materialPickerReducer,
    customerSelection,
    orders,
    agendas,
    orderAging,
    ticketAging,
    dailyReport,
    customersData: customerReducer,
    invoicesData: invoiceReducer,
    servicesData: serviceReducer,
    usersData: userReducer,
    taxesData: taxReducer,
    paymentsData: paymentsReducer,
    ordersData: orderReducer,
    settings
});

declare let window: any;

const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk, sagaMiddleware];

const store = createStore(
    rootReducer,
    compose(applyMiddleware(...middlewares),
        typeof window === 'object' &&
            typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' ?
        window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    ),
);

sagaMiddleware.run(rootSaga);

export {store};
