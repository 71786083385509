import paymentsTypes from "./payments.types";

export const setPayments: any = payments => ({
    type: paymentsTypes.FETCH_PAYMENTS,
    payload: payments
});

export const setPaymentsByCustomer: any = payments => ({
    type: paymentsTypes.FETCH_PAYMENTS_BY_CUSTOMER,
    payload: payments
});

export const fetchPaymentsStart: any = () => ({
    type: paymentsTypes.FETCH_PAYMENTS_START
});

export const fetchCustomerPayments: any = (customerId) => ({
    type: paymentsTypes.FETCH_PAYMENTS_BY_CUSTOMER_START,
    customerId,
});

export const fetchCustomerPaymentsStart: any = () => ({
    type: paymentsTypes.FETCH_PAYMENTS_BY_CUSTOMER,
});
