import { useState } from "react";
import Aging from "../Aging/Aging";
import OrderCard from "../../../components/OrderCard/OrderCard";
import PaymentCard from "../../../components/PaymentCard/PaymentCard";
import InvoiceCard from "../../../components/InvoiceCard/InvoiceCard";
import OrderBreakdown from "../../../components/OrderBreakdown/OrderBreakdown";
import Loading from "../../../components/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import { getAgingOrdersAction } from "../../../redux/actions/orderAging.action";

const dataInput = {
    "pickedup": [{"old": [28], "last_2": [], "last_4": [], "last_8": [], "current": [], "last_16": [], "last_32": []}],
    "in_progress": [{"old": [], "last_2": [], "last_4": [], "last_8": [], "current": [30], "last_16": [29], "last_32": []}],
    "not_pickedup": [{"old": [], "last_2": [], "last_4": [], "last_8": [], "current": [], "last_16": [21], "last_32": []}]
}

const OrderAging = ({data}) => {
    const dispatch = useDispatch();
    const [selectedAging, setSelectedAging] = useState();
    const selectedAgingList = useSelector<any>(state => state.orderAging.aging);

    return(
        <>
            {/*just for test*/}
            <Aging onSelectedAging={(evt) => dispatch(getAgingOrdersAction(evt))} agingList={data} selectedAging={selectedAgingList} setSelectedAging={setSelectedAging} />
        </>
    );
}

export default OrderAging;
