import React, {useRef, useState, useEffect} from 'react';
import {Customer} from '../../../classes/classes';
import CustomerForm from '../../../components/Forms/CustomerForm/CustomerForm';

import FlatList from 'flatlist-react';
import Searchbar from '../../../components/Searchbar/Searchbar';


import CustomerEnpoint from '../../../endpoints/Customer.endpoint';
import {useDispatch, useSelector} from 'react-redux';
import {getCustomersAction} from '../../../redux/actions/customerSelection.action';
import Loading from '../../../components/Loading/Loading';
import {fetchCustomersStart} from "../../../saga/Customer/customer.actions";
import Helper from '../../../Helper/Helper';
// import { getCustomersAction } from '../../../redux/actions/customerSelection.action';

const mapState = ({customersData}) => ({
    customers: customersData.customers,
    loadingAddCustomer: customersData.isLoading
});

const CustomerSelection = ({onCustomerSelected, customer_id}: { customer_id: number, onCustomerSelected: (customer: Customer) => any }) => {

    const dispatch = useDispatch();

    const [searchTerm, setSearchTerm] = useState('');
    const loading = useSelector<any>(state => state.customerSelection.loading);
    // FORM SHOWN STATE
    const [customerShown, setCustomerShown] = useState(false);

    const [customer, setCustomer] = useState(new Customer());

    const searchRef = useRef<any>();

    // const customers = useSelector<any>(state => state.customerSelection.customers) as Customer[];
    const {customers = [], loadingAddCustomer} = useSelector(mapState);

    async function getCustomers() {
        // return dispatch(getCustomersAction());
        return dispatch(fetchCustomersStart());
    }


    const customersFiltered = () => customers.filter(c => JSON.stringify({...c, phoneMock: c.phone.split('-').join('')}).toLowerCase().includes(searchTerm.trim().toLowerCase()));
    const renderCustomer = (customer: Customer) => <div key={'cust-' + customer.id}
                                                        onClick={(evt) => onCustomerSelected(customer)}
                                                        className={'fadein d-flex justify-content-between p-3 row-outlined mb-3' + (customer_id == customer.id ? ' orange' : '')}>
        <div className='col-5'>
            <h6 className="regular-text fw-600">{customer.name}</h6>
        </div>
        <div className='col-7'>
            <h6 className="regular-text fw-400">{customer.phone}</h6>
            <h6 className="regular-text fw-400">{customer.email}</h6>
        </div>
    </div>

    useEffect(() => {
        getCustomers();
    }, []);


    return (
        <div className='customer-selection-component flex-1-container'>

            {customerShown ? <CustomerForm customer={customer} onCancelClicked={(evt) => setCustomerShown(false)}
                                           onCustomerSubmitted={(customer) => {
                                               
                                               onCustomerSelected(customer);
                                               setCustomerShown(false);

                                           }}/> :


                <>
                    <div>
                        <Searchbar placeholder={'Search for customer'} value={searchTerm}
                               onChange={(evt: any) => setSearchTerm(evt.target.value)} reference={searchRef}/>
                        <div className='d-flex align-items-center justify-content-between mt-3 px-2'>
                            <h6 className="small-text fw-600">{customersFiltered().length} Customers</h6>
                            <button type='button' className="btn btn-clear" onClick={(evt) => {
                                let c = new Customer();

                                if (/^\d+$/.test(searchTerm.split('-').join('').split(' ').join('')))
                                    c.phone = Helper.Masks.USPhone(searchTerm)
                                else
                                    c.name = searchTerm;

                                setCustomer(c);
                                
                                setCustomerShown(true)
                            }}><i className="fas fa-plus mr-1"></i> NEW CUSTOMER
                            </button>
                        </div>
                    </div>
                    <div className='mt-3 px-2 flex-1-container'>
                        
                        {loading && <Loading/>}
                        <FlatList
                            list={customersFiltered()}
                            renderItem={(customer, index) => renderCustomer(customer)}
                            keyExtractor={(item, index) => index.toString()}
                            renderOnScroll
                            renderWhenEmpty={() => null}/>
                        {/* {
                    
                customers.map((c, i) => renderCustomer(c, i < customers.length - 1))} */}


                    </div>
                </>}

            {loadingAddCustomer && <Loading/>}
        </div>
    )
}

export default CustomerSelection;
