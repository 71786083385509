const taxType = {
    FETCH_TAXES_START: 'FETCH_TAX_START',
    SET_TAXES: 'SET_TAXES',
    ADD_TAX: 'ADD_TAX',
    ADD_TAX_START: 'ADD_TAX_START',
    UPDATE_TAX: 'UPDATE_TAX',
    UPDATE_TAX_START: 'UPDATE_TAX_START'
}

export default taxType;
