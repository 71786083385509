import moment from "moment";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Note, Service_charge, Ticket} from "../../classes/classes";
import ServiceChargeEnpoint from "../../endpoints/ServiceCharge.endpoint";
import Helper from "../../Helper/Helper";
import ServicesSelection from "../../pages/OverviewPage/ServicesSelection/ServicesSelection";
import DatePicker from "../DatePicker/DatePicker";
import ColorPicker from "../TypePicker/components/ColorPicker/ColorPicker";
import MaterialPicker from "../TypePicker/components/MaterialPicker/MaterialPicker";
import {mergeColors, resetColor} from "../TypePicker/components/ColorPicker/state/actions";
import {mergeMaterials, resetMaterial} from "../TypePicker/components/MaterialPicker/state/actions";
import Status from "../Status/Status";
import AuthService from "../../services/auth.service";
import TicketPickerModal from "../../pages/OverviewPage/TicketPickerModal/TicketPickerModal";
import Swal from "sweetalert2";
import ICONS from "../../../assets/svg";

export interface TicketInformationProps {
    scheduled: Date;
    ticket: Ticket;
    onTicketChanged: (ticket: Ticket, data: { note?: Note, services: boolean, status: boolean }) => void;
    onRemoveNotesClicked: (index: number) => void
}

const TicketInformation: React.SFC<TicketInformationProps> = ({
                                                                  scheduled,
                                                                  ticket,
                                                                  onTicketChanged,
                                                                  onRemoveNotesClicked
                                                              }) => {
    const [modalShown, setModalShown] = useState(false);
    const [currentMScreen, setCurrentMScreen] = useState(null);
    const [serviceCharges, setServiceCharges] = useState<Service_charge[]>([]);
    const [tempTicket, setTempTicket] = useState(new Ticket());
    const [note, setNote] = useState('');
    const [loadingServices, setLoadingServices] = useState(false);
    const [tpShown, setTPShown] = useState(false);
    const dispatch = useDispatch();

    async function getServices(name: string, services) {
        try {
            setServiceCharges([]);
            setLoadingServices(true);
            let data = await ServiceChargeEnpoint.GetByTicketName(name, [...services].map(x => {
                delete x.__typename;
                return x;
            }));
            setServiceCharges([...data]);
            setLoadingServices(false);
        } catch (ex) {
            setLoadingServices(false);
            console.log("ex", ex);
        }
    }

    const setMaterialProps = (selectedMaterials) => {
        dispatch(mergeMaterials(selectedMaterials));
    };

    const setColorProps = (selectedColors) => {
        dispatch(mergeColors(selectedColors));
    };

    const resetTypeColor = () => {
        dispatch(resetColor());
    };

    const resetTypeMaterial = () => {
        dispatch(resetMaterial());
    };

    enum ModalScreens {
        Status,
        Date,
        Name,
        Count,
        Label,
        Services,
        Description,
        Notes,
    }

    const style = {
        "--bs-gutter-x": 0,
    };

    const isValidToSave = () => {
        switch (currentMScreen) {
            case ModalScreens.Name:
                return tempTicket.name.trim() != '';
            case ModalScreens.Services:
                return tempTicket.service_charges.length > 0 && !tempTicket.service_charges.some(x => isNaN(x.base_amount) || x.base_amount as any == '') && tempTicket.service_charges.sumBy(x => x.amount) >= 0;
            case ModalScreens.Notes:
                return note.trim() != '';
            default:
                return true;
        }
    }

    const getModalTitle = () => {
        switch (currentMScreen) {
            case ModalScreens.Status:
                return "Set Ticket Status";
            case ModalScreens.Date:
                return "Set Ticket Date";
            case ModalScreens.Name:
                return "Set Ticket Name";
            case ModalScreens.Count:
                return "Set Ticket Count";
            case ModalScreens.Label:
                return "Set Tag";
            case ModalScreens.Services:
                return "Set Ticket Services";
            case ModalScreens.Description:
                return "Set Ticket Description";
            case ModalScreens.Notes:
                return "Add Ticket Notes";
        }
    };

    const getModalContent = () => {
        switch (currentMScreen) {
            case ModalScreens.Status:
                return (
                    <div className="form-group">
                        <label htmlFor="status">Select a status</label>
                        {/* <select
                            value={tempTicket.status}
                            onChange={(evt) =>
                                setTempTicket({...tempTicket, status: evt.target.value})
                            }
                            name="status"
                            id="status"
                            className="form-field"
                        >
                            <option value="RECEIVED">Received</option>
                            <option value="IN PROGRESS">In progress</option>
                            <option value="READY">Ready</option>
                            <option value="PICKED UP">Picked up</option>
                        </select> */}

                        <div >
                            <div onClick={(evt) => setTempTicket({...tempTicket, status: 'RECEIVED'})}>
                                <Status
                                    className={'big-status mb-2 ' + (tempTicket.status == 'RECEIVED' ? 'selected-status' : 'no-selected-status')}
                                    statusName={'RECEIVED'}/>
                            </div>
                            <div
                                 onClick={(evt) => setTempTicket({...tempTicket, status: 'IN PROGRESS'})}>
                                <Status
                                    className={'big-status mb-2 ' + (tempTicket.status == 'IN PROGRESS' ? 'selected-status' : 'no-selected-status')}
                                    statusName={'IN PROGRESS'}/>
                            </div>
                            <div onClick={(evt) => setTempTicket({...tempTicket, status: 'FINISHED'})}>
                                <Status
                                    className={'big-status mb-2 ' + (tempTicket.status == 'FINISHED' ? 'selected-status' : 'no-selected-status')}
                                    statusName={'FINISHED'}/>
                            </div>

                            <div onClick={(evt) => setTempTicket({...tempTicket, status: 'PICKED UP'})}>
                                <Status
                                    className={'big-status ' + (tempTicket.status == 'PICKED UP' ? 'selected-status' : 'no-selected-status')}
                                    statusName={'PICKED UP'}/>
                            </div>
                        </div>
                    </div>
                );
            case ModalScreens.Name:
                return (
                    <div className="form-group">
                        <label htmlFor="name">Name*</label>
                        <input
                            value={tempTicket.name}
                            onChange={(evt) =>
                                setTempTicket({...tempTicket, name: evt.target.value})
                            }
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Name"
                            className="form-field"
                        />
                    </div>
                );
            case ModalScreens.Label:
                return (
                    <div className="form-group">
                        <label htmlFor="tag">Tag</label>
                        <input
                            value={tempTicket.label}
                            onChange={(evt) =>
                                setTempTicket({...tempTicket, label: evt.target.value})
                            }
                            type="text"
                            id="tag"
                            name="tag"
                            placeholder="Tag"
                            className="form-field"
                        />
                    </div>
                );
            case ModalScreens.Services:
                return (
                    <ServicesSelection
                        onAddChargeClicked={(evt) => {
                            setModalShown(false);
                            setTPShown(true);
                        }}
                        ticket={tempTicket}
                        onToggleServices={(services) => {
                            services.removeBy(x => x.count == 0);
                            setTempTicket({...tempTicket, service_charges: services});
                        }}
                        loading={loadingServices}
                        serviceCharges={serviceCharges}
                    />
                );
            case ModalScreens.Description:
                return (
                    <div>
                        <div className="mb-3">
                            <h4 className="regular-text mb-3">Colors</h4>
                            <ColorPicker
                                onColorSelection={(colors) =>
                                    setTempTicket({...tempTicket, description: {...tempTicket.description, colors: colors.map(x => ({color: x.color, count: x.count}))}})
                                }
                                selectedColors={ticket.description.colors}
                            />
                        </div>

                        <div>
                            <h4 className="regular-text mb-3">Materials</h4>
                            <MaterialPicker
                                onColorSelection={(materials) =>
                                    setTempTicket({...tempTicket, description: {...tempTicket.description, materials: materials.map(x => ({material: x.material, count: x.count}))}})
                                }
                                selectedMaterials={tempTicket.description.materials}
                            />
                        </div>
                    </div>
                );
            case ModalScreens.Notes:
                return (
                    <div className="form-group">
                        <label htmlFor="notes">Notes*</label>
                        <textarea
                            value={note}
                            onChange={(evt) => setNote(evt.target.value)}
                            id="notes"
                            name="notes"
                            placeholder="notes"
                            className="form-field"
                        />
                    </div>
                );
        }
    };

    useEffect(() => {
        setTempTicket(JSON.parse(JSON.stringify(ticket || new Ticket())));
    }, [ticket]);

    return (
        <>
            {modalShown && currentMScreen == ModalScreens.Date && (
                <DatePicker
                    date={scheduled}
                    onDateSelected={(date) => {
                        setModalShown(false);
                    }}
                    onCancelClicked={(evt) => setModalShown(false)}
                    title={"Select pickup date"}
                />
            )}

            {tpShown && <TicketPickerModal service_group_id={ticket.service_group_id} onCloseClicked={(evt) => setTPShown(false)} onTickedAdded={(ticket) => {
                let t = {...tempTicket, service_charges: [...tempTicket.service_charges, ...ticket.service_charges]};
                let total = t.service_charges.sumBy(x => x.amount);
                if (total < 0)
                {
                    return Swal.fire({
                        title: `Error`,
                        text: `You could not add these services, the ticket can't be negative, Current Total: ` + Helper.Masks.ValidUSMoney(total),
                        icon: 'error'
                    });
                }
                onTicketChanged(t, {services: true, status: false});
                setTPShown(false);
            }} />}

            {modalShown && currentMScreen != ModalScreens.Date && (
                <div className="modal">
                    <div className={"modal-content " + (currentMScreen == ModalScreens.Services ? 'w-90 mw-720 ' : 'col-8')}>
                        <h4 className="tilte-text mb-3">{getModalTitle()}</h4>
                        <div style={{maxHeight: "80vh", overflow: "auto"}}>
                            {getModalContent()}
                        </div>
                        <div className="d-flex mt-4">
                            <div className="col-6 pl-0 pr-2">
                                <button
                                    className="btn col-12"
                                    onClick={(evt) => {
                                        setTempTicket(JSON.parse(JSON.stringify(ticket)));
                                        setModalShown(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="col-6 pr-0 pl-2">
                                <button
                                    disabled={!isValidToSave()}
                                    className="btn btn-orange col-12"
                                    onClick={(evt) => {
                                        let d = {
                                            note: ModalScreens.Notes == currentMScreen ? new Note({
                                                time: new Date(),
                                                message: note,
                                                user_id: AuthService.USER.id,
                                                username: AuthService.USER.name
                                            }) : null,
                                            services: ModalScreens.Services == currentMScreen,
                                            status: ModalScreens.Status == currentMScreen
                                        }

                                        onTicketChanged({...tempTicket, service_charges: tempTicket.service_charges.map(x => ({amount: +x.amount, base_amount: +x.base_amount, completed: x.completed, count: x.count, id: x.id, name: x.name, payment_id: x.payment_id, service_id: x.service_id, tax_amount: x.tax_amount, ticket_id: x.ticket_id}))}, d);

                                        setModalShown(false);
                                    }
                                        // {
                                        // onTicketChanged(tempTicket, currentMScreen == ModalScreens.Notes ? {note: new Note({
                                        //     time: new Date(),
                                        //     message: note,
                                        //     user_id: 1,
                                        //     username: 'admin'
                                        // }) : null)};
                                    }
                                >
                                    {currentMScreen == ModalScreens.Notes ? 'Add notes' : 'Save'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            <div className="row" style={style as any}>

            <div className="row-outlined no-action col-6 px-0 grid flex-column">
                            <div className="form-group px-2">
                                <label>Ticket name</label>
                                <div className="pl-3 d-flex justify-content-between w-100">
                                    <h4 className="big-text fw-400 mb-1">{ticket.name}</h4>
                                   
                                </div>
                            </div>
                        </div>

                <div className="row-outlined no-action col-6 px-0 grid flex-column">
                    {/* <h4 className='title-text mb-1'>STATUS</h4>
                                        <h4 className='regular-text'>{ticket.status}</h4> */}
                    <div className="form-group px-2">
                        
                        <div className="pl-3 d-flex align-items-center justify-content-between w-100">
                            <Status className='big-status' onClick={(evt) => {
                                setCurrentMScreen(ModalScreens.Status);
                                setModalShown(true);
                            }} statusName={ticket.status}/>
                            {/* <button
                                className="btn btn-orange-outline"
                                onClick={(evt) => {
                                    setCurrentMScreen(ModalScreens.Status);
                                    setModalShown(true);
                                }}
                            >
                                Edit
                            </button> */}
                        </div>
                    </div>
                </div>


                <div className="row-outlined no-action col-6 px-0 grid flex-column">
                    <div className="form-group px-2">
                        <label>Description</label>
                        <div className="pl-3 d-flex flex-column align-items-center w-100">
                            <div className="w-100 d-flex">
                                {ticket?.description?.colors.map((c, i) => (
                                    <div
                                        key={"color-" + i}
                                        className="d-flex align-items-center mr-2 mb-2"
                                    >
                                        <div
                                            className={"square-color " + c.color.toLowerCase()}
                                        ></div>
                                        <h6 className="small-text flex-wrap ml-1">{c.count}</h6>
                                    </div>
                                ))}
                            </div>

                            <div className="w-100 d-flex flex-wrap">
                                {ticket?.description?.materials.map((m, i) => (
                                    <h6 key={"material-" + i} className="small-text flex-wrap mr-2">
                                        {m.material} ({m.count})
                                    </h6>
                                ))}
                            </div>
                        </div>
                        <div className="d-flex justify-content-end w-100 mt-3">
                            <button
                                className="btn btn-orange-outline"
                                onClick={(evt) => {
                                    setCurrentMScreen(ModalScreens.Description);
                                    setModalShown(true);
                                    setMaterialProps(ticket.description.materials);
                                    setColorProps(ticket.description.colors);
                                }}
                            >
                                Add / Edit
                            </button>
                        </div>
                    </div>
                </div>
                
              
                <div className="row-outlined no-action col-6 px-0 grid flex-column">
                    <div className="form-group px-2">
                        <label>Tag</label>
                        <div className="pl-3 d-flex justify-content-between align-items-center w-100">
                            {/* <h4 className="title-text">{ticket.label}</h4> */}
                            <input value={ticket.label} readOnly placeholder='Tag' type="text" className="form-field cursor-pointer" onClick={(evt) => {
                                setCurrentMScreen(ModalScreens.Label);
                                setModalShown(true);
                            }}/>
                            {/* <button
                                className="btn btn-orange-outline"
                                onClick={(evt) => {
                                    setCurrentMScreen(ModalScreens.Label);
                                    setModalShown(true);
                                }}
                            >
                                Edit
                            </button> */}
                        </div>
                    </div>
                </div>
                <div className="row-outlined no-action col-12 px-0 grid flex-column" style={{borderRight: 'none'}} onClick={(evt) => {
                    setCurrentMScreen(ModalScreens.Services);
                    setModalShown(true);
                }}>
                    <div className="form-group px-0">
                        <label className='big-text'>Services</label>
                        <div className="pl-3 d-flex flex-column align-items-center w-100">
                            {ticket?.service_charges?.map((sc) => (
                                <div className="mb-1 d-flex justify-content-between w-100">
                                    <h4 className="medium-text fw-400">
                                        {sc.name} ({sc.count})
                                    </h4>
                                    <h4 className="medium-text fw-400">
                                        {Helper.FORMAT.USCurrency(sc.amount)}
                                    </h4>
                                </div>
                            ))}
                        </div>
                        {/* <div className="d-flex justify-content-end w-100 mt-3">
                            <button
                                className="btn btn-orange-outline"
                                onClick={(evt) => {
                                    setCurrentMScreen(ModalScreens.Services);
                                    setModalShown(true);
                                }}
                            >
                                Add / Edit
                            </button>
                        </div> */}
                    </div>
                </div>

                <div className="px-4 mt-3">
                    <div className="d-flex justify-content-between w-100">
                        <div className='form-group'>
                            <label>Notes</label>
                        </div>
                        
                    </div>

                    <div className="pl-3">
                        {ticket?.notes?.map((note, i) =>
                        <>
                        <div key={'n-' + (i + 1)} className='mt-3 d-flex justify-content-between align-items-start'>
                            <div>
                                <h6 style={{whiteSpace: 'pre'}} className="regular-text fw-400">{note.message}</h6>
                                <h6 className="regular-text fw-600 mt-2">Made by <span
                                    className=' text-orange'>{note.username}</span> at {Helper.FORMAT.USDate(note.time, 'n')}
                                </h6>
                            </div>
                            <button onClick={(evt) => onRemoveNotesClicked(i)} className='btn btn-clear p-0' style={{transform: 'scale(.75)'}}><img src={ICONS.IconCloseBig} alt=""/></button>
                        </div>
                        <hr className='mt-3'/>
                        </>)}
                    </div>


                    <div className='d-flex mt-3'>
                        <button onClick={(evt) => {
                                setNote('');
                                setCurrentMScreen(ModalScreens.Notes);
                                setModalShown(true);
                            }} className="btn btn-orange-outline">Add notes
                            </button>
                    </div>

                    <div>

                    </div>
                </div>

                <div className="px-4 mt-5">
                    <div className="d-flex justify-content-between w-100">
                        <div className='form-group'>
                            <label>Logs</label>
                        </div>
                    </div>

                    <div className="pl-3">
                        {ticket?.log?.map((note, i) => <div key={'n-' + (i + 1)} className='mt-3'>
                            <h6 className="regular-text fw-400">{note.message}</h6>
                            <h6 className="regular-text fw-600 mt-2">Made by <span
                                className=' text-orange'>{note.username}</span> at {Helper.FORMAT.USDate(note.time, 'n')}
                            </h6>
                        </div>)}
                    </div>


                    <div>
                        
                    </div>
                </div>
            </div>

            {/* <div className="mt-3">
        <h6 className="title-text mb-2">LOGS</h6>
        <div className="pl-2">
          <h4 className="regular-text mb-1 text-gray">TESTING</h4>
          <h4 className="regular-text mb-1 text-gray">TESTING</h4>
        </div>
      </div> */}
        </>
    );
};

export default TicketInformation;
