import gql from "graphql-tag";

export const CREATE_CUSTOMER = gql`
    mutation($name:String! $phone:String $note:String $email:String){
        create_customer(name:$name phone:$phone note:$note email:$email){
            id
        }
    }

`

export const UPDATE_CUSTOMER = gql`
    mutation($id:Int! $name:String $phone:String $note:String $email:String){
        update_customer(id:$id name:$name phone:$phone note:$note email:$email){
            id
        }
    }
`
