import gql from "graphql-tag";

export const GET_TICKET_AGING = gql`
    query{
        get_aging_not_pickedUp{
        paid{
        old
        last_16
        last_2
        last_4
        last_8
        current
        last_32
        }
        not_paid{
        old
        last_16
        last_2
        last_4
        last_8
        current
        last_32
        }
    }
    }
`