import React, { FC } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from 'chart.js';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

interface IDataset {
  label: string;
  data: number[];
  backgroundColor?: any;
  borderColor?: any;
}

interface IProps {
  type?: 'bar' | 'pie' | 'line' | 'doughnut';
  labels?: string[],
  datasets?: IDataset[]
}
const Chart: FC<IProps> = (props) => {
    const options = {
        responsive: true,
        onClick: (evt, element) => {
            console.log(evt, element)
        },
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: false,
            text: '',
          },
        },
      };
      
      // const labels = ['Current', '1-2 days', '3-4 days', '5-8 days', '9-16 days', '17-32 days', '33+ days'];
      
      const data = {
        labels: props.labels || [],
        datasets: props.datasets || []
      };

      if (!props.labels || !props.datasets)
        return <></>;
      if (props.type == 'bar')
        return <Bar options={options} data={data} />;
      if (props.type == 'pie')
        return <Pie options={options} data={data} />
      if (props.type == 'line')
        return <Line options={options} data={data} />
      if (props.type == 'doughnut')
        return <Doughnut options={options} data={data} />

      return <></>
      
}
export default Chart;