import moment from "moment";

export class Customer{
    id: number = -1;
    name: string = "";
    phone: string = "";
    note: string = "";
    email: string = "";
    constructor(x?: Customer){
        if(x){
            Object.assign(this, x);
        }
    }
}

export class User{
    id: number = -1;
    name: string = "";
    password?: string = "";
    active?: boolean = false;

    constructor(x?: User){
        if(x){
            Object.assign(this, x);
        }
    }
}

export class Tax{
    id: number = -1;
    amount: number = 0;
    name: string = "";
    active: boolean = false;

    constructor(x?: Tax) {
        if(x){
            Object.assign(this, x);
        }
    }
}

export class Service{
    id: number = -1;
    name: string = "";
    type: string = "";
    cost: number = 0;
    tax_id: number = null;
    tax: Tax = new Tax();

    constructor(x?: Service){
        if(x){
            Object.assign(this, x);
        }
    }
}

export class Settings{
    key: string = "";
    value: string = "";

    if(x?: Settings){
        if(x){
            Object.assign(this, x);
        }
    }
}

export class Employee{
    id: number = -1;
    name: string = "";
    user: User = new User();

    if(x?: Employee){
        if(x){
            Object.assign(this, x);
        }
    }
}

export class Order{
    id: number = -1;
    time: string = "";
    note: string = "";
    log: Note[] = [];
    completed: boolean = false;
    user: User = new User();
    user_id?: number = -1;
    scheduled: Date = new Date(moment().format('YYYY-MM-DD 3:00 ') + 'PM');
    customer: Customer = new Customer();
    tickets: Ticket[] = [];
    cancelled: boolean = false;
    service_charges: Service_charge[] = [];
    amount_remaining: number = 0;

    constructor(x?: Order){
        if(x){
            this.id = x.id;
            this.time = x.time;
            this.note = x.note;
            this.log = x.log;
            this.completed = x.completed;
            this.user = x.user;
            this.scheduled = x.scheduled;
            this.customer = x.customer;
            this.tickets = x.tickets;
            this.cancelled = x.cancelled;
            this.service_charges = x.service_charges;
            this.amount_remaining = x.amount_remaining;
        }
    }
}

export class Description
{
    colors: Color[] = [];
    materials: Material[] = [];

    constructor(x?: Description)
    {
        if (x)
            Object.assign(this, x);
    }
}

export class Ticket{
    id?: number = -1;
    label?: string = "";
    log?: Note[] = [];
    picked_up?: boolean = false;
    time_picked_up?: string = "";
    notes?: Note[] = [];
    name: string = "";
    cost?: number = 0;
    status?: string = "RECEIVED";
    description?: Description = new Description();
    service_charges: Service_charge[] = [];
    service_group_id: number = null;

    constructor(x?: Ticket){
        if(x){
            Object.assign(this, x);
        }
    }
}
export class Color {
    color: string = '';
    count: number = 0;
}

export class Material {
    material: string = '';
    count: number = 0;
}

export class Invoice{
    id: number = -1;
    order: Order = new Order();
    active: boolean = false;
    issued: string = "";
    paid: boolean = false;
    log: string = "";
    total: number = 0;
    due_by: string = "";
    json_data: string = "";

    constructor(x?: Invoice){
        if(x){
            Object.assign(this, x);
        }
    }
}

export class Payment{
    id: number = -1;
    customer: Customer = new Customer();
    type: string = "";
    amount: number = 0;
    time: string = "";
    response_log: string = "";
    reference: string = "";
    use_credit: number = 0;
    user: User = new User();
    order_id: number;
    order: Order = new Order();
    status: string = "";
    voided: boolean = false;
    refunded: boolean = false;

    constructor(x?: Payment){
        if(x){
            Object.assign(this, x);
        }
    }
} 

export class Note {
    message: string = '';
    time: Date = new Date();
    username: string = '';
    user_id: number = -1;

    constructor (x?: Note) {
        Object.assign(this, x);
    }
}

export class Refund{
    id: number = -1;
    response_log: string = "";
    reference: string = "";
    notes: string = "";
    user: User = new User();
    customer: Customer = new Customer();
    Payment: Payment = new Payment();
    constructor(x?: Refund){
        if(x){
            Object.assign(this, x);
        }
    }
}

export class TicketSelection {
    id: number = null;
    name: string = '';
    cost: number = '' as any;
    count: number = 1;
    tax_id: number = null;
    icon: string = '';

    constructor (x?: TicketSelection) {
        if (x)
            Object.assign(this, x)
    }
}


export class Service_charge{
    id?: number = 0;
    amount: number = 0;
    completed?: boolean = false;
    ticket_id?: number = null;
    name: string = "";
    base_amount: number = 0;
    tax_amount: number = 0;
    count: number = 0;
    service_id?: number= null;
    payment_id?: number = null;
    // ref
    constructor(x?: Service_charge){
        if(x){
            this.id = x.id;
            this.amount = x.amount;
            this.completed = x.completed;
            this.ticket_id = x.ticket_id;
            this.name = x.name;
            this.base_amount = x.base_amount;
            this.tax_amount = x.tax_amount;
            this.count = x.count;
            this.service_id = x.service_id;
            this.payment_id = x.payment_id;
        }
    }
}

export class AgendaTicket
{
    id: number = -1;
    scheduled: Date = new Date();
    status: string;

    constructor (x: AgendaTicket)
    {
        if (x)
            Object.assign(x, this);
    }
}

export class Agenda
{
    start_of_day: Date = new Date();
    tickets: AgendaTicket[] = [];

    constructor (x?: Agenda)
    {
        if (x)
            Object.assign(x, this);
    }
}
