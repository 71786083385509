import {gql} from "apollo-boost";

export const ALL_PAYMENTS = gql(`query {
  payments {
    time
    amount
    status
    payment_id
    order_number
    payment_number
    transaction_number
    type
    charges{
      id
      ticket_id
      name
      count
      base_amount
      tax_amount
      amount
    }
  }
}`);

export const CUSTOMER_PAYMENTS = gql(`query($customerId: Int!){
        get_customer_payments(customerId:$customerId){
            time
            amount
            status
            payment_id
            order_number
            payment_number
            transaction_number
            type
            charges{
                id
                ticket_id
                name
                count
                base_amount
                tax_amount
                amount
            }
        }
    }`);
