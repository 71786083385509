import {gql} from "apollo-boost";

export const ADD_SERVICE = gql(`
mutation($name: String!, $cost: Float, $service_group_id: Int!, $tax_id: Int) {
  create_service(name: $name, cost: $cost, tax_id: $tax_id, service_group_id: $service_group_id) {
     id
  }
}
`);

export const UPDATE_SERVICE = gql`
mutation($id:Int! $name:String $service_group_id: Int! $cost:Float $tax_id:Int){
  update_service(id:$id name:$name cost:$cost tax_id: $tax_id service_group_id: $service_group_id){
    id
  }
}
`;
