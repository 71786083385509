

import moment from 'moment';
import Swal from 'sweetalert2';
import { printReceipt, ReceiptData } from '../services/printerDrawer';
import { GET_RECEIPT_BY_PAYMENT_ID } from '../graphql/query/Receipt';
import { GraphqlService } from '../services/graphql.service';
import gql from 'graphql-tag';
import AuthService from '../services/auth.service';
import { ORDER_RECEIPT_DATA } from '../graphql/query/Order';
import { TicketService } from '../services/ticket.service';

interface INavigator
{
    push: (url: string) => void,
    goBack: () => void,
    location: {pathname: string}
}

export default class Helper  {

    private static Navigator: INavigator;
    static SetNavigator(navigator: INavigator)
    {
        if (this.Navigator)
            return;
        this.Navigator = navigator;
    }

    static Session = {
        DoLogout: () => {
            AuthService.USER = {
                id: -1,
                name: ''
            }
            localStorage.clear();
            GraphqlService.SetClient();
            Helper.Navigation.NavigateTo('/login')
        }
    }

    static Receipt = {
        ShowConfirmation: (payment_id: number, copies: number, openDrawer: boolean = false) => {
            return new Promise((resolve, reject) => {
                Swal.fire({
                    title: 'Thank you for your payment - ' + payment_id,
                    text: 'Please select an option',
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: `Print Receipt`,
                    denyButtonText: `Send Receipt`,
                    icon: 'question'
                  }).then(async result => {
                      
                    if (result.isConfirmed)
                    {
                        await Helper.Receipt._printReceipt(payment_id, copies, openDrawer);
                    }
                    else if (result.isDenied)
                    {
                        await Helper.Receipt.sendReceipt(payment_id);
                    }
                    resolve('');
                  });
            })
            
        },
        sendReceipt: async (payment_id) => {

            return new Promise(async (resolve, reject) => {
                try {
                    const { value: formValues } = await Swal.fire({
                        title: 'Send Receipt',
                        html:
                          `
                          <h4 class='title-text text-left'>Email</h4>
                          <input placeholder='abc@xyz.com' id="swal-input1" class="swal2-input">
                          <h4 class='title-text text-left'>Phone</h4>
                          <input placeholder='000-000-0000' id="swal-input2" class="swal2-input">`,
                        focusConfirm: false,
                        showCancelButton: true,
                        confirmButtonText: 'SEND',
                        preConfirm: () => {
                          return [
                            (document.getElementById('swal-input1') as any).value,
                            (document.getElementById('swal-input2') as any).value
                          ]
                        }
                      })
        
        
        
                      
                    await GraphqlService.SendMutation(gql`mutation($email: String, $phone: String, $paymentId: Int) {
                        resend_receipt(email: $email, phone: $phone, paymentId: $paymentId)
                      }`, {phone: formValues[1] || '', email: formValues[0] || '', paymentId: payment_id});
        
        
                    resolve('');
                } catch (ex) {
                    Swal.fire({
                        title: 'Somenthing went wrong',
                        text: "Do you want to try again?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, retry it!'
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                            await Helper.Receipt.sendReceipt(payment_id);
                        }
                        resolve('');
                      })
                }
            })
            
        },
    
        _printReceipt: async ( payment_id, copies, openDrawer) => {
            return new Promise(async (resolve, reject) => {
                try {
                    let data = await GraphqlService.SendQuery(GET_RECEIPT_BY_PAYMENT_ID, {paymentid: payment_id});
                    console.log('print data: ', data);
                    for (let i = 0; i < copies; i++)
                    {
                        await printReceipt(new ReceiptData(data), openDrawer);
                    }

                    resolve('');
                } catch (ex) {
                    Swal.fire({
                        title: 'Somenthing went wrong',
                        text: ex.message || "Do you want to try again?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, retry it!'
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                            await Helper.Receipt._printReceipt(payment_id, copies, openDrawer);
                        }
                        resolve('');
                      })
                }
            })
            
        },

        PrintOrderReceipt: async (order_id: number, copies: number) => {
            try {
                console.log('asking for receipt: ', order_id);
                let data = await GraphqlService.SendQuery(ORDER_RECEIPT_DATA, {orderid: +order_id});
                console.log('here is data: ', data);
                for (let i = 0; i < copies; i++)
                {
                    let d = await printReceipt(data, false);
                   if (!d)
                    throw new Error(`Can't comunicate with the printer`)
                }
            } catch (ex) {
                Swal.fire({
                    title: ex.message,
                    text: "Do you want to try again?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, retry it!'
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                        Helper.Receipt.PrintOrderReceipt(order_id, copies);
                    }
                  })
            }
        }
    
    }

    static Navigation = {
        NavigateTo: (url: string) => {
            TicketService.LastPage = Helper.Navigator.location?.pathname;
            Helper.Navigator.push(url)
        },
        GoBack: () => {
            Helper.Navigator.goBack();
        },
        NewTab: (url: string) => {
            Object.assign(document.createElement('a'), {
                target: '_blank',
                href: url,
            }).click();
        }
    }

    

    static FORMAT = {
        USCurrency: (value: number) => {
            if (isNaN(value) || value as any == '-0')
                value = 0;

            return new Intl.NumberFormat('en-US',
                    { style: 'currency', currency: 'USD' }
                  ).format(value)
        },
        USDate: (value: Date, formatType: 'n' | 'dd' | 'st' | 'do' | 'to' | 'ld' = 'n') => {
            
            if (!formatType)
                formatType = 'n';

            const isToday = () => moment(value).format('YYYY/MM/DD') == moment().format('YYYY/MM/DD');
            const getDaysDiff = () => {
                let diff = moment(new Date(value).setHours(0, 0, 0, 0)).diff(moment(new Date().setHours(0, 0, 0, 0)), 'day');
                
                
                
                if (diff == -1)
                    return 'Yesterday';
                else if (diff == 1)
                    return 'Tomorrow';
                else if (diff == 0)
                    return 'Today'


                return diff < -1 ? `${-diff} days ago` : `In ${diff} days`;
            }
            


            const format = {
                'n': 'MM/DD/YYYY hh:mm A',
                'dd': `(${isToday() ? 'Today' : `${getDaysDiff()}`})`,
                'st': (isToday() ? '' : 'MM/DD/YYYY ') + ' hh:mm A',
                'do': 'MM/DD/YYYY',
                'to': 'hh:mm A',
                'ld': 'dddd, MM/DD/YYYY'
            }
            
            return formatType == 'dd' ? format[formatType] : moment(value).format(format[formatType]);
        }
    }

    static Masks = {
        USPhone: (value: string) => {
            let phone = '';
        let hcount = value.split('').filter(x => x == '-').length;
        
        value = value.replace('-', '');
        for (let i = 0; i < value.length; i++)
        {
            const char = value.substring(i, i + 1);

            if (char >= '0' && char <='9')
                phone += char;
            if (phone.length == 12)
                break;
            
            if (phone.length == 3 || phone.length == 7)
                phone += '-';
        }

        let array = phone.split('-');

        let first = array[0] || '';
        let second = array[1] || '';
        let third = array[2] || '';



        return first + ((hcount > 0 || second.length >= 1) && first.length > 2 ? '-' : '') + second + ((hcount > 1 || third.length >= 1) && second.length > 2 ? '-' : '') + third;
        },
        IntNumbers: (value: string, maxLength?: number) => {
            let newString = '';
            for (let i = 0; i < value.length; i++) {
                const char = value.substring(i, i + 1);
                if (((char >= '0' && char <= '9')))
                {
                    newString += char;
                }
                if (maxLength != null && newString.length == maxLength)
                    return newString;
                
            }
            
            return newString;
        },
        DecimalNumbers: (value: string, allowNegative: boolean = false) => {
                let newString = '';
                let decimalCount = 0;
                for (let i = 0; i < value.length; i++) {
                    const char = value.substring(i, i + 1);
                    if (((char >= '0' && char <= '9') || (char == '.' && !newString.includes('.'))) && decimalCount < 2)   
                    {
                        if (newString.includes('.'))
                            decimalCount++;
                        newString += char;
                    }
                    else if (i == 0 && char == '-' && allowNegative)
                        newString += char;
                    
                }
                newString = newString == '.' ? '0.' : newString
                
                return newString;
        },
        CreditCardExp: (value: string) => {
            const hasSlash = value.includes('/');
            value = value.trim().replace('/', '');
            let exp = '';
            
            for (let i = 0; i < value.length; i++)
            {
    
                
                const char = value.substring(i, i + 1);
    
                if (exp.length == 5)
                    break;
    
                if (exp.length == 2)
                {
                    exp += '/';
                    if (char == '/')
                        continue;
                }
                
                
                if (char >='0' && char <='9')
                    exp += char;
            }
    
            let month = exp.split('/')[0] || '';
            let year = exp.split('/')[1] || '';
    
            if (month.length == 1)
            {
                if (Number(month) > 1)
                    month = '';
            }
            else if (month.length == 2)
            {
                if (Number(month) == 0)
                    month = '0';
                if (Number(month) > 12)
                    month = '0' + month.substring(1, 2);
            }
    
    
    
    
            return month + (hasSlash || year.length >= 1 ? '/' : '') + year;
        },
        ValidUSMoney: (value: number, skip = false) => {
            return new Intl.NumberFormat('en-US',
                { style: 'currency', currency: 'USD' }
            ).format(value).replace('$', skip ? '' : '$').trim();
        },
    }

    static Validators = {
        IsValidEmail: (email: string) => {
            let emails = email.split(';').filter(x => x != '');
            
            for (let email of emails)
            {
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim()))
                    return false;
                
            }
            return true;
            
        }
    }

}
