import ICONS from "../../../../assets/svg";
import {GraphqlService, ObservableQueryT} from "../../../services/graphql.service";


let usersQuery: ObservableQueryT;

export const baseItems = [
    {
        "id": 3,
        "cost": 3.75,
        "icon": "",
        "count": 0,
        "name": "Shirt",
        "label": "Shirt",
        image: ICONS.SHIRT,
        "tax_id": 13
    },
    {
        "id": 74,
        "cost": 5,
        "icon": "",
        "count": 0,
        "name": "Trousers",
        "label": "Trousers",
        image: ICONS.OVERCOAT,
        "tax_id": 14
    },
    {
        "id": 75,
        "cost": 20,
        "icon": "",
        "count": 0,
        "name": "Long Coat",
        "label": "Long Coat",
        image: ICONS.OVERCOAT,
        "tax_id": 14
    },
    {
        "id": 16,
        "cost": 15,
        "icon": "",
        "count": 0,
        "name": "Overcoat",
        "label": "Overcoat",
        image: ICONS.OVERCOAT,
        "tax_id": 14
    },
    {
        "id": 17,
        "cost": 5,
        "icon": "",
        "count": 0,
        "name": "Hat",
        "label": "Hat",
        image: ICONS.HAT,
        "tax_id": 14
    },
    {
        "id": 18,
        "cost": 3.75,
        "icon": "",
        "count": 0,
        "name": "Scarf",
        "label": "Scarf",
        image: ICONS.SCARF,
        "tax_id": 14
    },
    {
        "id": 19,
        "cost": 5,
        "icon": "",
        "count": 0,
        "name": "Tie",
        "label": "Tie",
        image: ICONS.TIE,
        "tax_id": 14
    },
    {
        "id": 20,
        "cost": 10,
        "icon": "",
        "count": 0,
        "name": "Custom",
        "label": "Custom",
        image: ICONS.CUSTOM,
        "tax_id": 14
    },
    {
        "id": 4,
        "cost": 5,
        "icon": "",
        "count": 0,
        "name": "Polo",
        "label": "Polo",
        image: ICONS.POLO,
        "tax_id": 14
    },
    {
        "id": 5,
        "cost": 7,
        "icon": "",
        "count": 0,
        "name": "Sweater",
        "label": "Sweater",
        image: ICONS.SWEATER,
        "tax_id": 14
    },
    {
        "id": 6,
        "cost": 12,
        "icon": "",
        "count": 0,
        "name": "Waistcoat",
        "label": "Waistcoat",
        image: ICONS.WAIST_COAT,
        "tax_id": 14
    },
    {
        "id": 7,
        "cost": 9,
        "icon": "",
        "count": 0,
        "name": "Jacket",
        "label": "Jacket",
        image: ICONS.JACKET,
        "tax_id": 14
    },
    {
        "id": 9,
        "cost": 8,
        "icon": "",
        "count": 0,
        "name": "Plated skirt",
        "label": "Plated skirt",
        image: ICONS.PLEATED_SKIRT,
        "tax_id": 14
    },
    {
        "id": 11,
        "cost": 15,
        "icon": "",
        "count": 0,
        "name": "Jeans",
        "label": "Jeans",
        image: ICONS.JEANS,
        "tax_id": 14
    },
    {
        "id": 12,
        "cost": 14,
        "icon": "",
        "count": 0,
        "name": "Suit",
        "label": "Suit",
        image: ICONS.SUIT,
        "tax_id": 14
    },
    {
        "id": 14,
        "cost": 150,
        "icon": "",
        "count": 0,
        "name": "Evening dress",
        "label": "Evening dress",
        image: ICONS.EVENING_DRESS,
        "tax_id": 14
    },
    {
        "id": 73,
        "cost": 150,
        "icon": "",
        "count": 0,
        "name": "Wedding Dress",
        "label": "Wedding Dress",
        image: ICONS.DRESS,
        "tax_id": 13
    },
    {
        "id": 15,
        "cost": 10,
        "icon": "",
        "count": 0,
        "name": "Jumpsuit",
        "label": "Jumpsuit",
        image: ICONS.JUMP_SUIT,
        "tax_id": 14
    },
    {
        "id": 13,
        "cost": 10,
        "icon": "",
        "count": 0,
        "name": "Dress",
        "label": "Dress",
        image: ICONS.DRESS,
        "tax_id": 14
    },
    {
        "id": 106,
        "cost": 8,
        "icon": "",
        "count": 0,
        "name": "Skirt",
        "label": "Skirt",
        image: ICONS.SKIRT,
        "tax_id": 14
    },
    {
        "id": 2,
        "cost": 5,
        "icon": "",
        "count": 0,
        "name": "Blouse",
        "label": "Blouse",
        image: ICONS.BLOUSE,
        "tax_id": 14
    },
    {
        "id": 10,
        "cost": 5,
        "icon": "",
        "count": 0,
        "name": "Slacks",
        "label": "Slacks",
        image: ICONS.SLACKS,
        "tax_id": 14
    }
];

export const handleFetchItems = () => {
    return new Promise(async (resolve, reject) => {
        simulateHttpRequest(()=>{
            resolve(baseItems)});
    });
};


export const handleAddItem = (item) => {
    return new Promise(async (resolve, reject) => {
        simulateHttpRequest(()=>{
            resolve(baseItems)});
    });
};


export function simulateHttpRequest(action) {
    setTimeout(() => {
        return action();
    }, 500)
}
