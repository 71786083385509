export const GET_ORDERS_STARTED = 'GET_ORDERS_STARTED';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR';

export const CREATE_ORDER_STARTED = 'CREATE_ORDER_STARTED';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_ERROR = 'CREATE_ORDER_ERROR';


export const GET_HISTORY_ORDERS_STARTED = 'GET_HISTORY_ORDERS_STARTED';
export const GET_HISTORY_ORDERS_SUCCESS = 'GET_HISTORY_ORDERS_SUCCESS';
export const GET_HISTORY_ORDERS_ERROR = 'GET_HISTORY_ORDERS_ERROR';

export const GET_OPEN_ORDERS_STARTED = 'GET_OPEN_ORDERS_STARTED';
export const GET_OPEN_ORDERS_SUCCESS = 'GET_OPEN_ORDERS_SUCCESS';
export const GET_OPEN_ORDERS_ERROR = 'GET_OPEN_ORDERS_ERROR';

export const SET_ORDER_EDITTING = 'SET_ORDER_EDITTING';


export const GET_PAYMENTS_ORDER_STARTED = 'GET_PAYMENTS_ORDER_STARTED';
export const GET_PAYMENTS_ORDER_SUCCESS = 'GET_PAYMENTS_ORDER_SUCCESS';
export const GET_PAYMENTS_ORDER_ERROR = 'GET_PAYMENTS_ORDER_ERROR';


export const GET_INVOICES_ORDER_STARTED = 'GET_INVOICES_ORDER_STARTED';
export const GET_INVOICES_ORDER_SUCCESS = 'GET_INVOICES_ORDER_SUCCESS';
export const GET_INVOICES_ORDER_ERROR = 'GET_INVOICES_ORDER_ERROR';

export const RESET_NEW_ORDER = 'RESET_NEW_ORDER';