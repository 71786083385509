import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from 'apollo-boost';
import AuthService from './app/services/auth.service';
import {GraphqlService} from './app/services/graphql.service';
import environments from './environment/environment';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {transitions, positions, Provider as AlertProvider} from 'react-alert'
import crypto from 'crypto-js';
import { Provider } from 'react-redux';
import {store} from "./app/store";
import { createClient } from './app/config/graphql.client';

import './Extensions';

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file


GraphqlService.SetClient();

function loadInfo() {
    AuthService.TOKEN = localStorage.getItem('token') || '';
}

loadInfo();




ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <App/>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
