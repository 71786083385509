import React, {useEffect, useState} from 'react';
import {Ticket} from '../../../classes/classes';
import Helper from '../../../Helper/Helper';
import {useDispatch, useSelector} from "react-redux";
import CustomTicketForm from "../../Forms/CustomTicketForm/CustomTicketForm";
import ICONS from '../../../../assets/svg';

interface ICustomTicketModalForm {
    onCancelClicked: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
    onSubmitted: (ticket: any) => any;
    ticket: any
}

const CustomTicketModalForm = ({title = "", currentTicket = undefined, onCloseModal, onSaveItem}) => {

    const [ticket, setTicket] = useState<Ticket>(new Ticket());
    const dispatch = useDispatch();


    const saveTicket = (ticket) => {
        onSaveItem(ticket);
    };

    const closeModal = () => {
        onCloseModal();
    };


    return (
        <>
            <div className='modal-container'>
                <div className='modal-component'>
                    <div className="modal-body">

                        <div className="modal-title">
                            <div className={'header'}>
                                <h6 className="title-text">{title}</h6>
                                <img src={ICONS.IconCloseBig} onClick={()=>{closeModal()}} alt=""/>
                            </div>
                        </div>

                        <div className="body">
                            <CustomTicketForm ticket={currentTicket || ticket} onCancelClicked={closeModal} onSubmitted={saveTicket} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CustomTicketModalForm;
