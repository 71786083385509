import IColumn from "../../../components/Table/models/IColumn";
import Table from "../../../components/Table/Table";
import Helper from "../../../Helper/Helper";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchCustomerPayments} from "../../../saga/Payments/payments.actions";


const mapState = ({paymentsData}) => ({
    payments: paymentsData.customerPayments.map(payment => {
        return {
            ...payment,
            time: [Helper.FORMAT.USDate(payment.time, 'do'), Helper.FORMAT.USDate(payment.time, 'dd')],
            amount: payment.amount,
            status: payment.status,
            payment_id: payment.payment_id,
            order_number: payment.order_number,
            payment_number: payment.payment_number,
            transaction_number: payment.transaction_number,
            type: payment.type,
            charges: payment.charges,
            tickets:  payment.charges && payment.charges.reduce((a, b) => {
                return ((a.id && ", " || '') + (b.id && 'T-' + b.id || ''))
            }, ''),
        };
    })
});


const CustomerDetailPayments = (props) => {
    const columns: IColumn[] = [
        { name: 'time',              label: 'PAID ON',           orderBy: 'ASC', active: false },
        { name: 'payment_number',    label: 'PAYMENT #',         orderBy: 'ASC', active: false },
        { name: 'amount',           label: 'AMOUNT',            orderBy: 'ASC', active: false },
        { name: 'type',             label: 'PAYMENT METHOD',    orderBy: 'ASC', active: false },
        { name: 'transaction_number',        label: 'REFERENCE',         orderBy: 'ASC', active: false },
        { name: 'tickets',    label: 'TICKETS',         orderBy: 'ASC', active: false },
    ];

    const dispatch = useDispatch();
    const customerId = props.customerId;

    const { payments } = useSelector(mapState);

    useEffect(() => {
        getCustomerPayments().then();
    }, [props.customerId]);


    async function getCustomerPayments() {
        return dispatch(fetchCustomerPayments(customerId));
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <Table columns={columns}
                           rows={payments}
                           onRowClicked={() => null}
                           tableHeight={'59vh'}/>
                </div>
            </div>
        </>
    );
}

export default CustomerDetailPayments;
