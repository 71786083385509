import {gql} from "apollo-boost";

export const GET_TAXES = gql(`query {
  taxes {
    id
    name
    amount
    active
  }
}`);
