import * as actionTypes from "./actionTypes"
import {ITypePickerState, TypePickerAction} from "../models";
// import {itemList} from "../assets/icons";

const initialState: ITypePickerState = {
    items: [],
};

const typePickerReducer = (state: ITypePickerState = initialState, action: TypePickerAction): ITypePickerState => {
    switch (action.type) {
        case actionTypes.ADD_TYPE:
            return {
                items: [...state.items].map((item) => {
                    if(item.id === action.item.id){
                        item.count! += 1;
                    }
                    return item;
                }) as any[],
            };
        case actionTypes.FETCH_ITEMS_TYPE_START:
            return {
                items: [],
                isLoading: true
            };
        case actionTypes.OPEN_CUSTOM_FORM:
            return {
                ...state,
                isOpenCustomForm: true
            };
        case actionTypes.CLOSE_CUSTOM_FORM:
            return {
                ...state,
                isOpenCustomForm: false
            };
        case actionTypes.SET_ITEMS_TYPE:
            return {
                ...state,
                items: action.items,
                isLoading: false
            };
        case actionTypes.REMOVE_TYPE:
            return {
                items: [...state.items].map((item) => {
                    if(item.id === action.item.id){
                        item.count! -= 1;
                    }
                    return item;
                }) as any[],
            };
        case actionTypes.RESET_TYPE:
            return {
                items: [...state.items].map((item) => {
                    item.count = 0;
                    return item;
                }) as any[],
            }
    }
    return state
}

export default typePickerReducer;
