import React, { useEffect, useRef, useState } from 'react';
import './DatePicker.scss';
import moment from 'moment';
import {parse} from 'fast-time-input';

interface IDatePicker
{
    date: Date,
    onDateSelected: (date: Date) => void,
    onCancelClicked: (evt) => void,
    title: string
    showTime?: boolean
}

const DatePicker = ({title, date, onDateSelected, onCancelClicked, showTime}: IDatePicker) => {


    const inputTime = useRef<HTMLInputElement>();

    const [tempTime, setTempTime] = useState('');
    function getDateFormatted(_date: Date, _time: string)
        {
            let time = '';
            let temp = _time.split(':');
            let temp2 = temp[1].split(" ");
            temp[1] = temp2[0];
            temp[2] = temp2[1];

            if (temp[2] != 'AM' && temp[0] != '12')
                temp[0] = (Number(temp[0]) + 12).toString();
            else if (temp[2] == 'AM' && temp[0] == '12')
                temp[0] = '00';


            time = `${temp[0]}:${temp[1]}:00`;

            return new Date(moment(_date).format('YYYY/MM/DD ') + time);
        }
    const monthLabel = {
        '01': 'January',
        '02': 'February',
        '03': 'March',
        '04': 'April',
        '05': 'May',
        '06': 'June',
        '07': 'July',
        '08': 'Agoust',
        '09': 'September',
        '10': 'October',
        '11': 'November',
        '12': 'December'
    }

    const [rows, setRows] = useState([]);
    
    const [month, setMonth] = useState(moment().format('MM'));

    const [year, setYear] = useState(moment().format('YYYY'));

    const [currentDate, setCurrentDate] = useState(moment());


    function calculateMonth(_month?, _year?)
    {
        if (!_month)
            _month =  month;
        if (!_year)
            _year = year;


            
        let startWeek = moment(_year + '/' + _month + '/01 00:00:00').week();
        let endWeek = moment(_year + '/' + _month + '/01 00:00:00').add(1, 'month').add(-1, 'day').week();
        let startDate = moment(_year + '/' + _month + '/01 00:00:00').day("Sunday")
        let endDate = moment(_year + '/' + _month + '/01 00:00:00').add(1, 'month').add(-1, 'day').day("Saturday");
        

        let days = [];
        

        let i = 0;
        do
        {
            let week = [];
            for (let i = 0; i < 7; i++)
            {
                week.push({label: startDate.format('DD'), value: startDate.clone()})
                startDate.add(1, 'day');
            }
            days.push(week);
        }
        while (startDate.diff(endDate) <= 0);

        

        setRows(days);
    
    }


    function addMonth(_month)
    {
        let newDate = moment(year + '/' + month + '/01 00:00:00').add(_month, 'month');

        let y = newDate.format('YYYY');
        let m = newDate.format('MM');

        

        setYear(y);
        setMonth(m);

        calculateMonth(m, y);
    }

    useEffect(() => {
        calculateMonth();
        let current = moment(date);
        setCurrentDate(current);
        setTempTime(current.format('hh:mm A'));

        let m = current.format('MM');
        let y = current.format('YYYY');
        setMonth(m)
        setYear(y);
        calculateMonth(m, y);
    }, []);

    return (
        <div className='date-picker-component modal'>
            <div className="modal-content col-8">
                <div className='date-body'>
                     <h4 className="title-text mb-3">{title}</h4>
                    <div className='d-flex justify-content-between position-relative mb-3'>
                        {/* <div style={{width: '3.53125rem'}} /> */}
                        {/* <div className='d-flex'>
                            <button className="btn btn-clear"><i className="fas fa-chevron-left"></i></button>
                            <button className="btn btn-clear"><i className="fas fa-chevron-right"></i></button>
                        </div> */}
                        <h2 className="form-title m-0 mx-auto">{monthLabel[month]} {year}</h2>
                        <div className='d-flex position-absolute' style={{right: '0', top: '50%', transform: 'translateY(-50%)'}}>
                            <button className="btn btn-clear" onClick={(evt) => addMonth(-1)}><i className="fas fa-chevron-left"></i></button>
                            <button className="btn btn-clear" onClick={(evt) => addMonth(1)}><i className="fas fa-chevron-right"></i></button>
                        </div>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>
                                    S
                                </th>
                                <th>
                                    M
                                </th>
                                <th>
                                    T
                                </th>
                                <th>
                                    W
                                </th>
                                <th>
                                    T
                                </th>
                                <th>
                                    F
                                </th>
                                <th>
                                    S
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map(week => <tr>
                                {week.map(day => <td>
                                    <span onClick={(evt) => {
                                        if (day.value.format('MM') != month || day.value.format('YYYY') != year)
                                        {
                                            let y = day.value.format('YYYY');
                                            let m = day.value.format('MM');

                                            setYear(y)
                                            setMonth(m);
                                            calculateMonth(m, y);
                                        }
                                        setCurrentDate(day.value)
                                    }} className={(day.value.format('YYYY/MM/DD') == moment().format('YYYY/MM/DD') ? 'today' : '') + (day.value.format('YYYY/MM/DD') == currentDate.format('YYYY/MM/DD') ? ' selected' : '')  + (day.value.format('MM') != month ? ' not-current' : '')}>{day.label}</span>
                                </td>)}
                            </tr>)}
                        </tbody>
                    </table>
                    {showTime && <div className="mt-3 form-group">
                    <input className='form-field'
                type='text'
                ref={inputTime}
                onSelect={(evt) => evt.preventDefault()}
                onFocus={(evt) => {
                    evt.target.select()
                }}
                value={tempTime}
                onBlur={(evt) => setTempTime(parse(evt.target.value.trim() ? evt.target.value : '12:00am'))}
                onKeyPress={(evt) => {
                    if (evt.which == 13)
                    {

                        setTempTime(parse(tempTime));
                        setTimeout(() => {

                            inputTime.current?.select();
                        }, 100);
                    }
                }}
                onChange={(evt) => setTempTime(evt.target.value)}
                />
                    </div>}
                    <div className="row mt-3">
                        <div className='col-6 pl-0'>

                            <button className="btn py-2 col-12" onClick={onCancelClicked}>Cancel</button>
                        </div>
                        <div className='col-6 pr-0'>

                            <button className="btn btn-orange py-2 col-12" onClick={(evt) => onDateSelected(getDateFormatted(currentDate.toDate(), tempTime))}>Select this date</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default DatePicker;