import './ConfirmMessageModal.scss'
import Helper from "../../Helper/Helper";
import ICONS from '../../../assets/svg';

export const ConfirmMessageModal = ({message, title = "", onCloseModal}) => {

    const closeModal = (event, isConfirmed) => {
        event.stopPropagation();
        onCloseModal(isConfirmed);
    };

    return (
        <div className='modal-container'>
            <div className='modal-component'>
                <div className="modal-body">

                    <div className="modal-title">
                        <div className={'header'}>
                            <h3>{ title }</h3>
                            <img src={ ICONS.IconCloseBig } onClick={(event) => closeModal(event, false)} alt=""/>
                        </div>
                    </div>

                    <div className="body">
                        <p>{ message }</p>
                    </div>

                    <div className="modal-confirm-footer">
                        <button className={'btn btn-danger-outline rounded-pill w-50'}
                                onClick={(event) => closeModal(event, false)}>Cancel</button>
                        <button className={'btn btn-orange rounded-pill w-50'}
                                onClick={(event) => closeModal(event, true)}>Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
