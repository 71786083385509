import {takeLatest, call, all, put} from 'redux-saga/effects';
import orderTypes from './orders.types';
import {
    setCustomerOrders,
} from "./orders.actions";
import {
    handleFetchCustomerOrders,
} from "./orders.helper";

// Fetch customers

export function* fetchCustomerOrders({customerId}) {
    try {
        const orders = yield handleFetchCustomerOrders(customerId);
        yield put(
            setCustomerOrders(orders)
        );
    } catch (err) {
        console.log(err);
    }
}

export function* onFetchCustomerOrdersStart() {
    // @ts-ignore
    yield takeLatest(orderTypes.FETCH_ORDERS_BY_CUSTOMER_START, fetchCustomerOrders);
}


export default function* orderSagas() {
    yield all([
        call(onFetchCustomerOrdersStart),
    ])
}
