import { Menu, MenuRadioGroup, MenuItem } from '@szhsin/react-menu';
import { Socket } from 'dgram';
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import environments from '../../../../../environment/environment';
import { Order } from '../../../../classes/classes';
// import { Master_order, Service_charge, Terminal } from '../../../../classes/classes';
import LoadSpinner from '../../../../components/LoadSpinner/LoadSpinner';
import MessageBox from '../../../../components/MessageBox/MessageBox';
import { GET_RECEIPT_BY_PAYMENT_ID } from '../../../../graphql/query/Receipt';
// import InvoiceEndpoint from '../../../../graphql/endpoints/invoice.endpoint';
// import PaymentsEndpoint from '../../../../graphql/endpoints/payment.endpoint';
// import SocketEndpoint from '../../../../graphql/endpoints/socket.endpoint';
// import WashOrderEndpoint from '../../../../graphql/endpoints/washOrder.endpoint';
import Helper from '../../../../Helper/Helper';
import AuthService from '../../../../services/auth.service';
import { GraphqlService } from '../../../../services/graphql.service';
import { printReceipt, ReceiptData } from '../../../../services/printerDrawer';
import {Mutation, Query} from '../../../../services/Endpoints/enpoints';
// import {printReceipt, findStarPrinterDrawer, checkConnection, printReceiptRemotely} from '../../../../services/printerDrawer';

const SwipeCard = ({onCancelClicked, onSubmit, order}: {order: Order, onCancelClicked: (evt: any) => void, onSubmit: (evt: any) => void}) => {

    const [terminal, setTerminal] = useState({hsn: '', name: ''});
    const [terminals, setTerminals] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [reference, setReference] = useState<any>();
    const [type, setType] = useState<'alert' | 'confirmation'>('alert');
    const [cancelShown, setCancelShown] = useState(false);
    let cancelled = useRef(false);

    const getUnPaidCharges = (o = order) => {
        return [...o.tickets.flatMap(x => x.service_charges), ...o.service_charges].filter(x => x.payment_id == null)
    }

    function showMessage(title: string, message: string, reference: any){
        setTitle(title);
        setMessage(message);
        setReference(reference);
        setOpen(true);
        setType('alert');
    }

    function showConfirmationMessage(title: string, message: string, reference: any){
        setTitle(title);
        setMessage(message);
        setReference(reference);
        setType('confirmation')
        setOpen(true);
    }

    async function swipeCard(term?) {
        try {

            if (!term)
                term = {...terminal};

            setLoading(true);
            let terminals = await Query.get_terminals();
            if(!terminals || terminals.length < 1){
                throw new Error('Could not find any terminals');
            }
            let hsn = '';
            if(terminals.length > 1){
                hsn = terminals[1].hsn;
            }
            else{
                hsn = terminals[0].hsn;
            }
            let {transaction_id} = await Mutation.process_charge({order_id: order.id, hsn});

                setLoading(false);
                let payment = await GraphqlService.SendQuery(gql`query($transactionId: Int){payment_by_id(transactionId:$transactionId){id}}`,{transactionId: transaction_id});
                Helper.Receipt.ShowConfirmation(payment.id, 2);
                Helper.Navigation.NavigateTo('/order/' + order.id);

            
              
            

            
            // if (data.success)
            //     showConfirmationMessage('Message', 'Payment success, do you want to print a receipt', {data: {address: environments.BUSINESS.ADDRESS, business_name: environments.BUSINESS.NAME, card_number: data.data.token.substring(data.data.token.length - 4, data.data.token.length), card_type: data.data.binInfo.product, payment_method: data.data.bintype, cardholdername:data.data.name, phone:  environments.BUSINESS.PHONE, order_reference: 'M-' + master.id, received_by: AuthService.USER.app_user.name, time: new Date(), transaction_id: data.data.transaction_id, items: Helper.Util.ServicesChargeToItems(master.orders[0].service_charges)}, key: 'print'});
            // else
            //     showMessage('Error', data.data.error || 'Payment Declined', null);
            
        } catch (ex) {
            setLoading(false);
            showMessage('Error', ex.message || 'Payment Declined', null);
            
        }
    }


   
    async function cancelTerminal(hsn) {
        try {
            cancelled.current = false;
            
            cancelled.current = true;

            
            setCancelShown(false);
            setLoading(false);
            return true;
        } catch (ex1) {
            setCancelShown(false);
            return false;
        }
    }
    async function loadTerminals() {
        try {
            // setTerminals();
            // setLoading(true);
            // let data = await PaymentsEndpoint.getTerminals();
            // setTerminals(data);
            // setLoading(false);
            // if (data.length == 1)
            // {
            //     setTerminal(data[0])
            //     swipeCard(data[0]);
            // }
        } catch (ex) {
            console.log('ex', ex.message);
            setLoading(false);
        }
    }

    useEffect(() => {
        swipeCard();

       
    }, [])


    async function handlePrintReceipt(ref) {
        try {
            // let success = await printReceiptRemotely(ref.data, false);
            // if (!success)
            //     throw new Error('Could not connect with the printer, do you want to retry?');
            // else
            //     Helper.Navigation.NavigateTo('/');
                
        } catch (ex) {
            showConfirmationMessage('Retry Print', ex.message, ref);
        }
    }
    return (
    <div className='swipe-card-component col-12 flex-1-container'>
        {loading && <LoadSpinner />}
        {cancelShown && <button style={{position: 'fixed', left: '50%', width: '50%', transform:'translateX(-50%)', height:'4rem', zIndex: 999999, bottom: '4rem'}} className='btn' onClick={(evt) => cancelTerminal(terminal.hsn)}>CANCEL TRANSACTION</button>}
        <MessageBox isOpen={open} setIsOpen={setOpen} title={title} message={message} type={type} onCancelClicked={(evt) => Helper.Navigation.NavigateTo('/')} 
            reference={reference}
            onConfirmClicked={(ref) => {
                
                if (ref && ref.key == 'print')
                    handlePrintReceipt(ref);
                
            }}
        />
       

                <div className="flex-1-container"></div>
                <button className="btn btn-orange btn-block mb-2" onClick={(evt) => swipeCard()}>SWIPE CARD</button>
                <button className="btn btn-orange-outline btn-block" onClick={onCancelClicked}>CANCEL</button>
                
    </div>)
}

export default SwipeCard;