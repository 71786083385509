import {gql} from "apollo-boost";

export const GET_USERS = gql(`query {
  accounts {
    id
    name
    active
    employee {
      id
      name
    }
  }
}`);
