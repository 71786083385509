import gql from "graphql-tag";
import { GraphqlService, ObservableQueryT } from "../../services/graphql.service";
import { GET_SETTINGS_ERROR, GET_SETTINGS_STARTED, GET_SETTINGS_SUCCESS } from "../types/settings.type";

let settingsQuery: ObservableQueryT

export function getSettingsAction()
{
    return dispatch => {
        dispatch(getSettingsStarted());

        if (!settingsQuery)
        {
            settingsQuery = GraphqlService.SendQueryObservable(gql`query{
                get_settings{
                  key
                  value
                }
              }`);
              settingsQuery.onResults.subscribe(result => {
                  if (result.error)
                    return dispatch(getSettingsError(result.error.message));
                   dispatch(getSettingsSuccess(result.data));
              })
        }
        else
            settingsQuery.refetch();
    }        
}


const getSettingsStarted = () => ({type: GET_SETTINGS_STARTED});
const getSettingsSuccess = settings => ({type: GET_SETTINGS_SUCCESS, payload: settings});
const getSettingsError = error => ({type: GET_SETTINGS_ERROR, payload: error});