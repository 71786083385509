import { Menu, MenuRadioGroup, MenuItem } from '@szhsin/react-menu';
import React, { useEffect, useState } from 'react';
// import { Attribute, Attributes, Master_order } from '../../../classes/classes';
// import AttributeEndpoint from '../../../graphql/endpoints/attribute.endpint';
import Helper from '../../../Helper/Helper';
import CardInput from './CardInput/CardInput';
import SwipeCard from './SwipeCard/SwipeCard';

const CardForm = ({onCancelClicked, master}: {master: any, onCancelClicked: (evt: any) => void}) => {
    
    
    const [type, setType] = useState<'swipe' | '' | 'manual'>('');
    

    useEffect(() => {
        // loadCard();
    }, []);
    return (
    <div className='card-form-component flex-1-container'>
        <div className='col-12 d-flex justify-content-between flex-wrap flex-1-container'>


            
            {type == '' && <>
           
            <div className='flex-1-container'>
                
            </div>
            <button onClick={(evt) => setType('manual')} className="btn btn-orange-light btn-block mt-4">INPUT MANUALLY</button>
            <button onClick={(evt) => setType('swipe')} className="btn btn-orange btn-block mt-2">SWIPE CARD</button>
            <button className="btn btn-orange-outline btn-block mt-2" type='button' onClick={onCancelClicked}>CANCEL</button></>}

            {type == 'manual' && <CardInput order={master} onCancelClicked={(evt) => setType('')} onPaymentSubmitted={(evt) => false} />}

            {type == 'swipe' && <SwipeCard order={master} onCancelClicked={(evt) => setType('')} onSubmit={(evt) => false} />}
        </div>
    </div>)
}

export default CardForm;