import './MessageModal.scss'
import Helper from "../../Helper/Helper";
import ICONS from '../../../assets/svg';

export const MessageModal = ({message, title = "", btnText = 'OK', onCloseModal}) => {

    const closeModal = (event) => {
        event.stopPropagation();
        onCloseModal();
    };

    return (
        <div className='modal-container'>
            <div className='modal-component'>
                <div className="modal-body">

                    <div className="modal-title">
                        <div className={'header'}>
                            <h3>{ title }</h3>
                            <img src={ ICONS.IconCloseBig } onClick={closeModal} alt=""/>
                        </div>
                    </div>

                    <div className="body">
                        <p>{ message }</p>
                    </div>

                    <div className="modal-footer">
                        <button className={'btn btn-orange rounded-pill w-50'} onClick={closeModal}>{ btnText }</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
