import React, { useEffect, useState } from 'react';
import { Service_charge, Ticket } from '../../../classes/classes';
import ServiceForm from '../../../components/Forms/ServiceForm/ServiceForm';
import Loading from '../../../components/Loading/Loading';
import Status from '../../../components/Status/Status';
import ServiceChargeEnpoint from '../../../endpoints/ServiceCharge.endpoint';
import Helper from '../../../Helper/Helper';
import TicketPickerModal from '../TicketPickerModal/TicketPickerModal';
import './ServicesSelection.scss';

interface IServicesSelection
{
    ticket: Ticket,
    onToggleServices: (service_charges: Service_charge[]) => any,
    serviceCharges: Service_charge[],
    loading: boolean,
    onAddChargeClicked: (evt: any) => void
}

const ServicesSelection = ({ticket, onToggleServices, serviceCharges, loading, onAddChargeClicked}: IServicesSelection) => {

    const [myName, setMyName] = useState('');
    const [addServiceShown, setAddServiceShown] = useState(false);

    const isNegative = () => ticket.service_charges.sumBy(x => x.amount) < 0;
    // const [nService, setNService] = useState<{name: string, amount: number}>({amount: '' as any, name: ''});

    

    function priceChanged(price, pos: number)
    {
        try {

            let temp = [...ticket.service_charges];

            temp[pos].base_amount = price;

            let p = isNaN(+price) ? 0 : +price;
            let amount = +((p + temp[pos].tax_amount) * temp[pos].count).toFixed(2);
            
            temp[pos].amount = amount;

            

            onToggleServices(temp.filter(x => x.count > 0));
            
        } catch (ex) {
            
        }
    }

    async function addCount(add: number, pos: number) {
        try {

            let temp = [...ticket.service_charges];

            
            temp[pos].count = temp[pos].count + add;
            temp[pos].amount = +((temp[pos].base_amount + temp[pos].tax_amount) * temp[pos].count).toFixed(2);



            onToggleServices(temp.filter(x => x.count > 0));
            
        } catch (ex) {
            
        }
    }
    

    function onNewServiceSubmitted(nService) {

        let temp = [...ticket.service_charges];
        let sc = new Service_charge();
        sc.name = nService.name;
        sc.amount = +nService.amount;
        sc.count = 1;
        sc.tax_amount = +nService.tax_amount;
        sc.base_amount = +nService.base_amount
        

        temp.push(sc)

        onToggleServices(temp);
        // setNService(new Service_charge());
        setAddServiceShown(false);

    }

    function handleAddServiceCharge(evt) {
        onAddChargeClicked(evt);
    }

    useEffect(() => {

    }, [ticket]);
    return (
        

        <div className='services-selection-component mt-3'>
            
            <h4 className="regular-text mb-3">Services</h4>
            <div className="row g-0">
                <div className="col-12">
                    {ticket.service_charges.map((sc, i) => <div className='d-flex justify-content-between align-items-center mb-3'>
                        <h4 className="title-text d-flex align-items-center">{sc.name}</h4>
                        <div className='form-group d-flex align-items-center'>
                            <input readOnly={sc.payment_id != null} style={{width: '96px'}} onChange={(evt) => sc.payment_id != null ? null : priceChanged(Helper.Masks.DecimalNumbers(evt.target.value, true), i)} value={sc.base_amount} type="text" className="form-field dollar-sign text-right"/>
                            <span className="btn btn-clear text-orange ml-1">X</span>
                            <h4 className="title-text d-flex align-items-center ml-1">
                                {!sc.payment_id && <button onClick={(evt) => addCount(-1, i)} className='btn btn-clear br-0'><i className="fas fa-minus"></i></button>} <span className='d-inline-block mx-2'>{sc.count}</span> {!sc.payment_id && <button onClick={(evt) => addCount(1, i)} className='btn btn-clear br-0 mr-2'><i className="fas fa-plus"></i></button>} 
                            </h4>

                            <h4 style={{minWidth: '60px'}} className="title-text ml-1 text-right ">{Helper.FORMAT.USCurrency(sc.base_amount * sc.count)} {sc.payment_id && <Status statusName='PAID' className='ml-2' />}</h4>
                        </div>
                    </div>)}
                    
                    <div className="row-outlined justify-content-start flex-wrap mt-2 no-action" onClick={(evt) => {
                        // setAddServiceShown(true);
                        handleAddServiceCharge(evt);
                    }}>
                        <div className='d-flex px-0'><i className="fas fa-plus mr-2 text-gray px-0"></i><h4 className="title-text text-gray">Add charge</h4></div>
                        {/* {addServiceShown && 
                        <ServiceForm onCancelClicked={(evt) => setAddServiceShown(false)} onNewServiceSubmitted={(evt) => onNewServiceSubmitted(evt)} />} */}
                    </div>
                    
                    
                    {ticket.service_charges.length > 0 && 
                        <>
                            <hr className='my-3'/> 
                            <div className={"d-flex justify-content-end " + (isNegative() ? 'text-red' : '')}>
                                <h4 className="title-text fw-400 mr-3">Subtotal</h4>
                                <h4 className="title-text">{Helper.FORMAT.USCurrency(ticket.service_charges.sumBy(x => x.base_amount * x.count))}</h4>
                            </div>

                            <div className={"d-flex justify-content-end mt-2 " + (isNegative() ? 'text-red' : '')}>
                                <h4 className="title-text fw-400 mr-3">Tax total</h4>
                                <h4 className="title-text">{Helper.FORMAT.USCurrency(ticket.service_charges.sumBy(x => x.tax_amount * x.count))}</h4>
                            </div>

                            <div className={"d-flex justify-content-end mt-2 " + (isNegative() ? 'text-red' : '')}>
                                <h4 className="title-text fw-400 mr-3">Total</h4>
                                <h4 className={"title-text"}>{Helper.FORMAT.USCurrency(ticket.service_charges.sumBy(x => x.amount))}</h4>
                            </div>
                        </>}

                        {(isNegative() || ticket.service_charges.length == 0) && <div className='error-banner mt-3 text-center'>
                            {isNegative() ? `Ticket can't be negative, please adjust the prices above.` : `Ticket needs to have at least one service, please add some services above.`}
                        </div>}
                    
                </div>
             
            </div>
        </div>)
}

export default ServicesSelection;