import gql from "graphql-tag";

export const GET_RECEIPT_BY_PAYMENT_ID = gql`
    query($paymentid: Int) {
    receiptData(paymentid: $paymentid) {
        items{
      name
      base_amount
      tax_amount
      count
      amount
      id
      ticket_id
    }
    header{
      name
      street
      city
      city_state
      contact
    }
    received_by
    time
    link
    note
    order_reference
    order_received
      footer{
      name
      payment_id
      transaction_id
      type
      lastfour
      phone
    }
    }
}
`