import {GraphqlService, ObservableQueryT} from "../../services/graphql.service";
import {GET_ORDERS_BY_CUSTOMER_ID} from "../../graphql/query/Order";

let customersQuery: ObservableQueryT;

export const handleFetchCustomerOrders = (customerId) => {
    return new Promise(async (resolve, reject) => {
        customersQuery = GraphqlService.SendQueryObservable(GET_ORDERS_BY_CUSTOMER_ID, {customerId: +customerId});
        customersQuery.onResults.subscribe(result => {
            if (result.error)
                reject(result.error.message);
            else
                resolve(result.data);
        });

    });
};
