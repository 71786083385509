const IFRAME_STYLES = `%23ccnumfield%7Bwidth%3Acalc%28100%25-2.5rem%29%3Bpadding%3A.65rem%201.2rem%3Bborder%3A1px%20solid%20%23e5e5e5%3Boutline%3A0%3Btransition%3A.3s%20all%20ease%3Bfont-size%3Acalc%281rem%20-%204px%29%3Bborder-top-left-radius%3A8px%3Bborder-top-right-radius%3A8px%7Dbody%7Bmargin%3A0%3Bpadding%3A0%7D%23tokenform%7Boverflow%3Ahidden%7Dform%7Boverflow%3Ahidden%7D%23ccnumfield.error%7Bcolor%3Ared%21important%3Bborder-color%3Ared%21important%7D%23ccnumfield%3A%3Aplaceholder%7Bfont-size%3A16px%3Bcolor%3A%23ccc%7D%23ccnumfield%3Afocus%7Bborder-color%3A%23000%7D`;
const IFRAME_OPTIONS = '?invalidinputevent=true&invalidcreditcardevent=true&formatinput=true&placeholder=0000%200000%200000%200000&css=' + IFRAME_STYLES;

const distribution = true;

const environments = {
    IFRAME_LINK: 'https://boltgw.cardconnect.com/itoke/ajax-tokenizer.html' + IFRAME_OPTIONS,
    IFRAME_OPTIONS: '?invalidinputevent=true&invalidcreditcardevent=true&formatinput=true&placeholder=XXXX%20XXXX%20XXXX%20XXXX&css=%23ccnumfield%7Bborder%3A%20none%3Bborder-radius%3A%200%3Bborder-bottom%3A%201px%20solid%20%23CCC%3Bfont-size%3A%2016px%3Bfont-weight%3A%20bold%3Bdisplay%3A%20block%3Bwidth%3A%20calc(100%25%20-%201.5rem)%3Bpadding%3A%200.45rem%200.75rem%200.45rem%200.75rem%3Bline-height%3A%201.5%3Bcolor%3A%20%23495057%3Bbackground-color%3A%20%23fff%3Bbackground-clip%3A%20padding-box%3Boutline%3A%20none%20!important%3B%7Dbody%7Bmargin%3A0%3B%7D%23tokenform%7Boverflow%3Ahidden%3B%7Dform%7Boverflow%3Ahidden%3B%7D%23ccnumfield.error%7Bcolor%3Ared%20!important%3Bborder-color%3Ared%20!important%3B%7D%23ccnumfield%3A%3Aplaceholder%7Bfont-size%3A14px%3Bcolor%3A%23CCCCCC%7D%23ccnumfield%3Afocus%7Bborder-color%3A%23000%3B%7D',
    //SERVER_LINK: 'http://38.75.18.145:40000'//'http://192.168.10.9:4000'
    SERVER_LINK: distribution ? 'https://api.profuseclean.com' : 'http://38.75.18.145:40000'
}

export default environments;