import React, { useRef } from 'react';
import ICONS from '../../../assets/svg';
import Helper from '../../Helper/Helper';

import './Searchbar.scss';

const Searchbar = ({placeholder = 'Search...', onFocus, onBlur, onChange, value, reference, background, onSubmit }: {onSubmit?: (HTMLFormElement) => void,background?: string, placeholder?: string, onFocus?: any, onBlur?: any, onChange?: any, value?: string, reference: any}) => {

    return (
        <form onSubmit={onSubmit} style={{position: 'relative'}} className='searchbar-component'>
            <img onClick={(evt) => reference.current.focus()} style={{position:'absolute', top: '50%', left: '.15rem', transform: 'translateY(-50%)', cursor: 'pointer'}} src={ICONS.IconSearch} alt=""/>
            <input style={{background: background ? background : ''}} onFocus={onFocus} onBlur={onBlur}  className='search-input' ref={reference} value={value} onChange={onChange} type="text" placeholder={placeholder} />
            <img onClick={(evt) => onChange({target: {value: ''}}) } style={{position:'absolute', top: '50%', right: '0.45rem', transform: 'translateY(-50%)', cursor: 'pointer'}} src={ICONS.IconDelete} alt=""/>
        </form>
    )
}
export default Searchbar;