import {GraphqlService, ObservableQueryT} from "../../services/graphql.service";
import {ALL_PAYMENTS, CUSTOMER_PAYMENTS} from "../../graphql/query/Payments";

let paymentsQuery: ObservableQueryT;
let customerPaymentsQuery: ObservableQueryT;

export const handleFetchPayments = () => {
    return new Promise((resolve, reject) => {
        if (!paymentsQuery) {
            paymentsQuery = GraphqlService.SendQueryObservable(ALL_PAYMENTS);
            paymentsQuery.onResults.subscribe(result => {
                

                if (result.error)
                    reject(result.error.message);
                else
                    resolve(result.data);
            });
        } else {
            paymentsQuery.refetch();
            paymentsQuery.onResults.subscribe(result => {
                if (result.error)
                    reject(result.error.message);
                else
                    resolve(result.data);
            });
        }
    });
}


export const handleFetchPaymentsByCustomer = (customerId) => {
    return new Promise((resolve, reject) => {
        if (!customerPaymentsQuery) {
            customerPaymentsQuery = GraphqlService.SendQueryObservable(CUSTOMER_PAYMENTS, {customerId});
            customerPaymentsQuery.onResults.subscribe(result => {
                

                if (result.error)
                    reject(result.error.message);
                else
                    resolve(result.data);
            });
        } else {
            customerPaymentsQuery.refetch();
            customerPaymentsQuery.onResults.subscribe(result => {
                if (result.error)
                    reject(result.error.message);
                else
                    resolve(result.data);
            });
        }
    });
}
