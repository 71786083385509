import customerTypes from './orders.types';

export const setCustomerOrders: any = customerOrders => ({
    type: customerTypes.SET_ORDERS_BY_CUSTOMER,
    payload: customerOrders
});

export const fetchCustomerOrders: any = (customerId) => ({
    type: customerTypes.FETCH_ORDERS_BY_CUSTOMER_START,
    customerId,
});
