import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { GraphqlService } from '../../../services/graphql.service';
import Loading from '../../Loading/Loading';


const ADD_SERVICE_GROUP = gql`
    mutation ($group: add_service_group){
        create_service_group(group:$group){
            id
            name
            icon
        }
    }
`;

const UPDATE_SERVICE_GROUP = gql`
    mutation ($group: add_service_group, $group_id:Int ){
        update_service_group(group_id:$group_id group:$group){
            id
            name
            icon
        }
    }
`;

const ServiceGroupForm = ({serviceGroup, onCancelClicked, onSubmit}) => {

    

    const [sgroup, setSGroup] = useState({id: null, name: ''});
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    async function handleSubmit() {
        try {
            let id = +sgroup.id;
            
            if (sgroup.id == -1)
            {
                id = (await GraphqlService.SendMutation(ADD_SERVICE_GROUP, {group: {name: sgroup.name, icon: ''}})).id;
            }
            else
            {
                await GraphqlService.SendMutation(UPDATE_SERVICE_GROUP, {group: { name: sgroup.name, icon: ''}, group_id: id})
            }
            setLoading(false);
            onSubmit({...sgroup, id});
        } catch (ex) {
            setLoading(false);
            setError(ex.message?.toLowerCase().includes('validation error') ? 'Group service already exists, you cannot have multiple group services with the same name.' : ex.message);
        }
    }

    useEffect(() => {
        setSGroup({id: serviceGroup.id, name: serviceGroup.name});
        setError('');
        setLoading(false);
    }, [serviceGroup]);

    return (<form onSubmit={(evt) => {evt.preventDefault();
        evt.preventDefault();
        setError('');
        setLoading(true);
        handleSubmit();
        
    }} className="d-flex flex-wrap mt-3">
    <div className="form-group col-6 pr-2 mb-3">
        <label htmlFor="cname">Service group name*</label>
        <input id='cname' name='cname' value={sgroup.name} onChange={(evt) => setSGroup({...sgroup, name: evt.target.value})} placeholder='Service group name' type="text" className="form-field"/>
    </div>
    
    {error && <div className="error-banner w-100">
        {error}
    </div>}

    {loading ? <div className="w-100"><Loading /></div> : <div className="d-flex col-12 px-0 justify-content-between">
        <button className="btn btn-danger px-3 btn-block mt-3 mr-2" onClick={(evt) => onCancelClicked(evt)}>Cancel</button>
        <button className="btn btn-orange px-3 btn-block mt-3 ml-2" disabled={sgroup.name.trim() == ''}>{sgroup.id == -1 ? 'Add' : 'Update'} group</button>
    </div>}

</form>)
}

export default ServiceGroupForm;