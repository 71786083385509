import IColumn from "../../../components/Table/models/IColumn";
import Table from "../../../components/Table/Table";
import Helper from "../../../Helper/Helper";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchCustomerOrders} from "../../../saga/Orders/orders.actions";

const mapState = ({ordersData}) => ({
    orders: ordersData.customerOrders.map(order => {
        return {
            ...order,
            pickup_date: [Helper.FORMAT.USDate(order.pickup_date, 'do'), Helper.FORMAT.USDate(order.pickup_date, 'dd')],
            tickets: ['', ...order.tickets],
            order_number: order.order_number,
            customer: [order.customer_name, order.customer_phone],
            charge: Helper.FORMAT.USCurrency(order.charge),
            balance: Helper.FORMAT.USCurrency(order.balance),
            ordered_on: Helper.FORMAT.USDate(order.ordered_on, 'do'),
            payments: order.payments
        };
    })
});


const CustomerDetailOrders = (props) => {
    const columns: IColumn[] = [
        {orderBy: 'ASC', active: false, label: 'PICKUP DATE', name: 'pickup_date'},
        {orderBy: 'ASC', active: false, label: 'TICKETS', name: 'tickets'},
        {orderBy: 'ASC', active: false, label: 'ORDER #', name: 'order_number'},
        {orderBy: 'ASC', active: false, label: 'CUSTOMER', name: 'customer'},
        {orderBy: 'ASC', active: false, label: 'CHARGE', name: 'charge'},
        {orderBy: 'ASC', active: false, label: 'BALANCE', name: 'balance'},
        {orderBy: 'ASC', active: false, label: 'ORDERED ON', name: 'ordered_on'},
        {orderBy: 'ASC', active: false, label: 'PAYMENT #', name: 'payments'}
    ];
    const dispatch = useDispatch();
    const customerId = props.customerId;

    const { orders } = useSelector(mapState);

    useEffect(() => {
        getCustomerOrders().then();
    }, [props.customerId]);


    async function getCustomerOrders() {
        return dispatch(fetchCustomerOrders(customerId));
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <Table columns={columns}
                           rows={orders}
                           onRowClicked={() => null}
                           
                           tableHeight={'59vh'}/>
                </div>
            </div>
        </>
    );
}

export default CustomerDetailOrders;
