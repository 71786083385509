import { Menu, MenuRadioGroup, MenuItem } from '@szhsin/react-menu';
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from 'react';
import environments from '../../../../../environment/environment';
import { Order } from '../../../../classes/classes';
// import { Attribute, Attributes, Master_order, Payinfo, Service_charge, Wash_order } from '../../../../classes/classes';
import LoadSpinner from '../../../../components/LoadSpinner/LoadSpinner';
import MessageBox from '../../../../components/MessageBox/MessageBox';
// import TipsPicker from '../../../../components/TipsPicker/TipsPicker';
// import AttributeEndpoint from '../../../../graphql/endpoints/attribute.endpint';
// import InvoiceEndpoint from '../../../../graphql/endpoints/invoice.endpoint';
// import PaymentsEndpoint from '../../../../graphql/endpoints/payment.endpoint';
// import SocketEndpoint from '../../../../graphql/endpoints/socket.endpoint';
// import WashOrderEndpoint from '../../../../graphql/endpoints/washOrder.endpoint';
import Helper from '../../../../Helper/Helper';
import AuthService from '../../../../services/auth.service';
import { Mutation } from '../../../../services/Endpoints/enpoints';
import { GraphqlService } from '../../../../services/graphql.service';
// import  {printReceipt, findStarPrinterDrawer, printReceiptRemotely } from '../../../../services/printerDrawer';
import IPayInfo from './models/IPayInfo';




const CardInput = ({onPaymentSubmitted, onCancelClicked, order}: {order: Order, onPaymentSubmitted: (payInfo: IPayInfo) => void, onCancelClicked?: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void}) => {


  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [reference, setReference] = useState<any>();
  const [type, setType] = useState<'alert' | 'confirmation'>('alert');
  const currentData = useRef({
    cardholder_name: '',
    city: '',
    cvv: '',
    exp: '',
    number: '',
    state: '',
    street: '',
    zip: ''
});
  const [loading, setLoading] = useState(false);
  const [tipOpen, setTipOpen] = useState(false);

  function getUnPaidCharges(){
    return [...order.tickets.flatMap(x => x.service_charges), ...order.service_charges].filter(x => x.payment_id == null)
}


  async function handleSubmit(info: IPayInfo) {
    try {
      setLoading(true);

      let res = await Mutation.manual_input({
        customer_id: order.customer.id, 
        order_id: order.id, 
        order_reference: 'O-' + order.id, 
        payinfo: payInfo, 
        charges: getUnPaidCharges()
      });

      if (!res || !res.id)
        throw new Error('Could not communicate with the server');

      setLoading(false);
      Helper.Receipt.ShowConfirmation(res.id, 2);
      Helper.Navigation.NavigateTo('/order/' + order.id);
     
    } catch (ex) {
      setLoading(false);
      showMessage('Error', ex.message || 'Payment Declined', null);
    }
  }

  function showMessage(title: string, message: string, reference: any){
    setTitle(title);
    setMessage(message);
    setReference(reference);
    setOpen(true);
    setType('alert');
}


  function showConfirmationMessage(title: string, message: string, reference: any){
    setTitle(title);
    setMessage(message);
    setReference(reference);
    setType('confirmation')
    setOpen(true);
}

  async function loadCard() {
      // try {
      //   setLoading(true);
      //     let data =await AttributeEndpoint.getAttributes(master.address.customerInfo.id) ;
      //     setCards(data)
      //     setLoading(false);
      // } catch (ex) {
      //   setLoading(false);
      // }
  }
    const states = [
        {
          "name": "Alabama",
          "Abbrev": "Ala.",
          "code": "AL"
        },
        {
          "name": "Alaska",
          "Abbrev": "Alaska",
          "code": "AK"
        },
        {
          "name": "Arizona",
          "Abbrev": "Ariz.",
          "code": "AZ"
        },
        {
          "name": "Arkansas",
          "Abbrev": "Ark.",
          "code": "AR"
        },
        {
          "name": "California",
          "Abbrev": "Calif.",
          "code": "CA"
        },
        {
          "name": "Colorado",
          "Abbrev": "Colo.",
          "code": "CO"
        },
        {
          "name": "Connecticut",
          "Abbrev": "Conn.",
          "code": "CT"
        },
        {
          "name": "Delaware",
          "Abbrev": "Del.",
          "code": "DE"
        },
        {
          "name": "District of Columbia",
          "Abbrev": "D.C.",
          "code": "DC"
        },
        {
          "name": "Florida",
          "Abbrev": "Fla.",
          "code": "FL"
        },
        {
          "name": "Georgia",
          "Abbrev": "Ga.",
          "code": "GA"
        },
        {
          "name": "Hawaii",
          "Abbrev": "Hawaii",
          "code": "HI"
        },
        {
          "name": "Idaho",
          "Abbrev": "Idaho",
          "code": "ID"
        },
        {
          "name": "Illinois",
          "Abbrev": "Ill.",
          "code": "IL"
        },
        {
          "name": "Indiana",
          "Abbrev": "Ind.",
          "code": "IN"
        },
        {
          "name": "Iowa",
          "Abbrev": "Iowa",
          "code": "IA"
        },
        {
          "name": "Kansas",
          "Abbrev": "Kans.",
          "code": "KS"
        },
        {
          "name": "Kentucky",
          "Abbrev": "Ky.",
          "code": "KY"
        },
        {
          "name": "Louisiana",
          "Abbrev": "La.",
          "code": "LA"
        },
        {
          "name": "Maine",
          "Abbrev": "Maine",
          "code": "ME"
        },
        {
          "name": "Maryland",
          "Abbrev": "Md.",
          "code": "MD"
        },
        {
          "name": "Massachusetts",
          "Abbrev": "Mass.",
          "code": "MA"
        },
        {
          "name": "Michigan",
          "Abbrev": "Mich.",
          "code": "MI"
        },
        {
          "name": "Minnesota",
          "Abbrev": "Minn.",
          "code": "MN"
        },
        {
          "name": "Mississippi",
          "Abbrev": "Miss.",
          "code": "MS"
        },
        {
          "name": "Missouri",
          "Abbrev": "Mo.",
          "code": "MO"
        },
        {
          "name": "Montana",
          "Abbrev": "Mont.",
          "code": "MT"
        },
        {
          "name": "Nebraska",
          "Abbrev": "Nebr.",
          "code": "NE"
        },
        {
          "name": "Nevada",
          "Abbrev": "Nev.",
          "code": "NV"
        },
        {
          "name": "New Hampshire",
          "Abbrev": "N.H.",
          "code": "NH"
        },
        {
          "name": "New Jersey",
          "Abbrev": "N.J.",
          "code": "NJ"
        },
        {
          "name": "New Mexico",
          "Abbrev": "N.M.",
          "code": "NM"
        },
        {
          "name": "New York",
          "Abbrev": "N.Y.",
          "code": "NY"
        },
        {
          "name": "North Carolina",
          "Abbrev": "N.C.",
          "code": "NC"
        },
        {
          "name": "North Dakota",
          "Abbrev": "N.D.",
          "code": "ND"
        },
        {
          "name": "Ohio",
          "Abbrev": "Ohio",
          "code": "OH"
        },
        {
          "name": "Oklahoma",
          "Abbrev": "Okla.",
          "code": "OK"
        },
        {
          "name": "Oregon",
          "Abbrev": "Ore.",
          "code": "OR"
        },
        {
          "name": "Pennsylvania",
          "Abbrev": "Pa.",
          "code": "PA"
        },
        {
          "name": "Rhode Island",
          "Abbrev": "R.I.",
          "code": "RI"
        },
        {
          "name": "South Carolina",
          "Abbrev": "S.C.",
          "code": "SC"
        },
        {
          "name": "South Dakota",
          "Abbrev": "S.D.",
          "code": "SD"
        },
        {
          "name": "Tennessee",
          "Abbrev": "Tenn.",
          "code": "TN"
        },
        {
          "name": "Texas",
          "Abbrev": "Tex.",
          "code": "TX"
        },
        {
          "name": "Utah",
          "Abbrev": "Utah",
          "code": "UT"
        },
        {
          "name": "Vermont",
          "Abbrev": "Vt.",
          "code": "VT"
        },
        {
          "name": "Virginia",
          "Abbrev": "Va.",
          "code": "VA"
        },
        {
          "name": "Washington",
          "Abbrev": "Wash.",
          "code": "WA"
        },
        {
          "name": "West Virginia",
          "Abbrev": "W.Va.",
          "code": "WV"
        },
        {
          "name": "Wisconsin",
          "Abbrev": "Wis.",
          "code": "WI"
        },
        {
          "name": "Wyoming",
          "Abbrev": "Wyo.",
          "code": "WY"
        }
      ]
      


    const [payInfo, setPayInfo] = useState<IPayInfo>
    ({
        cardholder_name: '',
        city: '',
        cvv: '',
        exp: '',
        number: '',
        state: '',
        street: '',
        zip: ''
    })

    const isValid = () =>  payInfo.cardholder_name.trim() != '' && payInfo.cvv.trim().length >= 3 && payInfo.exp.trim().length == 5 && payInfo.number.trim() != '' && 
         (payInfo.zip.length == 0? true : payInfo.zip.length == 5);

    const formatExp = (value: string) => {
        const hasSlash = value.includes('/');
        value = value.trim().replace('/', '');
        let exp = '';
        
        for (let i = 0; i < value.length; i++)
        {

            
            const char = value.substring(i, i + 1);

            if (exp.length == 5)
                break;

            if (exp.length == 2)
            {
                exp += '/';
                if (char == '/')
                    continue;
            }
            
            
            if (char >='0' && char <='9')
                exp += char;
            
        }

        let month = exp.split('/')[0] || '';
        let year = exp.split('/')[1] || '';

        if (month.length == 1)
        {
            if (Number(month) > 1)
                month = '';
        }
        else if (month.length == 2)
        {
            if (Number(month) == 0)
                month = '0';
            if (Number(month) > 12)
                month = '0' + month.substring(1, 2);
        }




        return month + (hasSlash || year.length >= 1 ? '/' : '') + year;
    }


    const handleOnValueChanged = (value: string, key: string) => {

        let temp: any = {...currentData.current};

        if (key == 'exp')
        {
            value = formatExp(value);
        }
        temp[key] = value;
        
        currentData.current = temp;
        setPayInfo(temp);

    }


    const messageCallback = (evt: MessageEvent<any>) => {
      
      // console.log(evt.data);/
      if (evt.data.message)
        return;
        let data = evt.data && JSON.parse(evt.data);

        // const temp = {...payInfo};

        // temp.number = data.message;
        // console.log('payinfo', data);
        // setPayInfo({...payInfo, number: data.message});
        
        handleOnValueChanged(data.message, 'number');
    }

    const firsUpdate = useRef(true);

    useEffect(() => {
      if (firsUpdate.current)
      {
        
        window.addEventListener('message', messageCallback);
        loadCard();
        firsUpdate.current = false;
      }

        return () => {
          
            window.removeEventListener('message', messageCallback);
          }

          
    }, [])

    async function handlePrintReceipt(ref) {
      try {
          // let success = await printReceiptRemotely(ref.data, false);
          // if (!success)
          //     throw new Error('Could not connect with the printer, do you want to retry?');
          // else
          //     Helper.Navigation.NavigateTo('/');
              
      } catch (ex) {
          showConfirmationMessage('Retry Print', ex.message, ref);
      }
  }
  
    
    return (
        <div className='d-flex flex-wrap justify-content-between flex-1-container'>
          {loading && <LoadSpinner />}
          <MessageBox isOpen={open} setIsOpen={setOpen} title={title} message={message} type={type}
            onConfirmClicked={(ref) => {
              if (ref && ref.key == 'print')
                    handlePrintReceipt(ref);
            }}
          onCancelClicked={(evt) => Helper.Navigation.NavigateTo('/')} />
     
            <div className='flex-1-container'>
              <div>
              <h4 className='col-12 mb-3 subtitle-text text-title'>BILLING INFORMATION</h4>
                <div className='px-2 col-12 d-flex justify-content-between flex-wrap mb-3'>
                
                <div className='form-group col-12 mb-3'>
                    <label className='form-label' htmlFor="cardholder">CARDHOLDER NAME*</label>
                    <input value={payInfo.cardholder_name} onChange={(evt) => handleOnValueChanged(evt.target.value, 'cardholder_name')} id='cardholder' type="text" className="form-field" placeholder='John Doe' />
                </div>
                <div className='form-group col-12 mb-3'>
                    
                    <label className='form-label' htmlFor="ccnumber">NUMBER*</label>
                    <iframe id="tokenFrame"
                        style={{height: '40px', width: '100%', overflow: 'hidden'}}
                        name="tokenFrame"
                        // https://boltgw-uat.cardconnect.com/itoke/ajax-tokenizer.html
                        src={environments.IFRAME_LINK + environments.IFRAME_OPTIONS} frameBorder="0" scrolling="yes"></iframe>
                </div>
                <div className='form-group col-6 mb-3 pr-2'>
                    <label className='form-label' htmlFor="exp">EXP*</label>
                    <input value={payInfo.exp} onChange={(evt) => handleOnValueChanged(evt.target.value, 'exp')} id='exp' type="text" className="form-field" placeholder='XX/XX' />
                </div>
                <div className='form-group col-6 mb-3 pl-2'>
                    <label className='form-label' htmlFor="cvv">CVV/CVV*</label>
                    <input value={payInfo.cvv} onChange={(evt) => handleOnValueChanged(Helper.Masks.IntNumbers(evt.target.value, 4), 'cvv')} id='cvv' type="text" className="form-field" placeholder='XXXX' />
                </div>
            </div>
        <h4 className='col-12 mb-3 subtitle-text text-title'>BILLING ADDRESS INFORMATION (OPTIONAL)</h4>
            <div className='px-2 col-12 d-flex justify-content-between flex-wrap mb-3'>
                <div className='form-group col-12 mb-3'>
                        <label className='form-label' htmlFor="street">STREET</label>
                        <input value={payInfo.street} onChange={(evt) => handleOnValueChanged(evt.target.value, 'street')} id='street' type="text" className="form-field" placeholder='123 Main ST' />
                    </div>
                

                <div className='form-group col-12 mb-3'>
                    <label className='form-label' htmlFor="city">CITY</label>
                    <input value={payInfo.city} onChange={(evt) => handleOnValueChanged(evt.target.value, 'city')} id='city' type="text" className="form-field" placeholder='XXXXX' />
                </div>
                <div className='form-group col-6 pr-2'>
                    <label className='form-label' htmlFor="state">STATE</label>
                    <select id='state' className="form-field" value={payInfo.state} onChange={(evt) => handleOnValueChanged(evt.target.value, 'state')}>
                        <option value="">--SELECT--</option>
                        {states.map((state: any, i: number) => <option value={state.code}>{state.code}</option>)}
                    </select>
                </div>
                <div className='form-group col-6 pl-2'>
                    <label className='form-label' htmlFor="zip">ZIP (RECOMMENDED)</label>
                    <input value={payInfo.zip} onChange={(evt) => handleOnValueChanged(Helper.Masks.IntNumbers(evt.target.value, 5), 'zip')} id='zip' name='zip' type="text" className="form-field" placeholder='XXXXX' />
                </div>

            </div>
              </div>
            </div>

            <div className='d-flex justify-content-between col-12 mt-3'>
                {onCancelClicked && <div className='col-6 pr-2'><button type='button' className="btn btn-large col-12"  onClick={(evt) =>onCancelClicked(evt)}>CANCEL</button></div>}
                {!onCancelClicked && <div className='col-6' />}
                <div className="col-6 pl-2">
                <button type='button' className="btn btn-orange btn-large col-12" disabled={!isValid()} onClick={(evt) => {
                  if (isValid())
                    handleSubmit(payInfo);
                }}>SUBMIT PAYMENT</button>
                </div>
            </div>
        </div>
    )
}

export default CardInput;