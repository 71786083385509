import {gql} from "apollo-boost";

export const ADD_USER = gql(`
mutation($name: String $password: String) {
  create_user(name:$name password:$password) {
    id
    name
  }
}`);

export const DELETE_USER = gql(`
mutation($userId:Int!) {
  delete_user(userId: $userId)
}`);

export const CHANGE_PASSWORD_USER = gql(`
mutation($name: String! $old_password: String! $new_password: String!) {
  change_password(name: $name old_password:$old_password new_password:$new_password)
}`);
