import customerTypes from './customer.types';

const INITIAL_STATE = {
    customers: [],
    isLoading: false,
    customerAdded: null
};

const customerReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case customerTypes.FETCH_CUSTOMER_TABLE_START:
            return {
                ...state,
                isLoading: true
            }
        case customerTypes.SET_CUSTOMERS:
            return {
                ...state,
                isLoading: false,
                customers: action.payload,
            };
        case customerTypes.SET_CUSTOMERS_TABLE:
            return {
                ...state,
                isLoading: false,
                customers: action.payload,
            };
        case customerTypes.GET_CUSTOMER_BY_ID:
            return {
                ...state,
                isLoading: true,
                customer: action.customer
            }
        case customerTypes.ADD_CUSTOMER_START:
            return {
                ...state,
                isLoading: true,
            };
        case customerTypes.ADD_CUSTOMER_SUCCESS:
        case customerTypes.UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                customerAdded: action.payload
            }
        case customerTypes.RESET_NEW_CUSTOMER:
            return {
                ...state,
                customerAdded: null
            }
        default:
            return state;
    }
};

export default customerReducer;
