import Aging from "../Aging/Aging";
import {useEffect, useState} from "react";
import { getAgingTicketsAction } from "../../../redux/actions/ticketAging.action";
import { useDispatch, useSelector } from "react-redux";
import Chart from "../../../components/Chart/Chart";

const dataInput = {
    "paid": [{"old": [], "last16": [], "last_2": [], "last_4": [], "last_8": [], "current": [], "last_32": []}],
    "not_paid": [{"old": [], "last16": [9, 19, 20, 21], "last_2": [], "last_4": [], "last_8": [], "current": [22, 23, 24, 25, 26, 27, 28], "last_32": []}]
};

const TicketAging = ({data}) => {

    const [selectedAging, setSelectedAging] = useState();
    const selectedAgingList = useSelector<any>(state => state.ticketAging.aging) as any;
    const dispatch = useDispatch();

    useEffect(() => {
        
        
    }, [data]);

    // return <Chart />
    return(<><Aging onSelectedAging={(evt) => dispatch(getAgingTicketsAction(evt))} agingList={data} selectedAging={selectedAgingList} setSelectedAging={setSelectedAging} /></>);
}

export default TicketAging;
