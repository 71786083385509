import gql from "graphql-tag";

export const GET_TRANSACTION_STATUS = gql`
    query($paymentId: Int) {
        transaction_status(paymentId: $paymentId) {
            batch_result {
            clearing_house
            refundtotal
            chargetotal
            status
            }
            resptext
            setlstat
            respcode
            refundable
            amount
            capturedate
            settledate
            batchid
            entrymode
            authdate
            lastfour
            name
        }
    }
`