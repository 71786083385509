import { GET_ORDERS_AGING, GET_ORDER_AGIGN } from "../../graphql/query/OrderAging";
import { GraphqlService, ObservableQueryT } from "../../services/graphql.service";
import { GET_OADATA_ERROR, GET_OADATA_STARTED, GET_OADATA_SUCCESS, GET_ORDERS_AGING_STARTED, GET_ORDERS_AGING_ERROR, GET_ORDERS_AGING_SUCCESS } from "../types/orderAging.type";

let agingDataQuery: ObservableQueryT;
let agignOrdersQuery: ObservableQueryT;

export function getOAgingDataAction() {
    return async dispatch => {
        dispatch(getAgingDataStarted());

        try {
            getAgingDataSuccess([]);
            let data = await GraphqlService.SendQuery(GET_ORDER_AGIGN);

            delete data.__typename;
            Object.keys(data).forEach(key => {
                delete data[key][0].__typename;
            })
            dispatch(getAgingDataSuccess(data));
        } catch (ex) {
            dispatch(getAgingDataError(ex.message));
        }
    }
}

const getAgingDataStarted = () => ({type: GET_OADATA_STARTED});
const getAgingDataSuccess = data => ({type: GET_OADATA_SUCCESS, payload: data});
const getAgingDataError = error => ({type: GET_OADATA_ERROR, payload: error});


export function getAgingOrdersAction(aging) {
    return async dispatch => {
        let ids = !aging || aging?.count == 0 ? [-1] : aging.ids;


        dispatch(getAgingOrdersStarted(aging));
        getAgingOrdersSuccess([]);
        try {
            let data = await GraphqlService.SendQuery(GET_ORDERS_AGING, {ids});
            dispatch(getAgingOrdersSuccess(data));
        } catch (ex) {
            dispatch(getAgingOrdersError(ex.message));   
        }
    }
}

const getAgingOrdersStarted = (data) => ({type: GET_ORDERS_AGING_STARTED, payload: data});
const getAgingOrdersSuccess = data => ({type: GET_ORDERS_AGING_SUCCESS, payload: data});
const getAgingOrdersError = error => ({type: GET_ORDERS_AGING_ERROR, payload: error});
