import gql from "graphql-tag";

export const GET_ORDER_AGIGN = gql`
    query{
        get_aging_notpaid{
            pickedup{
                old
                last_16
                last_2
                last_4
                last_8
                current
                last_32
            }
            in_progress{
                old
                last_16
                last_2
                last_4
                last_8
                current
                last_32
            }
            not_pickedup{
                old
                last_16
                last_2
                last_4
                last_8
                current
                last_32
            }
        }
    }
`

export const GET_ORDERS_AGING = gql`
    query($ids: [Int]!){
        table_orderByIds(ids: $ids){
            charge
            tickets
            order_id
            pickup_date
            order_number
            customer_name
            customer_email
            customer_phone
            completed
            cancelled
        }
    }
`