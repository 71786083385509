import styled from 'styled-components';
export const TicketCell = styled.div`
    background: #F4F4F4;
    padding: 1rem;
    width: calc(33.33% - .5rem);
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    img {
        width: 60%;
        display: block;
        margin: 0 auto;
    }
`