import {handleAddTax, handleFetchTaxes, handleUpdateTax} from "./tax.helper";
import {all, call, put, takeLatest} from "redux-saga/effects";
import {fetchTaxesStart, setTaxes} from "./tax.actions";
import taxType from "./tax.type";

export function* fetchTaxes() {
    try {
        const taxes = yield handleFetchTaxes();
        yield put(setTaxes(taxes));
    } catch (err) {
        console.log(err);
    }
}

export function* onFetchTaxesStart() {
    yield takeLatest(taxType.FETCH_TAXES_START, fetchTaxes);
}

export function* addTax({payload}) {
    try {
        yield handleAddTax({...payload});
        yield put(fetchTaxesStart());
    } catch (err) {
        console.log(err);
    }
}

export function* onAddTaxStart() {
    // @ts-ignore
    yield takeLatest(taxType.ADD_TAX_START, addTax);
}

export function* updateTax({payload}) {
    try {
        yield handleUpdateTax({...payload});
        yield put(fetchTaxesStart());
    } catch (err) {
        console.log(err);
    }
}

export function* onUpdateTaxStart() {
    // @ts-ignore
    yield takeLatest(taxType.UPDATE_TAX_START, updateTax);
}

export default function* taxSagas() {
    yield all([
        call(onFetchTaxesStart),
        call(onAddTaxStart),
        call(onUpdateTaxStart)
    ])
}
