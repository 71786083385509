import userType from "./user.type";

export const setUsers: any = users => ({
    type: userType.SET_USERS,
    payload: users
});

export const fetchUsersStart: any = () => ({
    type: userType.FETCH_USERS_START
});

export const addUserStart = userData => ({
    type: userType.ADD_USER_START,
    payload: userData
});

export const deleteUserStart = userData => ({
    type: userType.DELETE_USER_START,
    payload: userData
});

export const changePasswordUserStart = (userData, newPassword) => ({
    type: userType.CHANGE_PASSWORD_USER_START,
    newPassword: newPassword,
    payload: userData
});
