import * as actionTypes from "./actionTypes"
import {TypePickerAction, ITypePickerColorState, COLOR_LIST, ITypePickerColorItem} from "../models";

const initialState: ITypePickerColorState = {
    type: '',
    colorList: COLOR_LIST
};

const colorPickerReducer = (state: ITypePickerColorState = initialState, action: TypePickerAction): ITypePickerColorState => {
    switch (action.type) {
        case actionTypes.ADD_COLOR:
            action.item.count! += 1;

            let addColors = [...state.colorList];
            let addIndex = state.colorList.findIndex(e => e['color'] == action.item.color);

            addColors[addIndex] = action.item;
            return {...state, colorList: [...addColors]};

        case actionTypes.MERGE_COLORS:
            const colors: any = action.item;
            const selectedColors: any = state.colorList.map((color: ITypePickerColorItem)=>{
                color.selected = false;
               color.count = colors.find(y => y.color == color.color)?.count || 0;
               return color;
            });
            return {...state, colorList: [...selectedColors]};

        case actionTypes.REMOVE_COLOR:
            if (action.item.count > 0)
                action.item.count! -= 1;

            let removeColors = [...state.colorList];
            let removeIndex = state.colorList.findIndex(e => e['color'] == action.item.color);

            removeColors[removeIndex] = action.item;
            return {...state, colorList: [...removeColors]};

        case actionTypes.SELECT_COLOR:
            let selectColors = [...state.colorList];
            selectColors = selectColors.map(e => {
                e['selected'] = e['id'] == action.item.id;
                e['count'] = (e['id'] == action.item.id ? (e['count'] == 0 ? 1 : e['count']) : e['count']);
                return e;
            });

            return {...state, colorList: [...selectColors]};
        case actionTypes.RESET_COLOR:
            return {
                type: state.type,
                colorList: [...state.colorList].map((item: ITypePickerColorItem) => {
                    item.count = 0;
                    item.selected = false;
                    return item;
                }),
            };
        default:
            return state;
    }
}

export default colorPickerReducer;
