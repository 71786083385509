import { gql } from 'apollo-boost';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import ICONS from '../../../assets/svg';
import Chart from '../../components/Chart/Chart';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import LoadSpinner from '../../components/LoadSpinner/LoadSpinner';
import Searchbar from '../../components/Searchbar/Searchbar';
import Helper from '../../Helper/Helper';
import { GraphqlService } from '../../services/graphql.service';
import { DailyItem, printReport } from '../../services/printerDrawer';
import PaymentsComponent from './PaymentsComponent';
import SalesComponent from './SalesComponent';

const queryGroupped = gql`query($start: String, $end: String) {
    get_payments_report_grouped(start: $start, end: $end) {
      day
      cash {
        count
        amount
      }
      other {
        count
        amount
      }
      credit_card {
        count
        amount
      }
    }
  }`;

const querySalesGroupped = gql`query($start: String, $end: String) {
    get_sales_report_grouped(start: $start, end: $end) {
      day
      data {
        count
        name
        amount
      }
      amount
      orders
    }
  } 
  `;
  
export interface IPaymentsReportGrouped {
    day: Date,
    cash: IPaymentReport;
    other: IPaymentReport;
    credit_card: IPaymentReport;
}
export interface ISalesReportGrouped {
    day: Date,
    amount: number;
    orders: number;
    data: IPaymentReport[]
}

interface IPaymentReport {
    count: number;
    amount: number;
    name?: string;
}

const ReportsPage = () => {

    enum Screens {
        Payments,
        Sales
    }
    const [start, setStart] = useState(new Date(moment().add(-1, 'month').format('YYYY/MM/DD 00:00:00')));
    const [end, setEnd] = useState(new Date(moment().format('YYYY/MM/DD 23:59:59')))
    const [dateOpen, setDateOpen] = useState(false);
    const ref = useRef();
    const grapichParent = useRef<HTMLDivElement>();
    const [widthDounts, setWidthDounts] = useState(0);
    const [dataSetSales, setDataSetSales] = useState<ISalesReportGrouped[]>([]);

    const [dataSetPayments, setDataSetPayments] = useState<IPaymentsReportGrouped[]>([]);

    const [currentScreen, setCurrentScreen] = useState(Screens.Payments);

    const [loadingPayments, setLoadingPayments] = useState(false);
    const [loadingSales, setLoadingSales] = useState(false);
    
    async function loadDataGrouped(s = start, e = end) {
        try {
            setLoadingPayments(true);
            const data: IPaymentsReportGrouped[] = await GraphqlService.SendQuery(queryGroupped, {start: s, end: e});
            setDataSetPayments(data.filter(x => x.cash.amount + x.other.amount + x.credit_card.amount != 0));
        } catch (ex) {
            console.log(ex.message);
            Swal.fire({
                text: ex.message.toLowerCase().includes('not found') ? 'No response' : ex.message,
                title: 'Error',
                icon: 'error'
            })
        }
        setLoadingPayments(false);
    }

    async function loadSalesGrouped(s = start, e = end) {
        try {
            setLoadingSales(true);
            const data = await GraphqlService.SendQuery(querySalesGroupped, {start: s, end: e});
            // setStart(s);
            // setEnd(e);
            setDataSetSales(data.filter(x=>x.amount > 0));
        } catch (ex) {
            Swal.fire({
                text: ex.message.toLowerCase().includes('not found') ? 'No response' : ex.message,
                title: 'Error',
                icon: 'error'
            })
            console.log(ex.message);
        }
        setLoadingSales(false);
    }
    function handleResize(evt) {
        // console.log(grapichParent.current.scrollHeight);
        setWidthDounts(grapichParent.current?.scrollHeight || 0)
    }

    useEffect(() => {
        loadDataGrouped();
        loadSalesGrouped();
        // console.log(new Date('2022-03-27 10:00').getDay())

        handleResize(null);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    

    return <div className='flex-1-container'>

                {(loadingSales || loadingPayments) && <LoadSpinner />}
                <div className="d-flex justify-content-between col-12">
                    <h4 className='header-title'>Reports</h4>
                    <Link className="btn btn-orange px-5" to='/new-order'>Create new order</Link>
                </div>

                <div className="d-flex justify-content-between align-items-center my-3">
                    <nav className="App-header pb-0" style={{width: 'auto'}}>
                        <ul className='pt-0'>
                            <li>
                                <a onClick={(evt) => setCurrentScreen(Screens.Payments)} className={currentScreen == Screens.Payments ? 'active' : ''}>PAYMENTS</a>
                            </li>
                            <li>
                                <a onClick={(evt) => setCurrentScreen(Screens.Sales)} className={currentScreen == Screens.Sales ? 'active' : ''}>SALES</a>
                            </li>
                        </ul>
                    </nav>

                    <div className="col-6 d-flex justify-content-end">
                    <div className="mr-3">
                        <button style={{minWidth: 'max-content'}} className="btn btn-orange-outline d-flex" onClick={(evt) => setDateOpen(true)}>
                            <i className="fa fa-calendar mr-2"></i> 
                            {moment(start).format('YYYY/MM/DD') == moment(end).format('YYYY/MM/DD') ? 
                            moment(start).format('MM/DD/YYYY')    
                            :
                            moment(start).format('MM/DD/YYYY')    + ' - ' + moment(end).format('MM/DD/YYYY')    
                        }
                        </button>
                        </div>
                        <div className='col-8'>
                            <Searchbar background='#FFF' reference={ref} />
                        </div>
                            
                        <button className="btn ml-3" style={{background: '#FFF', width: '2rem', height: '2rem', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img src={ICONS.IconRefresh} alt=""/>
                        </button>
                            
                    </div>
                </div>

                <div className=" bg-white p-3"  style={{borderRadius: 8}}>
                    {currentScreen == Screens.Payments && <PaymentsComponent dataSet={dataSetPayments} end={end} start={start} />}
                    {currentScreen == Screens.Sales && <SalesComponent dataSet={dataSetSales} end={end} start={start} />}
                
                </div>


                    {dateOpen && <DateTimePicker
                hideTime
                onCancelClicked={(evt) => {
                    setDateOpen(false)
                }}
                date={[start, end]} onDateSubmitted={evt => {
                    evt[0] = new Date(moment(evt[0]).format('YYYY/MM/DD') + ' 00:00:00');
                    evt[1] = new Date(moment(evt[1]).format('YYYY/MM/DD') + ' 23:59:59');

                    let s = evt[0];
                    let e = evt[1];
                    setStart(s);
                    setEnd(e);
                    
                    loadDataGrouped(s, e);
                    loadSalesGrouped(s, e);

                    setDateOpen(false)
                }} />}
            </div>
}

export default ReportsPage;