import * as actionTypes from "./actionTypes"
import {DispatchType, ITypePickerMaterialItem, TypePickerAction} from "../models";

export const addMaterial = (material: ITypePickerMaterialItem) => ({
    type: actionTypes.ADD_MATERIAL, item: material
});

export const resetMaterial = () => ({
    type: actionTypes.RESET_MATERIAL
});

export const mergeMaterials = (items: ITypePickerMaterialItem[]) => ({
    type: actionTypes.MERGE_MATERIALS,
    item: items
});

export const removeMaterial = (material: ITypePickerMaterialItem) => ({
    type: actionTypes.REMOVE_MATERIAL, item: material
});

export const selectMaterial = (material: ITypePickerMaterialItem) => ({
    type: actionTypes.SELECT_MATERIAL, item: material
});

export function simulateHttpRequest(action: TypePickerAction) {
    return (dispatch: DispatchType) => {
        setTimeout(() => {
            dispatch(action)
        }, 500)
    }
}
