import {handleAddService, handleFetchServices} from "./service.helper";
import {all, call, put, takeLatest} from "redux-saga/effects";
import {fetchServicesStart, setServices} from "./service.actions";
import serviceType from "./service.type";

// FETCH SERVICES

export function* fetchServices() {
    try {
        const services = yield handleFetchServices();
        yield put(setServices(services));
    } catch (err) {
        console.log(err);
    }
}

export function* onFetchServicesStart() {
    yield takeLatest(serviceType.FETCH_SERVICES_START, fetchServices);
}

// ADD SERVICE

export function* addService({payload}) {
    try {
        yield handleAddService({...payload});
        yield put(fetchServicesStart());
    } catch (err) {
        console.log(err);
    }
}

export function* onAddServiceStart() {
    // @ts-ignore
    yield takeLatest(serviceType.ADD_SERVICE_START, addService);
}

export default function* serviceSaga() {
    yield all([
        call(onFetchServicesStart),
        call(onAddServiceStart)
    ])
}
