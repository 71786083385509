import {GraphqlService, ObservableQueryT} from "../../services/graphql.service";
import {User} from "../../classes/classes";
import {ADD_USER, CHANGE_PASSWORD_USER, DELETE_USER} from "../../graphql/mutation/user";
import {GET_USERS} from "../../graphql/query/User";

let usersQuery: ObservableQueryT;

export class UserChangePassword {
    id: number = -1;
    name: string = "";
    oldPassword: string = "";
    newPassword: string = "";
    active: boolean = false;

    constructor(x?: User) {
        if(x) {
            Object.assign(this, x);
        }
    }
}

export const handleFetchUsers = () => {
    return new Promise(async (resolve, reject) => {
        if (!usersQuery) {
            usersQuery = GraphqlService.SendQueryObservable(GET_USERS);
            usersQuery.onResults.subscribe(result => {
                if (result.error)
                    reject(result.error.message);
                else
                    resolve(result.data);
            });
        } else{
            usersQuery.refetch();
            usersQuery.onResults.subscribe(result => {
                if (result.error)
                    reject(result.error.message);
                else
                    resolve(result.data);
            });
        }
    });
};

export const handleAddUser = (userData: User) => {
    return new Promise(async (resolve, reject) => {
       try {
           const {name, password} = userData;
           const result = await GraphqlService.SendMutation(ADD_USER, {name, password});

           resolve(result);
       } catch (err) {
           reject(err);
       }
    });
};

export const handleDeleteUser = (userData: User) => {
    return new Promise(async (resolve, reject) => {
        try {
            const userId = userData.id;
            const result = await GraphqlService.SendMutation(DELETE_USER, {userId});

            resolve(result);
        } catch (err) {
            reject(err);
        }
    });
};

export const handleChangePasswordUser = (userData: UserChangePassword) => {
    return new Promise(async (resolve, reject) => {
        try {
            const {name, oldPassword, newPassword} = userData;
            const result = await GraphqlService.SendMutation(CHANGE_PASSWORD_USER, {name, oldPassword, newPassword});

            resolve(result);
        } catch (err) {
            reject(err);
        }
    });
};
