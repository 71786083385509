import React from 'react';
import './ColorPicker.scss';
import {ITypePickerColorItem, ITypeColorPickerProps} from "./models";
// import ICONS from "../../assets/icons";
import {useDispatch, useSelector} from "react-redux";
import {ReducerType} from "../../../../store";
import {addColor, removeColor, selectColor} from "./state/actions";
import {ITypePickerItem} from "../../models";
import ICONS from '../../../../../assets/svg';

export const ColorPicker: React.FC<ITypeColorPickerProps> = (props) => {
    const dispatch = useDispatch();

    const colorList =
        useSelector<ReducerType, ReducerType['colorPickerReducer']['colorList']>(
            (state) => state.colorPickerReducer.colorList
        );

    const selectItem = async (color: ITypePickerColorItem) => {
        dispatch(selectColor(color))
        const selectedItems = await Promise.all(colorList.map((icolor: ITypePickerColorItem) => {
            if (icolor.color === color.color) {
                icolor = color
            }
            return icolor
        }));
        props.onColorSelection(selectedItems.filter((item: ITypePickerColorItem) => item.count! > 0))
    }

    const addItemColor = async (color: ITypePickerColorItem) => {
        dispatch(addColor(color))

        const selectedItems = await Promise.all(colorList.map((icolor: ITypePickerColorItem) => {
            if (icolor.color === color.color) {
                icolor = color
            }
            return icolor
        }));
        props.onColorSelection(selectedItems.filter((item: ITypePickerColorItem) => item.count! > 0))
    }

    const removeItemColor = async (event: any, color: ITypePickerColorItem) => {
        event.stopPropagation();
        dispatch(removeColor(color))

        const selectedItems = await Promise.all(colorList.map((icolor: ITypePickerColorItem) => {
            if (icolor.color === color.color) {
                icolor = color
            }
            return icolor
        }));
        props.onColorSelection(selectedItems.filter((item: ITypePickerColorItem) => item.count! > 0))
    }

    return (
        <>
            <div className="row color-picker-cnt g-0">
                <div className="col-12">
                    <div className="row g-25">
                        {
                            colorList.map((color: any) => (
                                <div
                                    className="col-6 col-sm-4 col-md-4 col-lg-4 text-center mb-3 g-25">
                                    <div id={color.id}
                                         className={`item-type ${(color.selected && color.count > 0) ? 'active' : ''} ${!color.selected && color.count > 0 ? 'color-active' : ''}`}
                                         onClick={() => selectItem(color)}>
                                        <div className={'d-flex align-items-center justify-content-start ml-4'}>
                                            <div style={{
                                                backgroundColor: color.value,
                                                width: "12px",
                                                height: "13px",
                                                borderRadius: '5px'
                                            }}> </div>
                                            <p className="ml-2 mr-2">{color.color}</p>
                                            {!color.selected && color.count > 0 ?
                                                <p className='ml-auto mr-4'>{color.count}</p> : null}
                                        </div>

                                        <div className="selected-layer  ">
                                            <div className="row g-0 w-100 mt-3 align-items-center">
                                                <div className="g-25 col-4 text-center ">
                                                    <div className="item-btn item-remove"
                                                         onClick={(e) => removeItemColor(e, color)}>
                                                        <img src={ICONS.REMOVE} alt="remove"/>
                                                    </div>
                                                </div>
                                                <div className="g-25 col-4 text-center">
                                                    <span className="item-qty">
                                                        {color.count}
                                                    </span>
                                                </div>
                                                <div className="g-25 col-4 text-center">
                                                    <div className="item-btn item-add"
                                                         onClick={() => addItemColor(color)}>
                                                        <img src={ICONS.ADD} alt="blouse"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ColorPicker;
