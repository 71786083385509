const customerTypes = {
    FETCH_CUSTOMER_TABLE_START: 'FETCH_CUSTOMER_TABLE_START',
    SET_CUSTOMERS_TABLE: 'SET_CUSTOMERS_TABLE',
    SET_CUSTOMERS_TABLE_BY_ID: 'SET_CUSTOMERS_TABLE_BY_ID',
    FETCH_CUSTOMERS_START: 'FETCH_CUSTOMERS_START',
    SET_CUSTOMERS: 'SET_CUSTOMERS',
    GET_CUSTOMER_BY_ID: 'GET_CUSTOMER_BY_ID',
    GET_CUSTOMER_BY_ID_START: 'GET_CUSTOMER_BY_ID_START',
    FETCH_CUSTOMER_TABLE_START_BY_ID: 'FETCH_CUSTOMER_TABLE_START_BY_ID',
    ADD_CUSTOMER_START: 'ADD_CUSTOMERS_START',
    ADD_CUSTOMER: 'ADD_CUSTOMER',
    UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
    UPDATE_CUSTOMER_START: 'UPDATE_CUSTOMERS_START',
    ADD_CUSTOMER_SUCCESS: 'ADD_CUSTOMER_SUCCESS',
    UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
    RESET_NEW_CUSTOMER: 'RESET_NEW_CUSTOMER'
};

export default customerTypes;
