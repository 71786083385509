import '../Aging/Aging.scss'
import Status from "../../../components/Status/Status";
import {useEffect, useState} from "react";

const Aging = ({agingList, selectedAging, setSelectedAging, onSelectedAging}) => {
    
    const formatLabels = (status) => {
        switch (status)
        {
            case 'pickedup':
                return 'PICKED UP';
            case 'in_progress':
                return 'IN PROGRESS';
            case 'not_pickedup':
                return 'READY';
            case 'paid':
                return 'PAID';
            case 'not_paid':
                return 'NOT PAID';
            default: return 'NONE';
        }
    }

    const [reformatAgingList, setReformatAgingList] = useState(reorderArray(agingList));

    function reorderArray(agingList) {

        
        let newArray = {};
        if (!agingList)
            return {}

        Object.keys(agingList).forEach(status => {
            newArray[status] = [];

            // SAVE ALL
            let {sum, ids } = getAll(agingList, status);
            newArray[status].push({ label: 'All', ids: ids, count: sum, selected: false, aging: 'all', status });

            // SAVE CURRENT
            let current = 0;
            let currents_id = [];
            agingList[status].forEach(e => {
                Object.keys(e).forEach(aging => {
                    if (aging == 'current') 
                    {
                        current = e[aging].length;
                        currents_id = e[aging];
                    }
                });
            });
            newArray[status].push({ label: 'Current', ids: currents_id, count: current, selected: false, aging: 'current', status });

            // SAVE THE REST OF THEM. IGNORING 'current' VALUE
            agingList[status].forEach(e => {
                let agingArray = Object.keys(e).filter(e => e != 'current');

                agingArray.sort((a, b) => {
                    if (a == 'old') return -1;

                    let lastA = a.indexOf('_') !== -1 ? a.split('last_')[1] : a.split('last')[1];
                    let lastB = b.indexOf('_') !== -1 ? b.split('last_')[1] : b.split('last')[1];

                    if (!lastA) return 1;
                    if (!lastB) return 1;

                    return +lastA == +lastB ? 0 : (+lastA > +lastB ? 1 : -1);
                }).forEach((aging, index) => {

                    
                    if (index == 0) {
                        // get next number
                        let next = agingArray[index + 1];
                        next = next.indexOf('_') !== -1 ? next.split('last_')[1] : next.split('last')[1];

                        // put array
                        newArray[status].push({ label: `1 - ${next} days old`, ids: e[aging], count: e[aging].length, selected: false, aging: aging, status });
                    } else if (index == agingArray.length - 1) {
                        // get current number
                        let current = aging.indexOf('_') !== -1 ? aging.split('last_')[1] : aging.split('last')[1];

                        // put array
                        newArray[status].push({ label: `${+current + 1} + days`, ids: e[aging], count: e[aging].length, selected: false, aging: aging, status});
                    } else {
                        // get current number
                        let current = aging.indexOf('_') !== -1 ? aging.split('last_')[1] : aging.split('last')[1];

                        let next = agingArray[index + 1];
                        next = next.indexOf('_') !== -1 ? next.split('last_')[1] : next.split('last')[1];

                        // put array
                        newArray[status].push({ label: `${+current + 1} - ${next} days old`, ids: e[aging], count: e[aging].length, selected: false, aging: aging, status });
                    }
                });
            });
        });
        return newArray;
    }

    function getAll(agingList, orderStatus) {
        let array = agingList[orderStatus];
        let sum = 0;
        let ids = [];

        array.forEach(e => {
            

            Object.keys(e).forEach(aging => {
                sum += e[aging].length;
                e[aging].forEach(x => {
                    ids.push(x);
                })
            } );
        });

        return {sum, ids};
    }

    const getAgingDetails = (orderStatus) => {
        return (
            <div>
                {
                    reformatAgingList[orderStatus].map((aging, index) => {
                        return(
                            <div key={ aging.label + '-' + index }
                                 className={'d-flex justify-content-between align-items-center orders-cell ' + (aging.selected ? 'active' : '')}
                                 onClick={ () => onSelectAging(orderStatus, aging) }>
                                <div>{ aging.label }</div>
                                <div className="fw-bold">{ aging.count }</div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    function onSelectAging(selectedOrderStatus, aging, list?) {
  
        if (!list)
            list = reformatAgingList;
        Object.keys(list).forEach(orderStatus => {
            if (selectedOrderStatus == orderStatus)
                list[orderStatus].forEach(e => {
                    if (e.label == aging.label)
                    {
                        e.selected = true;
                        aging = e;
                    }
                    else
                    {
                        e.selected = false;
                    }
                });
            else list[orderStatus].forEach(e => e.selected = false);
        });


        
        if (selectedOrderStatus)
            onSelectedAging(aging);
        setReformatAgingList(list);
    }

    
    useEffect(() => {
        
        const list = reorderArray(agingList);
        onSelectAging(selectedAging?.status, selectedAging, list);

        
    }, [agingList])
    return(
        <>
            {agingList != null && <div className="col-12 col-sm-12 flex-1-container">
                    <div className="d-flex justify-content-around align-items-center order-header pt-0">
                        { Object.keys(agingList).map(key => <Status key={ key }
                                                                    statusName={ formatLabels(key || '') } />) }
                    </div>

                    <div className="flex-1-container">
                            <div className="orders">
                            { Object.keys(agingList).map(key => <div key={ 'order-' + key }
                                                                 className="orders-column">{ getAgingDetails(key) }</div>) }
                            </div>
                            
                    </div>
                    
            </div>}
        </>
    );
}

export default Aging;
