import {gql} from "apollo-boost";

export const ALL_INVOICES = gql(`query {
  gett_allInvoices{
    id
    name
    note
    time
    email
    link
    profusepay_link
    items {
      name
      count
      amount
      tax_amount
      base_amount
      payment_id
    }
    amount
    phone
    token
    business_id
    transaction_id
    order_reference
  }
}`);

export const CUSTOMER_INVOICES = gql(`query($id: Int) {
  get_customers_invoices(id:$id){
    id
    name
    note
    time
    email
    link
    profusepay_link
    items {
      name
      count
      amount
      tax_amount
      base_amount
      payment_id
    }
    amount
    phone
    token
    business_id
    transaction_id
    order_reference
  }
}`);
