import React, {useEffect, useRef, useState} from 'react';
import {Link, NavLink, Redirect, Route, Switch, useLocation, useParams} from 'react-router-dom';
import {CHECK_AUTH} from '../../graphql/query/auth';
import Helper from '../../Helper/Helper';
import AuthService from '../../services/auth.service';
import {GraphqlService} from '../../services/graphql.service';
import OverviewPage from '../OverviewPage/OverviewPage';
import './MainPage.scss';
import SettingPage from "../SettingPage/SettingPage";
import OrderAging from "../OverviewPage/OrderAging/OrderAging";
import TicketAging from "../OverviewPage/TicketAging/TicketAging";
import OrdersPage from '../OrderPage/OrderPage';
import AccountingPage from '../AccountingPage/AccoutingPage';
import DashboardPage from '../DashboardPage/DashboardPage';
import ProcessPayment from '../ProcessPayment/ProcessPayment';
import { useSelector } from 'react-redux';
import DailyReportPage from '../DailyReportPage/DailyReportPage';
import CustomerDetail from '../CustomerDetail/CustomerDetail';
import { User } from '../../classes/classes';
import {
    
    useWindowHeight,
  } from '@react-hook/window-size';
import ReportsPage from '../ReportsPage/ReportsPage';

const MainPage = () => {

    const location = useLocation();
    
    const navRef = useRef<any>();
    const [user, setUser] = useState<any>(new User());
    // const { order_id } = useParams<any>();

    const order_id = useSelector<any>(state => state.orders.editOrder);


    const height = useWindowHeight();


    const check = async () => {
        let token = AuthService.TOKEN;
        try {
            let data = await GraphqlService.SendQuery(CHECK_AUTH);
            setUser(data);
        } catch (ex) {
            console.log('Error in MainPage.check: ', ex.message, 'token: ', token);
        }
    }


    

    useEffect(() => {

        if (AuthService.TOKEN.trim().length > 8)
            check();

        if (!AuthService.TOKEN)
            Helper.Navigation.NavigateTo('/login')

            

    }, [])

    useEffect(()=>{
        AuthService.USER = user;
    }
    , [user])

    return (
        <>
            {/*<div className="main-wrapper" style={{height}}>*/}
            <div className="main-wrapper container p-0 flex-1-container" style={{height: `${height}px`}}>
                {<div className="row g-0">
                    <div className="col-12">
                        <nav ref={navRef} className='App-header' id='header'>
                            <ul>
                                <li><NavLink exact activeClassName='active' to="/">DASHBOARD</NavLink></li>
                                <li><NavLink activeClassName='active' to="/orders">ORDERS</NavLink></li>
                                <li><NavLink activeClassName='active' to="/daily-report">DAILY REPORT</NavLink></li>
                                <li><NavLink activeClassName='active' to="/reports">REPORTS</NavLink></li>
                                <li><NavLink activeClassName='active' to="/accounting">ACCOUNTING</NavLink></li>
                                <li><NavLink activeClassName='active' to="/settings">SETTINGS</NavLink></li>
                            </ul>
                            <ul style={{display: 'flex', alignItems: 'center'}}>
                                {/* <i className="far fa-bell mr-3"> </i> */}
                                <div className='ml-1 d-flex align-items-center'>
                                    {/* <div style={{
                                        width: '24px',
                                        height: '24px',
                                        background: '#CCCCCC',
                                        borderRadius: '50%'
                                    }}/> */}
                                    <Link to='/settings' className="ml-2 title-text fs-14">{user.name}</Link>
                                </div>
                            </ul>
                        </nav>
                    </div>
                </div>}
                <div className={'row g-0 flex-1-container mb-3'}>
                    <div className="col-12 flex-1-container">
                        <Switch>
                            <Route exact path="/" component={DashboardPage}/>
                            <Route exact path='/new-order' component={OverviewPage} />
                            <Route exact path="/orders" component={OrdersPage}/>
                            <Route exact path="/daily-report" component={DailyReportPage}/>
                            <Route exact path="/accounting" component={AccountingPage}/>
                            <Route exact path="/settings" component={SettingPage}/>
                            <Route exact path='/order/:order_id' render={() => <OverviewPage />} />
                            <Route exact path='/process-payment/:master_id' component={ProcessPayment} />
                            <Route exact path='/reports' component={ReportsPage} />
                            <Route exact path='/customer/:customer_id' component={CustomerDetail} />
                            {/* <Redirect to='/404' /> */}
                        </ Switch>
                    </div>
                </div>
            </div>
        </>);
}

export default MainPage;
