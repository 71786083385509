import {gql} from "apollo-boost";

export const GET_SERVICES = gql(
`query {
  services {
    id
    name
    type
    cost
  }
}`);
