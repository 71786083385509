import { Customer, Order } from '../../classes/classes';
import IReducerAction from '../models/IReducerAction';
import
{
    CREATE_ORDER_STARTED,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_ERROR,
    GET_HISTORY_ORDERS_STARTED,
    GET_HISTORY_ORDERS_SUCCESS,
    GET_HISTORY_ORDERS_ERROR,
    SET_ORDER_EDITTING,
    GET_PAYMENTS_ORDER_STARTED,
    GET_PAYMENTS_ORDER_SUCCESS,
    GET_PAYMENTS_ORDER_ERROR,
    RESET_NEW_ORDER,
    GET_INVOICES_ORDER_STARTED,
    GET_INVOICES_ORDER_SUCCESS,
    GET_INVOICES_ORDER_ERROR,
    GET_OPEN_ORDERS_STARTED,
    GET_OPEN_ORDERS_SUCCESS,
    GET_OPEN_ORDERS_ERROR
} from '../types/orders.type';


interface IState {
    orders: any[],
    error: Error,
    loading: boolean,
    newOrder?: Order,
    editOrder?: number,
    payments: any[],
    invoices: any[]
}

const initalState: IState = {
    orders: [],
    error: null,
    loading: false,
    editOrder: null,
    newOrder: null,
    payments: [],
    invoices: []
}



export default (state: IState = initalState, action: IReducerAction): IState =>
{   
    switch (action.type)
    {
        case CREATE_ORDER_STARTED:
            return {
                ...state,
                loading: true,
                newOrder: action.payload
            }
        case CREATE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                newOrder: action.payload
            }
        case CREATE_ORDER_ERROR:
            return {
                ...state,
                loading: false,
                newOrder: null,
                error: action.payload
            }
        case GET_HISTORY_ORDERS_STARTED:
            return {
                ...state,
                loading: true
            }
        case GET_HISTORY_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: action.payload
            }
        case GET_HISTORY_ORDERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_OPEN_ORDERS_STARTED:
            return {
                ...state,
                loading: true
            }
        case GET_OPEN_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: action.payload
            }
        case GET_OPEN_ORDERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case SET_ORDER_EDITTING:
            return {
                ...state,
                editOrder: action.payload
            }
        case GET_PAYMENTS_ORDER_STARTED:
            return {
                ...state,
                loading: true,
                editOrder: action.payload
            }

        case GET_PAYMENTS_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                payments: action.payload
            }
        case GET_PAYMENTS_ORDER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case GET_INVOICES_ORDER_STARTED:
            return {
                ...state,
                loading: true,
                editOrder: action.payload
            }

        case GET_INVOICES_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                invoices: action.payload
            }
        case GET_INVOICES_ORDER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case RESET_NEW_ORDER:
            return {
                ...state,
                newOrder: null
            }
        default:
            return state;
    }
};