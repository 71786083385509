import serviceType from "./service.type";

const INITIAL_STATE = {
    services: [],
    isLoading: false
};

const serviceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case serviceType.SET_SERVICES:
            return {
                ...state,
                isLoading: false,
                services: action.payload
            };
        case serviceType.ADD_SERVICE:
            return {
                ...state,
                isLoading: true,
            };
        default:
            return state;
    }
};

export default serviceReducer;
