import userType from "./user.type";

const INITIAL_STATE = {
    users: [],
    newPassword: '',
    isLoading: false
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case userType.SET_USERS:
            return {
                ...state,
                isLoading: false,
                users: action.payload,
            };
        case userType.ADD_USER_START:
            return {...state, isLoading: true};
        case userType.DELETE_USER_START:
            return {...state, isLoading: true};
        case userType.CHANGE_PASSWORD_USER_START:
            return {...state, newPassword: action.newPassword, isLoading: true}
        default:
            return state;
    }
};

export default userReducer;
