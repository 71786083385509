import * as actionTypes from "./actionTypes"
import {ITypePickerItem, TypePickerAction} from "../models";

export const addTypePickerItem = (item: ITypePickerItem): TypePickerAction => ({
        type: actionTypes.ADD_TYPE,
        item,
    }
);

export const removeTypePickerItem = (item: ITypePickerItem): TypePickerAction => ({
        type: actionTypes.REMOVE_TYPE,
        item,
    }
);

export const resetTypePickerItem = (): TypePickerAction => ({
        type: actionTypes.RESET_TYPE,
        item: null,
    }
);

export const setItems = (items: ITypePickerItem[]): TypePickerAction => ({
    type: actionTypes.SET_ITEMS_TYPE,
    items: items
});

export const fetchItemsStart: any = () => ({
    type: actionTypes.FETCH_ITEMS_TYPE_START,
    items: []
});

export const addCustomItemStart: any = () => ({
    type: actionTypes.ADD_CUSTOM_ITEM_START,
});

export const openCustomItemForm: any = () => ({
    type: actionTypes.OPEN_CUSTOM_FORM,
});


export const closeCustomItemForm: any = () => ({
    type: actionTypes.OPEN_CUSTOM_FORM,
});
