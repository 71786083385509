import React from 'react';

export const Loading = () => {
    return (
        <div className="loading-component">
            <img style={{display: 'block', margin: '1rem auto', width: '24px', height: '24px'}} src="https://media0.giphy.com/media/l4FGKbWgkhHVGXzTW/giphy.gif" alt=""/>
        </div>
    )
}

export default Loading;