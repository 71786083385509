import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from '../../components/DatePicker/DatePicker';
import Searchbar from '../../components/Searchbar/Searchbar';
import IColumn from '../../components/Table/models/IColumn';
import Table from '../../components/Table/Table';
import Helper from '../../Helper/Helper';
import { getDailyReportAction, getPickupsReportAction, getTakeInsReportAction } from '../../redux/actions/dailyReport.action';
import { getHistoryOrdersAction } from '../../redux/actions/order.action';
import { GraphqlService } from '../../services/graphql.service';
import {VOID_TRANSACTION} from '../../graphql/mutation/Transaction';
import {ReceiptData, DailyItem, Header, printReceipt, printReport} from '../../services/printerDrawer';
import { GET_TRANSACTION_STATUS } from '../../graphql/query/Transaction';
import Swal from 'sweetalert2';
import { GET_RECEIPT_BY_PAYMENT_ID } from '../../graphql/query/Receipt';
import { Link } from 'react-router-dom';
import ICONS from '../../../assets/svg';
import { TicketService } from '../../services/ticket.service';
enum Screens {
    Transactions,
    OrdersIn,
    PickedUp
}
const DailyReportPage = () => {
    const dispatch = useDispatch();

    const getInit = () => {
        const temp = new Date();
        temp.setHours(0, 0, 0, 0);
        return temp
    }
    const beginingOfDay = useSelector<any>(state => state.dailyReport.beginingOfDay) as string || getInit().toISOString();
    
    const reports = useSelector<any>(state => state.dailyReport.reports) as any[];
    const loading = useSelector<any>(state => state.dailyReport.loading) as boolean;


    const takeins = useSelector<any>(state => state.dailyReport.takeIns) as any[];
    const loadingTakeins = useSelector<any>(state => state.dailyReport.loading) as boolean;


    const pickups = useSelector<any>(state => state.dailyReport.pickUps) as any[];
    const loadingPickups = useSelector<any>(state => state.dailyReport.loading) as boolean;

    // console.log(takeins);
    const [searchTerm, setSearchTerm] = useState('');
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [currentScreen, setCurrentScreen] = useState(Screens.Transactions);

    const accountColumns: IColumn[] = [
        { orderBy: 'ASC', active: false, label: 'TIME', name: 'time' },
        { orderBy: 'ASC', active: false, label: 'TYPE', name: 'type' },
        { orderBy: 'ASC', active: false, label: 'AMOUNT', name: 'amount_formatted', sort_by: 'amount'},
        { orderBy: 'ASC', active: false, label: 'STATUS', name: 'status' },
        { orderBy: 'ASC', active: false, label: 'ORDER #', name: 'order_number', sort_by: 'order_id'},
        { orderBy: 'ASC', active: false, label: 'PAYMENT #', name: 'payment_number' },
        { orderBy: 'ASC', active: false, label: 'TRANSACTION #', name: 'transaction_number' }
    ];

    const orderColumns: IColumn[] = [
        { orderBy: 'ASC', active: false, label: 'PICKUP DATE', name: 'pickup_date' },
        { orderBy: 'ASC', active: false, label: 'TICKETS', name: 'tickets' },
        { orderBy: 'ASC', active: false, label: 'ORDER #', name: 'order_number' },
        { orderBy: 'ASC', active: false, label: 'CUSTOMER', name: 'customer' },
        { orderBy: 'ASC', active: false, label: 'CHARGE', name: 'charge' },
        { orderBy: 'ASC', active: false, label: 'BALANCE', name: 'balance' },
        { orderBy: 'ASC', active: false, label: 'ORDERED ON', name: 'ordered_on' },
        { orderBy: 'ASC', active: false, label: 'PAYMENT #', name: 'payments' }
    ];

    const pickedupColumns: IColumn[] = [
        {
            active: false, label: 'TASKS #', name: 'ticket_id', orderBy: 'ASC',
        },
        {
            active: false, label: 'TASKS NAME', name: 'ticket_name', orderBy: 'ASC',
        },
        {
            active: false, label: 'STATUS', name: 'status', orderBy: 'ASC',
        },
        {
            active: false, label: 'CHARGE', name: 'charge', orderBy: 'ASC',
        },
        {
            active: false, label: 'CHARGES', name: 'charges', orderBy: 'ASC',
        },
        {
            active: false, label: 'PICKED UP DATE', name: 'pickup_date', orderBy: 'ASC',
        },
        {
            active: false, label: 'CUSTOMER', name: 'customer', orderBy: 'ASC',
        },
        {
            active: false, label: 'TAG', name: 'label', orderBy: 'ASC',
        }
    ];

    const getTotal = () => {
        let total = 0;
        switch (currentScreen)
        {
            case Screens.Transactions:
                total = reports.sumBy(x => +(x.amount).replace('$', ''));
                break;
            case Screens.OrdersIn:
                total = takeins.sumBy(x => x.charge);
                break;
            case Screens.PickedUp:
                total = pickups.sumBy(x => x.charge);
                break;
        }
        return Helper.Masks.ValidUSMoney(total);
    }

    async function handlePrint(evt) {
        let data: DailyItem[] = reports;
        let header = {
            name: '',
            street: '',
            city_state: '',
            contact: ''
        };
        await printReport(data, new Date(beginingOfDay), header);
    }
    
    const handleDblClick = (evt) => {
        let order_id = Helper.Masks.IntNumbers(evt.order_number || evt.order_id);
        Helper.Navigation.NavigateTo('/order/' + order_id)
    }

    const options = [
        {
            label: 'See order details',
            icon: ICONS.IconDetails,
            action: (data) => {
                let ticket_id = data.ticket_id ? (+Helper.Masks.IntNumbers(data.ticket_id[1])) : null;
                TicketService.TicketSelected = ticket_id;
                let id = Helper.Masks.IntNumbers(data.order_number || data.order_id);
                
                Helper.Navigation.NavigateTo('/order/' + id);
            }
        },
        {
            label: 'Receipt',
            icon: ICONS.IconReceipt,
            action: async (data) => {
                let payment_id = +Helper.Masks.IntNumbers(data.payment_number || data.payments || '');
                if (payment_id)
                    Helper.Receipt.ShowConfirmation(payment_id, 1);
                else
                    Helper.Receipt.PrintOrderReceipt(+Helper.Masks.IntNumbers(data.order_number), 1);
            }
        },
        {
            label: 'Void Payment',
            icon: ICONS.IconDelete,
            condition: x => x?.payment_number || x?.payments,
            action: async (data) => {
                let payment_id = +Helper.Masks.IntNumbers(data.payment_number || '');
               
                Swal.fire({
                    title: 'Are you sure?',
                    text:  `Void Payment #` + payment_id + `, this action can't be undo!`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, void it!'
                  }).then(async result => {
                      if (result.isConfirmed)
                      {
                           

                        try {
                            let data = await GraphqlService.SendMutation(VOID_TRANSACTION, {paymentId: payment_id})
                            reload();
                        } catch (ex) {
                            console.log('ex', ex.message);
                        }
                      }
                  })
            }
        },
        {
            label: 'Check Status',
            icon: ICONS.IconCheck,
            condition: x => x?.payment_number,
            action: async (data) => {
                let payment_id = +Helper.Masks.IntNumbers(data.payment_number);
                try {
                    let result = await GraphqlService.SendQuery(GET_TRANSACTION_STATUS, {paymentId: payment_id});
                    Swal.fire({
                        title: 'Transaction ' + data.transaction_number,
                        text: JSON.stringify(result),
                        icon: 'info'
                    })
                } catch (ex) {
                    Swal.fire({
                        text: ex.message.toLowerCase().includes('not found') ? 'Error: Transaction Not Found' : ex.message,
                        title: 'Error',
                        icon: 'error'
                    })
                }
            }
        }
    ];


    async function loadData(event) {
        event.complete();
    }
    

    const ref = useRef();

    function addDay(day: number)
    {
        dispatch(getDailyReportAction(new Date(moment(new Date(beginingOfDay)).add(day, 'day').format('YYYY/MM/DD'))));
        dispatch(getTakeInsReportAction(new Date(moment(new Date(beginingOfDay)).add(day, 'day').format('YYYY/MM/DD'))));
        dispatch(getPickupsReportAction(new Date(moment(new Date(beginingOfDay)).add(day, 'day').format('YYYY/MM/DD'))));

        
    }
    
    function reload(evt?)
    {
        dispatch(getDailyReportAction(evt || beginingOfDay || new Date( moment().format('YYYY/MM/DD'))));
        dispatch(getTakeInsReportAction(evt || beginingOfDay || new Date( moment().format('YYYY/MM/DD'))));
        dispatch(getPickupsReportAction(evt || beginingOfDay || new Date( moment().format('YYYY/MM/DD'))));
    }

    useEffect(() => {
        reload();
    }, [])

    return (
        <>
            {calendarOpen && <DatePicker title="Select report day" date={new Date(beginingOfDay)} onCancelClicked={(evt) => setCalendarOpen(false)} onDateSelected={(evt) => {
                reload(evt);
                setCalendarOpen(false);
            }} />}
            <div className="px-0 flex-1-container">
                <div className="d-flex align-items-center justify-content-between col-12 mb-3">
                    
                    <h4 className='header-title'>Daily Report</h4>
                    <div className="col-6 d-flex">
                        <div className='col-12 mr-3'>
                            <Searchbar value={searchTerm} onChange={(evt) => setSearchTerm(evt.target.value)} background='#FFF' reference={ref} />
                        </div>
                        <button className="btn" style={{background: '#FFF', width: '2rem', height: '2rem', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img  onClick={(evt) => reload()} className={loading && 'spin'} src={ICONS.IconRefresh} alt=""/>
                        </button>
                    </div>
                    <Link className="btn btn-orange px-5" to='/new-order'>Create new order</Link>
                </div>



            <div className="flex-1-container">
                 <div className="d-flex justify-content-between align-items-center mb-3">
                               {/* <p className="font-14 fw-600">{reports.length} Transactions</p> */}
                               <nav className="App-header pb-0" style={{width: 'auto'}}>
                                   <ul className='pt-0'>
                                       <li>
                                           <a onClick={(evt) => setCurrentScreen(Screens.Transactions)} className={currentScreen == Screens.Transactions ? 'active' : ''}>TRANSACTIONS</a>
                                       </li>
                                       <li>
                                           <a onClick={(evt) => setCurrentScreen(Screens.OrdersIn)} className={currentScreen == Screens.OrdersIn ? 'active' : ''}>NEW ORDERS</a>
                                       </li>
                                       <li>
                                           <a onClick={(evt) => setCurrentScreen(Screens.PickedUp)} className={currentScreen == Screens.PickedUp ? 'active' : ''}>PICKUPS</a>
                                       </li>
                                   </ul>
                               </nav>

                               <div className='d-flex align-items-center'>
                                   <button onClick={(evt) => setCalendarOpen(true)} className="btn btn-orange px-3 regular-text mr-2">{Helper.FORMAT.USDate(new Date(beginingOfDay), 'do')} <i className="far fa-calendar-alt ml-2"></i></button>
                                   <button onClick={(evt) => addDay(-1)} className='btn btn-clear mx-2'><i className="fas fa-chevron-left"></i></button>
                                   <button onClick={(evt) => addDay(1)} className='btn btn-clear'><i className="fas fa-chevron-right"></i></button>
                                   {currentScreen == Screens.Transactions && <button className="btn" style={{background: '#FFF', width: '2rem', height: '2rem', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                       <img  onClick={handlePrint} src={ICONS.IconPrintYellow} alt=""/>
                                   </button>}
                               </div>
                </div>
                <div className='flex-1-container p-3 bg-white rounded-card'>

                           {Screens.Transactions == currentScreen && <Table
                           
                                  menuOptions={options}
                                  onDoubleClick={handleDblClick}
                                  columns={accountColumns}
                                  loading={loading}
                                  rows={reports.map(x => ({
                                      time: [Helper.FORMAT.USDate(x.time, 'do'), Helper.FORMAT.USDate(x.time, 'to')],
                                      amount: +(x.amount.replace('$','')),
                                      amount_formatted: x.amount,
                                      status: x.status,
                                      order_number: x.order_number,
                                      order_id: x.order_id,
                                      payment_id: x.payment_id,
                                      transaction_number: x.transaction_number,
                                      type: x.type,
                                      payment_number: x.payment_number
                                  })).filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim()))}
                                  tableHeight="65vh"
                                  onRowClicked={() => {}}/>}

                           {Screens.OrdersIn == currentScreen && <Table 
                                  menuOptions={options}
                                  onDoubleClick={handleDblClick}
                                  columns={orderColumns}
                                  loading={loading}
                                  rows={takeins.map(x => ({
                                   pickup_date: [Helper.FORMAT.USDate(x.pickup_date, 'do'), Helper.FORMAT.USDate(x.pickup_date, 'dd')],
                                   tickets: ['', ...x.tickets],
                                   order_number: x.order_number,
                                   customer: [x.customer_name, x.customer_phone],
                                   charge: Helper.FORMAT.USCurrency(x.charge),
                                   balance: Helper.FORMAT.USCurrency(x.balance),
                                   ordered_on: Helper.FORMAT.USDate(x.ordered_on, 'do'),
                                   payments: x.payments
                               })).filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim()))}
                                  tableHeight="65vh"
                                  onRowClicked={() => {}}/>}


                               {Screens.PickedUp == currentScreen && <Table
                               
                                  menuOptions={options}
                                  onDoubleClick={handleDblClick}
                                  columns={pickedupColumns}
                                  loading={loading}
                                  rows={pickups.map(x => (
                                   {
                                       ticket_id: [x.order_number, x.ticket_number],
                                       ticket_name: x.ticket_name,
                                       status: x.status.text,
                                       charges: ['', ...x.charges],
                                       pickup_date: [Helper.FORMAT.USDate(x.pickedup_date, 'do'), Helper.FORMAT.USDate(x.pickedup_date, 'dd')],
                                       customer: [x.customer_name, x.customer_phone],
                                       label: x.label,
                                       order_number: x.order_number,
                                       charge: Helper.Masks.ValidUSMoney(x.charge)
                                   })).filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim()))}
                                  tableHeight="65vh"
                                  onRowClicked={() => {}}/>}
                                  
                            <div className='bg-white'>
                               <h4 className="form-title mb-0 text-right pt-3">Total: {getTotal()}</h4>
                           </div>
                </div>
           </div>
            </div>

        </>
    );
}

export default DailyReportPage;