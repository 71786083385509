import { ControlledMenu, MenuItem } from '@szhsin/react-menu';
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import ICONS from '../../../../assets/svg';
import { Service, Service_charge, Ticket, TicketSelection } from '../../../classes/classes';
import ServiceForm from '../../../components/Forms/ServiceForm/ServiceForm';
import ServiceGroupForm from '../../../components/Forms/ServiceGroupForm/ServiceGroupForm';
import Loading from '../../../components/Loading/Loading';
import { TypePicker } from '../../../components/TypePicker/TypePicker';
import Helper from '../../../Helper/Helper';
import { service_selectionSPRes } from '../../../services/Endpoints/classes';
import { Query } from '../../../services/Endpoints/enpoints';
import { GraphqlService } from '../../../services/graphql.service';
import { TicketCell } from './TicketPickerModal.style';


const GET_SERVICES_QUERY =  gql`
    query{
        get_service_group{
            id
            name
            icon
        }
    }
`;

const BUILD_CHARGES = gql`
    mutation($charges: [chargesSP]) {
        build_charges(charges: $charges) {
            id
            amount  
            completed
            name
            base_amount
            tax_amount
            count
            payment_id
            ticket_id
            service_id
        }
    }
`;

const TicketPickerModal = ({onCloseClicked, onTickedAdded, service_group_id = null, editing = false}) => {

    const [loadingGroups, setLoadingGroups] = useState(false);
    const [groups, setGroups] = useState<any[]>([]);
    const [group, setGroup] = useState(null);

    const [sgMenu, setSGMenu] = useState({index: -1});

    const modalRef = useRef();

    enum Screens {
        ServicesSelection,
        TypeSelection,
        AddCustomService,
        AddServiceGroup
    }

    const [currentScreen, setCurrentScreen] = useState(Screens.ServicesSelection);


    const [ticketSelection, setTicketSelection] = useState<TicketSelection[]>([]);

    const [servicesCharges, setServicesCharges] = useState<Service_charge[]>([]);

    const [items, setItems] = useState<service_selectionSPRes[]>([]);

    const [anchorPoint, setAnchorPoint] = useState({x: 0, y: 0});

    const interval = useRef<any>(null);


    const [service, setService] = useState(new TicketSelection());
    const [sGroup, setSGroup] = useState({id: -1, name: ''});



    const onTouchStart = (evt: React.TouchEvent<any>, idx: number) => {

        interval.current = setInterval(() => {

            setAnchorPoint({x: evt.touches[0].clientX, y: evt.touches[0].clientY});
            setSGMenu({index: idx});
            clearInterval(interval.current);
        }, 500)
    }


    const onTouchEnd = (evt: any) => {
        clearInterval(interval.current);
    }

    const onContextMenuHandled = (event, index) => {
        event.preventDefault();
        setAnchorPoint({ x: event.clientX, y: event.clientY });
        setSGMenu({index});
        
        
    }


    async function loadGroups() {
        try {
            setLoadingGroups(true);
            let data = await GraphqlService.SendQuery(GET_SERVICES_QUERY);

            setGroups(data);
            setLoadingGroups(false);

            if (service_group_id)
            {
                
                let g = data.find(x => x.id == service_group_id);
                handleGroupClicked(g, []);
            }
        } catch (ex) {
            setLoadingGroups(false);
        }
    }

    function getIcon(icon: string) {

        return ICONS[icon] || ICONS.IconDots;
    }

    async function handleGroupClicked(group, charges) {
        setCurrentScreen(Screens.TypeSelection);
        try {
            setGroup(group);
            setLoadingGroups(true);
            setItems([]);
            let data = await Query.get_service_selection({servicegroupid: group.id, selection: charges});

            setItems(data);
            setTicketSelection(data);
            setLoadingGroups(false);
            
            
        } catch (ex) {
            console.log('ex', ex.message);
            setLoadingGroups(false);
        }
    }

    async function handleAddCustomCharge(evt) {
        setSGroup({id: -1, name: ''});
        setService(new TicketSelection());
        setCurrentScreen(Screens.ServicesSelection == currentScreen ? Screens.AddServiceGroup : Screens.AddCustomService);
    }


    function renderModalContent()
    {
        switch (currentScreen)
        {
            case Screens.ServicesSelection:
                return (<><div className='d-flex justify-content-between flex-wrap'>
                          {groups.map((g, index) => 
                            <TicketCell className={'mb-3 ' + (sgMenu.index == index ? 'selected' : '')} onClick={(evt) => handleGroupClicked(g, [])}
                            onTouchStart={(evt) => onTouchStart(evt, index)}
                            onTouchEnd={onTouchEnd}
                            onContextMenu={(e) => onContextMenuHandled(e, index) }>
                                <img src={getIcon(g.icon)} alt=""/>
                                <h4 className='form-title mt-3 mb-0 text-center'>{g.name}</h4>
                            </TicketCell>)}
                            {(Math.ceil(groups.length / 3) * 3) - groups.length == 1 && <div  style={{width: 'calc(33.33% - .5rem)'}}  />}
                        </div>
                            
                                <ControlledMenu className='submenu' anchorPoint={anchorPoint} boundingBoxRef={modalRef} onClose={(evt) => setSGMenu({index: -1})} isOpen={sgMenu.index != -1}>
                                    <MenuItem onClick={(evt) => {
                                        
                                        setSGroup({id: groups[sgMenu.index].id, name: groups[sgMenu.index].name});
                                        setCurrentScreen(Screens.AddServiceGroup)
                                    }}><img className='mr-3' style={{width: '1rem'}} src={ICONS.IconPencilEdit} alt=""/> Edit Group "{groups[sgMenu.index]?.name}"</MenuItem>
                                    <MenuItem onClick={(evt) => {
                                        let id = groups[sgMenu.index]?.id;
                                        Swal.fire({
                                            title: 'Are you sure',
                                            text: `Are you sure you want to delete service group "${groups[sgMenu.index]?.name}"`,
                                            icon: 'question',
                                            showConfirmButton: true,
                                            confirmButtonText: 'Yes',
                                            showCancelButton: true,
                                            cancelButtonText: 'No'
                                        }).then(async result => {
                                            if (result.isConfirmed)
                                            {
                                                try {
                                                    await GraphqlService.SendMutation(gql`mutation ($group_id: Int){
                                                        delete_service_group(group_id:$group_id)
                                                      }`, {group_id: id});
                                                    loadGroups();
                                                    Swal.fire({
                                                        text: 'Service group has been deleted',
                                                        title: 'Success',
                                                        icon: 'success'
                                                    });
                                                } catch (ex) {
                                                    Swal.fire({
                                                        text: ex.message,
                                                        title: 'Error',
                                                        icon: 'error'
                                                    });
                                                }
                                            }
                                        })
                                    }}><img className='mr-3' style={{width: '1rem'}} src={ICONS.IconDelete} alt=""/> Delete Group "{groups[sgMenu.index]?.name}"</MenuItem>
                                </ControlledMenu>   
                        </>
                        )
            
            case Screens.TypeSelection:
                return (
                    <TypePicker
                        editing={editing}
                        onEditItem={(item)=>{
                            setService(new TicketSelection(item));
                            setCurrentScreen(Screens.AddCustomService)
                            
                        }
                        }
                        onDeleteItem={(item)=>{
                            
                            let id = item?.id;
                            let name = item?.name;
                            Swal.fire({
                                title: 'Are you sure',
                                text: `Are you sure you want to delete service "${name}"`,
                                icon: 'question',
                                showConfirmButton: true,
                                confirmButtonText: 'Yes',
                                showCancelButton: true,
                                cancelButtonText: 'No'
                            }).then(async result => {
                                if (result.isConfirmed)
                                {
                                    try {
                                        await GraphqlService.SendMutation(gql`mutation($serviceId:Int){
                                            delete_service(serviceId:$serviceId)
                                          }`, {serviceId: id});
                                        handleGroupClicked(group, []);
                                        Swal.fire({
                                            text: 'Service has been deleted',
                                            title: 'Success',
                                            icon: 'success'
                                        });
                                    } catch (ex) {
                                        Swal.fire({
                                            text: ex.message,
                                            title: 'Error',
                                            icon: 'error'
                                        });
                                    }
                                }
                            })
                        }}
                        onItemSelection={(evt) => {
                        setTicketSelection(evt);
                        setItems(items);
                    }} selectedItems={items} onSaveItem={(evt) => false } onShowItemForm={(evt) => false} />
                )
            case Screens.AddCustomService:
                return (<ServiceForm services={items.map(x => x.name.toLowerCase()).removeBy(z => z == service?.name)} editing={editing} service={service} service_group_id={group?.id} selection={ticketSelection} onCancelClicked={(evt) => {
                    setCurrentScreen(Screens.TypeSelection);
                    setTicketSelection(evt);
                    setItems(evt);
                    
                }} onNewServiceSubmitted={(evt) => {
                    setItems(evt);
                    setTicketSelection(evt);
                    setCurrentScreen(Screens.TypeSelection);
                }} />)
            
            case Screens.AddServiceGroup:
                return (<ServiceGroupForm serviceGroup={sGroup}
                    onSubmit={(sg) => {
                        setCurrentScreen(Screens.ServicesSelection);
                        setSGroup({id: -1, name: ''});

                        loadGroups();
                    }}
                    onCancelClicked={(evt) => {
                    setSGroup({id: -1, name: ''});
                    setCurrentScreen(Screens.ServicesSelection)
                }} />)
        
        }
    }

    const getModalTitle = () => {
        switch (currentScreen)
        {
            case Screens.ServicesSelection:
                return "Please Choose one service group"

            case Screens.TypeSelection:
                return "Please choose your types"
            
            case Screens.AddCustomService:
                return "Service Form"

            case Screens.AddServiceGroup:
                    return "Service Group Form"
        }
    }

    useEffect(() => {
        loadGroups();
        if (service_group_id)
            setCurrentScreen(Screens.TypeSelection);
    }, []);
    return (
        <div className='modal'>
            <div className="modal-content col-8" ref={modalRef}>
                <div className="sticky-header pb-3">
                        <div className='sticky-header align-items-center pb-3 d-flex justify-content-between'>
                            <h6 className='form-title m-0 fw-600'>{getModalTitle()}</h6>
                            {(Screens.AddCustomService != currentScreen && Screens.AddServiceGroup != currentScreen) && <button onClick={(evt) => handleAddCustomCharge(evt)} className="btn btn-orange">{Screens.TypeSelection == currentScreen ? 'Add new charge' : 'Add new group'}</button>}
                        </div>
                    </div>
                    <div style={{maxHeight: '60vh', minHeight: '60vh', overflow: 'auto'}}>
                        {loadingGroups && <Loading />}
                        {renderModalContent()}
                    </div>
                    <div className="d-flex justify-content-between pt-3">
                        {(currentScreen != Screens.AddCustomService && currentScreen != Screens.AddServiceGroup) && <div className={"pl-0 " + (currentScreen == Screens.TypeSelection ? 'col-6' : 'col-12')}><button className="btn btn-clear col-12" onClick={(evt) => {

                            if (currentScreen == Screens.ServicesSelection || service_group_id != null)
                                onCloseClicked(evt);
                            else
                                setCurrentScreen(Screens.ServicesSelection);
                        }}>{currentScreen == Screens.ServicesSelection || service_group_id != null ? 'Cancel' : 'Go Back'}</button></div>}
                        {currentScreen == Screens.TypeSelection && <div className="col-6 pr-0"><button className="btn btn-orange col-12" onClick={async (evt) => {
                            
                            try {
                                let data = await GraphqlService.SendMutation(BUILD_CHARGES, {charges: ticketSelection.filter(x => x.count > 0).map((x: any) => {
                                    delete x.__typename;
                                    return x;
                                })});

                                
                                let t = new Ticket();
                                t.service_charges = data;
                                t.name = group?.name;   
                                t.service_group_id = group?.id;
                                
                                onTickedAdded(t);
                            } catch (ex) {
                                console.log('ex', ex.message);
                            }
                    }} disabled={ticketSelection.filter(x => x.count > 0).length == 0}>Save</button></div>}
                    
                </div>
                        
            </div>
            
        </div>
    )
}

export default TicketPickerModal;
