import Services from "./Services/Services";
import Accounts from "./Accounts/Accounts";
import Helper from "../../Helper/Helper";
import Taxes from "./Taxes/Taxes";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getSettingsAction } from "../../redux/actions/settings.action";
import LoadSpinner from "../../components/LoadSpinner/LoadSpinner";
import Loading from "../../components/Loading/Loading";
import gql from "graphql-tag";
import { GraphqlService } from "../../services/graphql.service";
import { Link } from "react-router-dom";
import TicketPickerModal from "../OverviewPage/TicketPickerModal/TicketPickerModal";

const SettingPage = () => {

    const dispatch = useDispatch();
    const settings = useSelector<any>(state => state.settings.settings) as any;
    const loading = useSelector<any>(state => state.settings.loading) as any;
    const [tempSettings, setTempSettings] = useState<any>({});
    const [modalOpen, setModalOpen] = useState(0);
    const [sending, setSending] = useState(false);
    const [tpShown, setTPShown] = useState(false);


    async function handleSend(evt) {
        try {

            setSending(true);
            let mutation = gql`mutation ($settings:[settingInput]){
                update_settings(settings: $settings)
              }`;

            // const variables = modalOpen == 1 ? [{key: 'business_name', value: tempSettings.business_name}] : [{key: 'invoice_text', value: tempSettings.invoice_text}, {key: 'receipt_text', value: tempSettings.receipt_text}]

            const variables = Object.keys(tempSettings).map(key => ({key, value: tempSettings[key]}));

            await GraphqlService.SendMutation(mutation, {settings: variables});

            setModalOpen(0);
            setSending(false);
        } catch (ex) {
            console.log('ex', ex.message);
            setSending(false);
        }

    }
    useEffect(() => {
        dispatch(getSettingsAction());
    }, []);
    return (
        <>
        {tpShown && <TicketPickerModal editing={true} onCloseClicked={(evt) => setTPShown(false)} onTickedAdded={(ticket) => {
                setTPShown(false);
            }} />}
            {modalOpen != 0 && <div className="modal">
                <div className="modal-content col-8">
                    <h4 className="text-title mb-3">Update {modalOpen == 1 ? 'Business Name' : 'Preset Text'}</h4>

                    {modalOpen == 1 && <div className='form-group mb-3'>
                        <label htmlFor="business_name">Business Name*</label>
                        <input value={tempSettings.business_name} onChange={(evt) => setTempSettings({...tempSettings, business_name: evt.target.value})} placeholder='Invoice text...' name="business_name" id="invoice_text" className="form-field" />
                    </div>}
                    
                    {modalOpen == 2 && 
                    <>
                        <div className='form-group mb-3'>
                            <label htmlFor="invoice_text">Invoice*</label>
                            <textarea value={tempSettings.invoice_text} onChange={(evt) => setTempSettings({...tempSettings, invoice_text: evt.target.value})} placeholder='Invoice text...' name="invoice_text" id="invoice_text" className="form-field"></textarea>
                        </div>

                        <div className='form-group mb-3'>
                            <label htmlFor="receipt_text">Receipt*</label>
                            <textarea value={tempSettings.receipt_text} onChange={(evt) => setTempSettings({...tempSettings, receipt_text: evt.target.value})} placeholder='Receipt text...' name="receipt_text" id="receipt_text" className="form-field"></textarea>
                        </div>
                    </>}

                    {sending ? <Loading/> :  <div className="d-flex justify-content-between">
                        <div className='col-6 pr-2'>
                            <button onClick={(evt) => setModalOpen(0)} className="btn w-100">Cancel</button>
                        </div>
                        <div className='col-6 pl-2'>
                            <button onClick={handleSend} className="btn btn-orange w-100">Update</button>
                        </div>
                    </div>}

                </div>
            </div>}
            <div className="flex-1-container">
                <div className="d-flex justify-content-between col-12 mb-3">
                        
                    <h4 className='header-title'>Settings</h4>
                    <Link className="btn btn-orange px-5" to='/new-order'>Create new order</Link>
                </div>
                <div className="flex-1-container row-container">
                    <div className="col-4 d-flex mr-3">
                       <div className='flex-1-container'>
                       <div className="card mb-3">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h6 className="title-text fw-400">Business name</h6>
                                    
                                    <button disabled={loading} className="btn btn-orange-light" onClick={(evt) => {
                                        setTempSettings({
                                            business_name: settings?.find(x => x.key == 'business_name')?.value || ''
                                        });
                                        setModalOpen(1);
                                    }}>Edit</button>
                                </div>
                                {loading && <Loading />}

                                <h6 className="title-text fw-700 mb-2">{settings?.find(x => x.key == 'business_name')?.value}</h6>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h6 className="title-text fw-400">Preset texts</h6>
                                    <button disabled={loading} className="btn btn-orange-light" onClick={(evt) => {
                                        setTempSettings({
                                            invoice_text: settings?.find(x => x.key == 'invoice_text')?.value || '',
                                            receipt_text: settings?.find(x => x.key == 'receipt_text')?.value || ''
                                        });
                                        setModalOpen(2);
                                    }}>Edit</button>
                                </div>

                                <h6 className="font-14 mb-2 fw-500">Invoice</h6>
                                {loading && <Loading />}
                                <p className={"fw-600 font-16 mb-3 " + (settings?.find(x => x.key == 'invoice_text')?.value ? '' : 'text-gray')}>
                                    {settings?.find(x => x.key == 'invoice_text')?.value || 'Empty'}
                                </p>
                                

                                <h6 className="font-14 mb-2 fw-500">Receipt</h6>
                                {loading && <Loading />}
                                <p className={"fw-600 font-16 mb-3 " + (settings?.find(x => x.key == 'receipt_text')?.value ? '' : 'text-gray')}>
                                    {settings?.find(x => x.key == 'receipt_text')?.value || 'Empty'}
                                </p>

                            </div>
                        </div>
                        <button onClick={(evt) => setTPShown(true)} className="btn btn-blue w-100 mb-3">UPDATE SERVICES GROUP</button>
                        <button onClick={(evt) => Helper.Session.DoLogout()} className="btn btn-danger w-100">LOG OUT</button>
                       </div>
                    </div>
                    <div className="col-8 d-flex">
                       <div className="flex-1-container col-12">
                           <div className='flex-1-container'>
                           <Accounts />
                           </div>
                           <div className="flex-1-container mt-3">

                                <Taxes />
                           </div>
                       </div>
                </div>
              
            </div>
            </div>

        
        </>
    );
}

export default SettingPage;
