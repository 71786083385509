import Table from "../../components/Table/Table";
import IColumn from "../../components/Table/models/IColumn";
import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import Searchbar from "../../components/Searchbar/Searchbar";
import Helper from "../../Helper/Helper";
import IMenuOption from "../../components/Table/models/IMenuOption";
import InvoicesTable from "./InvoicesTable/InvoicesTable";
import PaymentsTable from "./PaymentsTable/PaymentsTable";
import CustomersTable from "./CustomersTable/CustomersTable";
import ICONS from "../../../assets/svg";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import moment from "moment";


const AccountingPage = () => {

    enum Screens {
        Invoices,
        Payments,
        Customers
    }
    const [start, setStart] = useState(new Date(moment().add(-1, 'month').format('YYYY/MM/DD 00:00:00')));
    const [end, setEnd] = useState(new Date(moment().format('YYYY/MM/DD 23:59:59')))
    const [dateOpen, setDateOpen] = useState(false);

    const [currentScreen, setCurrentScreen] = useState(Screens.Invoices);

    async function loadData(event) {
        event.complete();
    }


    const renderView = () => {
        switch (currentScreen)
        {
            case Screens.Invoices:
                return <><InvoicesTable dates={[start, end]} /></>
            case Screens.Payments:
                return <><PaymentsTable dates={[start, end]} /></>
             case Screens.Customers:
                return <><CustomersTable/></>
        }
    }
    const ref = useRef();
    return (
        <>
            <div className="px-0 flex-1-container">
                <div className="d-flex justify-content-between col-12">
                    
                    <h4 className='header-title'>Accounting</h4>
                    <Link className="btn btn-orange px-5" to='/new-order'>Create new order</Link>
                </div>
                <div className="d-flex justify-content-between align-items-center my-3">
                    <nav className="App-header pb-0" style={{width: 'auto'}}>
                        <ul className='pt-0'>
                            <li>
                                <a onClick={(evt) => setCurrentScreen(Screens.Invoices)} className={currentScreen == Screens.Invoices ? 'active' : ''}>INVOICES</a>
                            </li>
                            <li>
                                <a onClick={(evt) => setCurrentScreen(Screens.Payments)} className={currentScreen == Screens.Payments ? 'active' : ''}>PAYMENTS</a>
                            </li>
                            <li>
                                <a onClick={(evt) => setCurrentScreen(Screens.Customers)} className={currentScreen == Screens.Customers ? 'active' : ''}>CUSTOMERS</a>
                            </li>
                        </ul>
                    </nav>

                    <div className="col-6 d-flex justify-content-end">
                    <div className="mr-3">
                            <button style={{minWidth: 'max-content'}} className="btn btn-orange-outline d-flex" onClick={(evt) => setDateOpen(true)}>
                                <i className="fa fa-calendar mr-2"></i> 
                                {moment(start).format('YYYY/MM/DD') == moment(end).format('YYYY/MM/DD') ? 
                                moment(start).format('MM/DD/YYYY')    
                                :
                                moment(start).format('MM/DD/YYYY')    + ' - ' + moment(end).format('MM/DD/YYYY')    
                            }
                            </button>
                        </div>
                            <div className='col-8'>
                                <Searchbar background='#FFF' reference={ref} />
                            </div>
                            
                            <button className="btn ml-3" style={{background: '#FFF', width: '2rem', height: '2rem', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <img src={ICONS.IconRefresh} alt=""/>
                            </button>
                            
                        </div>
                </div>

                <div className="flex-1-container bg-white rounded-card p-3">
                    <div className="flex-1-container">

                    {renderView()}
                    </div>
                {/* {renderView()} */}
                </div>
            </div>


            {dateOpen && <DateTimePicker
        hideTime
        onCancelClicked={(evt) => {
            setDateOpen(false)
        }}
        date={[start, end]} onDateSubmitted={evt => {
            evt[0] = new Date(moment(evt[0]).format('YYYY/MM/DD') + ' 00:00:00');
            evt[1] = new Date(moment(evt[1]).format('YYYY/MM/DD') + ' 23:59:59');
            
            setStart(evt[0]);
            setEnd(evt[1]);

            setDateOpen(false)
        }} />}

            
        </>
    );
}

export default AccountingPage;
