import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Agenda } from '../../classes/classes';
import Loading from '../Loading/Loading';

import './AgendaTable.scss';

interface IAgendaTable
{
    agendas: Agenda[],
    currentDate: moment.Moment,
    onMonthChange: (date: moment.Moment) => void,
    loading: boolean,
    onAgendaSelected: (agenda: Agenda[]) => void
}

const AgendaTable = ({agendas, currentDate, onMonthChange, loading, onAgendaSelected}: IAgendaTable) => {

    const mymonth = useRef<string>();
    function addMonth(month) {
        let c = currentDate.clone();
        c.add(month, 'month');
        onMonthChange(c);
        setMyAgenda([]);
        onAgendaSelected([]);
    }
    const dispatch = useDispatch();
    

    const [myAgenda, setMyAgenda] = useState<any[]>([]);

    const [_selected, setSelected] = useState<Agenda[]>([]);

    const selected = useSelector<any>(state => state.agendas.agendaSelected) as Agenda[];

    useEffect(() => {
        // console.log(_selected);
        if (!loading && selected.length > 0)
            select(selected)
    }, [loading]);

    const renderColumn = (agenda: Agenda) => {

        let finished = agenda.tickets.filter(x => x.status?.toUpperCase() == 'FINISHED').length;
        let received = agenda.tickets.filter(x => x.status?.toUpperCase() == 'RECEIVED').length;
        let in_progress = agenda.tickets.filter(x => x.status?.toUpperCase() == 'IN PROGRESS').length;
        let picked_up = agenda.tickets.filter(x => x.status?.toUpperCase() == 'PICKED UP').length;

        const isCurrent = () => currentDate.format('MM/YYYY') == moment(agenda.start_of_day).format('MM/YYYY');
        const isCurrentDay = () => moment().format('DD/MM/YYYY') == moment(agenda.start_of_day).format('DD/MM/YYYY');
        return (<td onClick={(evt) => {

           
            select(agenda);
            
        }} className={(!isCurrent() ? 'not-current' : '') + (selected.some(x => x.start_of_day == agenda.start_of_day) ? ' selected' : '')}>
            <h4 className="small-text text-center fw-bold" style={{fontSize: '12px'}}><span className={isCurrentDay() && 'current-day'}>{moment(agenda.start_of_day).format('DD')}</span></h4>

            <h4 className="title-text text-center" style={{margin: '11px 0 9px 0', fontSize: '16px'}}>{agenda.tickets.length || '-'}</h4>


            <h4 className="small-text" style={{fontSize: '12px'}}><span style={{display: 'inline-block', width: '16px'}}>{received || '-'}</span><span style={{fontSize: '10px', marginLeft: '0'}} className='tiny-text fw-600 d-inline-block'>Received</span></h4>
            <h4 className="small-text" style={{fontSize: '12px'}}><span style={{display: 'inline-block', width: '16px'}}>{in_progress || '-'}</span><span style={{fontSize: '10px', marginLeft: '0'}} className='tiny-text fw-600 d-inline-block'>In progress</span></h4>
            <h4 className="small-text" style={{fontSize: '12px'}}><span style={{display: 'inline-block', width: '16px'}}>{finished || '-'}</span><span style={{fontSize: '10px', marginLeft: '0'}} className='tiny-text fw-600 d-inline-block'>Finished</span></h4>
            <h4 className="small-text" style={{fontSize: '12px'}}><span style={{display: 'inline-block', width: '16px'}}>{picked_up || '-'}</span><span style={{fontSize: '10px', marginLeft: '0'}} className='tiny-text fw-600 d-inline-block'>Picked up</span></h4>
        </td>)
    }

    function select(agenda) {


        const isCurrent = () => currentDate.format('MM/YYYY') == moment(agenda.start_of_day).format('MM/YYYY');
        let temp = selected.removeBy(x => x.start_of_day == agenda.start_of_day)

            
        let isvalid = isCurrent();
        
        if (temp.length == selected.length && isvalid)
            temp = [agenda];
        else if (!isvalid)
        {
            return setSelected(temp);
        }
        

        setSelected(temp);
        onAgendaSelected(temp);
    }

    useEffect(() =>{
        let temp = [];
        agendas.forEach((agenda, i) => {
            if (i % 7 == 0 || i == 0)
                temp.push([agenda]);
            else
                temp[Math.trunc(i / 7)].push(agenda)
        });

        
        setMyAgenda(temp);
        
        if (moment().format('MM/YYYY') == currentDate.format('MM/YYYY'))
        {

            if (selected.length == 0)
            {
                
                let a = agendas.find(x => moment(x.start_of_day).format('MM/DD/YYYY') == moment().format('MM/DD/YYYY'));
                
                
                if (a)
                    select(a);
                else
                    setSelected([])
            }
        }
        else if (mymonth.current == currentDate.format('MM/YYYY'))
        {   
            
           
                let temp2 = [...selected].map(x => {
                    x.tickets = agendas.find(y => y.start_of_day == x.start_of_day)?.tickets || x.tickets;
                    if (!selected)
                    {
                        select(x);
                    }
                    return x;
                }) // [...temp].flatMap(x => x.tickets = selected.find(y => y.start_of_day == x.start_of_day)?.tickets || x.tickets);//.map(x => x.tickets = selected.find(y => y.start_of_day == x.start_of_day)?.tickets || x.tickets)
                setSelected(temp2);
                onAgendaSelected(temp2);
            

        }
        else
            setSelected([]);


        mymonth.current = currentDate.format('MM/YYYY');

    }, [agendas]);

    return (
        <div className="agenda-component p-0">
            <div className='pt-3' style={{position: 'sticky', top: '0', background: '#FFF', zIndex: 1}}>
                <h4 className="title-text m-0 text-center">{currentDate.format('MMMM YYYY')}</h4>
                <div className='d-flex position-absolute' style={{right: '0', top: '50%', transform: 'translateY(-50%)'}}>
                    <button className="btn btn-clear" onClick={(evt) => addMonth(-1)}><i className="fas fa-chevron-left"></i></button>
                    <button className="btn btn-clear" onClick={(evt) => addMonth(1)}><i className="fas fa-chevron-right"></i></button>
                </div>
            </div>

            <table cellSpacing='0'>
                <thead>
                    <tr>
                        <th>S</th>
                        <th>M</th>
                        <th>T</th>
                        <th>W</th>
                        <th>T</th>
                        <th>F</th>
                        <th>S</th>
                    </tr>
                </thead>
                <tbody>
                    {loading && <tr>
                        <th colSpan={7}>
                            <Loading />
                        </th>
                    </tr>}
                    {myAgenda.map(r => <tr>
                        {r.map(c => renderColumn(c))}
                        
                    </tr>)}
                </tbody>
            </table>
            
        
        </div>
    )
}

export default AgendaTable;