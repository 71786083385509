import { all, call } from 'redux-saga/effects';

import customerSagas from "./Customer/customer.saga";
import invoiceSagas from "./Invoice/invoice.saga";
import serviceSagas from "./Service/service.saga";
import userSagas from "./User/user.saga";
import paymentsSagas from "./Payments/payments.saga";
import orderSagas from "./Orders/orders.saga";
import taxSagas from "./Tax/tax.saga";
import typePickerSagas from "../components/TypePicker/state/saga";

export default function* rootSaga() {
    yield all([
        call(customerSagas),
        call(invoiceSagas),
        call(paymentsSagas),
        call(serviceSagas),
        call(userSagas),
        call(taxSagas),
        call(orderSagas),
        call(typePickerSagas),
    ])
}
