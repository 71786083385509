import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.scss';
import "@szhsin/react-menu/dist/index.css";
import { Link, NavLink, Route, Router, Switch, useHistory, useLocation } from 'react-router-dom';
import MainPage from './app/pages/MainPage/MainPage';
import LoginPage from './app/pages/LoginPage/LoginPage';
import Helper from './app/Helper/Helper';
import ReactModal from 'react-modal';
import PayCharge from './app/pages/PayCharge/PayCharge';
function App() {
    const history = useHistory();
    // history.location.pathname;
    Helper.SetNavigator(history);

    useEffect(() => {

        ReactModal.setAppElement('body');
    }, []);


    return (
        <Switch>
            <Route path={'/invoice/:token'} component={PayCharge} />
            <Route path='/login' component={LoginPage}/>
            <Route path='/404' component={() => (<h1>404</h1>)}/>
            <Route path="/" component={MainPage} />
        </Switch>

    );
}

export default App;
