import {GraphqlService, ObservableQueryT} from "../../services/graphql.service";
import {GET_CUSTOMER_BY_ID, GET_CUSTOMERS, GET_CUSTOMERS_TABLE} from "../../graphql/query/Customer";
import {Customer} from "../../classes/classes";
import {CREATE_CUSTOMER, UPDATE_CUSTOMER} from "../../graphql/mutation/customers";

let customersQuery: ObservableQueryT;
let customerQuery: ObservableQueryT;

export const handleFetchCustomers = () => {
    return new Promise(async (resolve, reject) => {
        if (!customersQuery) {
            customersQuery = GraphqlService.SendQueryObservable(GET_CUSTOMERS);
            customersQuery.onResults.subscribe(result => {
                if (result.error)
                    reject(result.error.message);
                else
                    resolve(result.data);
            });
        } else {
            customersQuery.refetch();
            customersQuery.onResults.subscribe(result => {
                if (result.error)
                    reject(result.error.message);
                else
                    resolve(result.data);
            });
        }
    });
};

export const handleFetchCustomerTable = () => {
    return new Promise(async (resolve, reject) => {
        if (!customersQuery) {
            customersQuery = GraphqlService.SendQueryObservable(GET_CUSTOMERS_TABLE);
            customersQuery.onResults.subscribe(result => {
                if (result.error)
                    reject(result.error.message);
                else
                    resolve(result.data || []);
            });
        } else {
            customersQuery.refetch();
            customersQuery.onResults.subscribe(result => {
                if (result.error)
                    reject(result.error.message);
                else
                    resolve(result.data || []);
            });
        }
    });
};

export const handleFetchCustomerTableByID = (customerId: number = null) => {
    return new Promise(async (resolve, reject) => {
        if (!customersQuery) {
            customersQuery = GraphqlService.SendQueryObservable(GET_CUSTOMERS_TABLE, {customerId});
            customersQuery.onResults.subscribe(result => {
                if (result.error)
                    reject(result.error.message);
                else
                    resolve(result.data);
            });
        } else {
            customersQuery.refetch();
            customersQuery.onResults.subscribe(result => {
                if (result.error)
                    reject(result.error.message);
                else
                    resolve(result.data);
            });
        }
    });
};

export const handleGetCustomerById = (customerId) => {
    return new Promise(async (resolve, reject) => {
        customerQuery = GraphqlService.SendQueryObservable(GET_CUSTOMER_BY_ID, {customerId});
        customerQuery.onResults.subscribe(result => {
            if (result.error)
                reject(result.error.message);
            else
                resolve(result.data);
        });
    });
}

export const handleAddCustomer = (customerData: Customer) => {
    return new Promise(async (resolve, reject) => {
        try {
            const {name, phone, note, email} = customerData;
            const result = await GraphqlService.SendMutation(CREATE_CUSTOMER, {name, phone, note, email});
            
            resolve(result)
        } catch (ex) {
            console.log('ex', ex.message);
            reject(ex);
        }
    });
};

export const handleUpdateCustomer = (customerData: Customer) => {
    return new Promise(async (resolve, reject) => {
        try {
            const {id, name, phone, note, email} = customerData;
            const result = await GraphqlService.SendMutation(UPDATE_CUSTOMER, {id, name, phone, note, email});
            
            resolve(result)
        } catch (ex) {
            console.log('ex', ex.message);
            reject(ex);
        }
    });
};
