import gql from "graphql-tag";
import { GET_DAILY_REPORT } from "../../graphql/query/DailyReport";
import { GraphqlService, ObservableQueryT } from "../../services/graphql.service";
import { GET_DAILY_REPORT_ERROR, GET_DAILY_REPORT_STARTED, GET_DAILY_REPORT_SUCCESS, GET_PICKUPS_REPORT_ERROR, GET_PICKUPS_REPORT_STARTED, GET_PICKUPS_REPORT_SUCCESS, GET_TAKEINS_REPORT_ERROR, GET_TAKEINS_REPORT_STARTED, GET_TAKEINS_REPORT_SUCCESS } from "../types/dailyReport.type"

let dailyReportQuery: ObservableQueryT;

export function getDailyReportAction(beginingOfDay)
{
    
    return dispatch => {

        dispatch(getDailyReportStarted(beginingOfDay));
        if (!dailyReportQuery)
        {
            dailyReportQuery = GraphqlService.SendQueryObservable(GET_DAILY_REPORT, {beginingOfDay});

            dailyReportQuery.onResults.subscribe(result => {
                if (result.error)
                    return dispatch(getDailyReportError(result.error.message));

                dispatch(getDailyReportSuccess(result.data));
            
            });
        }
        else
            dailyReportQuery.refetch({beginingOfDay});
    }   
}

const getDailyReportStarted = (beginingOfDay) => ({type: GET_DAILY_REPORT_STARTED, payload: beginingOfDay});
const getDailyReportSuccess = (reports: any[]) => ({type: GET_DAILY_REPORT_SUCCESS, payload: reports});
const getDailyReportError = (error: string) => ({type: GET_DAILY_REPORT_ERROR, payload: error});





const ORDER_QUERY = gql`
    query($startday: String, $pickedUp:Boolean) {
  get_takeIn_orders(startday: $startday, pickedUp:$pickedUp) {
    charge
    balance
    tickets
    order_id
    payments
    ordered_on
    pickup_date
    order_number
    customer_name
    customer_email
    customer_phone
  }
}
`;

let takeinsReportQuery: ObservableQueryT;

export function getTakeInsReportAction(startday)
{
    
    return dispatch => {

        dispatch(getTakeinsReportStarted({startday, pickedUp: false}));
        if (!takeinsReportQuery)
        {
            takeinsReportQuery = GraphqlService.SendQueryObservable(ORDER_QUERY, {startday, pickedUp: false});

            takeinsReportQuery.onResults.subscribe(result => {
                if (result.error)
                    return dispatch(getTakeinsReportError(result.error.message));

                dispatch(getTakeinsReportSuccess(result.data));
            
            });
        }
        else
            takeinsReportQuery.refetch({startday, pickedUp: false});
    }   
}

const getTakeinsReportStarted = (beginingOfDay) => ({type: GET_TAKEINS_REPORT_STARTED, payload: beginingOfDay});
const getTakeinsReportSuccess = (reports: any[]) => ({type: GET_TAKEINS_REPORT_SUCCESS, payload: reports});
const getTakeinsReportError = (error: string) => ({type: GET_TAKEINS_REPORT_ERROR, payload: error});




let pickupsReportQuery: ObservableQueryT;

const PICKEDUP_TICKETS = gql`
query($startday: String!){
    tickets_pickedup_by_date(startday: $startday){
        label
        status{
            text
            fill_color
            text_color
        }
        charges
        ticket_id
        pickedup_date
        customer_name
        customer_email
        customer_phone
        order_number
        ticket_name
        ticket_number
        charge
    }
}
`;

export function getPickupsReportAction(startday)
{
    
    return dispatch => {

        dispatch(getPickupsReportStarted({startday, pickedUp: true}));
        if (!pickupsReportQuery)
        {
            pickupsReportQuery = GraphqlService.SendQueryObservable(PICKEDUP_TICKETS, {startday});

            pickupsReportQuery.onResults.subscribe(result => {
                if (result.error)
                    return dispatch(getPickupsReportError(result.error.message));

                dispatch(getPickupsReportSuccess(result.data));
            
            });
        }
        else
            pickupsReportQuery.refetch({startday, pickedUp: true});
    }   
}

const getPickupsReportStarted = (beginingOfDay) => ({type: GET_PICKUPS_REPORT_STARTED, payload: beginingOfDay});
const getPickupsReportSuccess = (reports: any[]) => ({type: GET_PICKUPS_REPORT_SUCCESS, payload: reports});
const getPickupsReportError = (error: string) => ({type: GET_PICKUPS_REPORT_ERROR, payload: error});
