import { GET_SETTINGS_ERROR, GET_SETTINGS_STARTED, GET_SETTINGS_SUCCESS } from "../types/settings.type"
import IReducerAction from "../models/IReducerAction"

export {}


interface IState
{
    settings: any,
    loading: boolean,
    error: string
}

const initialState: IState = {
    settings: [],
    loading: false,
    error: null
}


export default function settings(state: IState = initialState, action: IReducerAction): IState
{
    switch (action.type)
    {
        case GET_SETTINGS_STARTED:
            return {
                ...state,
                loading: true
            }

        case GET_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                settings: action.payload
            }

        case GET_SETTINGS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default: return state;
    }
}