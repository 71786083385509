import EscPosEncoder from 'esc-pos-encoder';
import { link } from 'fs';
import moment from 'moment';
import helper from '../Helper/Helper';
import paidImageSource from './images/paidImage.jpg'
import notPaidImageSource from './images/notPaidImage.jpeg'


enum aligment {left, center, right};
let openRegister = new Uint8Array([27, 112, 0, 25, 250]);
let starPrinter = null;
let paidImage = null;
let notPaidImage = null;

export class  Item{
    name: string;
    base_amount?: number;
    tax_amount?: number;
    count?: number;
    amount: number;
    id: number;
    ticket_id: number;

    constructor(){
        this.name = '';
        this.base_amount = 0;
        this.tax_amount = 0;
        this.count = 1;
        this.amount = 0;
        this.id = -1;
        this.ticket_id = -1;
    }
}
export class  GenericItem{
    firstLine: string = '';
    secondLine: string = '';
    rightLine: string = '';

    constructor(x: any){
        this.firstLine = x.firstLine || '';
        this.secondLine = x.secondLine || '';
        this.rightLine = x.rightLine || '';
    }
}

export class  DailyItem{
    time: string;
    type: string;
    customer_name: string;
    amount: string;
    status: string;
    payment_id: number;
    order_number: string;
    payment_number: string;
    transaction_number: string;

    constructor(){
        this.time = new Date().toString();
        this.type = '';
        this.amount = '';
        this.customer_name = '';
        this.status = '';
        this.payment_id = -1;
        this.order_number = '';
        this.payment_number = '';
        this.transaction_number = '';
    }
}
export class  Header{
    name: string;
    street: string;
    city_state: string;
    contact: string;

    constructor(){
        this.name = '';
        this.street = '';
        this.city_state = '';
        this.contact = '';
    }
}
export class  Footer{
    name: string;
    lastfour: string;
    payment_id: string;
    transaction_id: string;
    type: string
    phone: string;

    constructor(){
        this.name = '';
        this.lastfour = '';
        this.payment_id = '';
        this.transaction_id = '';
        this.type = '';
        this.phone = '';
    }
}


export class ReceiptData{
    items: Item[];
    header: Header;
    footer: Footer;
    order_reference: string;
    received_by: string;
    time: string;
    order_received: string;
    link: string;
    note: string;
    constructor(x?: ReceiptData){
        this.items = [];
        this.header = new Header();
        this.footer = new Footer();
        this.order_reference = '';
        this.received_by = '';
        this.time = new Date().toString();
        this.order_received = new Date().toString();
        this.link = '';
        this.note = '';
        if (x)
        {
            Object.assign(this, x);
        }
    }
}

export async function findStarPrinterDrawer() {
    try {
        const options = {
            filters: []
            //filters: []
        };
        let encoder = new EscPosEncoder();
        if(!paidImage){
            let img = new Image();
            img.src = paidImageSource;
            await img.decode();
            paidImage = encoder
                .image(img, 200, 104, 'atkinson')
                .encode();
        }
        if(!notPaidImage){
            let img = new Image();
            img.src = notPaidImageSource;
            await img.decode();
            notPaidImage = encoder
                    .image(img, 200, 88, 'atkinson')
                    .encode()
        }

        let device = await (navigator as any).usb.requestDevice(options);
        
        return device;
    } catch (ex) {
        console.log('ex 2', ex)
        return null;
    }
}

export async function printGenericReport(items: GenericItem[], title: GenericItem, totalLine: GenericItem, header: Header) {
    try{
        if (!starPrinter){
            starPrinter = await findStarPrinterDrawer();
        }
        
        if (!starPrinter)
            throw new Error('Not connected');

        let device  = await starPrinter.open()
        .then(() => starPrinter.selectConfiguration(1))
        .then(() => {
            starPrinter.configuration.interfaces.forEach( (interf: any) =>{
                
            });
            return starPrinter.claimInterface(starPrinter.configuration.interfaces[0].interfaceNumber);
        })
        .then(() => starPrinter);

        if(items){
            let encoder = new EscPosEncoder();
            let top = encoder
            .initialize()
            .newline()
            .align('center')
            .line(header.name)
            .size('small')
            .line(' ')
            .size('normal')
            .line(header.street)
            .size('small')
            .line(' ')
            .size('normal')
            .line(header.contact)
            .size('small')
            .line(' ')
            .size('normal')
            .newline()
            .align('left')
            .line('_______________________________________________')
            .size('small')
            .line(' ')
            .size('normal')
            .line(title.firstLine)
            .line(title.secondLine)
            .align('right')
            .line(title.rightLine)
            .align('left')
            .line('_______________________________________________')
            .encode()

            let middle = encoder.newline().encode();
            
            items.forEach(item =>{
                
                middle = encoder
                .raw(middle)
                .newline()
                .align('left')
                .bold()
                .line(item.firstLine)
                .bold()
                .line(item.secondLine)
                .align('right')
                .line(item.rightLine)
                .encode();
            });
            middle = encoder
                .raw(middle)
                .newline()
                .newline()
                .bold()
                .align('left')
                .line(totalLine.firstLine)
                .line(totalLine.secondLine)
                .align('right')
                .line(totalLine.rightLine)
                .bold()
                .align('center')
                .line('_______________________________________________')
                .align('left')
                .newline()
                .encode();
            let body = encoder
                .raw(top)
                .raw(middle)
                .align('center')
                .line('**')
                .line('THANK YOU!')
                .line('**')
                .newline()
                .newline()
                .newline()
                .newline()
                .newline()
                .newline()
                .cut('partial')
                .encode()

            await device.transferOut(1, body);
        }
        return true;
    }
    catch (exc){
        console.log(exc.message);
        starPrinter = null;
        return false;
    }
}

export async function printReport(items: DailyItem[], time: Date, header: Header) {
    try{
        if (!starPrinter){
            starPrinter = await findStarPrinterDrawer();
        }
        
        if (!starPrinter)
            throw new Error('Not connected');

        let device  = await starPrinter.open()
        .then(() => starPrinter.selectConfiguration(1))
        .then(() => {
            starPrinter.configuration.interfaces.forEach( (interf: any) =>{
                
            });
            return starPrinter.claimInterface(starPrinter.configuration.interfaces[0].interfaceNumber);
        })
        .then(() => starPrinter);

        if(items){
            items.filter(x => x.transaction_number == '-').forEach(x => x.transaction_number = null);

            let encoder = new EscPosEncoder();
            let top = encoder
            .initialize()
            .newline()
            .align('center')
            .line(header.name)
            .size('small')
            .line(' ')
            .size('normal')
            .line(header.street)
            .size('small')
            .line(' ')
            .size('normal')
            .line(header.contact)
            .size('small')
            .line(' ')
            .size('normal')
            .newline()
            .align('left')
            .line('_______________________________________________')
            .size('small')
            .line(' ')
            .size('normal')
            .line('PAYMENTS MADE ON')
            .line(time.toLocaleDateString())
            .line('_______________________________________________')
            .encode()

            let middle = encoder.newline().encode();
            items = items.sort((a, b) => (a.time > b.time) ? 1 : -1);

            //CREDIT CARDS
            if(items.find(x => x.transaction_number)){
                middle = encoder
                .raw(middle)
                .align('left')
                .size('normal')
                .line('CREDIT CARDS')
                .line('_______________________________________________')
                .newline()
                .encode();
                items.filter(x => x.transaction_number).forEach(item =>{
                    middle = encoder
                    .raw(middle)
                    .align('left')
                    .bold()
                    .line(`${item.payment_number} FOR ${item.order_number} (${item.customer_name})`)
                    .bold()
                    .line(`${new Date(item.time).toLocaleTimeString()}`)
                    .align('right')
                    .line(item.amount)
                    .encode();
                });
                middle = encoder
                    .raw(middle)
                    .newline()
                    .newline()
                    .bold()
                    .align('left')
                    .line('TOTAL')
                    .align('right')
                    .line(helper.FORMAT.USCurrency(items.filter(x => x.transaction_number).map(a => +(a.amount.toUpperCase().replace('$','').replace('VOIDED', '0.00'))).reduce((a, b) => a + b)))
                    .bold()
                    .align('center')
                    .line('_______________________________________________')
                    .align('left')
                    .newline()
                    .encode();
            }
            
            //CASH
            if(items.find(x => x.type == 'CASH')){
                middle = encoder
                .raw(middle)
                .align('left')
                .size('normal')
                .line('CASH')
                .line('_______________________________________________')
                .newline()
                .encode();
                items.filter(x => x.type == 'CASH').forEach(item =>{
                    middle = encoder
                    .raw(middle)
                    .align('left')
                    .bold()
                    .line(`${item.payment_number} FOR ${item.order_number} (${item.customer_name})`)
                    .bold()
                    .line(`${new Date(item.time).toLocaleTimeString()}`)
                    .align('right')
                    .line(item.amount)
                    .encode();
                });
                middle = encoder
                    .raw(middle)
                    .newline()
                    .newline()
                    .bold()
                    .align('left')
                    .line('TOTAL')
                    .align('right')
                    .line(helper.FORMAT.USCurrency(items.filter(x => x.type == 'CASH').map(a => +(a.amount.toUpperCase().replace('$','').replace('VOIDED', '0.00'))).reduce((a, b) => a + b)))
                    .bold()
                    .align('center')
                    .line('_______________________________________________')
                    .align('left')
                    .newline()
                    .encode();
            }

            //OTHER PAYMENTS
            if(items.find(x => !x.transaction_number && x.type != 'CASH')){
                middle = encoder
                .raw(middle)
                .align('left')
                .size('normal')
                .line('OTHER')
                .line('_______________________________________________')
                .newline()
                .encode();
                items.filter(x => !x.transaction_number && x.type != 'CASH').forEach(item =>{
                    middle = encoder
                    .raw(middle)
                    .align('left')
                    .bold()
                    .line(`${item.payment_number} FOR ${item.order_number} (${item.customer_name})`)
                    .bold()
                    .line(`${new Date(item.time).toLocaleTimeString()} (${item.type})`)
                    .align('right')
                    .line(item.amount)
                    .encode();
                });
                middle = encoder
                    .raw(middle)
                    .newline()
                    .newline()
                    .bold()
                    .align('left')
                    .line('TOTAL')
                    .align('right')
                    .line(helper.FORMAT.USCurrency(items.filter(x => !x.transaction_number && x.type != 'CASH').map(a => +(a.amount.toUpperCase().replace('$','').replace('VOIDED', '0.00'))).reduce((a, b) => a + b)))
                    .bold()
                    .align('center')
                    .line('_______________________________________________')
                    .align('left')
                    .encode();
            }

            middle = encoder
                .raw(middle)
                .newline()
                .newline()
                .bold()
                .align('left')
                .line('GRAND TOTAL')
                .align('right')
                .line(helper.FORMAT.USCurrency(items.map(a => +(a.amount.toUpperCase().replace('$','').replace('VOIDED', '0.00'))).reduce((a, b) => a + b)))
                .bold()
                .align('center')
                .line('_______________________________________________')
                .align('left')
                .newline()
                .encode();
            let body = encoder
                .raw(top)
                .raw(middle)
                .align('center')
                .line('**')
                .line('THANK YOU!')
                .line('**')
                .newline()
                .newline()
                .newline()
                .newline()
                .newline()
                .newline()
                .cut('partial')
                .encode()

            await device.transferOut(1, body);
        }
        return true;
    }
    catch (exc){
        console.log(exc.message);
        starPrinter = null;
        return false;
    }
}


export async function checkConnection(starPrinter: any) {
    try{
        if (!starPrinter){
            starPrinter = await findStarPrinterDrawer();
        }
        
        if (!starPrinter)
            throw new Error('Not connected');

        let device  = await starPrinter.open()
        .then(() => starPrinter.selectConfiguration(1))
        .then(() => {
            starPrinter.configuration.interfaces.forEach( (interf: any) =>{
                
            });
            return starPrinter.claimInterface(starPrinter.configuration.interfaces[0].interfaceNumber);
        })
        .then(() => starPrinter);

        return true;
    }
    catch (exc){
        console.log(exc.message);
        starPrinter = null;
        return false;
    }
}

export async function openDrawer(starPrinter: any) {
    try{
        if (!starPrinter){
            starPrinter = await findStarPrinterDrawer();
        }
        
        if (!starPrinter)
            throw new Error('Not connected');

        let device  = await starPrinter.open()
        .then(() => starPrinter.selectConfiguration(1))
        .then(() => {
            starPrinter.configuration.interfaces.forEach( (interf: any) =>{
                
            });
            return starPrinter.claimInterface(starPrinter.configuration.interfaces[0].interfaceNumber);
        })
        .then(() => starPrinter);

            

        await device.transferOut(1, openRegister);

        

        return true;
    }
    catch (exc){
        console.log(exc.message);
        starPrinter = null;
        return false;
    }
}

export async function checkPrinter() {
    if (!starPrinter){
        starPrinter = await findStarPrinterDrawer();
    }
    
    if (!starPrinter)
        throw new Error('Not connected');
}

export async function printReceipt(data: ReceiptData, openDrawer: boolean) {
    try{
        if (!starPrinter){
            starPrinter = await findStarPrinterDrawer();
        }
        
        if (!starPrinter)
            throw new Error('Not connected');

        let device  = await starPrinter.open()
        .then(() => starPrinter.selectConfiguration(1))
        .then(() => {
            
            return starPrinter.claimInterface(starPrinter.configuration.interfaces[0].interfaceNumber);
        })
        .catch((ex1) => null)
        .then(() => starPrinter);

        if(!device){
            throw new Error('Failed to communicate with the printer');
        }

        let encoder = new EscPosEncoder();
        if (data && data.items)
        {
            let items: any[] = data.items.sort((a, b) => (a.amount < b.amount) ? 1 : -1);
            let header = data.header;
            let footer = data.footer;
            let tickets = 'Tickets #: ' + data.items.map(x => 'T-' + x.ticket_id).filter((v, i, a) => a.indexOf(v) === i).join(', ');
            
            let top = encoder
            .newline()
            .align('center')
            .line(header.name)
            .line(' ')
            .line(header.street)
            .line(' ')
            .line(header.contact)
            .line(' ')
            .newline()
            .align('center')
            .line('_______________________________________________')
            .line(' ')
            .bold()
            .align('left')
            .line(data.footer.name)
            .line(data.footer.phone)
            .align('right')
            .line(moment(new Date(data.order_received)).format('dddd, M/D/YYYY'))
            .line(new Date(data.order_received).toLocaleTimeString())
            .bold()
            .align('left')
            .line('ORDER #: ' + data.order_reference)
            .line('RECEIVED BY: ' + data.received_by.toUpperCase())
            .line(tickets)
            .align('right')
            .line('SCHEDULED ON:')
            .bold()
            .line(moment(new Date(data.time)).format('dddd, M/D/YYYY hh:mm A'))
            .bold()
            .align('center')
            .line('_______________________________________________')
            .encode()

            let middle = encoder.newline().encode();
            
            items.forEach(item =>{
                
                middle = encoder
                .raw(middle)
                .align('left')
                .line(item.count + ' X ' + item.name.toUpperCase())
                .align('right')
                .line(helper.FORMAT.USCurrency(item.count * item.base_amount))
                .encode();
            });
            middle = encoder
                .raw(middle)
                .newline()
                .align('left')
                .line('TAX')
                .align('right')
                .line(helper.FORMAT.USCurrency(items.map(a => a.tax_amount * a.count).reduce((a, b) => a + b)))
                .newline()
                .newline()
                .align('left')
                .line('TOTAL')
                .align('right')
                .line(helper.FORMAT.USCurrency(items.map(a => a.amount).reduce((a, b) => a + b)))
                .align('center')
                .raw(((data.footer.type == 'NOT PAID') ? notPaidImage : paidImage) /*|| encoder.line('MISSING ASSET').encode()*/)
                .line('_______________________________________________')
                .align('left')
                .encode();

            if (data.note && data.note.length > 0){
                console.log('inside IF');
                middle = encoder
                .raw(middle)
                .line('NOTES:')
                .newline()
                .line(data.note)
                .align('center')
                .line('_______________________________________________')
                .align('left')
                .encode()
            }
            
            let bottom = encoder
                .align('left')
                .line(footer.name.toUpperCase())
                .align('right')
                .line(footer.lastfour.toUpperCase())
                .align('left')
                .line('PAYMENT TYPE')
                .align('right')
                .line(footer.type.toUpperCase())
                .align('left')
                .line('PAYMENT ID')
                .align('right')
                .line(footer.payment_id)
                .align('left')
                .line('TRANSACTION ID')
                .align('right')
                .line(footer.transaction_id)
                .newline()
                .align('center')
                .line('To View/Pay Online:')
                .qrcode(data.link)
                .encode()

            let body = encoder
            .raw(top)
            .raw(middle)
            .raw(bottom)
            .newline()
            .newline()
            .newline()
            .newline()
            .newline()
            .newline()
            .cut('partial')
            .encode()
            
            await device.transferOut(1, body);
        }

        if(openDrawer){
            await device.transferOut(1, openRegister);
        }

        await device.close();

        return true;
    }
    catch (exc){
        console.log('This exc', exc.message);
        starPrinter = null;
        return false;
    }
}

