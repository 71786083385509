import invoiceTypes from "./invoice.types";

export const setInvoices: any = invoices => ({
    type: invoiceTypes.FETCH_INVOICES,
    payload: invoices
});

export const fetchInvoicesStart: any = () => ({
    type: invoiceTypes.FETCH_INVOICES_START
});


export const setCustomerInvoices: any = invoices => ({
    type: invoiceTypes.FETCH_CUSTOMER_INVOICES,
    payload: invoices
});

export const fetchCustomerInvoicesStart: any = (customerId) => ({
    type: invoiceTypes.FETCH_CUSTOMER_INVOICES_START,
    customerId: +customerId
});
