import Table from "../../../components/Table/Table";
import IColumn from "../../../components/Table/models/IColumn";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import React from "react";
import Helper from "../../../Helper/Helper";
import IMenuOption from "../../../components/Table/models/IMenuOption";
import {fetchCustomerTableStart} from "../../../saga/Customer/customer.actions";
import ICONS from "../../../../assets/svg";


const CustomersTable = () => {
    const dispatch = useDispatch();
    const {customers, isLoading} = useSelector<any>(x => x.customersData) as any;
    const wasLoaded = useRef(false);
    const [rows, setRows] = useState([]);

    async function getCustomers() {
        return dispatch(fetchCustomerTableStart());
    }


    useEffect(() => {
        setRows(customers.map(customer => {
            return {
                ...customer,
                id: customer.id,
                customer: customer.name,
                open_orders: customer.orders_id && customer.orders_id.reduce((a, b) => {
                    return (a + (a && ", " || '') + (b && 'O-' + b || ''))
                }, ''),
                terms: customer.terms,
                current: customer.current,
                _31_60: customer._31_60,
                _61_90: customer._61_90,
                _91_: customer._91
            };
        }))
    }, [customers]);


    useEffect(() => {
        if (isLoading)
            wasLoaded.current = true;
    }, [isLoading]);

    
    const customerColumns: IColumn[] = [
        {orderBy: 'ASC', active: false, label: 'CUSTOMER', name: 'customer'},
        {orderBy: 'ASC', active: false, label: 'OPEN ORDERS', name: 'open_orders'},
        {orderBy: 'ASC', active: false, label: 'TERMS', name: 'terms'},
        {orderBy: 'ASC', active: false, label: 'CURRENT', name: 'current'},
        {orderBy: 'ASC', active: false, label: '31 - 60 DAYS', name: '_31_60'},
        {orderBy: 'ASC', active: false, label: '61 - 90 DAYS', name: '_61_90'},
        {orderBy: 'ASC', active: false, label: '91+ DAYS', name: '_91_'},
    ];

    const customerOptions: IMenuOption[] = [
        {
            label: 'See Customer Detail',
            icon: ICONS.IconUser,
            action: (row) => {
                Helper.Navigation.NavigateTo("/customer/" + row['id']);
            }
        }
    ];

    useEffect(() => {
        getCustomers().then();
    }, []);


    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <p className="font-14 fw-600">{customers?.length} Customers</p>
            </div>
            <Table 
            
                   loading={isLoading}
                   onDoubleClick={(row) => {
                    Helper.Navigation.NavigateTo("/customer/" + row['id']);
                   }}
                   menuOptions={customerOptions}
                   columns={customerColumns}
                   rows={rows}
                   tableHeight="65vh"
                   onRowClicked={() => {
                   }}/>
        </>
    );
}

export default CustomersTable;
