import React, {useEffect, useState} from 'react';
import {Ticket} from '../../../classes/classes';

interface ICustomTicketForm {
    onCancelClicked: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
    onSubmitted: (ticket: Ticket) => any;
    ticket: any
}

const CustomTicketForm = ({onCancelClicked, onSubmitted, ticket}: ICustomTicketForm) => {

    const [newTicket, setNewTicket] = useState<Ticket>(new Ticket());

    useEffect(() => {
        if(ticket){
            setNewTicket(ticket);
        }
    }, []);

    const saveTicket = (ticket) => {
        if (ticket) {
            onSubmitted(ticket);
        }
    };

    return (<form className='row'>
        <div className="form-group col-12 mb-3">
            <label htmlFor="">Ticket name*</label>
            <input value={newTicket.name || newTicket.name}
                   onChange={(evt) => setNewTicket({...newTicket, name: evt.target.value, label: evt.target.value})}
                   placeholder='item name' type="text" className="form-field"/>
        </div>

        <div className="form-group col-12 mb-3">
            <label htmlFor="">Cost</label>
            <input value={newTicket.cost || newTicket.cost}
                   onChange={(evt) => setNewTicket({...newTicket, cost: +evt.target.value})}
                   placeholder='$0.00' type="text" className="form-field"/>
        </div>

        <div className="d-flex justify-content-end mb-5">
            <button className="btn btn-clear mr-2" type='button' onClick={onCancelClicked}>Cancel</button>
            <button className="btn btn-orange" type='button' onClick={(evt) => saveTicket(newTicket)}>Save Ticket
            </button>
        </div>

    </form>)
}

export default CustomTicketForm;
