import {handleFetchCustomerInvoices, handleFetchInvoices} from "./invoice.helper";
import {all, call, put, takeLatest} from "redux-saga/effects";
import {setCustomerInvoices, setInvoices} from "./invoice.actions";
import invoiceTypes from "./invoice.types";

// Fetch invoice table

export function* fetchInvoices() {
    try {
        const invoices = yield handleFetchInvoices();
        yield put(setInvoices(invoices));
    } catch (err) {
        console.log(err);
    }
}

export function* onFetchInvoicesStart() {
    yield takeLatest(invoiceTypes.FETCH_INVOICES_START, fetchInvoices);
}


// Fetch customer invoices

export function* fetchCustomerInvoices({customerId}) {
    try {
        const invoices = yield handleFetchCustomerInvoices(customerId);
        yield put(setCustomerInvoices(invoices));
    } catch (err) {
        console.log(err);
    }
}

export function* onFetchCustomerInvoicesStart() {
    // @ts-ignore
    yield takeLatest(invoiceTypes.FETCH_CUSTOMER_INVOICES_START, fetchCustomerInvoices);
}

export default function* invoiceSagas() {
    yield all([
        call(onFetchInvoicesStart),
        call(onFetchCustomerInvoicesStart),
    ]);
}
