import {Material} from "../../../../../classes/classes";

// PROPS
export type ITypeMaterialPickerProps = {
    selectedMaterials: ITypePickerMaterialItem[] | Material[];
    onColorSelection: any;
}

export type ITypePickerMaterialItem = {
    id: number,
    material: string,
    count: number,
    selected: boolean
}

// STATE
export type ITypePickerMaterialState = {
    type: string;
    materialList: ITypePickerMaterialItem[] | Material[];
}

// ACTION
export type TypePickerAction = {
    type: string
    item: ITypePickerMaterialItem
}

export type DispatchType = (args: TypePickerAction) => TypePickerAction

// CONST
export const MATERIAL_LIST =
    [
        {
            id: 1,
            material: 'Cotton',
            count: 0,
            selected: false
        },
        {
            id: 2,
            material: 'Silk',
            count: 0,
            selected: false
        },
        {
            id: 3,
            material: 'Wool',
            count: 0,
            selected: false
        },
        {
            id: 4,
            material: 'Cashmere',
            count: 0,
            selected: false
        },
        {
            id: 5,
            material: 'Synthenic',
            count: 0,
            selected: false
        },
        {
            id: 6,
            material: 'Leather',
            count: 0,
            selected: false
        },
        {
            id: 7,
            material: 'Suede',
            count: 0,
            selected: false
        },
        {
            id: 8,
            material: 'Other',
            count: 0,
            selected: false
        },

    ];
