import React, { useState } from 'react';
import { Customer } from '../../../classes/classes';
import CustomerForm from '../../../components/Forms/CustomerForm/CustomerForm';

const CustomerInfo = ({customer, onCustomerEditClicked, onCustomerUpdated}: {customer: Customer, onCustomerEditClicked: (customer: Customer) => void, onCustomerUpdated: (customer: Customer) => void}) => {
    const [editMode, setEditMode] = useState(false);
    return (
            
            <div className='customer-info-component'>
                {editMode && <div className="modal">
                    <div className="modal-content col-6">
                        <CustomerForm customer={customer} onCancelClicked={(evt) => setEditMode(false)} onCustomerSubmitted={(evt) => {
                            setEditMode(false);
                            onCustomerUpdated(evt);
                        }} />
                    </div>            
                </div>}
                <div className='justify-content-between d-flex'>
                    <h6 className="title-text fw-400">Customer</h6>
                    <div className='d-flex flex-column'>
                        <button className='btn btn-orange-light'
                                onClick={(evt) => onCustomerEditClicked(customer)}>{customer.id == -1 ? 'Select' : 'Change'}
                        </button>
                        {customer.id != -1 && <button onClick={(evt) => {
                            setEditMode(true);
                        }} className="btn btn-clear mt-2 text-orange" style={{marginBottom: '-2rem'}}>Edit</button>}
                    </div>

                </div>
                <div>
                    <h6 className="regular-text mb-1">{customer.name}</h6>
                    <h6 className="regular-text fw-400">{customer.phone}</h6>
                    <h6 className="regular-text fw-400">{customer.email}</h6>
                </div>
        </div>)
}

export default CustomerInfo;