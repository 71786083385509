import { gql } from 'apollo-boost';
import React, { FC, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Service_charge, Tax } from '../../../classes/classes';
import { GET_TAXES } from '../../../graphql/query/Tax';
import Helper from '../../../Helper/Helper';
import { IServiceCharge } from '../../../models/interfaces';
import { GraphqlService } from '../../../services/graphql.service';

const mutation = gql`mutation($order_id: Int, $service_charge: add_service_charge) {
    update_order_service_charge(
      order_id: $order_id
      service_charge: $service_charge
    ) {
      id
      amount
      name
      base_amount
      tax_amount
      count
    }
  }`;

interface IProps {
    service_charge: Service_charge,
    onCancelClicked: (evt: any) => any,
    order_id: number,
    onSubmit: (data: Service_charge) => any
}

const ServiceChargeForm: FC<IProps> = (props) => {

    const edit = props.service_charge.name.trim() != '';

    const [charge, setCharge] = useState(new Service_charge())

    const [taxes, setTaxes] = useState<Tax[]>([]);

    const [isCharge, setIsCharge] = useState(true);

    async function saveCharge(charge: Service_charge) {
        try {
            const service_charge: IServiceCharge = {
                amount: charge.amount,
                tax_amount: charge.tax_amount,
                name: charge.name,
                base_amount: charge.base_amount,
                count: charge.count,
                id: charge.id
            }
            const data = await GraphqlService.SendMutation(mutation, {order_id: props.order_id, service_charge});

            const msg = charge.id == -1 || !charge.id ? 'Charge Created' : 'Charge Updated';

            Swal.fire({
                titleText: 'Success',
                text: msg,
                icon: 'success'
            })
            charge.id = data.id;
            props.onSubmit(charge);
        } catch (ex) {
            setError(ex.message);
        }
    }
    const [error, setError] = useState('');

    const loadTaxes = async () => {
        try {
            const data = await GraphqlService.SendQuery(GET_TAXES);
            setTaxes(data);
            console.log(props.service_charge.tax_amount, props.service_charge.count)
            const x = +((1 / ((props.service_charge.base_amount * props.service_charge.count) / props.service_charge.tax_amount)).toFixed(4)) * 100;
            console.log(x);
            console.log(data);
            const tax = data.find(y => y.amount == x);
            console.log(tax);
            setTax(new Tax(tax));
            
        } catch (ex) {
            console.log(ex.message)
        }
    }

    useEffect(() => {
        setIsCharge(props.service_charge.amount >= 0);
        setCharge(new Service_charge({...props.service_charge, base_amount: Math.abs(props.service_charge.base_amount), amount: Math.abs(props.service_charge.amount)}));
        console.log({edit})

        
    }, [props.service_charge]);

    useEffect(() => {
        loadTaxes();
    }, []);

    const [tax, setTax] = useState(new Tax({id: -1, name: '', active: false, amount: 0}));

    function handleSubmit() {
        setError('');
        // props.onSubmit(charge);
        const temp = new Service_charge(charge);

        temp.amount = +temp.amount;
        temp.base_amount = +temp.base_amount;
        temp.tax_amount = +temp.tax_amount;

        if (!isCharge)
        {
            temp.base_amount = -Math.abs(temp.base_amount);
            temp.amount = temp.base_amount;
            temp.tax_amount = 0;
            temp.count = 1;
        }
        

        // console.log(props.order_id)
        if (props.order_id == -1)
            return props.onSubmit(temp);
        saveCharge(temp);
            

    }

    const isValid = () => {
        return charge.name.trim().length > 0 && charge.count > 0;
    }

    function addCount(count ) {
        let nc = charge.count + count;

        // if (nc <= 0)
        //     nc = 1;

        // setCharge({...charge, count: nc});

        handleChange(nc.toString(), 'count');
    }

    function handleChange(value: string, key: string) {
        const temp = new Service_charge(charge);
        let _tax = new Tax(tax);
        
        if (key == 'base_amount')
        {
            temp.base_amount = value as any;
        }
        else if (key == 'count')
        {
            temp.count = +value;
        }
        else
        {
            _tax = taxes.find(x => x.id == value as any) || {id: -1, name: '-- Not selected --', amount: 0} as any;
            console.log(_tax);
            setTax(_tax)
        }
        const tax_amount = (+temp.base_amount * ((_tax.amount || 0) / 100)) * temp.count;
        // console.log(tax_amount)

        const amount = +temp.base_amount + tax_amount;

        temp.tax_amount = tax_amount;
        temp.amount = amount;

        setCharge(temp);
    }

    return (<form onSubmit={(evt) => {evt.preventDefault();
        evt.preventDefault();
        handleSubmit();
    }} className="d-flex flex-wrap mt-3">
    <div className="form-group col-6 pr-2 mb-3">
        <div className='d-flex justify-content-between'>
            <label className='mr-5' htmlFor="cname">Charge name* </label>
            <div className='d-flex'>
                <label onClick={(evt) => setIsCharge(true)} className={'cursor-pointer no-select fw-500' + (!isCharge ? '' : ' text-blue')} htmlFor="">Charge</label>
                <label onClick={(evt) => setIsCharge(false)} className={'ml-2 no-select cursor-pointer fw-500' + (isCharge ? '' : ' text-blue')} htmlFor="">Discount</label>
            </div>
        </div>
        <input id='cname' name='cname' value={charge.name} onChange={(evt) => setCharge({...charge, name: evt.target.value})} placeholder='Charge name' type="text" className="form-field"/>
    </div>
    <div className="form-group col-6 pl-2 mb-3">
        {isCharge && <>
            {<><label htmlFor="cname">Count*</label>
        <h4 className="title-text d-flex align-items-center"><button disabled={charge.count <= 0} type='button' onClick={(evt) => addCount(-1)} className='btn btn-orange-light'><i className="fas fa-minus"></i></button> <span className='d-inline-block mx-3'>{charge.count}</span> <button type='button' onClick={(evt) => addCount(1)} className='btn btn-orange-light mr-2'><i className="fas fa-plus"></i></button></h4></>}
        </>}
    </div>
    <div className="form-group col-6 px-0 mb-3">
        <label htmlFor="amount">Base Amount*</label>
        <input id='amount' name='amount' value={charge.base_amount} onChange={(evt) => handleChange(Helper.Masks.DecimalNumbers(evt.target.value), 'base_amount')} placeholder='00.00' type="text" className="form-field dollar-sign"/>
    </div>
    <div className="col-6"></div>
    {isCharge && <>
    <div className="form-group col-6 pr-2 mb-3">
        <label htmlFor="tax_amount">Tax*</label>
        {/* <input id='amount' name='amount' value={nService.amount} onChange={(evt) => setNService({...nService, amount: Helper.Masks.DecimalNumbers(evt.target.value) as any})} placeholder='00.00' type="text" className="form-field dollar-sign"/> */}
        <select name="tax_amount" id="tax_amount" className="form-field" value={tax.id} onChange={(evt) => handleChange(evt.target.value, 'tax')}>
            <option value={-1}>-- Not selected --</option>
            {taxes.map(t => <option value={t.id}>{t.name} {t.amount}%</option>)}
        </select>
    </div>

    <div className="form-group col-6 pl-2 mb-3">
        <label htmlFor="tax">Tax amount*</label>
        <input id='tax' name='tax' readOnly value={Helper.FORMAT.USCurrency((charge.tax_amount))}  style={{background: '#FFF'}} placeholder='00.00' type="text" className="form-field dollar-sign"/>
    </div>

    </>}
    {error && <p className='font-12 text-red'>{error}</p>}

    <div className="d-flex col-12 px-0 justify-content-between">
        
    <button type='button' className="btn btn-danger px-3 btn-block mt-3 mr-2" onClick={(evt) => props.onCancelClicked(evt)}>Cancel</button>
    <button className="btn btn-orange px-3 btn-block mt-3 ml-2" disabled={!isValid()}>{!edit ? 'Add' : 'Update'} charge</button>
    </div>

</form>)
}

export default ServiceChargeForm;