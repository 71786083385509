import moment from 'moment';
import React, { FC, useEffect, useRef, useState } from 'react';
import { start } from 'repl';
import Chart from '../../components/Chart/Chart';
import Helper from '../../Helper/Helper';
import { ISalesReportGrouped } from './ReportsPage';

interface IProp {
    start: Date,
    end: Date,
    dataSet: ISalesReportGrouped[]
}

const SalesComponent: FC<IProp> = ({start, end, dataSet}) => {
    const ref = useRef();

    const grapichParent = useRef<HTMLDivElement>();
    const [widthDounts, setWidthDounts] = useState(0);
    const [lineGraphFilter, setLineGraphFilter] = useState<string>('day');
    const [filteredDataSet, setFilteredDataSet] = useState<ISalesReportGrouped[]>([]);

    //filterDataSetForLineGraph(JSON.parse(JSON.stringify(dataSet)));

    const getByDay = (day = 0, count = true) => {
        return dataSet.filter(x => new Date(x.day).getDay() == day).sumBy(x => count? x.orders: x.amount);
    }

    const getByDayAndName = (day = 0, name: string, count = true) => {
        return dataSet.filter(x => new Date(x.day).getDay() == day).sumBy(x => count? x.data.find(y => y.name == name).count : x.data.find(y => y.name == name).amount);
    }
    
    function getWeek(day: Date){
        return `${moment(day).week()}-${new Date(moment(day).year())}`;
    }
    function getMonth(day: Date){
        return moment(day).format('MM-YYYY');
    }

    useEffect(() => {
        filterDataSetForLineGraph(JSON.parse(JSON.stringify(dataSet)));
    }, [lineGraphFilter, dataSet]);


    function filterDataSetForLineGraph(currentDataSet: ISalesReportGrouped[]){
        if(currentDataSet.length < 1) return;
        let temp: ISalesReportGrouped[] = [];
        let count = currentDataSet.length;
        let filter = count < 14 ? 'day' : lineGraphFilter == 'month' && count < 60 ? 'week' : lineGraphFilter;
        switch(filter){
            case 'week':
                let weekData: ISalesReportGrouped[] = JSON.parse(JSON.stringify(currentDataSet));
                for(let item of weekData){
                    if(temp.length == 0 || getWeek(item.day) != getWeek(temp[temp.length - 1].day)){
                        temp.push(item);
                    }
                    else{
                        let i = temp.length - 1;
                        temp[i].amount = temp[i].amount + item.amount;
                        temp[i].orders = temp[i].orders + item.orders;
                        temp[i].data.forEach(d => {
                            d.amount = d.amount + (item.data.find(x => x.name === d.name)?.amount || 0.00);
                            d.count = d.count + (item.data.find(x => x.name === d.name)?.count || 0.00);
                        });
                    }
                }
                break;
            case 'month':
                let monthData = JSON.parse(JSON.stringify(currentDataSet));
                for(let item of monthData){
                    if(temp.length == 0 || getMonth(item.day) != getMonth(temp[temp.length - 1].day)){
                        temp.push(item);
                    }
                    else{
                        let i = temp.length - 1;
                        temp[i].amount = temp[i].amount + item.amount;
                        temp[i].orders = temp[i].orders + item.orders;
                        temp[i].data.forEach(d => {
                            d.amount = d.amount + (item.data.find(x => x.name === d.name)?.amount || 0.00);
                            d.count = d.count + (item.data.find(x => x.name === d.name)?.count || 0.00);
                        });
                    }
                }
                break;
            default:
                temp = JSON.parse(JSON.stringify(currentDataSet));
                break;
        }
        setFilteredDataSet(temp);
        return temp;
    }

    const colors = ['#FE5F6D', '#FF8548', '#FFE61F', '#91E64B', '#17A8EB', '#76D6FF', '#BD68FF', '#00B74D', '#FDAD1A', '#0663CF', '#FF35B8', '#FF8ABF']

    function getColorForName(name: string){
        if(dataSet.length < 1) return '#D92434';

        let allNames = dataSet[0].data.map(x => x.name).sort();
        let index = allNames.indexOf(name);
        return index < 0 ? '#D92434' : colors[index];
    }
    
    return   <div className="flex-1-container">
    <div className='mx-auto' style={{maxWidth: '100%', width: '100%'}}>

        <div className="row col-12 align-items-start">
            <div className="col-7">
                <div className='row col-12 mb-3'>
                    <h4 style={{fontSize: '16px'}} className="mb-1 fw-400">From: {Helper.FORMAT.USDate(start, 'do')}</h4>
                    <h4 style={{fontSize: '16px'}} className="mb-2 fw-400">To: {Helper.FORMAT.USDate(end, 'do')}</h4>
                    <div className="row col-12">
                        <h5 className='col-6 pl-4' style={{fontSize: '40px'}}><sup style={{fontSize: '16px', fontWeight: 300}}>$</sup> <span className='fw-600'>{(dataSet.sumBy(x => x.amount))?.toCurrency('')}</span></h5>
                        <h5 className='col-3 pl-4' style={{fontSize: '40px'}}><sup style={{fontSize: '16px', fontWeight: 300}}>count</sup> <span className='fw-600'>{dataSet?.sumBy(x => x.orders) || 0}</span></h5>
                        {/* <div className="col-3 row">
                            <h4 style={{fontSize: '16px'}} className="mb-1 fw-400">Total Orders</h4>
                        </div> */}
                    </div>
                            
                    {dataSet.length > 0 && (
                    <div className="row mt-2">
                        {dataSet[0].data.sort((a, b) => a.name < b.name ? -1:1).map(t =>
                        (
                            <div className="col-3 row">
                                <h4 style={{fontSize: '16px'}} className="mb-1 mt-2 fw-400">{t.name}</h4>
                                <h5 className='pl-4' style={{fontSize: '32px'}}><sup style={{fontSize: '16px', fontWeight: 300}}>count</sup> <span className='fw-600'>{(dataSet.sumBy(x => x.data.find(d => d.name == t.name).count)) || 0}</span></h5>
                            </div>
                        ))}
                    </div>)
                    }
                </div>

                
                <div className='col-12'>
                    <div className='row col-12 mt-4' style={{alignItems: 'center', justifyContent: 'space-between'}}>
                        <div className='col-8' style={{alignItems: 'center', display: 'flex'}}>
                            <h4 style={{fontSize: '32px'}} className="mb-1 mt-2 fw-400 mr-2">Total per </h4>
                            <button className={'btn mx-2 btn-orange' + (lineGraphFilter == 'day' ? '' : '-outline')} onClick={()=>setLineGraphFilter('day')}>Day</button>
                            {dataSet.length >= 14 && <button className={'btn mx-2 btn-orange' + (lineGraphFilter == 'week' ? '' : '-outline')} onClick={()=>setLineGraphFilter('week')}>Week</button>}
                            {dataSet.length >= 60 && <button className={'btn mx-2 btn-orange' + (lineGraphFilter == 'month' ? '' : '-outline')} onClick={()=>setLineGraphFilter('month')}>Month</button>}
                        </div>
                    </div>
                <h4 style={{fontSize: '24px'}} className="mb-1 mt-2 fw-400 mr-2">Count</h4>
                <Chart
                    labels={filteredDataSet.map(x => moment(x.day).format('M/D/YY'))}
                    datasets={[
                        ... (filteredDataSet.length > 0 ? filteredDataSet[0].data.map(x => ({
                            label: x.name,
                            borderColor: getColorForName(x.name),
                            data: filteredDataSet.map(d => d.data.find(y => y.name == x.name).count)
                        })) : [])
                        ,{
                            label: 'Orders',
                            borderColor: '#D92434',
                            data: filteredDataSet.map(x => x.orders)
                        }
                    ]}
                    type={'line'} />
                <h4 style={{fontSize: '24px'}} className="mb-1 mt-2 fw-400 mr-2">Amount</h4>
                <Chart
                    labels={filteredDataSet.map(x => moment(x.day).format('M/D/YY'))}
                    datasets={[
                        ... (filteredDataSet.length > 0 ? filteredDataSet[0].data.map(x => ({
                            label: x.name,
                            borderColor: getColorForName(x.name),
                            data: filteredDataSet.map(d => d.data.find(y => y.name == x.name).amount)
                        })) : [])
                        ,{
                            label: 'Orders',
                            borderColor: '#D92434',
                            data: filteredDataSet.map(x => x.amount)
                        }
                    ]}
                    type={'line'} />
                </div>
                {dataSet.length > 0 && (
                    <div className='col-12 mt-4'>
                        <h4 style={{fontSize: '32px'}} className="mb-1 mt-2 fw-400 mr-2">Total count per day of the week</h4>
                        <Chart 
                            labels={[
                                'SUN',
                                'MON',
                                'TUE',
                                'WED',
                                'THU',
                                'FRI',
                                'SAT'
                            ]}
                            datasets={[
                                ...dataSet[0].data.map(x =>(
                                {
                                    label: x.name,
                                    data: [
                                        getByDayAndName(0, x.name),
                                        getByDayAndName(1, x.name),
                                        getByDayAndName(2, x.name),
                                        getByDayAndName(3, x.name),
                                        getByDayAndName(4, x.name),
                                        getByDayAndName(5, x.name),
                                        getByDayAndName(6, x.name)
                                    ],
                                    backgroundColor: getColorForName(x.name)
                                }
                            )),
                            {
                                label: 'Orders',
                                data: [
                                    getByDay(0),
                                    getByDay(1),
                                    getByDay(2),
                                    getByDay(3),
                                    getByDay(4),
                                    getByDay(5),
                                    getByDay(6)
                                ],
                                backgroundColor: '#D92434'
                            }]}
                            type={'bar'} />
                </div>
                )}
                {dataSet.length > 0 && (
                    <div className='col-12 mt-4'>
                        <h4 style={{fontSize: '32px'}} className="mb-1 mt-2 fw-400 mr-2">Total sum per day of the week</h4>
                        <Chart 
                            labels={[
                                'SUN',
                                'MON',
                                'TUE',
                                'WED',
                                'THU',
                                'FRI',
                                'SAT'
                            ]}
                            datasets={[
                                ...dataSet[0].data.map(x =>(
                                {
                                    label: x.name,
                                    data: [
                                        getByDayAndName(0, x.name, false),
                                        getByDayAndName(1, x.name, false),
                                        getByDayAndName(2, x.name, false),
                                        getByDayAndName(3, x.name, false),
                                        getByDayAndName(4, x.name, false),
                                        getByDayAndName(5, x.name, false),
                                        getByDayAndName(6, x.name, false)
                                    ],
                                    backgroundColor: getColorForName(x.name)
                                }
                            )),
                            {
                                label: 'Orders',
                                data: [
                                    getByDay(0, false),
                                    getByDay(1, false),
                                    getByDay(2, false),
                                    getByDay(3, false),
                                    getByDay(4, false),
                                    getByDay(5, false),
                                    getByDay(6, false)
                                ],
                                backgroundColor: '#D92434'
                            }]}
                            type={'bar'} />
                </div>
                )}

            </div>
            <div className="col-5">
                <div className='col-12'>
                    <h4 style={{fontSize: '32px'}} className="mb-1 fw-400 mr-2">Total Count per type of Service</h4>
                    <div className='p-5'>
                        <Chart 
                                    labels={(dataSet.length > 0 ? dataSet[0].data.map(x => x.name) : [])}
                                    datasets={
                                        (dataSet.length > 0 ?
                                        [
                                            {
                                                label: 'Count',
                                                backgroundColor: dataSet[0].data.map(c => getColorForName(c.name)),
                                                data: dataSet[0].data.map(x => dataSet.sumBy(d => d.data.find(y => y.name == x.name).count))
                                            }
                                        ] : [])
                                    }
                                    type={'doughnut'} /> 
                    </div>
                </div>
                <div className='col-12 mt-5'>
                    <h4 style={{fontSize: '32px'}} className="mb-1 fw-400 mr-2">Total Amount per type of Service</h4>
                    <div className='p-5'>
                    <Chart 
                                labels={(dataSet.length > 0 ? dataSet[0].data.map(x => x.name) : [])}
                                datasets={
                                    (dataSet.length > 0 ?
                                    [
                                        {
                                            label: 'Count',
                                            backgroundColor: dataSet[0].data.map(c => getColorForName(c.name)),
                                            data: dataSet[0].data.map(x => dataSet.sumBy(d => d.data.find(y => y.name == x.name).amount))
                                        }
                                    ] : [])
                                }
                                type={'doughnut'} />  
                </div>
                </div>
            </div>
        </div>

   

         
    </div>
 </div>
}

export default SalesComponent;