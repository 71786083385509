import Table from "../../../components/Table/Table";
import IColumn from "../../../components/Table/models/IColumn";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import AccountModal from "./AccountModal/AccountModal";
import Helper from "../../../Helper/Helper";
import {deleteUserStart, fetchUsersStart} from "../../../saga/User/user.actions";
import PasswordAccountModal from "./PasswordAccountModal/PasswordAccountModal";
import {ConfirmMessageModal} from "../../../components/ConfirmMessageModal/ConfirmMessageModal";
import ICONS from "../../../../assets/svg";

const mapState = ({usersData}) => ({
    users: usersData.users.filter(user => user.active).map(user => {
        return {
            ...user,
            username: user.employee ? [user.name, user.employee.name] : user.name,
            type: '-',
            address: '-',
            contact: '-',
            password: '********'
        }
    }),
    isLoading: usersData.isLoading
});

const Accounts = () => {
    const dispatch = useDispatch();

    const [selectedRow, setSelectedRow] = useState(null);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

    const accountColumns: IColumn[] = [
        { orderBy: 'ASC', active: false, label: 'USER / USERNAME', name: 'username' },
        { orderBy: 'ASC', active: false, label: 'TYPE', name: 'type' },
        { orderBy: 'ASC', active: false, label: 'ADDRESS', name: 'address' },
        { orderBy: 'ASC', active: false, label: 'CONTACT', name: 'contact' },
        { orderBy: 'ASC', active: false, label: 'PASSWORD', name: 'password' },
    ];

    const options = [
        {
            label: 'Change Password',
            icon: ICONS.IconPencilEdit,
            action: (event) => changePassword(event)
        },
        {
            label: 'Delete Account',
            icon: ICONS.IconDelete,
            action: (event) => deleteAccount(event)
        }
    ];

    useEffect(() => {
        getUsers().then();
    }, []);

    async function getUsers() {
        return dispatch(fetchUsersStart());
    }

    const {users = [], isLoading} = useSelector(mapState);

    const changePassword = (row) => {
        setSelectedRow(row);
        setShowChangePasswordModal(true);
    }

    const deleteAccount = (row) => {
        setShowConfirmModal(true);
        setSelectedRow(row);
    }

    const executeDeleteAccount = (wasConfirm) => {
        setShowConfirmModal(false);
        setSelectedRow(null);

        if (wasConfirm)
            dispatch(deleteUserStart(selectedRow));
    }

    async function loadData(event) {
        event.complete();
    }

    return (
        <>
            {
                showAddUserModal
                    ? <AccountModal onCloseModal={() => setShowAddUserModal(false)} />
                    : null
            }
            {
                showChangePasswordModal
                    ? <PasswordAccountModal user={selectedRow} onCloseModal={() => {
                        setShowChangePasswordModal(false); setSelectedRow(null);
                    }} />
                    : null
            }
            {
                showConfirmModal ?
                    <ConfirmMessageModal title={'Delete Account'}
                                         message={'Are you sure you want to delete this account?'}
                                         onCloseModal={ executeDeleteAccount } />
                    : null
            }
            <div className="card flex-1-container">
                <div className="card-body flex-1-container">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <p className="font-14">Account ({users?.length})</p>
                        <button className="btn btn-orange-light rounded-pill font-10"
                                onClick={() => setShowAddUserModal(true)}><i className="ti-plus"> </i> Add account</button>
                    </div>
                    <Table groupRows={true}
                           menuOptions={options}
                           columns={accountColumns}
                           rows={users}
                           tableHeight="30vh"
                           onRowClicked={() => {}}/>
                </div>
            </div>
        </>
    );
};

export default Accounts;
