import {all, call, put, takeLatest} from "redux-saga/effects";
import userType from "./user.type";
import {handleAddUser, handleChangePasswordUser, handleDeleteUser, handleFetchUsers} from "./user.helper";
import {fetchUsersStart, setUsers} from "./user.actions";

export function* fetchUsers() {
    try {
        const users = yield handleFetchUsers();
        yield put(setUsers(users));
    } catch (err) {
        console.log(err);
    }
}

export function* onFetchUsersStart() {
    yield takeLatest(userType.FETCH_USERS_START, fetchUsers)
}

export function* addUser({payload}) {
    try {
        yield handleAddUser({...payload});
        yield put(fetchUsersStart());
    } catch (err) {
        console.log(err);
    }
}

export function* deleteUser({payload}) {
    try {
        yield handleDeleteUser({...payload});
        yield put(fetchUsersStart());
    } catch (err) {
        console.log(err);
    }
}

export function* changePasswordUser({payload, newPassword}) {
    try {
        yield handleChangePasswordUser({...payload, oldPassword: payload.password, newPassword});
        yield put(fetchUsersStart());
    } catch (err) {
        console.log(err);
    }
}

export function* onAddUserStart() {
    // @ts-ignore
    yield takeLatest(userType.ADD_USER_START, addUser);
}

export function* onDeleteUserStart() {
    // @ts-ignore
    yield takeLatest(userType.DELETE_USER_START, deleteUser);
}

export function* onChangePasswordUserStart() {
    // @ts-ignore
    yield takeLatest(userType.CHANGE_PASSWORD_USER_START, changePasswordUser);
}

export default function* userSagas() {
    yield all([
        call(onFetchUsersStart),
        call(onAddUserStart),
        call(onDeleteUserStart),
        call(onChangePasswordUserStart)
    ])
}
