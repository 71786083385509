import {gql} from "apollo-boost";

export const ADD_TAX = gql(`mutation($tax: add_tax!) {
  create_tax(tax: $tax) {
    id
  }
}`);

export const UPDATE_TAX = gql(`mutation($tax: add_tax!, $id: Int!) {
  update_tax(tax: $tax, id: $id) {
    id
  }
}`);
