import { gql } from "apollo-boost";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { Order, Service, Service_charge } from "../../classes/classes";
import Helper from "../../Helper/Helper";
import { GraphqlService } from "../../services/graphql.service";
import ServiceChargeForm from "../Forms/ServiceForm/ServiceChargeForm";
import ServiceForm from "../Forms/ServiceForm/ServiceForm";

const mutation = gql`mutation ($service_charge_id: Int!){
    delete_service_charge(service_charge_id: $service_charge_id){
      success
      message
    }
  }`;

const OrderBreakdown = ({order, onChargesUpdated}: {order?: Order, onChargesUpdated}) => {

    const [open, setOpen] = useState(false);
    const modalRef = useRef<HTMLInputElement>();

    const [indexCharge, setIndexCharge] = useState<number>(null);
    const getTotal = (key: 'amount' | 'tax_amount' | 'base_amount' = 'amount') => {
        if (key == 'amount'){
            return order.tickets.sumBy(x => x.service_charges.sumBy(y => y.amount)) + order.service_charges.sumBy(x => x.amount);
        }
        else{
            return order.tickets.sumBy(x => x.service_charges.sumBy(y => y[key] * y.count)) + order.service_charges.sumBy(x => x[key] * x.count);
        }
    }

    const getTotalPaid = () => {
        return [...order.tickets.flatMap(x => x.service_charges), ...order.service_charges].filter(y => y.payment_id != null).sumBy(x => x.amount);
    }


    useEffect(() => {
        if (!open)
            setIndexCharge(null);

    }, [open]);
   
    async function deleteCharge(charge: Service_charge) {
        try {
            const data = await GraphqlService.SendMutation(mutation, {service_charge_id: charge.id});
            if (!data?.success)
                throw new Error(data?.message);
                const temp = new Order(JSON.parse(JSON.stringify(order)));
                
            Swal.fire({
                titleText: 'Success',
                text: data.message,
                icon: 'success'
            });
            const index = temp.service_charges.findIndex(x => x.id == charge.id);
            temp.service_charges.splice(index, 1);
            onChargesUpdated(temp);
        } catch (ex) {
            Swal.fire({
                titleText: 'Error',
                text: ex.message,
                icon: 'error'
            });
        }
    }

    const handleDeleteCharge = (index) => {
        if (order.id == -1 || !order.id)
        {
            const temp = new Order(JSON.parse(JSON.stringify(order)));
            temp.service_charges.splice(index, 1);
            return onChargesUpdated(temp);
        }
        const charge = order.service_charges[index];
        Swal.fire({
            text: `Delete charge ${charge.name}, you won't be able to revert this!`,
            titleText: `Are you sure?`,
            icon: 'question',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            showCancelButton: true
        })
        .then((d) => {
            if (d.isConfirmed)
                deleteCharge(charge);
        })
    }


    return (
        <>
         {open && <div className='modal'>
                <div className="modal-content col-8" ref={modalRef}>
                <div className="sticky-header pb-3">
                        <div className='sticky-header align-items-center pb-3 d-flex justify-content-between'>
                            <h6 className='form-title m-0 fw-600'>Create Service</h6>
                            <h6 className='form-title m-0 fw-600 cursor-pointer' onClick={(evt) => setOpen(false)}><i className="fas fa-times"></i></h6>
                            
                            
                        </div>
                    </div>
                    <div style={{maxHeight: '60vh', minHeight: '60vh', overflow: 'auto'}}>
                        
                        <ServiceChargeForm onCancelClicked={evt => setOpen(false)}
                        service_charge={indexCharge != null ? new Service_charge(order.service_charges[indexCharge]) : new Service_charge({...new Service_charge(), count: 1})}
                        order_id={order.id}
                        onSubmit={(evt) => {
                            const temp = new Order(JSON.parse(JSON.stringify(order)));
                            if (indexCharge != null)
                            {
                                temp.service_charges[indexCharge] = evt;
                            }
                            else
                            {
                                temp.service_charges.push(evt);
                            }

                            
                            setOpen(false);
                            onChargesUpdated(temp);
                        }} />    
                        
                        
                    </div>
                    
                    
                        
                </div>

            </div>}
            <div className="card">
                <div className="card-body">
                    <p className="font-14 mb-3">Breakdown</p>

                    <div className="row pb-3 mb-3 border-bottom">
                        {order.tickets.map(x => <div>
                            <h4 className="font-16 mb-1" style={{fontWeight: 600}}>{x.name}</h4>
                            {x.service_charges.map(sc => <div className="col-12 d-flex justify-content-between align-items-center pl-3">
                            <p className="mb-2 font-14 fw-600">{sc.count}x {sc.name}</p>
                            <p className="mb-2 font-14 fw-600">{Helper.FORMAT.USCurrency(sc.base_amount * sc.count)}</p>
                        </div>)}
                        </div>)}

                    </div>

                    <div className="row pb-3 mb-3 border-bottom">
                        <div className="d-flex justify-content-between">
                            <h4 className="font-16" style={{fontWeight: 600}}>Order Charges</h4>
                            <button className="btn btn-orange" onClick={(evt) => setOpen(true)}>ADD CHARGE</button>
                        </div>

                        {order.service_charges?.map((sc, index) => <div className="col-12 d-flex justify-content-between align-items-center pl-3 mt-2">
                            <div className="d-flex">
                                <h4 onClick={(evt) => {
                                    handleDeleteCharge(index)
                                }} className="font-14 cursor-pointer"><i className="fas fa-trash text-red"></i></h4>
                                <h4 onClick={(evt) => {
                                    setIndexCharge(index);
                                    setOpen(true);
                                }} className="font-14 cursor-pointer mx-2"><i className="fas fa-pen text-blue"></i></h4>
                                <p className="mb-2 font-14 fw-600">{sc.count}x {sc.name}</p>
                            </div>
                            <p className="mb-2 font-14 fw-600">{Helper.FORMAT.USCurrency(sc.base_amount * sc.count)}</p>
                        </div>)}
                        
                        
                    </div>

                    <div className="row pb-3 mb-3 border-bottom">
                        <div className="col-lg-9 col-md-9 col-sm-9 col-8 text-right">
                            <p className="mb-2 font-16">Subtotal</p>
                            <p className="mb-2 font-16">Tax</p>
                            <p className="mb-2 font-16">Total</p>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-3 col-4 text-right">
                            <p className="mb-2 font-16 fw-600">{Helper.FORMAT.USCurrency(getTotal('base_amount'))}</p>
                            <p className="mb-2 font-16 fw-600">{Helper.FORMAT.USCurrency(getTotal('tax_amount'))}</p>
                            <p className="mb-2 font-16 fw-600">{Helper.FORMAT.USCurrency(getTotal('amount'))}</p>
                        </div>
                    </div>

                   {order.id &&  <div className="row">
                        <div className="col-lg-9 col-md-9 col-sm-9 col-8 text-right">
                            <p className="mb-2 font-16">Total payment</p>
                            <p className="mb-2 font-16 text-red">Balance</p>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-3 col-4 text-right">
                            <p className="mb-2 font-16 fw-600">{Helper.FORMAT.USCurrency(getTotalPaid())}</p>
                            <p className="mb-2 font-16 fw-600 text-red">{Helper.FORMAT.USCurrency(order.amount_remaining)}</p>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    );
}

export default OrderBreakdown;
