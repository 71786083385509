import React, { useEffect, useRef, useState } from 'react';
import environments from '../../../../environment/environment';


import Helper from '../../../Helper/Helper';
import IPayInfo from './models/IPayInfo';




const CardInput = ({onPaymentSubmitted, submitText = '', onCancelClicked, zipMandatory = false}: {zipMandatory?: boolean, submitText?: string, onPaymentSubmitted: (payInfo: IPayInfo) => void, onCancelClicked?: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void}) => {

    const states = [
        {
          "name": "Alabama",
          "Abbrev": "Ala.",
          "code": "AL"
        },
        {
          "name": "Alaska",
          "Abbrev": "Alaska",
          "code": "AK"
        },
        {
          "name": "Arizona",
          "Abbrev": "Ariz.",
          "code": "AZ"
        },
        {
          "name": "Arkansas",
          "Abbrev": "Ark.",
          "code": "AR"
        },
        {
          "name": "California",
          "Abbrev": "Calif.",
          "code": "CA"
        },
        {
          "name": "Colorado",
          "Abbrev": "Colo.",
          "code": "CO"
        },
        {
          "name": "Connecticut",
          "Abbrev": "Conn.",
          "code": "CT"
        },
        {
          "name": "Delaware",
          "Abbrev": "Del.",
          "code": "DE"
        },
        {
          "name": "District of Columbia",
          "Abbrev": "D.C.",
          "code": "DC"
        },
        {
          "name": "Florida",
          "Abbrev": "Fla.",
          "code": "FL"
        },
        {
          "name": "Georgia",
          "Abbrev": "Ga.",
          "code": "GA"
        },
        {
          "name": "Hawaii",
          "Abbrev": "Hawaii",
          "code": "HI"
        },
        {
          "name": "Idaho",
          "Abbrev": "Idaho",
          "code": "ID"
        },
        {
          "name": "Illinois",
          "Abbrev": "Ill.",
          "code": "IL"
        },
        {
          "name": "Indiana",
          "Abbrev": "Ind.",
          "code": "IN"
        },
        {
          "name": "Iowa",
          "Abbrev": "Iowa",
          "code": "IA"
        },
        {
          "name": "Kansas",
          "Abbrev": "Kans.",
          "code": "KS"
        },
        {
          "name": "Kentucky",
          "Abbrev": "Ky.",
          "code": "KY"
        },
        {
          "name": "Louisiana",
          "Abbrev": "La.",
          "code": "LA"
        },
        {
          "name": "Maine",
          "Abbrev": "Maine",
          "code": "ME"
        },
        {
          "name": "Maryland",
          "Abbrev": "Md.",
          "code": "MD"
        },
        {
          "name": "Massachusetts",
          "Abbrev": "Mass.",
          "code": "MA"
        },
        {
          "name": "Michigan",
          "Abbrev": "Mich.",
          "code": "MI"
        },
        {
          "name": "Minnesota",
          "Abbrev": "Minn.",
          "code": "MN"
        },
        {
          "name": "Mississippi",
          "Abbrev": "Miss.",
          "code": "MS"
        },
        {
          "name": "Missouri",
          "Abbrev": "Mo.",
          "code": "MO"
        },
        {
          "name": "Montana",
          "Abbrev": "Mont.",
          "code": "MT"
        },
        {
          "name": "Nebraska",
          "Abbrev": "Nebr.",
          "code": "NE"
        },
        {
          "name": "Nevada",
          "Abbrev": "Nev.",
          "code": "NV"
        },
        {
          "name": "New Hampshire",
          "Abbrev": "N.H.",
          "code": "NH"
        },
        {
          "name": "New Jersey",
          "Abbrev": "N.J.",
          "code": "NJ"
        },
        {
          "name": "New Mexico",
          "Abbrev": "N.M.",
          "code": "NM"
        },
        {
          "name": "New York",
          "Abbrev": "N.Y.",
          "code": "NY"
        },
        {
          "name": "North Carolina",
          "Abbrev": "N.C.",
          "code": "NC"
        },
        {
          "name": "North Dakota",
          "Abbrev": "N.D.",
          "code": "ND"
        },
        {
          "name": "Ohio",
          "Abbrev": "Ohio",
          "code": "OH"
        },
        {
          "name": "Oklahoma",
          "Abbrev": "Okla.",
          "code": "OK"
        },
        {
          "name": "Oregon",
          "Abbrev": "Ore.",
          "code": "OR"
        },
        {
          "name": "Pennsylvania",
          "Abbrev": "Pa.",
          "code": "PA"
        },
        {
          "name": "Rhode Island",
          "Abbrev": "R.I.",
          "code": "RI"
        },
        {
          "name": "South Carolina",
          "Abbrev": "S.C.",
          "code": "SC"
        },
        {
          "name": "South Dakota",
          "Abbrev": "S.D.",
          "code": "SD"
        },
        {
          "name": "Tennessee",
          "Abbrev": "Tenn.",
          "code": "TN"
        },
        {
          "name": "Texas",
          "Abbrev": "Tex.",
          "code": "TX"
        },
        {
          "name": "Utah",
          "Abbrev": "Utah",
          "code": "UT"
        },
        {
          "name": "Vermont",
          "Abbrev": "Vt.",
          "code": "VT"
        },
        {
          "name": "Virginia",
          "Abbrev": "Va.",
          "code": "VA"
        },
        {
          "name": "Washington",
          "Abbrev": "Wash.",
          "code": "WA"
        },
        {
          "name": "West Virginia",
          "Abbrev": "W.Va.",
          "code": "WV"
        },
        {
          "name": "Wisconsin",
          "Abbrev": "Wis.",
          "code": "WI"
        },
        {
          "name": "Wyoming",
          "Abbrev": "Wyo.",
          "code": "WY"
        }
      ]
      


    const [payInfo, setPayInfo] = useState<IPayInfo>
    ({
        cardholder_name: '',
        city: '',
        cvv: '',
        exp: '',
        number: '',
        state: '',
        street: '',
        zip: '',
        email: '',
        phone: ''
    })

    const [valid, setValid] = useState(false);

    const currentPayInfo = useRef<IPayInfo>({
      cardholder_name: '',
      city: '',
      cvv: '',
      exp: '',
      number: '',
      state: '',
      street: '',
      zip: '',
      email: '',
      phone: ''
  });


    const formatExp = (value: string) => {
        const hasSlash = value.includes('/');
        value = value.trim().replace('/', '');
        let exp = '';
        
        for (let i = 0; i < value.length; i++)
        {

            
            const char = value.substring(i, i + 1);

            if (exp.length == 5)
                break;

            if (exp.length == 2)
            {
                exp += '/';
                if (char == '/')
                    continue;
            }
            
            
            if (char >='0' && char <='9')
                exp += char;
            
        }

        let month = exp.split('/')[0] || '';
        let year = exp.split('/')[1] || '';

        if (month.length == 1)
        {
            if (Number(month) > 1)
                month = '';
        }
        else if (month.length == 2)
        {
            if (Number(month) == 0)
                month = '0';
            if (Number(month) > 12)
                month = '0' + month.substring(1, 2);
        }




        return month + (hasSlash || year.length >= 1 ? '/' : '') + year;
    }


    const handleOnValueChanged = (value: string, key: string) => {

        let temp: any = {...payInfo};

        if (key == 'exp')
        {
            value = formatExp(value);
        }
        temp[key] = value;

        setPayInfo(temp);

    }


    const messageCallback = (evt: MessageEvent<any>) => {
        // console.log(evt)
        try {
          let data = evt.data && JSON.parse(evt.data);

          const temp = {...currentPayInfo.current};

          temp.number = data.message;
          setPayInfo(temp);
        } catch (ex) {
          
        }
    }

    useEffect(() => {
      window.addEventListener('message', messageCallback);

      return () => {
        window.removeEventListener('message', messageCallback);
      }
    }, []);

    useEffect(() => {
        
    


        setValid(payInfo.cardholder_name.trim() != '' && payInfo.cvv.trim().length >= 3 && payInfo.exp.trim().length == 5 && payInfo.number.trim() != '' && 
         (payInfo.zip.length == 0 && !zipMandatory? true : payInfo.zip.length == 5) && ((payInfo.email || '').length == 0? true : Helper.Validators.IsValidEmail(payInfo.email)) && ((payInfo.phone || '').length == 0? true : payInfo.phone.length == 12));
        
         currentPayInfo.current = payInfo;
        // console.log(input);
    }, [payInfo])

    
    return (
        <div className='d-flex flex-wrap justify-content-between flex-wrap mb-3'>
          {/* <h4 className='col-12 mb-3 subtitle-text mt-3'>RECEIPT INFORMATION</h4> */}
          <div className='col-12 px-2'>
            <label className='font-14 mb-2' htmlFor='email'>Send confirmation to</label>
            <div className='form-group col-12'>
                <input style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}} value={payInfo.email} onChange={(evt) => handleOnValueChanged(evt.target.value, 'email')} id='email' name='email' type="text" className="form-field" placeholder='abc@kyz.com' />
            </div>
            <div className='form-group col-12 mb-3'>
                <input style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}} value={payInfo.phone} onChange={(evt) => handleOnValueChanged(Helper.Masks.USPhone(evt.target.value), 'phone')} id='phone' name='phone' type="text" className="form-field" placeholder='000-000-0000' />
            </div>
          </div>
        {/* <h4 className='col-12 mb-3 subtitle-text'>BILLING INFORMATION</h4> */}
            <div className='px-2 col-12 d-flex justify-content-between flex-wrap mb-3'>
                
                <div className='form-group col-12 mb-3'>
                    {/* <p className='font-14'>Name on card</p> */}
                    <label className='font-14' htmlFor="cardholder">Name on card*</label>
                    <input value={payInfo.cardholder_name} onChange={(evt) => handleOnValueChanged(evt.target.value, 'cardholder_name')} id='cardholder' type="text" className="form-field" placeholder='John Doe' />
                </div>
                <div className='form-group col-12'>
                    
                  <label className='font-14' htmlFor="ccnumfield">Card info*</label>
                    <iframe id="tokenFrame"
                        style={{height: '40px', width: '100%', overflow: 'hidden'}}
                        name="tokenFrame"
                        // https://boltgw-uat.cardconnect.com/itoke/ajax-tokenizer.html
                        src={environments.IFRAME_LINK} frameBorder="0" scrolling="no"></iframe>
                </div>
                <div className="d-flex" style={{marginTop: '-10px', width: '100%'}}>
                  <div className='form-group pr-0 mb-3' style={{width: '50%'}}>
                      <input style={{borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0}} value={payInfo.exp} onChange={(evt) => handleOnValueChanged(evt.target.value, 'exp')} id='exp' type="text" className="form-field" placeholder='MM/YY' />
                  </div>
                  <div className='form-group pl-0 mb-3' style={{width: '50%'}}>
                      <input style={{borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0}} value={payInfo.cvv} onChange={(evt) => handleOnValueChanged(Helper.Masks.IntNumbers(evt.target.value, 4), 'cvv')} id='cvv' type="text" className="form-field" placeholder='CVC / CVV' />
                  </div>
                </div>
            </div>

            <div className='px-2 col-12 d-flex justify-content-between flex-wrap mb-3'>

              <label className='font-14' htmlFor="zip">Billing address</label>
              <div className="form-group" style={{width: '100%'}}>
                  <select value={'United States'} className='form-field' disabled style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}>
                    <option value="United States">United States</option>
                  </select>
              </div>
                <div className='form-group' style={{width: '50%'}} >
                      <input style={{borderRadius: 0}} value={payInfo.zip} onChange={(evt) => handleOnValueChanged(Helper.Masks.IntNumbers(evt.target.value, 5), 'zip')} id='zip' name='zip' type="text" className="form-field" placeholder='Zip' />
                      
                </div>
                <div className='form-group col-12' style={{width: '50%'}}>
                    <select style={{borderRadius: 0}} id='state' className="form-field" value={payInfo.state} onChange={(evt) => handleOnValueChanged(evt.target.value, 'state')}>
                        <option value="">State</option>
                        {states.map((state: any, i: number) => <option value={state.code}>{state.code}</option>)}
                    </select>
                </div>


                  <div className="form-group col-12">
                    <input style={{borderRadius: 0}} value={payInfo.street} onChange={(evt) => handleOnValueChanged(evt.target.value, 'street')} id='street' type="text" className="form-field" placeholder='Street' />
                  </div>
                

                <div className='form-group col-12'>
                  <input style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}} value={payInfo.city} onChange={(evt) => handleOnValueChanged(evt.target.value, 'city')} id='city' type="text" className="form-field" placeholder='City' />
                    
                </div>
                
                

                
            </div>

            <div className='d-flex justify-content-between col-12'>
                {onCancelClicked && <button type='button' className="btn font-18 col-6"  onClick={(evt) =>onCancelClicked(evt)}>CANCEL</button>}
                {/* {!onCancelClicked && <div />} */}
                <button type='button' className={"btn font-18 btn-gray col-" + (onCancelClicked ? '6' : '12')} disabled={!valid} onClick={(evt) => {
                  if (onPaymentSubmitted != null)
                    onPaymentSubmitted(payInfo);
                }}>{submitText || 'SUBMIT PAYMENT'}</button>
            </div>
        </div>
    )
}

export default CardInput;