import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import environments from '../../../../environment/environment';
import { Order } from '../../../classes/classes';
// import { Item, Master_order } from '../../../classes/classes';
import LoadSpinner from '../../../components/LoadSpinner/LoadSpinner';
import MessageBox from '../../../components/MessageBox/MessageBox';
import { GET_RECEIPT_BY_PAYMENT_ID } from '../../../graphql/query/Receipt';
// import PaymentsEndpoint from '../../../graphql/endpoints/payment.endpoint';
import Helper from '../../../Helper/Helper';
import AuthService from '../../../services/auth.service';
import { GraphqlService } from '../../../services/graphql.service';
import { printReceipt, ReceiptData } from '../../../services/printerDrawer';
// import {checkConnection, createReportBytes, printReceipt, printReceiptRemotely} from '../../../services/printerDrawer';

const CashForm = ({onCancelClicked, order, check = false}: {order: Order, check?: boolean, onCancelClicked: (evt: any) => void}) => {
    
    const [amount, setAmount] = useState(0);
    const [myAmount, setMyAmount] = useState<number>('' as any);
    const [cashReference, setCashReference] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [reference, setReference] = useState('');
    const [loading, setLoading] = useState(false);

    const getUnPaidCharges = (o = order) => {
        return [...o.tickets.flatMap(x => x.service_charges), ...o.service_charges].filter(x => x.payment_id == null)
    }
    useEffect(() => {
        // let t = order.tickets.flatMap(x => x.service_charges).filter(x => x.payment_id == null).sumBy(x => x.amount);
        setAmount(order.amount_remaining);
        setMyAmount(order.amount_remaining);
    }, []);

    function showMessage(title: string, message: string, reference: string){
        setTitle(title);
        setMessage(message);
        setReference(reference);
        setOpen(true);
    }

    useEffect(() => {
        
    }, []);
    return (
    <div className='cash-form-component flex-1-container'>
        {loading && <LoadSpinner />}
        <MessageBox isOpen={open} setIsOpen={setOpen} message={message} title={title} reference={reference} onConfirmClicked={(ref) => {
            if (ref == 'redirect')
                Helper.Navigation.NavigateTo('/');
        }} />
        <form className='col-12 d-flex justify-content-between flex-wrap flex-1-container'  onSubmit={async (evt) => {
        evt.preventDefault();
        
        try {
            setLoading(true);
                    
            // let data = await PaymentsEndpoint.registerTransaction(AuthService.REGISTER_ID, +myAmount, cashReference, +master.id, AuthService.USER.app_user.id);
            let id = await GraphqlService.SendMutation(gql`
                mutation($cashamount:Float $customerid:Int $paymentType:String  $orderid:Int  $referencetext:String $charges: [add_service_charge] $userid: Int){
                   post_cashpayment(cashamount:$cashamount customerid:$customerid orderid: $orderid referencetext:$referencetext charges:$charges userid:$userid paymentType: $paymentType)
                }
            `, {cashamount: +myAmount, paymentType: check ? 'CHECK' : 'CASH', userid: AuthService.USER.id, customerid: order.customer.id, orderid: order.id, referencetext: cashReference, charges: getUnPaidCharges().map(x => ({"id": x.id == -1 ? null : x.id, "name": x.name, "count": x.count, "amount": x.amount, "completed": false, "refund_id": null, "ticket_id": x.ticket_id, "payment_id": null, "service_id": null, "tax_amount": x.tax_amount, "base_amount": x.base_amount}))})
            
            
            setLoading(false);

            Helper.Receipt.ShowConfirmation(id, 2, true);
            Helper.Navigation.NavigateTo('/order/' + order.id);

        } catch (ex) {
            setLoading(false);
            showMessage('Error', ex.message, '');
        }
        // onAmountSubmitted(myAmount);
    }}>

            <div className="flex-1-container">
            <div className="form-group col-6 position-relative mb-4">
                    <label htmlFor="amount">AMOUNT RECEIVED*</label>
                    <span style={{position: 'absolute', bottom: '.405rem', fontSize: 'calc(1rem - 2px)'}} >$</span>
                    <input value={myAmount} onChange={(evt) => setMyAmount(Helper.Masks.DecimalNumbers(evt.target.value) as any)} name='amount' id='name' className='form-field' placeholder={Helper.Masks.ValidUSMoney(amount, true)} type="text"/>
                </div>

                <div className='col-12 mt-2'>

                    <div className={"col-6 position-relative form-group"}>

                        <label htmlFor="change" className={(myAmount - amount < 0 ? ' text-red' : '')}>CHANGE</label>
                        <input value={Helper.Masks.ValidUSMoney(Math.abs(+Number(myAmount - amount).toFixed(2)), true)} readOnly className={'form-field dollar-sign'  + (myAmount - amount < 0 ? ' text-red' : '')} placeholder={Helper.Masks.ValidUSMoney(amount, true)} type="text" style={{border: 'none'}}/>
                    </div>

                </div>

                <div className="form-group col-12 position-relative my-4">
                    <label htmlFor="reference">REFERENCE</label>
                    <input value={cashReference} onChange={(evt) => setCashReference(evt.target.value)} name='reference' id='reference' className='form-field' placeholder={'Reference...'} type="text"/>
                </div>
            </div>

            <button className="btn btn-orange btn-block mt-4" disabled={myAmount < amount} type='submit'>PROCESS PAYMENT</button>
            <button className="btn btn-orange-outline btn-block mt-2" type='button' onClick={onCancelClicked}>CANCEL</button>
        </form>
    </div>)
}

export default CashForm;