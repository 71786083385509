import * as crypto from 'crypto-js';

export function encrypt(data: any){
    return step_three(step_one(data), step_two());
}

function step_one(data: any){
    let x1 = JSON.stringify(data);
    return x1;
}

function step_two(){
    let key1 = '9' + randomHexString(7);
    return key1;
}

function step_three(x1: string, key1: string){
    let key2 = Number("0x"+key1).toString(2);
    let x2 = crypto.AES.encrypt(x1, key2).toString();
    return mesh(x2, key1);
}

function mesh(x2: string, key1: string){
    let res = '';
    let index: number = 4;
    for(let i = 0, k = 0; i< x2.length; i++){
        res += x2[i];
        if(i == index && k < key1.length){
            res += key1[k];
            k++;
            index += newSpacing(x2[i]);
        }
    }
    console.log({res, key1, x2});
    console.log({resLength: res.length, key1: key1.length, x2: x2.length});
    if(res.length != key1.length + x2.length){
        throw new Error("Data too short");
    }
    return res;
}

export function randomRegularString(length:number, exclude:string){
    var result = "";
    var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789qwertyuiopasdfghjkl ".split("");
    for (var i = 0; i < length; i++){
      var r=(Math.floor(Math.random() * chars.length));
      if(!exclude.includes(chars[r]))
          result+= chars[r];
      else
         i--;
    }

    return result;
}

function newSpacing(char: string){
    if ('qwertyuiopasdfghjklzxcvbnm'.includes(char)){
        return 3;
    }
    if('QWERTYUIOPASDFGHJKLZXCVBNM'.includes(char)){
        return 7;
    }
    if('1234567890'.includes(char)){
        return (+char + 1);
    }
    else{
        return 5;
    }
}

function randomHexString(length:number){
    var result = "";
    var chars = "0123456789ABCDEF".split("");
    for (var i = 0; i < length; i++){
      var r=(Math.floor(Math.random() * chars.length));
          result+= chars[r];
    }

    return result;
}
