import Helper from "../../../../Helper/Helper";
import {useDispatch} from "react-redux";
import {useState} from "react";
import {User} from "../../../../classes/classes";
import {addUserStart} from "../../../../saga/User/user.actions";
import ICONS from "../../../../../assets/svg";

const AccountModal = ({onCloseModal}) => {
    const dispatch = useDispatch();
    const [myUser, setMyUser] = useState(new User());
    const [showError, setShowError] = useState('');
    
    const closeModal = (event) => {
        event.stopPropagation();
        onCloseModal();
    };

    const saveUser = (event) => {
        event.stopPropagation();

        if (myUser.name == '') {
            setShowError('Username can not be empty');
            return;
        }
        if (myUser.password == '') {
            setShowError('Password can not be empty');
            return;
        }

        setShowError('');
        dispatch(addUserStart(myUser));
        onCloseModal();
    }
    
    return(
        <div className='modal-container'>
            <div className='modal-component'>
                <div className="modal-body">

                    <div className="modal-title">
                        <div className={'header'}>
                            <h6 className="title-text">Create User</h6>
                            <img src={ ICONS.IconCloseBig } onClick={closeModal} alt=""/>
                        </div>
                    </div>

                    <div className="body">
                        <form>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group mb-3">
                                        <label className="m-0">Username*</label>
                                        <input type="text"
                                               value={ myUser.name }
                                               className="form-field"
                                               placeholder="username"
                                               onChange={(event) => setMyUser({...myUser, name: event.target.value})}/>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-group mb-3">
                                        <label>Password*</label>
                                        <input type="password"
                                               value={ myUser.password }
                                               className="form-field"
                                               placeholder="XXXXXXXX"
                                               onChange={(event) => setMyUser({...myUser, password: event.target.value})}/>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <p className="text-red">{showError}</p>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <div className="row">
                            <div className="col-6">
                                <button className={'btn btn-clear btn-block rounded-pill'} onClick={closeModal}>Cancel</button>
                            </div>

                            <div className="col-6">
                                <button className={'btn btn-orange btn-block rounded-pill'}
                                        disabled={myUser.name == '' || myUser.password == ''}
                                        onClick={saveUser}>Save User</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountModal;
