const Status = ({statusName, className, onClick}: {statusName: string, className?: string, onClick?: (evt: any) => void}) => {

    // const getStatusBadge = () => {
    //     switch (statusName.toLowerCase().split(' ').join('_')) {
    //         case 'paid': return { className: 'badge badge-paid', label: 'PAID' };
    //         case 'not_paid': return { className: 'badge badge-not-paid', label: 'NOT PAID' };
    //         case 'pickedup': return { className: 'badge badge-picked-up', label: 'PICKED UP' };
    //         case 'in_progress': return { className: 'badge badge-in-progress', label: 'IN PROGRESS' };
    //         case 'not_pickedup': return { className: 'badge badge-not-picked-up', label: 'NOT PICKED UP' };
    //         case 'ready': return { className: 'badge badge-ready', label: 'READY' };
    //         default: return { className: 'badge badge-default', label: 'NOT DEFINED' };
    //     }
    // };

    return(
        <div onClick={(evt) => onClick && onClick(evt)} className={"badge badge-" + statusName?.toLowerCase().split(' ').join('-') + ' ' + className}>{statusName}</div>
        // <div className={ getStatusBadge().className }>{ getStatusBadge().label }</div>
    );
}

export default Status;
