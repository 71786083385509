export const GET_DAILY_REPORT_STARTED = 'GET_DAILY_REPORT_STARTED';
export const GET_DAILY_REPORT_SUCCESS = 'GET_DAILY_REPORT_SUCCESS';
export const GET_DAILY_REPORT_ERROR = 'GET_DAILY_REPORT_ERROR';

export const GET_TAKEINS_REPORT_STARTED = 'GET_TAKEINS_REPORT_STARTED';
export const GET_TAKEINS_REPORT_SUCCESS = 'GET_TAKEINS_REPORT_SUCCESS';
export const GET_TAKEINS_REPORT_ERROR = 'GET_TAKEINS_REPORT_ERROR';

export const GET_PICKUPS_REPORT_STARTED = 'GET_PICKUPS_REPORT_STARTED';
export const GET_PICKUPS_REPORT_SUCCESS = 'GET_PICKUPS_REPORT_SUCCESS';
export const GET_PICKUPS_REPORT_ERROR = 'GET_PICKUPS_REPORT_ERROR';
