import Helper from '../../Helper/Helper';
import React, { useEffect, useState } from 'react';

import './LoginPage.scss';
import environments from '../../../environment/environment';
import AuthService from '../../services/auth.service';
import { GraphqlService } from '../../services/graphql.service';
import { CHECK_AUTH } from '../../graphql/query/auth';
import ICONS from '../../../assets/svg';
import { createClient } from '../../config/graphql.client';

const LoginPage = () => {
    const [username, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [businessID, setBusinessID] = useState<string>('')
    const [errorText, setErrorText] = useState('');
    const [loading, setLoading] = useState(false);


    const check = async () => {
        try {
            await GraphqlService.SendQuery(CHECK_AUTH, null, true);
            Helper.Navigation.NavigateTo('/');
        } catch (ex) {
            
        }
    }

    
    const clear = () => {
        setUserName('')
        setPassword('')
        setBusinessID('')
    }


    const isValid = () => username.trim() && password.trim() && businessID.trim();


    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        
        let data = {
            username,
            password,
            code: businessID
        }


        if (!isValid() || loading) return;
        
        setLoading(true);
        fetch(environments.SERVER_LINK + '/auth', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({username, password, code: data.code})
        })
        .then(d => d.json())
        .then((result) => {
            if (result.success)
            {
                AuthService.TOKEN = result.token;
                
                localStorage.setItem('token', AuthService.TOKEN);
                GraphqlService.SetClient();
                Helper.Navigation.NavigateTo('/');
                setLoading(false);
            }
            else{
                setLoading(false);
                setErrorText(result.message)
            }
        })
        .catch(err => {
            setLoading(false)
            setErrorText(err.message)
        })


    }
    
    useEffect(() => {
        if (AuthService.TOKEN)
            check();
    }, [])

    return (
        <>
        <div className='login-container'>
            <h4>Log In</h4>

            <form onSubmit={handleSubmit.bind(this)}>
                <div className='input-group'>
                    <img src={ICONS.IconUser} className='left' />
                    <input value={username} onChange={(evt) => setUserName(evt.target.value)} id='name' name='username' placeholder='Username' />
                    {username != '' ? <img src={ICONS.IconDelete} className='right'  onClick={(evt) => setUserName('')} /> : null} 
                </div>
                <div className='input-group'>
                    <img src={ICONS.IconLocked} className='left' />
                    <input autoComplete='off' type='password' value={password} onChange={(evt) => setPassword(evt.target.value)} id='password' name='password' placeholder='Password' />
                    {password != '' ? <img src={ICONS.IconDelete} className='right'  onClick={(evt) => setPassword('')} /> : null} 
                </div>
                <div className='input-group'>
                    <img src={ICONS.IconBusiness} className='left' />
                    <input value={businessID} onChange={(evt) => setBusinessID(evt.target.value)} id='business_id' name='business_id' placeholder='Business ID' />
                    {businessID != '' ? <img src={ICONS.IconDelete} className='right' onClick={(evt) => setBusinessID('')} /> : null} 
                </div>

                {errorText && <p className='wrong'>{errorText}</p>}

                <button disabled={!isValid() || loading} type='submit'>LOG IN{loading && <span className='doing-login'>...<span></span></span>}</button>
              
            </form>
        </div>

      
        </>
    )
}

export default LoginPage;