import React, { useEffect, useState } from 'react';
import { Ticket } from '../../../classes/classes';
import Status from '../../../components/Status/Status';
import Helper from '../../../Helper/Helper';


interface ITicketsDetails
{
    tickets: Ticket[],
    onTicketSelected: (index: number) => any,
    onDeleteClicked: (index: number) => any,
    currentTicket: number,
    onAddMoreItemsClicked: (evt) => void
}

const TicketsDetails = ({tickets, onDeleteClicked, onTicketSelected, currentTicket, onAddMoreItemsClicked}: ITicketsDetails) => {

    const [mySelecteItem, setMySelectedItem] = useState(-1);

    useEffect(() => {
        if (currentTicket == -1)
            setMySelectedItem(-1);
        else
        {
            setMySelectedItem(currentTicket);
            onTicketSelected(currentTicket);
        }
            
    }, [currentTicket])

    return (<div className='tickets-details-component'>

        <div className="d-flex justify-content-between px-0">
            
        <h4 className="title-text fw-400">Tickets</h4>
            {mySelecteItem != -1 && <div className="col d-flex px-0 justify-content-end">
                <button onClick={(evt) => onDeleteClicked(currentTicket)} className="btn btn-danger-outline">Delete ticket</button>
            </div>}
        </div>


     {tickets?.map((t, i) => <div onClick={(evt) => {if(mySelecteItem == i) return; onTicketSelected(i); setMySelectedItem(i);}} key={'item-' + i} className={"row-outlined mt-3 " + (mySelecteItem == i? 'orange' : 'light')}>
            <div className='col-6 px-0'>
                <h4 className="title-text">{t.id == -1 ? '' : `"T-${t.id}" `}{t.name}</h4>
                {t.service_charges.map((sc, j) => <h6 key={'sc1-' + j} className="small-text">{sc.name}</h6>)}
            </div>
            <div className='col-6 px-0 d-flex justify-content-end'>
                <div>
                    <Status className='mb-2' statusName={t.status} />
                    <h4 className="title-text text-right">{Helper.FORMAT.USCurrency(t.service_charges.sumBy(x => x.amount))}</h4>
                </div>
            </div>

            <div className="col-6 px-0">
                <div className='d-flex flex-wrap mt-2'>
                        {t.description.colors.map((c, i) => <div key={'color-' + i} className='d-flex align-items-center mr-1 mb-2'>
                            <div className={"square-color " + c.color.toLowerCase()}></div>
                            <h6 className="small-text ml-1">{c.count}</h6>
                        </div>)}
                    </div>
            
            </div>
            
            <div className="col-6 px-0">
                <div className='d-flex flex-wrap mt-2 justify-content-end'>
                    {t.description.materials.map((m, i) => <h6 key={'material-' + i} className="small-text ml-2">{m.material} ({m.count})</h6>)}  
                </div>
            
            </div>
            
        </div>)}

        <div className="row-outlined mt-3 d-flex justify-content-start text-gray" onClick={onAddMoreItemsClicked}>
            <i className="fas fa-plus mr-2"></i>
            <h4 className="title-text fw-400">Add more tickets</h4>
        </div>
    </div>)
}

export default TicketsDetails;